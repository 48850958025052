import {
    React,Component, BootstrapTable, Redirect,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, useState, Tabs, Tab,
    Accordion
} 
from '../../noser-hris-component';

class PayrollFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            loadingText     :   "",
            
            payPeriodListAutocomplete   :   [],
            clientListAutocomplete      :   [],
            bdoBatchNo	                :   "",
            mlBatchNo	                :   "",
            mbBatchNo	                :   "",
            palawanBatchNo	            :   "",
            bdoDataList	                :   [],
            metroBankDataList	        :   [],
            mlListData	                :   [],
            palawanListData	            :   [],
            fileName	                :   [],
            payrollTypeId	            :   [],
            selectedClientId            :   "",
            payPeriodSelectedId         :   "",
            isDisableBDO                :   true,
            isDisableMetro              :   true,
            isDisableML                 :   true,
            isDisablePalawan            :   true,
            // filteredBatch               :   [],
            payrollDataArry             :   [],
            filteredBatch               :   [],
            batchNumber                 :   "",
            employeeDDL                 :   [],
            BatchLists                   :   [],
            ubBatchNo	                :   "",
            ubListData	                :   [],
            updateStatus                : "0"
        }
    }

    componentDidMount() {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();
    };
    getClientList(){
        this.setState({
            isloading   :   true
        })
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}

        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            sleep(1000).then(() => {
                this.setState({
                    clientListAutocomplete      :   res.data.clients ? res.data.clients     :   [],
                    // isloading                   :   false
                })
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDataGrid()
    };
    GetDataGrid = async() => {
       
        this.setState({isloading:true})
        let param = {
        "IpAddress"         :   "0.0.0.0",
        "ClientId"          :   "",
        "UserId"            :   "35954",
        "ProfileId"            :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;   
            // // console.log('data')
            // // console.log(data)
            let filterData = data.filter(x=>x.isFinalized==="1" && parseFloat(x.isClearanceStatus)>=8 && x.netPay>0)
            // // console.log("filterData")
            // // console.log(filterData)
            if(data.length > 0) {
                this.setState({
                    payrollDataArry : filterData.sort((a, b) => new Date(a.id) > new Date(b.id) ? 1 : -1),
                    _tmpData: filterData.sort((a, b) => new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1)
                })
            }
            let _filteredBatch = filterData.filter( x => x.batchPayroll !== "")
            
            let _BatchNumberDDL = _filteredBatch.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.batchPayroll === thing.batchPayroll
                ))
            )
            this.setState({
                filteredBatch    : _BatchNumberDDL.length>0?_BatchNumberDDL.sort((a, b) => a.batchPayroll > b.batchPayroll ? -1 : 1):[],
                // isloading   : false,
            });
            
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetEmployees()
    };
    GetEmployees = () => {
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   "0",
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList",  getParams)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            const data = res.data
            // // console.log("GetEmployeeList")
            // // console.log(data)
            this.setState({
                employeeDDL        :   data.employees,
                isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
    };
    GetPayPeriodList() {
        const periodListParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":"",
            "IsProcessed":"1"
         };
 
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  periodListParams
            )
            .then(res => {
                const data = res.data;
                // // console.log("Get Payroll Periods")
                // // console.log(data.payrollPeriods)
                this.setState({payPeriodListAutocomplete: data.payrollPeriods})
                //// // console.log("data.employees list count: " + this.state.employeeList.length)
            })
    };

    

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientId     =   ""
            this.state.selectedClientName   =   ""
            return
        } 
        this.state.selectedClientId         =   e[0].id
        this.state.selectedClientName       =   e[0].name
        this.GetPayPeriodList()
    };
    onChangePayPeriod = (e) => {

        if(e.length == 0) {
            this.state.payPeriodSelectedId = ""
            this.state.selectedPayPeriodName = ""
            return
        } 

        this.state.payPeriodSelectedId = e[0].periodId
        this.state.selectedPayPeriodName = e[0].payPeriod
    };
    onChangeBatchNumber = async(e) =>{
        // // // console.log(e)
        if(e.length === 0) {
            this.state.batchNumber   =   ""
        } else{
            this.state.batchNumber   =   e[0].batchPayroll
            this.setState({
                isshow : false
            })
        }
    };
    onChangeBDOBatchNo = (e) => {
        this.setState({
            bdoBatchNo	:   e.target.value
        })
    };
    onChangeMlBatchNo = (e) => {
        this.setState({
            mlBatchNo	:   e.target.value
        })
    };
    onChangeMbBatchNo = (e) => {
        this.setState({
            mbBatchNo	:   e.target.value
        })
    };
    onChangepalawanBatchNo = (e) => {
        this.setState({
            palawanBatchNo	:   e.target.value
        })
    };
    onChangeubBatchNo = (e) => {
        this.setState({
            ubBatchNo	:   e.target.value
        })
    };




    handleClickGenerate = (e) => {
        this.setState({isloading:true})

        let _fltrNegative = this.state.payrollDataArry.filter(x=>parseFloat(String(x.netPay).replace(',','')) > 0)
        let _generateArry = _fltrNegative.filter(item => item.batchPayroll === this.state.batchNumber)

        let _filterGenerated = _generateArry.filter( x => x.isCash === "0")

        let _data = []
        let payrolperiodid = ""
        let payrollPeriods = ""

        _filterGenerated.map(function(itm,i) {
            payrolperiodid = itm.payPeriodId
            payrollPeriods = itm.payrollPeriods
            let paycardid = ""
            if(itm.typeOfCashCardMember==="BDO"){
                paycardid = "2"
            }
            else if(itm.typeOfCashCardMember==="METROBANK"){
                paycardid = "6"
            }
            else if(itm.typeOfCashCardMember==="UNION BANK"){
                paycardid = "14"
            }
            else if(itm.typeOfCashCardMember==="M-LHUILLIER"){
                paycardid = "4"
            }
            else if(itm.typeOfCashCardMember==="PALAWAN"){
                paycardid = "15"
            }
            else { 
                console.log("eeror on paycard id")
            }
            _data.push({
                "ClearanceId":itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "employeeId":itm.employeeId,
                "PayCardTypeId":paycardid,
                "PayCardNumber":itm.cashCardNumberMember,
                "NetPay":String(parseFloat(itm.netPay).toFixed(2))
            })
        })
        
        let param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "PayPeriodId":payrolperiodid,
            "PayPeriod":payrollPeriods,
            "ReferenceNo":this.state.batchNumber,
            "BDOBatchNumber":this.state.bdoBatchNo,
            "MLBatchNumber":this.state.mlBatchNo,
            "MetroBankBatchNumber":this.state.mbBatchNo,
            "PalawanBatchNumber":this.state.palawanBatchNo,
            "UBBatchNumber":this.state.ubBatchNo,
            "clearancepayroll":_data
        };
        console.log('Final Generation param')
        console.log(param)
        axios 
        .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/GenerateClearancePayFile",  param)
            .then(res => {
            let data = res.data;
            console.log('data')
            console.log(data)
            if (data.status === "1"){
                this.setState({ 
                    isloading       :   false,
                    isshow : false
                })
                this.handleClickSearch();
                this.UpdateClearance()
            }
            if (data.status==="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    UpdateClearance = async(filterId) =>{
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let _fltrAttchment = this.state.payrollDataArry.filter( x => x.batchPayroll === this.state.batchNumber)
        
        let arrLst = []
        _fltrAttchment.forEach(itm => {
            let _userId = this.state.userinfo.userId
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.cashCardNumberMember,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,
                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": itm.isClearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,
                    
                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":_userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":_userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":_userId,

                "DateUpdatedByServices":itm.dateUpdatedByServices,
                "UpdatedByServices":_userId,

                "DateUpdatedByARE":itm.dateUpdatedByARE,
                "UpdatedByARE":itm.isUpdatedByARE,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":_userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": _userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":_userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":_userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":_userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":_userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":_userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":_userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":_userId,
                
                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,

                
                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : itm.lastSalary,
                "Incentives" : itm.incentives,
                "Sil" : itm.sil,
                "ThirteenthMonth" : itm.thirteenthMonth,
                "Inclusions" : itm.inclusions,
                "GrossPay" : itm.grossPay,
                "NetPay" : itm.netPay,
                "totalBalance" : itm.totalBalance,
                "PeriodCovered" : itm.periodCovered,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                "IsClearedSubmit" : "0",
                "IsGenerated" : /* this.state.updateStatus === "0" ?  */"1" /* : "0" */,
                "IsFinalized" : itm.isFinalized,
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : itm.batchPayroll,
                "Adjustment" : typeof itm.adjustment === "undefined" ? "" : itm.adjustment,
                "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : itm.otherPayroll,
                "Cbu" : typeof itm.cbu === "undefined" ? "" : itm.cbu,
                "Dividend" : typeof itm.dividend === "undefined" ? "" : itm.dividend,
                "TotalInclusion" : itm.totalInclusion,
                
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : String(itm.settlementFee),                
                "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                "ReviewStatus" : "2",
                "IsClearedSubmit" : "1",
                "IsAre" : "1",
                "IsPayroll" : "1",
                "FinancePayoutDate" : itm.financePayoutDate,
                "LastModifiedBy" : this.state.userinfo.fullName
                /* END */
            
            })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }
        console.log("update param")
        console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // this.setState({
            //     isloading   :   false,
            //     alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
            //     isshow      :   true,
            //     color       :   res.data.status=="1" ? "success" : "danger",
            //     message     :   data.message,
            //     fade        :   true
            // });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    handleClickSearch = () => {
        this.setState({isloading:true,updateStatus:"1"})
        console.log("this.state.batchNumber")
        console.log(this.state.batchNumber)
        if (this.state.batchNumber===""){
            console.log("if")
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select batch number!.",
                fade            :   true
            })
            return
        } 
        else{
            console.log("else")
            const params = {
                "IpAddress":"0.0.0.0",
                "ClientId":this.state.userinfo.clientId, // --> session
                "UserId":this.state.userinfo.userId,
                "PayPeriodId":"",
                "ReferenceNumber":this.state.batchNumber, // --> clearance reference number,
                "IsClearance":"1" // --> default 1
            }
    
            console.log("params")
            console.log(params)
            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/GetPayrollFiles", params)
            .then(res => {
                const data = res.data// .filter(x=>x.isClearanceStatus==="7" && x.isFinalized ==="1")
                console.log("data")
                console.log(data)
                // let fltrbybatch = data.filter(x=>x.batchNumber === this.state.batchNumber)
    
                this.setState({
                    bdoDataList         :   data.payrollFilesBDO,
                    metroBankDataList   :   data.payrollFilesMetroBank,
                    mlListData          :   data.payrollFilesML,
                    palawanListData     :   data.payrollFilesPalawan,
                    ubListData          :   data.payrollFilesUB,
                    isloading           :   false,
                })
                
                if(data.status=="0"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
                // this.UpdateClearance()
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        }
    };
    downloadHandleClick = (row) =>{
        this.state.fileName        =   row.fileName
        this.state.payrollTypeId   =   row.payrollTypeId

        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "PayrollTypeId" :   this.state.payrollTypeId,
            "FileName"      :   this.state.fileName
        }

        // // console.log("Start get params")
        // // console.log(getParams)
        // // console.log(" End get params")

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/DownloadPayrollFile", getParams,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', row.fileName);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    handleDeleteBDO = (e) => {
        this.setState({isloading:true,isDisable:true})
        
        let payrollfiles  = []     
        
        for (let i = 0; i < this.state.bdoDataList.length; i++) {
            if (this.state.bdoDataList[i]["isDeleted"] == 1) {
                let obj = this.state.bdoDataList[i]
                payrollfiles.push(obj);
            } 
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "payrollFiles":payrollfiles
        };


        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/DeletePayrollFiles", params)
            .then(res => {
                //this.refreshList();
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.handleClickSearch(e)
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    };
    handleDeleteMetro = (e) => {
        this.setState({isloading:true,isDisable:true})
        
        let payrollfiles  = []     
        
        for (let i = 0; i < this.state.metroBankDataList.length; i++) {
            if (this.state.metroBankDataList[i]["isDeleted"] == 1) {
                let obj = this.state.metroBankDataList[i]
                payrollfiles.push(obj);
            } 
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "payrollFiles":payrollfiles
        };


        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/DeletePayrollFiles", params)
            .then(res => {
                //this.refreshList();
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.handleClickSearch(e)
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    };
    handleDeleteML = (e) => {
        this.setState({isloading:true,isDisable:true})
        
        let payrollfiles  = []     
        
        for (let i = 0; i < this.state.mlListData.length; i++) {
            if (this.state.mlListData[i]["isDeleted"] == 1) {
                let obj = this.state.mlListData[i]
                payrollfiles.push(obj);
            } 
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "payrollFiles":payrollfiles
        };


        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/DeletePayrollFiles", params)
            .then(res => {
                //this.refreshList();
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.handleClickSearch(e)
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    }
    handleDeletePalawan = (e) => {
        this.setState({isloading:true,isDisable:true})
        
        let payrollfiles  = []     
        
        for (let i = 0; i < this.state.palawanListData.length; i++) {
            if (this.state.palawanListData[i]["isDeleted"] == 1) {
                let obj = this.state.palawanListData[i]
                payrollfiles.push(obj);
            } 
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "payrollFiles":payrollfiles
        };


        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/DeletePayrollFiles", params)
            .then(res => {
                //this.refreshList();
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.handleClickSearch(e)
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    };
    handleDeleteUB = (e) => {
        this.setState({isloading:true,isDisable:true})
        
        let payrollfiles  = []     
        
        for (let i = 0; i < this.state.ubListData.length; i++) {
            if (this.state.ubListData[i]["isDeleted"] == 1) {
                let obj = this.state.ubListData[i]
                payrollfiles.push(obj);
            } 
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "payrollFiles":payrollfiles
        };


        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/DeletePayrollFiles", params)
            .then(res => {
                //this.refreshList();
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.handleClickSearch(e)
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    }
    render(){
        const columnBDO = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            },
        ]

        const columnMetroBank = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            },
        ]


        const columnML = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            },
        ]
        const columnPalawan = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            },
        ]
        const columnUB = [
            // {
            //     text        :   'PERIOD',
            //     editable    :   false,
            //     dataField   :   'payPeriod',
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'20%',textAlign: 'left' }},
            //     style:{textAlign:'left'}
            // },
            // {
            //     text        :   "CLIENT NAME",
            //     editable    :   false,
            //     dataField   :   "client",
            //     headerStyle: (colum, colIndex) => {
            //         return { width:'28%' }},
            // },
            {
                text        :   'REFERENCE NUMBER',
                editable    :   false,
                dataField   :   'referenceNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   'BATCH NUMBER',
                editable    :   false,
                dataField   :   'batchNumber',
                headerStyle: (colum, colIndex) => {
                    return { width:'15%',textAlign: 'center' }},
                style:{textAlign:'center'}
            },
            {
                text        :   "TRANSACTION DATE",
                editable    :   false,
                dataField   :   "txnDate",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'center'}
                
            },
            {
                text        :   "TOTAL TXN",
                editable    :   false,
                dataField   :   "totalTxn",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "TOTAL AMOUNT",
                editable    :   false,
                dataField   :   "totalTxnAmount",
                headerStyle: (colum, colIndex) => {
                    return {width:'20%',textAlign: 'center' }},
                style:{textAlign:'right'}
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    this.state.fileNameLinkMetro = row.fileName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.downloadHandleClick(row)}
                        >download file</Button>
                    );
                },
                headerStyle : () => {
                    return { width  : "10%",textAlign: 'center' };
                },
                style:{textAlign:'center'}
            },
        ]


        
        const selectRowBDO = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableBDO=true
                this.state.bdoDataList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableBDO =  false
                })
                this.setState({isDisableBDO:isDisableBDO})
            }
        };
        const selectRowMetrobank = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableMetro=true
                this.state.metroBankDataList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableMetro =  false
                })
                this.setState({isDisableMetro:isDisableMetro})
            }
        };
        const selectRowML = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableML=true
                this.state.mlListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableML =  false
                })
                this.setState({isDisableML:isDisableML})
            }
        };
        const selectRowPalawan = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisablePalawan=true
                this.state.palawanListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisablePalawan =  false
                })
                this.setState({isDisablePalawan:isDisablePalawan})
            }
        };
        const selectRowUB = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToExpand: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisableUB=true
                this.state.ubListData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisableUB =  false
                })
                this.setState({isDisableUB:isDisableUB})
            }
        };

        const columnBDOExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
        ]

        const columnMetroBankExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
        ]

        const columnMLExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Reference Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Sender Last Name",
                editable    :   false,
                dataField   :   "senderLastName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Sender First Name",
                editable    :   false,
                dataField   :   "senderFirstName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Sender Middle Name",
                editable    :   false,
                dataField   :   "senderMiddleName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Sender Address",
                editable    :   false,
                dataField   :   "senderAddress",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Sender Contact No",
                editable    :   false,
                dataField   :   "senderContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Receiver Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Receiver Address",
                editable    :   false,
                dataField   :   "receiverAddress",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Receiver Contact No",
                editable    :   false,
                dataField   :   "receiverContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
        ]

        const columnPalawanExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Sender FN",
                editable    :   false,
                dataField   :   "senderLastName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Sender LN",
                editable    :   false,
                dataField   :   "senderFirstName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "Sender MPN",
                editable    :   false,
                dataField   :   "senderContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "To Branch",
                editable    :   false,
                dataField   :   "senderAddress",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                
            },
            {
                text        :   "First Name",
                editable    :   false,
                dataField   :   "receiverFirstName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Middle Initial",
                editable    :   false,
                dataField   :   "receiverMiddleName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Last Name",
                editable    :   false,
                dataField   :   "receiverLastName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "MPN",
                editable    :   false,
                dataField   :   "receiverContactNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%',whiteSpace:'nowrap' }},
                style:{whiteSpace:'nowrap'}
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
        ]
        const columnUBExpand = [
            {
                text        :   "PAYROLL REF NO",
                editable    :   false,
                dataField   :   "referenceNo",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Pay Card Number",
                editable    :   false,
                dataField   :   "payCardNumber",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
            {
                text        :   "Amount",
                editable    :   false,
                dataField   :   "amount",
                headerStyle: (colum, colIndex) => {
                    return {width:'15%' }},
                
            },
        ]
        const expandBDORowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.payrollFileDetails }
                    columns = { columnBDOExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                /> 
            ),
            showExpandColumn: true,
            
        };

        const expandMetroBankRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.payrollFileDetails }
                    columns = { columnMetroBankExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                //   rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                /> 
            ),
            showExpandColumn: true,
            
        };

        const expandMLRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.payrollFileDetails }
                    columns = { columnMLExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                /> 
            ),
            showExpandColumn: true,
            
        };

        const expandPalawanRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.payrollFileDetails }
                    columns = { columnPalawanExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                /> 
            ),
            showExpandColumn: true,
            
        };
        const expandUBRowDetails = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.payrollFileDetails }
                    columns = { columnUBExpand }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    // rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No Payroll details.</div> }
                /> 
            ),
            showExpandColumn: true,
            
        };
        let clearanceArray = [
            {
                dataField: 'batchNumber',
                text: 'BATCH NUMBER',
                editable: false,
                sort: true,
                headerStyle : () => {
                    return { /* width  : "7%" */ };
                }
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle : () => {
                    return { /* width  : "5%" */ };
                }
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE',
                editable: false,
                headerStyle : () => {
                    return { /* width  : "10% */ };
                }
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO.',
                editable: false,
                headerStyle : () => {
                    return { /* width  : "10% */ };
                }
            },
            {
                dataField: 'dateStart',
                text: 'DATE START',
                editable: false,
                headerStyle : () => {
                    return { /* width  : "6%" */ textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'lastWorkingDateMember',
                text: 'DATE END',
                editable: false,
                headerStyle : () => {
                    return { /* width  : "7%" */  textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'cashCardNumberMember',
                text: 'PAYCARD NUMBER',
                editable: false,
                headerStyle : () => {
                    return { /* width  : "6%" */ textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            // {
            //     dataField: 'paycardBatchNo',
            //     text: 'PAYCARD BATCH NO',
            //     editable: true,
            //     headerStyle : () => {
            //         return { width  : "5%",  textAlign : "right" };
            //     },
            //     style:{textAlign : "right"}
            // },
            {
                dataField: 'cbu',
                text: 'CBU',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"} //
            },
            {
                dataField: '',
                text: 'DIVIDEND',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },
            {
                dataField: 'lastSalary',
                text: 'LAST SALARY',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            {
                dataField: 'thirteenthMonth',
                text: '13TH MONTH',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            {
                dataField: 'sil',
                text: 'SIL',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            {
                dataField: 'separationPay',
                text: 'SEPARATION PAY',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            {
                dataField: 'incentives',
                text: 'INCENTIVES',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            {
                dataField: 'inclusions',
                text: 'INCLUSIONS',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            {
                dataField: 'grossPay',
                text: 'GROSS PAY',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            }, 
            {
                dataField: 'totalBalance',
                text: 'TOTAL DEDUCTIONS',
                editable: false,
                headerStyle : () => {
                    return { width  : "4%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            {
                dataField: 'netPay',
                text: 'NET PAY',
                editable: false,
                headerStyle : () => {
                    return { width  : "5%",  textAlign : "right" };
                },
                style:{textAlign : "right"}
            },  
            // {
            //     dataField: 'finalized',
            //     text: 'FINALIZED',
            //     editable: false,
            //     headerStyle : () => {
            //         return { width  : "5%",  textAlign : "right" };
            //     },// finalized
            //     style:{textAlign : "right"}
            // },  
            // {
            //     dataField: '',
            //     text: 'BREAKDOWN',
            //     editable: false,
            //     headerStyle: () => {
            //         return { width: "5%", };
            //     },
            //     formatter   :   (cell, row, isSelect) => {
            //         return (
            //             <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} /* disabled = {row.clearanceStatus === "9" ? true : false} */
            //                 variant="link" onClick={e => this.handleShowBreakdown(row)}
            //             >VIEW</Button>
            //         )
            //     },
            //     searchable: false
            // },
        ]
        return(
            <div>
                <Banner/>

                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; Payroll File</Card.Header>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formGridEmail">
                                <Col sm='6'>
                                    <Typeahead
                                        labelKey='batchPayroll'
                                        id="basic-example"
                                        onChange={this.onChangeBatchNumber}
                                        options={this.state.filteredBatch}
                                        placeholder="Select Batch Number"
                                        // multiple
                                        // clearButton
                                    />
                                </Col>
                                <Col sm='6'>
                                    <Typeahead
                                        ref={this.employee}
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder='SELECT EMPLOYEE'
                                        //defaultSelected={[this.state.employeeName]}
                                    />
                                </Col>
                            </Form.Group>
                            
                            <Form.Row>
                                <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="bdoBatchNo"
                                        value={this.state.bdoBatchNo}
                                        placeholder="BDO Batch No"
                                        onChange={this.onChangeBDOBatchNo}
                                        maxLength="3"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="mlBatchNo"
                                        value={this.state.mlBatchNo}
                                        placeholder="ML Batch No"
                                        onChange={this.onChangeMlBatchNo}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="mbBatchNo"
                                        value={this.state.mbBatchNo}
                                        placeholder="Metro Bank Batch No"
                                        onChange={this.onChangeMbBatchNo}
                                        maxLength="3"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="palawanBatchNo"
                                        value={this.state.palawanBatchNo}
                                        placeholder="PALAWAN Batch No"
                                        onChange={this.onChangepalawanBatchNo}
                                        maxLength="3"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="ubBatchNo"
                                        value={this.state.ubBatchNo}
                                        placeholder="UB Batch No"
                                        onChange={this.onChangeubBatchNo}
                                        maxLength="3"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar className="mt-2" sm={12}>
                                <Button className="ml-auto noser-button-mr1 noser-button" variant="success" onClick={this.handleClickSearch}>
                                    Search
                                </Button>
                                <Button variant="success" className="noser-button" onClick={this.handleClickGenerate}>
                                    Generate
                                </Button>
                            </ButtonToolbar>

                            <Tabs className="mt-3" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                <Tab eventKey="default" title="BDO">
                                    <Form.Row>
                                        <Form.Group controlId="formGridPassword" as={Col}>
                                            <Card className="card-tab-no-border">
                                                <div className="card-header-tab"></div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.bdoDataList }
                                                    columns = { columnBDO }
                                                    selectRow = { selectRowBDO }
                                                    striped
                                                    hover
                                                    condensed
                                                    expandRow={ expandBDORowDetails }
                                                    noDataIndication={ () => <div>No record found.</div> }
                                                />
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-5" sm={12}>
                                        <Button className="ml-auto noser-button" variant="success" onClick={this.handleDeleteBDO} disabled={this.state.isDisableBDO}>
                                            DELETE
                                        </Button>
                                    </ButtonToolbar>
                                </Tab>

                                <Tab eventKey="metrobank" title="METROBANK">
                                    <Form.Row>
                                        <Form.Group controlId="formGridPassword" as={Col}>
                                            <Card className="card-tab-no-border">
                                                <div className="card-header-tab"></div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.metroBankDataList }
                                                    columns = { columnMetroBank }
                                                    selectRow = { selectRowMetrobank }
                                                    striped
                                                    hover
                                                    condensed
                                                    expandRow={ expandMetroBankRowDetails }
                                                    noDataIndication={ () => <div>No record found.</div> }
                                                />
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-5" sm={12}>
                                        <Button className="ml-auto noser-button" variant="success" onClick={this.handleDeleteMetro} disabled={this.state.isDisableMetro}>
                                            DELETE
                                        </Button>
                                    </ButtonToolbar>
                                </Tab>

                                <Tab eventKey="ml" title="ML">
                                    <Form.Row>
                                        <Form.Group controlId="formGridPassword" as={Col}>
                                            <Card className="card-tab-no-border">
                                                <div className="card-header-tab"></div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.mlListData }
                                                    /* data = { this.state.mlListData } */
                                                    columns = { columnML }
                                                    selectRow = { selectRowML }
                                                    striped
                                                    hover
                                                    condensed
                                                    expandRow={ expandMLRowDetails }
                                                    noDataIndication={ () => <div>No record found.</div> }
                                                />
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-5" sm={12}>
                                        <Button className="ml-auto noser-button" variant="success" onClick={this.handleDeleteML} disabled={this.state.isDisableML}>
                                            DELETE
                                        </Button>
                                    </ButtonToolbar>
                                </Tab>

                                <Tab eventKey="palawan" title="PALAWAN">
                                    <Form.Row>
                                        <Form.Group controlId="formGridPassword" as={Col}>
                                            <Card className="card-tab-no-border">
                                                <div className="card-header-tab"></div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.palawanListData }
                                                    /* data = { this.state.mlListData } */
                                                    columns = { columnPalawan }
                                                    selectRow = { selectRowPalawan }
                                                    striped
                                                    hover
                                                    condensed
                                                    expandRow={ expandPalawanRowDetails }
                                                    noDataIndication={ () => <div>No record found.</div> }
                                                />
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-5" sm={12}>
                                        <Button className="ml-auto noser-button" variant="success" onClick={this.handleDeletePalawan} disabled={this.state.isDisablePalawan}>
                                            DELETE
                                        </Button>
                                    </ButtonToolbar>
                                </Tab>
                                <Tab eventKey="ub" title="UB">
                                    <Form.Row>
                                        <Form.Group controlId="formGridPassword" as={Col}>
                                            <Card className="card-tab-no-border">
                                                <div className="card-header-tab"></div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.ubListData }
                                                    columns = { columnUB }
                                                    selectRow = { selectRowUB }
                                                    striped
                                                    hover
                                                    condensed
                                                    expandRow={ expandUBRowDetails }
                                                    noDataIndication={ () => <div>No record found.</div> }
                                                />
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-5" sm={12}>
                                        <Button className="ml-auto noser-button" variant="success" onClick={this.handleDeleteUB} disabled={this.state.isDisableUB}>
                                            DELETE
                                        </Button>
                                    </ButtonToolbar>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}



export  default PayrollFile