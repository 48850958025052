import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import CreateDisengagementModeModal from './Modal/CreateDisengagementModeModal';

class DisengagementMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            dataGrid : [],
            disengagementModeDDL : [],
            disengagementModeId : "",
            disableDeleteBtn : true,
            disableUpdateBtn : true,
            arrList : [],
            openModal : false,

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }
    
    componentDidMount(){
        this.GetData();
    }

    GetData = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            const data = res.data.disengagementMode;
            console.log("DisengagementMode")
            console.log(data)
            this.setState({
                dataGrid    : data,
                isloading   : false,
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedDisengagementMode = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.disengagementModeId=""
            return
        } 
        this.state.disengagementModeId = e[0].id
    }
    handleSearchClick = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   this.state.disengagementModeId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            const data = res.data.disengagementMode;
            console.log("DisengagementMode")
            console.log(data)
            this.setState({
                dataGrid    : data,
                isloading   : false,
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleDeleteClick = async() => {
        this.setState({arrList:[],isloading:true})

        for (let i = 0; i < this.state.dataGrid.length; i++) {
            if (this.state.dataGrid[i]["isDeleted"] == 1) {
                const obj = {
                    "Id"        :   this.state.dataGrid[i]["id"],
                    "Name"      :   this.state.dataGrid[i]["name"],
                    "ModifiedBy":   this.state.userinfo.userId,
                    "IsDeleted" :   this.state.dataGrid[i]["isDeleted"].toString()
                };

                this.state.arrList.push(obj);
            }
        }
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "disengagementMode"   :   this.state.arrList,
        };
        console.log("delete param")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditDisengagementMode",  param)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   "Successfully Deleted!",
                    fade        :   true,
                    disableDeleteBtn : true,
                });
                this.GetData();
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleUpdateClick = async() => {
        this.setState({arrList:[],isloading:true})

        for (let i = 0; i < this.state.dataGrid.length; i++) {
            if (this.state.dataGrid[i]["isModified"] == 1) {
                const obj = {
                    "Id"        :   this.state.dataGrid[i]["id"],
                    "Name"      :   this.state.dataGrid[i]["name"].toUpperCase(),
                    "ModifiedBy":   this.state.userinfo.userId,
                    "IsDeleted" :   this.state.dataGrid[i]["isDeleted"].toString()
                };

                this.state.arrList.push(obj);
            }
        }
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "disengagementMode"   :   this.state.arrList,
        };
        console.log("edit param")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditDisengagementMode",  param)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   "Successfully Updated!",
                    fade        :   true,
                    disableUpdateBtn:true
                });
                this.GetData();
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.dataGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableUpdateBtn:disable})
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetData();
    }
    render(){
        const columns = [
            {
                dataField: 'name',
                text: 'Reason For Leaving',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"100%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"100%"},
                sort:true,
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.dataGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableDeleteBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>Maintenance >> Clearance >> Reason For Leaving</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Reason For Leaving
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedDisengagementMode}
                                            options={this.state.disengagementModeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-3">
                                    <Card.Header>List Of Reason For Leaving</Card.Header>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.dataGrid }
                                        columns = { columns }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div>No record found.</div> }
                                        //rowClasses="noser-table-row-class no-checkbox"
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                    />
                                </div>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button className="ml-auto noser-button-mr1" variant="danger" onClick={this.handleDeleteClick} disabled={this.state.disableDeleteBtn}>Delete</Button>
                                        <Button className="noser-button-mr1" variant="success" onClick={this.handleUpdateClick} disabled={this.state.disableUpdateBtn}>Update</Button>
                                        <Button className="noser-button" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
                <CreateDisengagementModeModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }

}

export  default DisengagementMode