import {
    React,Component, CipherString, Button, ButtonToolbar, Card, 
    Form, Col, axios, Alert, AppConfiguration,
    NoserLoading, Redirect, Container, NavLink
} 
from '../../noser-hris-component';

class Login extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            isloading:false,isshow:false,alerttype:"",message:"",color:"",fade:true,
            navigate: false,
            referrer: null,
            username: "",
            password: "",
            loginErrors: "",
            clientId:"",
            userId:"",
            employeeId:"",
            roleId:""
        };
    }
    componentDidMount(){
        sessionStorage.clear();
        //console.log('ds')
        console.log(CipherString('1'))
        //console.log(DeciperString('3QP9JB8lVouiuaZ7u9Mk86IBLem7mQOGvttbNJFY10RpYt6skgmrhQ=='))
    }
    handleChange = (e) =>{
        this.setState({[e.target.name]:e.target.value,isshow:false,message:'',fade:true,alerttype:'',color:''})
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }
    handleSubmit = async(e) =>
    {
        if(this.state.username.toString().trim().length==0 || this.state.password.toString().trim().length==0)
        {
            this.setState({isshow:true,alerttype:"Warning!",message:"User name or Password is required.",color:"warning",fade:true})
            return
        }
        this.setState({isloading:true})
        const params = {
            "UserName": this.state.username,
            "Password":CipherString(this.state.password)
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Authentication/AuthenticateUser", params)
            .then(res => {
                const data = res.data;
                this.setState({isloading:false})
                if(data.status=="1")
                {
                    sessionStorage.setItem("userData", JSON.stringify(data))
                    this.setState({clientId:data.clientId,userId:data.userId,employeeId:data.employeeId,roleId:data.roleId})
                    this.GetUserAccess()
                }
                else
                {
                    this.setState(
                        {
                            isloading:false,
                            alerttype:"Error!",
                            isshow:true,
                            color:"danger",
                            message: data.message,
                            fade:true
                        })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isloading:false,
                    alertType:"Error!",
                    show:true,
                    color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
                })
            })
    }
    GetUserAccess = async (e) =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userId,
            "EmployeeId":this.state.employeeId,
            "RoleId":this.state.roleId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetUserAccess", params)
        .then(res => {
          const data = res.data
          this.setState({
              isloading   :   false,
              alerttype   :   data.status=="1" ? "Success!" : "Error!",
              isshow      :   data.status=="1" ? false : true,
              color       :   data.status=="1" ? "success" : "danger",
              message     :   data.message,
              fade        :   true,
          });
          if(data.status=="1"){
            sessionStorage.setItem("userAccess", JSON.stringify(data.features))
            this.GetUserControlAccess()
            //sessionStorage.setItem("userControlAccess", JSON.stringify([]))
            this.setState({navigate: true,isloading:false})
          }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetUserControlAccess = async (e) =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userId,
            "EmployeeId":this.state.employeeId,
            "AccessCode":""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetUserControlAccess", params)
        .then(res => {
          const data = res.data
          console.log(data)
          this.setState({
              isloading   :   false,
              alerttype   :   data.status=="1" ? "Success!" : "Error!",
              isshow      :   data.status=="1" ? false : true,
              color       :   data.status=="1" ? "success" : "danger",
              message     :   data.message,
              fade        :   true,
          });
          if(data.status=="1"){
            
            sessionStorage.setItem("userControlAccess", JSON.stringify(data.userControls))
            this.setState({navigate: true,isloading:false})
          }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/home" push={true} />;
        } 
        return (
            <div className="login-frm">
                <Container className="mt-50">
                    <Card>
                        <Card.Header className='login-card'>PHR CLEARANCE</Card.Header>
                        <Card.Body>
                            <div>
                                <Form >
                                <Form.Group as={Col} controlId="formGridEmail">
                                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                            <div className={this.state.color}></div> 
                                            {this.state.message}
                                        </Alert>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Username"
                                        autoComplete="off" 
                                        name="username"
                                        value={this.state.username}
                                        onChange={this.handleChange}
                                        className="no-text-transform"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Password" 
                                        autoComplete="off" 
                                        name="password"
                                        value={this.state.password}
                                        onKeyDown={this.handleKeyDown}
                                        onChange={this.handleChange}
                                        className="no-text-transform"
                                    />
                                    <ButtonToolbar className="mt-3">
                                        <Button variant="success" style={{minWidth:'60px'}} className="lg-button" type="button" onClick={this.handleSubmit}>
                                            Login
                                        </Button>
                                    </ButtonToolbar>
                                </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                        <Card.Footer>PHR Clearance © 2019-2020. All Rights Reserved</Card.Footer>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        );
    }
    
}
export default Login;
