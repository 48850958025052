import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker
} 
from '../../noser-hris-component';
import {CSVLink, CSVDownload} from 'react-csv';
//import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { GetRequest, PostRequest } from '../../noser.dataaccess'
let ref = React.createRef();

class BatchClearanceAre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            openClientModal :   false,
            openPositionModal:  false,
            disabled        :   true,
            clientId        :   "",
            trainingId      :   "",
            trainingDDL     :   [],
            trainingGrid    :   [],
            clientDDL       :   [],
            employeeDDL     :   [],
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            gridDataExcel   :   [],
            isshowExpand    :   false,
            reasonDDL: [
                // {'id' : '', 'name' : 'Select Reason'},
                // {'id':'1','name':'Clearance'},
                // {'id':'2','name':'Clearance & Resignation'},
                // {'id':'3','name':'Dole Cases'}
            ],
            reasonId: "",
            reason: "",
            // reasonDDL: [
            //     {'id' : '', 'name' : 'Select Reason'},
            //     {'id':'1','name':'Clearance'},
            //     {'id':'2','name':'Clearance & Resignation'},
            //     {'id':'3','name':'Dole Cases'}
            // ],
            // reasonId: "",
            reason: "",
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            memberNotes:"",
            memberDDL:[],
            isCheckSSS:false,
            isCheckTIN:false,
            isCheckPHIC:false,
            isCheckHDMF:false,
            isCheckCashCard:false,
            isCheckValidId:false,
            isCheckResignation:false,
            isCheckTurnover:false,
            isCheckRequestForm:false,
            isRadioCashcard:false,
            radioCashCardDDL:[
                {'id' : '', 'name' : 'Select Type of Cash Card'},
                {'id' : '1', 'name' : 'BDO'},
                {'id' : '2', 'name' : 'UNION BANK'},
                {'id' : '3', 'name' : 'METROBANK'}
            ],
            cashCardType:"",
            accountNumber:"",
            isRadioCheck:false,
            isRadioMlhuillier:false,
            isRadioPalawan:false,
            cashCardField:true,
            memberRemarks:"",
            dateCreated: new Date(),
            dateSubmitted: "",
            clearanceStatus:"",
            // END MEMBER FIELDS

            clientRemarks:"",
            clientTabDDL:[],
            lrdRemarks:"",
            lrdTabDDL:[],
            rmtRemarks:"",
            rmtDDL:[],
            serviceTabDDL:[],
            servicesRemarks:"",
            attachedDDL:[],

            disabledButtonMember:true,
            disabledCleintButton:true,
            disabledLrdButton:true,
            disabledRmtButton:true,
            disabledServicesButton:true,
            dateRecieved : "",
            dateForwarded : "",
            notEligible : false,
            eligible : false,
            isClearanceStatus : "",
            files : [],
            daysCount : "0",
            cycleDays : "",

            //from edit details
            employeeName:"",
            fromDateCreated : "",
            toDateCreated : "",
            fromClearanceDate:"",
            toClearanceDate:"",
            fromDateReceived:"",
            toDateReceived:"",
            fromLastWorkingDate:"",
            toLastWorkingDate:"",
            statusId : "",
            statusDDL : [],
            toLastWorkingDate : "",
            fromLastWorkingDate : "",
            toDateReceived : "",
            fromDateReceived :"", 
            toClearanceDate : "",
            fromClearanceDate : "",
            toDateCreated : "",
            fromDateCreated : "",
            datenow : new Date(),
            batchNumber : "",
            filteredBatch : [],
            selectedId : [],
            _tmpData : [],
            dataListArr:[],
            collectionArray:[],

            chargesArray : [],
            totalBalanceArr : [],
            finalArrays:[],
            deductionEmptyArr : [],
            isDisabledButton : true,
            reviewDDL    :   [
                { "id" : "1", "name" : "YES" },
                { "id" : "0", "name" : "NO" },
            ],
            tblReviewedArrLst :[],
            selectedHeaderId:   [],
            isDisabledButtonDone : true,
            isDisabledButtonSubmit : true,
            tempArr :[],
            clearedDDL    :   [
                { "id" : "1", "name" : "YES" },
                { "id" : "0", "name" : "NO" },
            ],
            tblClearedArrLst :[],
            deductionListArray:[],
            deductionLength : [],
            dedArrLength : "0",
            exportDataGrid : [],
            submittedDateFromPayroll : "",
            submittedDateToPayroll : "",
            reviewStatusDDL    :   [
                { "id" : "0", "name" : "NOT-DONE" },
                { "id" : "1", "name" : "DONE" },
                { "id" : "2", "name" : "SUBMITTED" },
            ],
            reviewStatusId : "",
            remarksAre:"",
            profileId:"",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.GetReviewedList()
        this.GetClearedList()
        //this.GetDataGrid();
        //this.GetDeductionList();
        this.GetClient();
        
    }

    GetClient = async() =>{
        this.setState({isloading:true})
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            let data = res.data;
            // // ////////////////////////////////console.log("GetClient")
            // // ////////////////////////////////console.log(data)
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    ////////// // // //// // // // ////////////////////////////////console.log("access")
                    ////////// // // //// // // // ////////////////////////////////console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,})
            }
            else{
                this.setState({clientDDL : data.clients,})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployees();
   };

   handleChangeClient = (e) => {
       if(e.length == 0) {
           this.state.clientId     =   ""
           this.state.clientName   =   ""
           return
       }
       this.state.clientId     =   e[0].id
       this.state.clientName   =   e[0].name
        this.setState({
           isshow:false,
           
        })
       this.GetEmployees();
       // this.refBatch.current.clear()
   };

   GetEmployees = () => {
    this.setState({isloading:true})
        let param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }
        axios
        //.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  param)
        .then(res => {
            //let data = res.data
            const data = res.data.profiles;
            //////console.log("GetEmployees")
            //////console.log(data)
            this.setState({
                employeeDDL        :   data,
                //isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
        this.GetDisengagementMode();
    
    };

    handleChangeEmployee = (e) => {
        //////////////////////console.log(e)
        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.contractDateStart  =   ""
            this.state.phicNo = ""
            this.state.sssNo = ""
            this.state.tinNo = ""
            this.state.pagibigNo = ""
            // return
        } else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.phicNo       =   e[0].phicNumber
            this.state.sssNo        =   e[0].sssNumber
            this.state.tinNo        =   e[0].tinNumber
            this.state.pagibigNo    =   e[0].hdmfNumber
        }
        // // // //// // // // ////////////////////////////////console.log(e)
        this.setState({  })
        //this.getEmployeeClearance();
    };

    GetDisengagementMode = async() => {
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            let data = res.data.disengagementMode;
            //// // // //// // // // ////////////////////////////////console.log("DisengagementMode")
            //// // // //// // // // ////////////////////////////////console.log(data)
            this.setState({
                // isloading           : false,
                reasonDDL: data,
            });

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.getStatusClearance()
    };

    handleChangedReason = (e) => {
        if (e.length === 0){
            this.setState({ reason : "" })
        } else {
            this.setState({ reason : e[0].name })
        }
    }
    getStatusClearance = async() =>{
        let statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0040"
        };
        

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
        .then(res => {
            let data = res.data;
            // // // //// // // // ////////////////////////////////console.log('data')
            // // // //// // // // ////////////////////////////////console.log(data)
            this.setState({
                statusDDL : res.data.dataReferences
            })
                
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetBatchNumber();
    }
    handleChangeStatus = (e) =>{
        if (e.length === 0){
            this.state.statusId = ""
        } else {
            this.state.statusId = e[0].id
        }
    }
    handleChangeReviewStatus = (e) =>{
        if (e.length === 0){
            this.state.reviewStatusId = ""
        } else {
            this.state.reviewStatusId = e[0].id
        }
    }
    handleChangeFromDateCreated = date =>{
        this.setState({ fromDateCreated: date, isshow:false , })
    }
    handleChangeToDateCreated = date =>{
        this.setState({ toDateCreated: date, isshow:false , })
    }
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false})
    }
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false})
    }
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false})
    }
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false})
    }
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false})
    }
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false})
    }
    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false , })
    }
    

    GetBatchNumber = async() => {
        let submittedDateFromPayroll = this.state.submittedDateFromPayroll ? moment(this.state.submittedDateFromPayroll).format('MM/DD/YYYY') : ""
        let submittedDateToPayroll = this.state.submittedDateToPayroll ? moment(this.state.submittedDateToPayroll).format('MM/DD/YYYY') : ""
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   this.state.statusId,
            "FromDateCreated"       :   this.state.fromDateCreated,
            "ToDateCreated"         :   this.state.toDateCreated,
            "FromClearanceDate"     :   this.state.fromClearanceDate,
            "ToClearanceDate"       :   this.state.toClearanceDate,
            "FromDateReceive"       :   this.state.fromDateReceived,
            "ToDateReceive"         :   this.state.toDateReceived,
            "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
            "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
            "SubmittedDateFromPayroll"   :   submittedDateFromPayroll,
            "SubmittedDateToPayroll"     :  submittedDateToPayroll,
            "ReviewStatusId"     :   this.state.reviewStatusId,
        };
        ////console.log("param 01")
        ////console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance.filter( x => x.isClearanceStatus === "6");
            let _dataTmp = res.data.clearance;
            let userinfo = this.state.userinfo.userId            
            //let filterData = data.filter( x => x.isClearanceStatus === "6" || x.isPayroll === "1")
            let filterData = data.filter( x => x.isClearanceStatus === "6")
            let _filteredBatch = filterData.filter( x => x.batchNumber !== "")
            let _BatchNumberDDL = _filteredBatch.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.batchNumber === thing.batchNumber
                ))
            )
            //////////////////////////////console.log("_BatchNumberDDL")
            //////////////////////////////console.log(_BatchNumberDDL)
            this.setState({
                filteredBatch    : _BatchNumberDDL,
            });
            
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetData();
        //this.GetData();
    };
    handleChangedBatchNumber = async(e) =>{
        if(e.length == 0) {
            this.state.batchNumber   =   ""
            this.setState({selectedHeaderId : [], batchNumber : "", isshowExpand: false,})
            this.GetData();
        } else{
            this.state.batchNumber   =   e[0].batchNumber
            this.setState({
                isshow:false,
            })
        }
    }

    GetReviewedList() {

        for (let i = 0; i < this.state.reviewDDL.length; i++) {
            const obj = {
                value : this.state.reviewDDL[i]["id"],
                label : this.state.reviewDDL[i]["name"],
            };
            this.state.tblReviewedArrLst.push(obj);
        }
    }

    GetClearedList() {

        for (let i = 0; i < this.state.clearedDDL.length; i++) {
            const obj = {
                value : this.state.clearedDDL[i]["id"],
                label : this.state.clearedDDL[i]["name"],
            };
            this.state.tblClearedArrLst.push(obj);
        }
    }

    GetData = async() => {
        this.setState({
            finalArrays:[],
            //isloading:true
        })
        let submittedDateFromPayroll = this.state.submittedDateFromPayroll ? moment(this.state.submittedDateFromPayroll).format('MM/DD/YYYY') : ""
        let submittedDateToPayroll = this.state.submittedDateToPayroll ? moment(this.state.submittedDateToPayroll).format('MM/DD/YYYY') : ""
        let arrLst = []
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   this.state.statusId,
            "FromDateCreated"       :   this.state.fromDateCreated,
            "ToDateCreated"         :   this.state.toDateCreated,
            "FromClearanceDate"     :   this.state.fromClearanceDate,
            "ToClearanceDate"       :   this.state.toClearanceDate,
            "FromDateReceive"       :   this.state.fromDateReceived,
            "ToDateReceive"         :   this.state.toDateReceived,
            "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
            "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
            "SubmittedDateFromPayroll"   :   submittedDateFromPayroll,
            "SubmittedDateToPayroll"     :  submittedDateToPayroll,
            "ReviewStatusId"     :   this.state.reviewStatusId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            //////console.log("GetClearance")
            //////console.log(data)
            //let filterData = data.filter( x => x.isClearanceStatus === "6" || x.isPayroll === "1")
            let data = res.data.clearance.filter( x => x.isClearanceStatus === "6");
            ////console.log("GetClearance")
            ////console.log(data)
            //let data = res.data.clearance.filter ( x => x.isClearanceStatus === "6");
            // data.forEach(itm => {
            // });
            //for(let x = 0; x < data.length; x++){
                this.GetSubsidiaryLedgerStaging(data)
            //}
            
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    };

    GetSubsidiaryLedgerStaging = async(data) =>{
        //let tempArr = []
        ////console.log("SL data")
        ////console.log(data)
        let temporaryArr = []
        //let deductionLength = "0"
        for(let x = 0; x < data.length; x++) {
            let resp = await GetRequest({
                url : AppConfiguration.Setting().noserareapi + "action/get",
                params : {
                    "dbname"      :  AppConfiguration.Setting().noseraredb,
                    "_collection" : "SubsidiaryLedgerStaging",
                    "filter_data" : {"isDeleted" : '0', "employeeNo" : data[x]["employeeNo"]}
                }    
            })
            ////console.log("SL resp")
            ////console.log(resp)
            if(resp.status==="1") {
                let data_ = resp.ResultList.filter(x => x.deductionId !== "3" && x.deductionId !== "5" && x.deductionId !== "9" && x.deductionId !== "51" && x.deductionId !== "55" && x.deductionId !== "141" && x.deductionId !== "142")
                // data_ = resp.SubsidiaryLedgerStaging
                ////////console.log("SubsidiaryLedgerStaging")
                ////////console.log("SubsidiaryLedgerStaging")
                //console.log("data__")
                //console.log(data_)
                let deductionArr = []
                let client = ''
                let clientArr = []
                let totalBalance = "0"
                let gridArry = []
                data_.reduce(function(res, value) {
                if (!res[value.deduction]) {
                    res[value.deduction] = {
                        id: value.id,
                        deduction: value.deduction, 
                        debit: 0, 
                        credit: 0, 
                        employeeName: value.employeeName, 
                        employeeNo: value.employeeNo, 
                        groupName: value.groupName, 
                        deductionId: value.deductionId, 
                        deductionType: value.deductionType, 
                        deductionTypeId: value.deductionTypeId, 
                        client: value.client, 
                        priority: value.priority, 
                        docNo: value.docNo, 

                        // isInclusion: value.isInclusion, 
                        // inclusion: value.inclusion, 
                        // isMF: value.isMF, 
                        // mf: value.mf, 
                        // //priority: value.priority, 
                        // series: value.series, 
                        // referenceNo: value.referenceNo, 
                        // scheddeduction: value.scheddeduction, 
                        // transactionDate: value.transactionDate, 
                        // collectionDate: (value.collectionDate) ? value.collectionDate : "", 
                        // dateStart:  (value.dateStart) ? value.dateStart : "", 
                        // payOutDate: (value.payOutDate) ? value.payOutDate : "", 
                        // payrollDate: value.payrollDate, 
                        // deductionStatus: value.deductionStatus, 
                        // payMode: value.payMode, 
                        // cutOffDate: value.cutOffDate, 
                        // numberOfDeduction: value.numberOfDeduction, 
                        // remarks: value.remarks, 
                        // batchNumber: value.batchNumber, 
                        // hrisId: value.hrisId, 
                        // hris: value.hris, 
                        // upload: value.upload, 
                        // isPosted: value.isPosted, 
                        // isPaid: value.isPaid, 
                        // createdby: value.createdby, 
                        // createddate: value.createddate, 
                        // modifiedby: value.modifiedby, 
                        // modifieddate: value.modifieddate, 
                        // isModified: value.isModified
                    };
                    deductionArr.push(res[value.deduction])
                }
                res[value.deduction].debit += parseFloat(value.debit);
                res[value.deduction].credit += parseFloat(value.credit);
                return res;
                }, {});
                //deductionLength = deductionArr.length
                //////console.log("deductionArr 1")
                //////console.log(deductionArr)
                for(let ded = 0; ded < deductionArr.length; ded++){
                    if(data[x]["clearanceDeductions"].some(items => items.employeeNo === deductionArr[ded]["employeeNo"] && items.deductionId === deductionArr[ded]["deductionId"] && items.balance === (deductionArr[ded]["debit"] - deductionArr[ded]["credit"]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')) === false){
                        //////////////////////console.log("frome are")
                        //////////////////////console.log(deductionArr[ded])
                        const objPrem = {
                            "ClearanceDeductionId"  : deductionArr[ded]["id"],
                            "Client"                : deductionArr[ded]["client"],
                            "EmployeeId"            : data[x]["employeeId"],
                            "GroupBy"               : deductionArr[ded]["groupName"],
                            "Deduction"             : deductionArr[ded]["deduction"],
                            "DeductionId"           : deductionArr[ded]["deductionId"],
                            "DeductionType"         : deductionArr[ded]["deductionType"],
                            "DeductiontypeId"       : deductionArr[ded]["deductionTypeId"],
                            "EmployeeNo"            : deductionArr[ded]["employeeNo"],
                            "ChargesAmount"         : (deductionArr[ded]["debit"]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                            "CollectionAmount"      :(deductionArr[ded]["credit"]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                            "Balance"               : (deductionArr[ded]["debit"] - deductionArr[ded]["credit"]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                            "Remarks"               : "",
                            "CheckBox"              : "0",
                            "Priority"              : deductionArr[ded]["priority"],
                            "IsModified"         : "0",
                            "DocNo"            : deductionArr[ded]["docNo"],
                            
                            // "IsInclusion"            : deductionArr[ded]["isInclusion"],
                            // "Inclusion"            : deductionArr[ded]["inclusion"],
                            // "IsMF"            : deductionArr[ded]["isMF"],
                            // "Mf"            : deductionArr[ded]["mf"],
                            // "Priority"            : deductionArr[ded]["priority"],
                            // "Series"            : deductionArr[ded]["series"],
                            // "ReferenceNo"            : deductionArr[ded]["referenceNo"],
                            // "Scheddeduction"            : deductionArr[ded]["scheddeduction"],
                            // "TransactionDate"            : deductionArr[ded]["transactionDate"],
                            // "CollectionDate"            : deductionArr[ded]["collectionDate"],
                            // "DateStart"            : deductionArr[ded]["dateStart"],
                            // "PayOutDate"            : deductionArr[ded]["payOutDate"],
                            // "PayrollDate"            : deductionArr[ded]["payrollDate"],
                            // "DeductionStatus"            : deductionArr[ded]["deductionStatus"],
                            // "payMode"            : deductionArr[ded]["payMode"],
                            // "CutOffDate"            : deductionArr[ded]["cutOffDate"],
                            // "NumberOfDeduction"            : deductionArr[ded]["numberOfDeduction"],
                            // "Remarks"            : deductionArr[ded]["remarks"],
                            // "BatchNumber"            : deductionArr[ded]["batchNumber"],
                            // "HrisId"            : deductionArr[ded]["hrisId"],
                            // "Hris"            : deductionArr[ded]["hris"],
                            // "Upload"            : deductionArr[ded]["upload"],
                            // "IsPosted"            : deductionArr[ded]["isPosted"],
                            // "IsPaid"            : deductionArr[ded]["isPaid"],
                            // "AreCreatedby"            : deductionArr[ded]["createdby"],
                            // "AreCreateddate"            : deductionArr[ded]["createddate"],
                            // "AreModifiedby"            : deductionArr[ded]["modifiedby"],
                            // "AreModifieddate"            : deductionArr[ded]["modifieddate"],
                            // "AreIsModified"            : deductionArr[ded]["isModified"],
                            
                        }
                        gridArry.push(objPrem)
                        this.state.deductionLength.push(objPrem)
                    }
                }
                for(let i = 0; i < data[x]["clearanceDeductions"].length; i++) {
                    if(deductionArr.some(items => items.employeeNo === data[x]["clearanceDeductions"][i]["employeeNo"] && items.deductionId === data[x]["clearanceDeductions"][i]["deductionId"] && (items.debit - items.credit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') === data[x]["clearanceDeductions"][i]["balance"]) === true) {
                        
                        gridArry.push({
                            "ClearanceDeductionId"         : data[x]["clearanceDeductions"][i]["clearanceDeductionId"],
                            "Client"         : data[x]["clearanceDeductions"][i]["client"],
                            "EmployeeId"        : data[x]["clearanceDeductions"][i]["employeeId"],
                            "GroupBy"         : data[x]["clearanceDeductions"][i]["groupBy"],
                            "Deduction"         : data[x]["clearanceDeductions"][i]["deduction"],
                            "DeductionId"         : data[x]["clearanceDeductions"][i]["deductionId"],
                            "DeductionType"     : data[x]["clearanceDeductions"][i]["deductionType"],
                            "DeductiontypeId"   : data[x]["clearanceDeductions"][i]["deductiontypeId"],
                            "EmployeeNo"        : data[x]["clearanceDeductions"][i]["employeeNo"],
                            "ChargesAmount"     : data[x]["clearanceDeductions"][i]["chargesAmount"],
                            "CollectionAmount"  :data[x]["clearanceDeductions"][i]["collectionAmount"],
                            "Balance"      : data[x]["clearanceDeductions"][i]["balance"],
                            "Remarks"   : data[x]["clearanceDeductions"][i]["remarks"],
                            "CheckBox"   : data[x]["clearanceDeductions"][i]["checkBox"],
                            "Priority"   : data[x]["clearanceDeductions"][i]["priority"],
                            "IsModified"         : data[x]["clearanceDeductions"][i]["isModified"],
                            "DocNo"   : data[x]["clearanceDeductions"][i]["docNo"],
                            
                            // "IsInclusion"            : data[x]["clearanceDeductions"][i]["isInclusion"],
                            // "Inclusion"            : data[x]["clearanceDeductions"][i]["inclusion"],
                            // "IsMF"            : data[x]["clearanceDeductions"][i]["isMF"],
                            // "Mf"            : data[x]["clearanceDeductions"][i]["mf"],
                            // "Priority"            : data[x]["clearanceDeductions"][i]["priority"],
                            // "Series"            : data[x]["clearanceDeductions"][i]["series"],
                            // "ReferenceNo"            : data[x]["clearanceDeductions"][i]["referenceNo"],
                            // "Scheddeduction"            : data[x]["clearanceDeductions"][i]["scheddeduction"],
                            // "TransactionDate"            : data[x]["clearanceDeductions"][i]["transactionDate"],
                            // "CollectionDate"            : data[x]["clearanceDeductions"][i]["collectionDate"],
                            // "DateStart"            : data[x]["clearanceDeductions"][i]["dateStart"],
                            // "PayOutDate"            : data[x]["clearanceDeductions"][i]["payOutDate"],
                            // "PayrollDate"            : data[x]["clearanceDeductions"][i]["payrollDate"],
                            // "DeductionStatus"            : data[x]["clearanceDeductions"][i]["deductionStatus"],
                            // "payMode"            : data[x]["clearanceDeductions"][i]["payMode"],
                            // "CutOffDate"            : data[x]["clearanceDeductions"][i]["cutOffDate"],
                            // "NumberOfDeduction"            : data[x]["clearanceDeductions"][i]["numberOfDeduction"],
                            // "Remarks"            : data[x]["clearanceDeductions"][i]["remarks"],
                            // "BatchNumber"            : data[x]["clearanceDeductions"][i]["batchNumber"],
                            // "HrisId"            : data[x]["clearanceDeductions"][i]["hrisId"],
                            // "Hris"            : data[x]["clearanceDeductions"][i]["hris"],
                            // "Upload"            : data[x]["clearanceDeductions"][i]["upload"],
                            // "IsPosted"            : data[x]["clearanceDeductions"][i]["isPosted"],
                            // "IsPaid"            : data[x]["clearanceDeductions"][i]["isPaid"],
                            // "AreCreatedby"            : data[x]["clearanceDeductions"][i]["createdby"],
                            // "AreCreateddate"            : data[x]["clearanceDeductions"][i]["createddate"],
                            // "AreModifiedby"            : data[x]["clearanceDeductions"][i]["modifiedby"],
                            // "AreModifieddate"            : data[x]["clearanceDeductions"][i]["modifieddate"],
                            // "AreIsModified"            : data[x]["clearanceDeductions"][i]["isModified"],
                            
                        })
                        this.state.deductionLength.push({
                            "ClearanceDeductionId"         : data[x]["clearanceDeductions"][i]["clearanceDeductionId"],
                            "Client"         : data[x]["clearanceDeductions"][i]["client"],
                            "EmployeeId"        : data[x]["clearanceDeductions"][i]["employeeId"],
                            "GroupBy"         : data[x]["clearanceDeductions"][i]["groupBy"],
                            "Deduction"         : data[x]["clearanceDeductions"][i]["deduction"],
                            "DeductionId"         : data[x]["clearanceDeductions"][i]["deductionId"],
                            "DeductionType"     : data[x]["clearanceDeductions"][i]["deductionType"],
                            "DeductiontypeId"   : data[x]["clearanceDeductions"][i]["deductiontypeId"],
                            "EmployeeNo"        : data[x]["clearanceDeductions"][i]["employeeNo"],
                            "ChargesAmount"     : data[x]["clearanceDeductions"][i]["chargesAmount"],
                            "CollectionAmount"  :data[x]["clearanceDeductions"][i]["collectionAmount"],
                            "Balance"      : data[x]["clearanceDeductions"][i]["balance"],
                            "Remarks"   : data[x]["clearanceDeductions"][i]["remarks"],
                            "CheckBox"   : data[x]["clearanceDeductions"][i]["checkBox"],
                            "Priority"   : data[x]["clearanceDeductions"][i]["priority"],
                            "IsModified"         : data[x]["clearanceDeductions"][i]["isModified"],
                            "DocNo"   : data[x]["clearanceDeductions"][i]["docNo"],
                            
                            // "IsInclusion"            : data[x]["clearanceDeductions"][i]["isInclusion"],
                            // "Inclusion"            : data[x]["clearanceDeductions"][i]["inclusion"],
                            // "IsMF"            : data[x]["clearanceDeductions"][i]["isMF"],
                            // "Mf"            : data[x]["clearanceDeductions"][i]["mf"],
                            // "Priority"            : data[x]["clearanceDeductions"][i]["priority"],
                            // "Series"            : data[x]["clearanceDeductions"][i]["series"],
                            // "ReferenceNo"            : data[x]["clearanceDeductions"][i]["referenceNo"],
                            // "Scheddeduction"            : data[x]["clearanceDeductions"][i]["scheddeduction"],
                            // "TransactionDate"            : data[x]["clearanceDeductions"][i]["transactionDate"],
                            // "CollectionDate"            : data[x]["clearanceDeductions"][i]["collectionDate"],
                            // "DateStart"            : data[x]["clearanceDeductions"][i]["dateStart"],
                            // "PayOutDate"            : data[x]["clearanceDeductions"][i]["payOutDate"],
                            // "PayrollDate"            : data[x]["clearanceDeductions"][i]["payrollDate"],
                            // "DeductionStatus"            : data[x]["clearanceDeductions"][i]["deductionStatus"],
                            // "payMode"            : data[x]["clearanceDeductions"][i]["payMode"],
                            // "CutOffDate"            : data[x]["clearanceDeductions"][i]["cutOffDate"],
                            // "NumberOfDeduction"            : data[x]["clearanceDeductions"][i]["numberOfDeduction"],
                            // "Remarks"            : data[x]["clearanceDeductions"][i]["remarks"],
                            // "BatchNumber"            : data[x]["clearanceDeductions"][i]["batchNumber"],
                            // "HrisId"            : data[x]["clearanceDeductions"][i]["hrisId"],
                            // "Hris"            : data[x]["clearanceDeductions"][i]["hris"],
                            // "Upload"            : data[x]["clearanceDeductions"][i]["upload"],
                            // "IsPosted"            : data[x]["clearanceDeductions"][i]["isPosted"],
                            // "IsPaid"            : data[x]["clearanceDeductions"][i]["isPaid"],
                            // "AreCreatedby"            : data[x]["clearanceDeductions"][i]["createdby"],
                            // "AreCreateddate"            : data[x]["clearanceDeductions"][i]["createddate"],
                            // "AreModifiedby"            : data[x]["clearanceDeductions"][i]["modifiedby"],
                            // "AreModifieddate"            : data[x]["clearanceDeductions"][i]["modifieddate"],
                            // "AreIsModified"            : data[x]["clearanceDeductions"][i]["isModified"],
                            
                        })
                    }
                }
                this.setState({
                    deductionLength : this.state.deductionLength
                })
                let _totalDeduction = "0.00"
                let _checking = 0
                let removeDup = gridArry.filter( (ele, ind) => ind === gridArry.findIndex( elem => elem.Balance === ele.Balance && elem.ChargesAmount === ele.ChargesAmount && elem.DeductionId === ele.DeductionId && elem.EmployeeNo === ele.EmployeeNo))
                
                let _deductions = gridArry.reduce((total, currentValue) => total = total + parseFloat(String(currentValue.Balance).replace(",","").replace(",","").replace(",","")),0)
                //console.log("_deductions")
                //console.log( _deductions.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') < _checking.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
                if(_deductions.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') < _checking.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')) {
                    console.log("_deductions")
                    console.log(_checking.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
                    _checking = _checking.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }else{
                    console.log("_deductions")
                    console.log(_deductions.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
                    _checking = _deductions.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }
                let finalObjListArray = {
                    "Id" : data[x]["id"],
                    "ClientId":data[x]["clientId"],
                    "ProfileId":data[x]["profileId"],
                    "EmployeeId":data[x]["employeeId"],
                    "PayPeriodId":data[x]["payPeriodId"],
                    "DateCreated":data[x]["dateCreated"],
                    "ClearanceDateMember":data[x]["clearanceDateMember"],
                    "ResignationDateMember":data[x]["resignationDateMember"],
                    "SubmissionDateMember":data[x]["submissionDateMember"],
                    "LastWorkingDateMember":data[x]["lastWorkingDateMember"],
                    "DateSubmitted":data[x]["dateSubmitted"],
                    "IsSSSNumberMember":data[x]["isSSSNumberMember"],
                    "IsTINNumberMember":data[x]["isTINNumberMember"],
                    "IsPHICNumberMember":data[x]["isPHICNumberMember"],
                    "IsHDMFNumberMember":data[x]["isHDMFNumberMember"],
                    "IsCashCardMember":data[x]["isCashCardMember"],
                    "IsValidIdMember":data[x]["isValidIdMember"],
                    "IsResignationLetterMember":data[x]["isResignationLetterMember"],
                    "IsTurnoverChecklistMember":data[x]["isTurnoverChecklistMember"],
                    "IsCOERequestFormMember":data[x]["isCOERequestFormMember"],
                    "IsTypeOfCashCardMember":data[x]["isTypeOfCashCardMember"], // cash card mode of payment
                    "PaycardTypeIdMember":data[x]["paycardTypeIdMember"],
                    "TypeOfCashCardMember":data[x]["typeOfCashCardMember"],
                    "CashCardNumberMember":data[x]["cashCardNumberMember"],
                    "IsCheckMember":data[x]["isCheckMember"],
                    "IsMlhuillierMember":data[x]["isMlhuillierMember"],
                    "IsPalawanMember":data[x]["isPalawanMember"],
                    "RemarksMember":data[x]["remarksMember"],
                    "NotesMember":data[x]["notesMember"],
                    "ReasonForLeaving":data[x]["reasonForLeaving"],
                    "DateForwardedToAmt": data[x]["dateForwardedToAmt"],
                    "DateReceivedFromAmt": data[x]["dateReceivedFromAmt"],
                    "IsEligible":data[x]["isEligible"],
                    "IsNotEligible":data[x]["isNotEligible"],
                    "SssNumber": data[x]["sssNumber"],
                    "TinNumber": data[x]["tinNumber"],
                    "PhicNumber": data[x]["phicNumber"],
                    "HdmfNumber": data[x]["hdmfNumber"],
                    "IsClearedClient":data[x]["isClearedClient"],
                    "IsEligibleClient":data[x]["isEligibleClient"],
                    "IsNonEligibleClient":data[x]["isNonEligibleClient"],
                    "RemarksClient":data[x]["remarksClient"],

                    "IsClearedRmt":data[x]["isNonEligibleClient"],
                    "IsReturnedRmt":data[x]["isNonEligibleClient"],
                    "ValidateByRmt":data[x]["isNonEligibleClient"],
                    "IsForwardToARERmt":data[x]["isNonEligibleClient"] ,
                    "IsRetrunedServicesRmt":data[x]["isNonEligibleClient"],
                    "IsCheckedByRmt":data[x]["isCheckedByRmt"],
                    "RemarksRmt":data[x]["remarksRmt"],
                    "DateOfPayment":data[x]["dateOfPayment"],
                    //"DateOfPayment":data[x]["dateOfPayment"] ? moment(data[x]["dateOfPayment"]).format('MM/DD/YYYY') : "",
                    

                    "RemarksLrd":data[x]["remarksLrd"],
                    "ServicesRemarks":data[x]["servicesRemarks"] ,

                    "IsClearanceStatus": data[x]["isClearanceStatus"],
                    "clearanceDetails" :data[x]["clearanceDetails"],
                        
                    /* ADDITIONAL */
                    "DateUpdatedByClient": data[x]["dateUpdatedByClient"],
                    "UpdatedByClient":data[x]["isUpdatedByClient"],

                    "DateUpdatedByLRD":data[x]["dateUpdatedByLRD"],
                    "UpdatedByLRD":data[x]["isUpdatedByLRD"],

                    "DateUpdatedByRMT":data[x]["dateUpdatedByRMT"],
                    "UpdatedByRMT":data[x]["isUpdatedByRMT"],

                    "DateUpdatedByServices":data[x]["dateUpdatedByServices"],
                    "UpdatedByServices":data[x]["isUpdatedByServices"],

                    "DateUpdatedByARE":data[x]["dateUpdatedByARE"],
                    "UpdatedByARE":data[x]["isUpdatedByARE"],

                    "DateUpdatedByFinance":data[x]["dateUpdatedByFinance"],
                    "UpdatedByFinance":data[x]["isUpdatedByFinance"],
                    "DateSubmittedByStaff":data[x]["dateSubmittedByStaff"],
                    "SubmittedByStaff": data[x]["isSubmittedByStaff"],
                    "DateSubmittedByLead":data[x]["dateSubmittedByLead"],
                    "SubmittedByLead":data[x]["isSubmittedByLead"],

                    "DateReturnedByClient":data[x]["dateReturnedByClient"],
                    "ReturnedByClient":data[x]["isReturnedByClient"],
                    "DateReturnedByLRD":data[x]["dateReturnedByLRD"],
                    "ReturnedByLRD":data[x]["isReturnedByLRD"],
                    "DateReturnedByRMT":data[x]["dateReturnedByRMT"],
                    "ReturnedByRMT":data[x]["isReturnedByRMT"],
                    "DateReturnedByServices":data[x]["dateReturnedByServices"],
                    "ReturnedByServices":data[x]["isReturnedByServices"],
                    "DateReturnedByARE":data[x]["dateReturnedByARE"],
                    "ReturnedByARE":data[x]["isReturnedByARE"],
                    "DateReturnedByFinance":data[x]["dateReturnedByFinance"],
                    "ReturnedByFinance":data[x]["isReturnedByFinance"],
                    
                    "DateSettle"        : data[x]["dateSettle"],
                    "IsSettleWithDole"  :  data[x]["isSettleWithDole"],

                    "DateReceivedByAdmin" : data[x]["dateReceivedByAdmin"],
                    "IsCash" : data[x]["isCash"],
                    "BatchNumber" : data[x]["batchNumber"],
                    "servicesAttachments" : data[x]["servicesAttachments"],
                    "clearanceDeductions":gridArry.filter(x => x.Balance !== "0.00" && x.Balance > "0.00"),

                    
                    // 21/07/2023
                    "ParamountId" : data[x]["paramountId"],
                    "LastSalary" : data[x]["lastSalary"],
                    "Incentives" : data[x]["incentives"],
                    "Sil" : data[x]["sil"],
                    "ThirteenthMonth" : data[x]["thirteenthMonth"],
                    "Inclusions" : data[x]["inclusions"],
                    "GrossPay" : data[x]["grossPay"],
                    "NetPay" : data[x]["netPay"],
                    "PayPeriodId" : data[x]["payPeriodId"],
                    "PeriodCovered" : data[x]["periodCovered"],
                    "IsModifiedByARE" : data[x]["isDeleted"],
                    "SeparationPay" : data[x]["separationPay"],
                    "ReviewStatus" : gridArry.filter(x => x.Balance !== "0.00" && x.Balance > "0.00").length === 0 ? "1" : data[x]["reviewStatus"],
                    //"ReviewStatus" : data[x]["reviewStatus"] ? gridArry.length === "0" ,
                    //"ReviewStatus" : data[x]["reviewStatus"],
                    "AreRemarks" : data[x]["areRemarks"],
                    "PayrollRemarks" : data[x]["payrollRemarks"],
                    "FinanceRemarks" : data[x]["financeRemarks"],
                    "DateReturnedByPayroll" : data[x]["dateReturnedByPayroll"],
                    "TotalBalance" : _deductions.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                    //"TotalBalance" : _checking,
                    "IsClearedSubmit" : data[x]["isClearedSubmit"],
                    "PayrollPeriods" : data[x]["payrollPeriods"],
                    "IsGenerated" : data[x]["isGenerated"],
                    "IsFinalized" : data[x]["isFinalized"],

                    "BatchPayroll" : data[x]["batchPayroll"],
                    "Adjustment" : data[x]["adjustment"],
                    "OtherPayroll" : data[x]["otherPayroll"],
                    "Cbu" : data[x]["cbu"],
                    "Dividend" : data[x]["dividend"],

                    "TotalInclusion" : data[x]["totalInclusion"],
                    "DateHired" : data[x]["dateHired"],
                    "DateResigned" : data[x]["dateResigned"],
                    "IsParent" : data[x]["isParent"],
                    "IsChild" : data[x]["isChild"],
                    "ParentId" : data[x]["parentId"],
                    "SettlementFee" : data[x]["settlementFee"],

                    "CompletionDate" :data[x]["completionDate"],
                    "DateSubmittedToPayroll" : data[x]["dateSubmittedToPayroll"],
                    "IsAre" : data[x]["isAre"],
                    "IsPayroll" : data[x]["isPayroll"],
                    "PayrollAmount" : "",
                    "LastModifiedBy" : data[x]["lastModifiedBy"],
                    "ActualPayCard" : data[x]["actualPayCard"],
                }
                temporaryArr.push(finalObjListArray)
            }
        }
        //////console.log("temporaryArr")
        //////console.log(temporaryArr)
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :temporaryArr
        }
        //console.log("Submit param")
        //console.log(param)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // this.GetDataGrid()
            // this.setState({
            //     isloading   :   false,
            //     alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
            //     isshow      :   true,
            //     color       :   res.data.status=="1" ? "success" : "danger",
            //     message     :   data.message,
            //     fade        :   true,
            //     isDisabledButton : true
            // });
            //this.RefreshPage();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDataGrid();
    }

    GetDataGrid = async() => {
        this.setState({
            finalArrays:[],
            //isloading:true
        })
        let getNonselectionId = []
        let submittedDateFromPayroll = this.state.submittedDateFromPayroll ? moment(this.state.submittedDateFromPayroll).format('MM/DD/YYYY') : ""
        let submittedDateToPayroll = this.state.submittedDateToPayroll ? moment(this.state.submittedDateToPayroll).format('MM/DD/YYYY') : ""
        let arrLst = []
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   this.state.statusId,
            "FromDateCreated"       :   this.state.fromDateCreated,
            "ToDateCreated"         :   this.state.toDateCreated,
            "FromClearanceDate"     :   this.state.fromClearanceDate,
            "ToClearanceDate"       :   this.state.toClearanceDate,
            "FromDateReceive"       :   this.state.fromDateReceived,
            "ToDateReceive"         :   this.state.toDateReceived,
            "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
            "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
            "SubmittedDateFromPayroll"   :   submittedDateFromPayroll,
            "SubmittedDateToPayroll"     :  submittedDateToPayroll,
            "ReviewStatusId"     :   this.state.reviewStatusId,
        };
        ////console.log("param 2")
        ////console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            //let filterData = data.filter( x => x.isClearanceStatus === "6" || x.isPayroll === "1")
            let data = res.data.clearance.filter( x => x.isClearanceStatus === "6");
            //////console.log("data")
            //////console.log(data)
            this.setState({
                isloading   :   false,
            });
            let parentArray = []
            let exportDataArr = []
            let reviewStatus = "0"
            let reviewStatusResults = ""
            var dedArrLength = 0
            data.forEach(itm => {
                let clientArr = []
                itm.clearanceDetails.forEach(subitm => {
                    let deductionArr = []
                    itm.clearanceDeductions.forEach(subsubitm => {
                        if(subitm.companyName === subsubitm.client){
                            let objDed = {
                                "balance" : subsubitm.balance,
                                "chargesAmount" : subsubitm.chargesAmount,
                                "checkBox" : subsubitm.checkBox,
                                "id" : subsubitm.clearanceDeductionId,
                                "collectionAmount" : subsubitm.collectionAmount,
                                "deduction" : subsubitm.deduction,
                                "deductionId" : subsubitm.deductionId,
                                "deductionType" : subsubitm.deductionType,
                                "deductiontypeId" : subsubitm.deductiontypeId,
                                "employeeId" : subsubitm.employeeId,
                                "employeeNo" : subsubitm.employeeNo,
                                "groupBy" : subsubitm.groupBy,
                                "headerId" : subsubitm.headerId,
                                "isModified" : subsubitm.isModified,
                                "remarks" : subsubitm.remarks,
                                "client" : subsubitm.client,
                                "priority" : subsubitm.priority,
                                "employeeName" : itm.employeeName,
                                "docNo" : subsubitm.docNo,
                                
                                // "isInclusion" : subsubitm.isInclusion,
                                // "inclusion" : subsubitm.inclusion,
                                // "isMF" : subsubitm.isMF,
                                // "mf" : subsubitm.mf,
                                // "priority" : subsubitm.priority,
                                // "series" : subsubitm.series,
                                // "referenceNo" : subsubitm.referenceNo,
                                // "scheddeduction" : subsubitm.scheddeduction,
                                // "transactionDate" : subsubitm.transactionDate,
                                // "collectionDate" : subsubitm.collectionDate,
                                // "dateStart" : subsubitm.dateStart,
                                // "payOutDate" : subsubitm.payOutDate,
                                // "payrollDate" : subsubitm.payrollDate,
                                // "deductionStatus" : subsubitm.deductionStatus,
                                // "payMode" : subsubitm.payMode,
                                // "cutOffDate" : subsubitm.cutOffDate,
                                // "numberOfDeduction" : subsubitm.numberOfDeduction,
                                // "remarks" : subsubitm.remarks,
                                // "batchNumber" : subsubitm.batchNumber,
                                // "hrisId" : subsubitm.hrisId,
                                // "hris" : subsubitm.hris,
                                // "upload" : subsubitm.upload,
                                // "isPosted" : subsubitm.isPosted,
                                // "isPaid" : subsubitm.isPaid,
                                // "areCreatedby" : subsubitm.areCreatedby,
                                // "areCreateddate" : subsubitm.areCreateddate,
                                // "areModifiedby" : subsubitm.areModifiedby,
                                // "areModifieddate" : subsubitm.areModifieddate,
                                // "areIsModified" : subsubitm.areIsModified,
                            }
                            deductionArr.push(objDed)
                            exportDataArr.push(objDed)
                        }
                    })
                    this.setState({
                        dedArrLength : deductionArr.length
                    })
                    let clientObj = {
                        "id" : subitm.id,
                        "client" : subitm.companyName,
                        "contractDateStart" : subitm.contractDateStart,
                        "contractDateEnd" : subitm.contractDateEnd,
                        "dateHired" : subitm.dateHired,
                        "dateResigned" : subitm.dateResigned,
                        "isClearedSubmit" : deductionArr.length === 0 ? "1" : itm.isClearedSubmit,
                        "deducionList" : deductionArr
                    }
                    clientArr.push(clientObj)
                    ////////console.log("deductionArr")
                    ////////console.log(deductionArr)
                })
                let parentObj = {
                    "id" : itm.id,
                    "areRemarks" : itm.areRemarks,
                    "batchNumber" : itm.batchNumber,
                    "cashCardNumberMember" : itm.cashCardNumberMember,
                    "clearanceDateMember" : itm.clearanceDateMember,
                    "clearanceStatus" : itm.clearanceStatus,
                    "clearedSubmit" : itm.clearedSubmit,
                    "clientId" : itm.clientId,
                    "clientName" : itm.clientName,
                    "closingRemarks" : itm.closingRemarks,
                    "createdBy" : itm.createdBy,
                    "createdByName" : itm.createdByName,
                    "createdDate" : itm.createdDate,
                    "cycleDays" : itm.cycleDays,
                    "dateCreated" : itm.dateCreated,
                    "dateForwardedToAmt" : itm.dateForwardedToAmt,
                    "dateOfBirth" : itm.dateOfBirth,
                    "dateReceivedByAdmin" : itm.dateReceivedByAdmin,
                    "dateReceivedFromAmt" : itm.dateReceivedFromAmt,
                    "dateReturnedByARE" : itm.dateReturnedByARE,
                    "dateReturnedByClient" : itm.dateReturnedByClient,
                    "dateReturnedByFinance" : itm.dateReturnedByFinance,
                    "dateReturnedByLRD" : itm.dateReturnedByLRD,
                    "dateReturnedByPayroll" : itm.dateReturnedByPayroll,
                    "dateReturnedByRMT" : itm.dateReturnedByRMT,
                    "dateReturnedByServices" : itm.dateReturnedByServices,
                    "dateSettle" : itm.dateSettle,
                    "dateStart" : itm.dateStart,
                    "dateSubmitted" : itm.dateSubmitted,
                    "dateSubmittedByLead" : itm.dateSubmittedByLead,
                    "dateSubmittedByStaff" : itm.dateSubmittedByStaff,
                    "dateUpdatedByARE" : itm.dateUpdatedByARE,
                    "dateUpdatedByClient" : itm.dateUpdatedByClient,
                    "dateUpdatedByFinance" : itm.dateUpdatedByFinance,
                    "dateUpdatedByLRD" : itm.dateUpdatedByLRD,
                    "dateUpdatedByRMT" : itm.dateUpdatedByRMT,
                    "dateUpdatedByServices" : itm.dateUpdatedByServices,
                    "employeeId" : itm.employeeId,
                    "employeeName" : itm.employeeName,
                    "employeeNo" : itm.employeeNo,
                    "finalized" : itm.finalized,
                    "financeRemarks" : itm.financeRemarks,
                    "firstName" : itm.firstName,
                    "generation" : itm.generation,
                    "grossPay" : itm.grossPay,
                    "hdmfNumber" : itm.hdmfNumber,
                    "incentives" : itm.incentives,
                    "inclusions" : itm.inclusions,
                    "isCOERequestFormMember" : itm.isCOERequestFormMember,
                    "isCash" : itm.isCash,
                    "isCashCardMember" : itm.isCashCardMember,
                    "isCheckMember" : itm.isCheckMember,
                    "isCheckedByRmt" : itm.isCheckedByRmt,
                    "isClearanceStatus" : itm.isClearanceStatus,
                    "isClearedClient" : itm.isClearedClient,
                    "isClearedRmt" : itm.isClearedRmt,
                    "isDeleted" : itm.isDeleted,
                    "isEligible" : itm.isEligible,
                    "isEligibleClient" : itm.isEligibleClient,
                    "isFinalized" : itm.isFinalized,
                    "isForwardToARERmt" : itm.isForwardToARERmt,
                    "isGenerated" : itm.isGenerated,
                    "isHDMFNumberMember" : itm.isHDMFNumberMember,
                    "isMlhuillierMember" : itm.isMlhuillierMember,
                    "isModified" : itm.isModified,
                    "isModifiedByARE" : itm.isModifiedByARE,
                    "isNonEligibleClient" : itm.isNonEligibleClient,
                    "isNotEligible" : itm.isNotEligible,
                    "isPHICNumberMember" : itm.isPHICNumberMember,
                    "isPalawanMember" : itm.isPalawanMember,
                    "isResignationLetterMember" : itm.isResignationLetterMember,
                    "isRetrunedServicesRmt" : itm.isRetrunedServicesRmt,
                    "isReturnedRmt" : itm.isReturnedRmt,
                    "isSSSNumberMember" : itm.isSSSNumberMember,
                    "isSettleWithDole" : itm.isSettleWithDole,
                    "isTINNumberMember" : itm.isTINNumberMember,
                    "isTurnoverChecklistMember" : itm.isTurnoverChecklistMember,
                    "isTypeOfCashCardMember" : itm.isTypeOfCashCardMember,
                    "isValidIdMember" : itm.isValidIdMember,
                    "lastName" : itm.lastName,
                    "lastSalary" : itm.lastSalary,
                    "lastWorkingDateMember" : itm.lastWorkingDateMember,
                    "locationName" : itm.locationName,
                    "middleName" : itm.middleName,
                    "modifiedBy" : itm.modifiedBy,
                    "modifiedByName" : itm.modifiedByName,
                    "modifiedDate" : itm.modifiedDate,
                    "netPay" : itm.netPay,
                    "notesMember" : itm.notesMember,
                    "paramountId" : itm.paramountId,
                    "payPeriodId" : itm.payPeriodId,
                    "paycardTypeIdMember" : itm.paycardTypeIdMember,
                    "payrollPeriods" : itm.payrollPeriods,
                    "payrollRemarks" : itm.payrollRemarks,
                    "periodCovered" : itm.periodCovered,
                    "phicNumber" : itm.phicNumber,
                    "positionId" : itm.positionId,
                    "positionName" : itm.positionName,
                    "profileId" : itm.profileId,
                    "reasonForLeaving" : itm.reasonForLeaving,
                    "reasonForLeavingId" : itm.reasonForLeavingId,
                    "remarksClient" : itm.remarksClient,
                    "remarksLrd" : itm.remarksLrd,
                    "remarksMember" : itm.remarksMember,
                    "remarksRmt" : itm.remarksRmt,
                    "resignationDateMember" : itm.resignationDateMember,
                    "returnedByARE" : itm.returnedByARE,
                    "returnedByClient" : itm.returnedByClient,
                    "returnedByFinance" : itm.returnedByFinance,
                    "returnedByLRD" : itm.returnedByLRD,
                    "returnedByRMT" : itm.returnedByRMT,
                    "returnedByServices" : itm.returnedByServices,
                    //"reviewStatus" : dedArrLength === 0 ? "1" : itm.reviewStatus,
                    //"reviewStatusResults" : dedArrLength === 0 ? "DONE" : itm.reviewStatusResults,
                    "reviewStatus" : itm.reviewStatus,
                    "reviewStatusResults" : itm.reviewStatusResults,
                    "separationPay" : itm.separationPay,
                    "servicesRemarks" : itm.servicesRemarks,
                    "sil" : itm.sil,
                    "sssNumber" : itm.sssNumber,
                    "submissionDateMember" : itm.submissionDateMember,
                    "submitted" : itm.submitted,
                    "submittedByLead" : itm.submittedByLead,
                    "submittedByStaff" : itm.submittedByStaff,
                    "suffixName" : itm.suffixName,
                    "thirteenthMonth" : itm.thirteenthMonth,
                    "tinNumber" : itm.tinNumber,
                    "totalBalance" : itm.totalBalance,
                    "typeOfCashCardMember" : itm.typeOfCashCardMember,
                    "updatedByARE" : itm.updatedByARE,
                    "updatedByClient" : itm.updatedByClient,
                    "updatedByFinance" : itm.updatedByFinance,
                    "updatedByLRD" : itm.updatedByLRD,
                    "updatedByRMT" : itm.updatedByRMT,
                    "updatedByServices" : itm.updatedByServices,
                    "validateByRmt" : itm.validateByRmt,

                    "servicesAttachments" : itm.servicesAttachments,
                    "clearanceDetails" : itm.clearanceDetails,
                    "clientList" : clientArr,
                    "clearanceDeductions" : itm.clearanceDeductions,
                        
                    "isUpdatedByClient" : itm.isUpdatedByClient,
                    "isUpdatedByLRD" : itm.isUpdatedByLRD,
                    "isUpdatedByRMT" : itm.isUpdatedByRMT,
                    "isUpdatedByServices" : itm.isUpdatedByServices,
                    "isUpdatedByARE" : itm.isUpdatedByARE,
                    "isUpdatedByFinance" : itm.isUpdatedByFinance,
                    "isReturnedByClient" : itm.isReturnedByClient,
                    "isReturnedByLRD" : itm.isReturnedByLRD,
                    "isReturnedByRMT" : itm.isReturnedByRMT,
                    "isReturnedByServices" : itm.isReturnedByServices,
                    "isReturnedByARE" : itm.isReturnedByARE,
                    "isReturnedByFinance" : itm.isReturnedByFinance,
                    "isSubmittedByStaff": itm.isSubmittedByStaff,
                    "isSubmittedByLead":itm.isSubmittedByLead,
                    "dateOfPayment":itm.dateOfPayment,

                    "batchPayroll" : itm.batchPayroll,
                    "adjustment" : itm.adjustment,
                    "otherPayroll" : itm.otherPayroll,
                    "cbu" : itm.cbu,
                    "dividend" : itm.dividend,
                    "completionDate" : itm.completionDate,


                    "totalInclusion": itm.totalInclusion,
                    "dateHired": itm.dateHired,
                    "dateResigned": itm.dateResigned,
                    "isParent": itm.isParent,
                    "isChild": itm.isChild,
                    "parentId": itm.parentId,
                    "settlementFee": itm.settlementFee,
                    "dateSubmittedToPayroll": itm.dateSubmittedToPayroll,
                    "isAre":itm.isAre,
                    "isPayroll":itm.isPayroll,
                    "lastModifiedBy":itm.lastModifiedBy,
                    "actualPayCard":itm.actualPayCard,
                }
                parentArray.push(parentObj)
            })
            console.log("parentArray")
            console.log(parentArray)
            this.setState({
                trainingGrid : parentArray,
                exportDataGrid : exportDataArr,
                isloading       :   false,
            })
            
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    };

    GridDataModifiedClient(oldValue, newValue, row, employeeNo) {
        let dedArrs = []
        let clearanceDG = this.state.trainingGrid

    }


    handleClickSubmit = async() => {
        this.setState({
            isloading   :   true
        });
        let finalArr = []
        //////////////////console.log("this.state.trainingGrid")
        //////////////////console.log(this.state.trainingGrid)
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        for(let x = 0; x < this.state.trainingGrid.length; x++){
            if(this.state.trainingGrid[x]["isSelectedByAre"] === "1"){
                // if(this.state.trainingGrid[x]["reviewStatus"] === "0"){
                //     this.setState({
                //         isloading       :   false,
                //         alerttype       :   "Error!",
                //         isshow          :   true,
                //         color           :   "danger",
                //         message         :    "Please review the status." ,
                //         fade            :   true
                //     })
                //     return
                // }else{
                //     this.setState({
                //         isshow  :   true,
                //     })
                // }
                for(let i = 0; i < this.state.trainingGrid[x]["clientList"].length; i++){
                    if(this.state.trainingGrid[x]["clientList"][i]["isClearedSubmit"] === "0"){
                        this.setState({
                            isloading       :   false,
                            alerttype       :   "Error!",
                            isshow          :   true,
                            color           :   "danger",
                            message         :    this.state.trainingGrid[x]["clientList"][i]["client"] + ' not cleared.' ,
                            fade            :   true
                        })
                        return
                    }else{
                        this.setState({
                            isshow  :   true,
                        })
                    }
                    for(let j = 0; j < this.state.trainingGrid[x]["clientList"][i]["deducionList"].length; j++){
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "1" && this.state.trainingGrid[x]["clearanceDeductions"][i]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "3" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "5" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "6" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "7" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "8" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "9" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "10" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "11" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "12" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "13" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "14" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "15" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "16" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "17" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "18" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "19" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "20" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "21" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "22" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "23" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "24" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "26" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "27" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "28" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "29" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "30" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "33" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "34" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "35" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "38" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "39" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "40" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "41" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "42" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "43" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "44" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                        if(this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deductionTypeId"] === "45" && this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["checkBox"] === "0"){
                            this.setState({
                                isloading       :   false,
                                alerttype       :   "Error!",
                                isshow          :   true,
                                color           :   "danger",
                                message         :    "Please review a" + ' ' + this.state.trainingGrid[x]["clientList"][i]["deducionList"][j]["deduction"] + '.' ,
                                fade            :   true
                            })
                            return
                        }else{
                            this.setState({
                                isshow  :   false,
                            })
                        }
                    }
                    

                }
                let finalObjListArray = {
                    "Id" : this.state.trainingGrid[x]["id"],
                    "ClientId":this.state.trainingGrid[x]["clientId"],
                    "ProfileId":this.state.trainingGrid[x]["profileId"],
                    "EmployeeId":this.state.trainingGrid[x]["employeeId"],
                    "PayPeriodId":this.state.trainingGrid[x]["payPeriodId"],
                    "DateCreated":this.state.trainingGrid[x]["dateCreated"],
                    "ClearanceDateMember":this.state.trainingGrid[x]["clearanceDateMember"],
                    "ResignationDateMember":this.state.trainingGrid[x]["resignationDateMember"],
                    "SubmissionDateMember":this.state.trainingGrid[x]["submissionDateMember"],
                    "LastWorkingDateMember":this.state.trainingGrid[x]["lastWorkingDateMember"],
                    "DateSubmitted":this.state.trainingGrid[x]["dateSubmitted"],
                    "IsSSSNumberMember":this.state.trainingGrid[x]["isSSSNumberMember"],
                    "IsTINNumberMember":this.state.trainingGrid[x]["isTINNumberMember"],
                    "IsPHICNumberMember":this.state.trainingGrid[x]["isPHICNumberMember"],
                    "IsHDMFNumberMember":this.state.trainingGrid[x]["isHDMFNumberMember"],
                    "IsCashCardMember":this.state.trainingGrid[x]["isCashCardMember"],
                    "IsValidIdMember":this.state.trainingGrid[x]["isValidIdMember"],
                    "IsResignationLetterMember":this.state.trainingGrid[x]["isResignationLetterMember"],
                    "IsTurnoverChecklistMember":this.state.trainingGrid[x]["isTurnoverChecklistMember"],
                    "IsCOERequestFormMember":this.state.trainingGrid[x]["isCOERequestFormMember"],
                    "IsTypeOfCashCardMember":this.state.trainingGrid[x]["isTypeOfCashCardMember"], // cash card mode of payment
                    "PaycardTypeIdMember":this.state.trainingGrid[x]["paycardTypeIdMember"],
                    "TypeOfCashCardMember":this.state.trainingGrid[x]["typeOfCashCardMember"],
                    "CashCardNumberMember":this.state.trainingGrid[x]["cashCardNumberMember"],
                    "IsCheckMember":this.state.trainingGrid[x]["isCheckMember"],
                    "IsMlhuillierMember":this.state.trainingGrid[x]["isMlhuillierMember"],
                    "IsPalawanMember":this.state.trainingGrid[x]["isPalawanMember"],
                    "RemarksMember":this.state.trainingGrid[x]["remarksMember"],
                    "NotesMember":this.state.trainingGrid[x]["notesMember"],
                    "ReasonForLeaving":this.state.trainingGrid[x]["reasonForLeaving"],
                    "DateForwardedToAmt": this.state.trainingGrid[x]["dateForwardedToAmt"],
                    "DateReceivedFromAmt": this.state.trainingGrid[x]["dateReceivedFromAmt"],
                    "IsEligible":this.state.trainingGrid[x]["isEligible"],
                    "IsNotEligible":this.state.trainingGrid[x]["isNotEligible"],
                    "SssNumber": this.state.trainingGrid[x]["sssNumber"],
                    "TinNumber": this.state.trainingGrid[x]["tinNumber"],
                    "PhicNumber": this.state.trainingGrid[x]["phicNumber"],
                    "HdmfNumber": this.state.trainingGrid[x]["hdmfNumber"],
                    "IsClearedClient":this.state.trainingGrid[x]["isClearedClient"],
                    "IsEligibleClient":this.state.trainingGrid[x]["isEligibleClient"],
                    "IsNonEligibleClient":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "RemarksClient":this.state.trainingGrid[x]["remarksClient"],

                    "IsClearedRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "IsReturnedRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "ValidateByRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "IsForwardToARERmt":this.state.trainingGrid[x]["isNonEligibleClient"] ,
                    "IsRetrunedServicesRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "IsCheckedByRmt":this.state.trainingGrid[x]["isCheckedByRmt"],
                    "RemarksRmt":this.state.trainingGrid[x]["remarksRmt"],

                    "RemarksLrd":this.state.trainingGrid[x]["remarksLrd"],
                    "ServicesRemarks":this.state.trainingGrid[x]["servicesRemarks"] ,

                    "IsClearanceStatus": "7",
                        
                    /* ADDITIONAL */
                    "DateUpdatedByClient": this.state.trainingGrid[x]["dateUpdatedByClient"],
                    "UpdatedByClient":this.state.trainingGrid[x]["isUpdatedByClient"],

                    "DateUpdatedByLRD":this.state.trainingGrid[x]["dateUpdatedByLRD"],
                    "UpdatedByLRD":this.state.trainingGrid[x]["isUpdatedByLRD"],

                    "DateUpdatedByRMT":this.state.trainingGrid[x]["dateUpdatedByRMT"],
                    "UpdatedByRMT":this.state.trainingGrid[x]["isUpdatedByRMT"],

                    "DateUpdatedByServices":this.state.trainingGrid[x]["dateUpdatedByServices"],
                    "UpdatedByServices":this.state.trainingGrid[x]["isUpdatedByServices"],

                    "DateUpdatedByARE":moment(this.state.datenow).format('MM/DD/YYYY'),
                    "UpdatedByARE":this.state.userinfo.userId,

                    "DateUpdatedByFinance":this.state.trainingGrid[x]["dateUpdatedByFinance"],
                    "UpdatedByFinance":this.state.trainingGrid[x]["isUpdatedByFinance"],
                    "DateSubmittedByStaff":this.state.trainingGrid[x]["dateSubmittedByStaff"],
                    "SubmittedByStaff": this.state.trainingGrid[x]["isSubmittedByStaff"],
                    "DateSubmittedByLead":this.state.trainingGrid[x]["dateSubmittedByLead"],
                    "SubmittedByLead":this.state.trainingGrid[x]["isSubmittedByLead"],

                    "DateReturnedByClient":this.state.trainingGrid[x]["dateReturnedByClient"],
                    "ReturnedByClient":this.state.trainingGrid[x]["isReturnedByClient"],
                    "DateReturnedByLRD":this.state.trainingGrid[x]["dateReturnedByLRD"],
                    "ReturnedByLRD":this.state.trainingGrid[x]["isReturnedByLRD"],
                    "DateReturnedByRMT":this.state.trainingGrid[x]["dateReturnedByRMT"],
                    "ReturnedByRMT":this.state.trainingGrid[x]["isReturnedByRMT"],
                    "DateReturnedByServices":this.state.trainingGrid[x]["dateReturnedByServices"],
                    "ReturnedByServices":this.state.trainingGrid[x]["isReturnedByServices"],
                    "DateReturnedByARE":this.state.trainingGrid[x]["dateReturnedByARE"],
                    "ReturnedByARE":this.state.trainingGrid[x]["isReturnedByARE"],
                    "DateReturnedByFinance":this.state.trainingGrid[x]["dateReturnedByFinance"],
                    "ReturnedByFinance":this.state.trainingGrid[x]["isReturnedByFinance"],
                    
                    "DateSettle"        : this.state.trainingGrid[x]["dateSettle"],
                    "IsSettleWithDole"  :  this.state.trainingGrid[x]["isSettleWithDole"],

                    "DateReceivedByAdmin" : this.state.trainingGrid[x]["dateReceivedByAdmin"],
                    "IsCash" : this.state.trainingGrid[x]["isCash"],
                    "BatchNumber" : this.state.trainingGrid[x]["batchNumber"],
                    "IsDeleted" : "0",
                    
                    // 21/07/2023
                    "ParamountId" : this.state.trainingGrid[x]["paramountId"],
                    "LastSalary" : this.state.trainingGrid[x]["lastSalary"],
                    "Incentives" : this.state.trainingGrid[x]["incentives"],
                    "Sil" : this.state.trainingGrid[x]["sil"],
                    "ThirteenthMonth" : this.state.trainingGrid[x]["thirteenthMonth"],
                    "Inclusions" : this.state.trainingGrid[x]["inclusions"],
                    "GrossPay" : this.state.trainingGrid[x]["grossPay"],
                    "NetPay" : this.state.trainingGrid[x]["netPay"],
                    "PayPeriodId" : this.state.trainingGrid[x]["payPeriodId"],
                    "PeriodCovered" : this.state.trainingGrid[x]["periodCovered"],
                    "IsModifiedByARE" : this.state.trainingGrid[x]["isDeleted"],
                    "SeparationPay" : this.state.trainingGrid[x]["separationPay"],
                    "ReviewStatus" : "2",
                    "AreRemarks" : this.state.trainingGrid[x]["areRemarks"],
                    "PayrollRemarks" : this.state.trainingGrid[x]["payrollRemarks"],
                    "FinanceRemarks" : this.state.trainingGrid[x]["financeRemarks"],
                    "DateReturnedByPayroll" : this.state.trainingGrid[x]["dateReturnedByPayroll"],
                    "TotalBalance" : this.state.trainingGrid[x]["totalBalance"],
                    "IsClearedSubmit" : "1",
                    "PayrollPeriods" : this.state.trainingGrid[x]["payrollPeriods"],
                    "IsGenerated" : this.state.trainingGrid[x]["isGenerated"],
                    "IsFinalized" : this.state.trainingGrid[x]["isFinalized"],
                    "BatchPayroll" : this.state.trainingGrid[x]["batchPayroll"],
                    "Adjustment" : this.state.trainingGrid[x]["adjustment"],
                    "OtherPayroll" : this.state.trainingGrid[x]["otherPayroll"],
                    "Cbu" : this.state.trainingGrid[x]["cbu"],
                    "Dividend" : this.state.trainingGrid[x]["dividend"],
                    "CompletionDate" : this.state.trainingGrid[x]["completionDate"],
                    "TotalInclusion" : this.state.trainingGrid[x]["totalInclusion"],
                    "DateHired" : this.state.trainingGrid[x]["dateHired"],
                    "DateResigned" : this.state.trainingGrid[x]["dateResigned"],   

                    "IsParent" : this.state.trainingGrid[x]["isParent"],
                    "IsChild" : this.state.trainingGrid[x]["isChild"],
                    "ParentId" : this.state.trainingGrid[x]["parentId"],
                    "DateSubmittedToPayroll" : moment(new Date()).format('MM/DD/YYYY'),
                    "IsAre" : "1",
                    "IsPayroll" : "1",
                    
                    "servicesAttachments" : this.state.trainingGrid[x]["servicesAttachments"],
                    "clearanceDeductions":this.state.trainingGrid[x]["clearanceDeductions"],
                    "clearanceDetails" :this.state.trainingGrid[x]["clearanceDetails"],
                    "LastModifiedBy" :this.state.userinfo.fullName,
                    "ActualPayCard" : this.state.trainingGrid[x]["actualPayCard"],
                }
                finalArr.push(finalObjListArray)
            }
        }
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :finalArr.filter(q => q.IsClearanceStatus === "7")
        }
        ////console.log("Submit param")
        ////console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // this.GetDataGrid()
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "CLEARANCE SUCCESSFULLY SUBMITTED!",
                fade            :   true,
                isDisabledButton : true
            });
            //this.RefreshPage();
            this.GetDataGrid();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleClickRefresh = async() => {
        this.setState({
            isloading   :   true,
        });
        this.GetData();
    }

    handleClickedDone = async(row) => {
        //////////////////////console.log(this.state.trainingGrid.filter(x => x.employeeId === row.employeeId))
        
    }

    GridDataModifiedDeduction(oldValue, newValue, row, employeeNo) {
        this.setState({
            isloading   :   true,
            isshow      :   false,
        });
        let clearanceDG = this.state.trainingGrid
        let deductionList = this.state.deductionLength.filter(x => x.EmployeeNo === row.employeeNo && x.Balance !== "0.00")
        let deductionLength = this.state.deductionLength.filter(x => x.EmployeeNo === row.employeeNo && x.Balance !== "0.00").length
        let arrLists = this.state.trainingGrid.filter(x => x.employeeNo === row.employeeNo)

        let parentArray = []
        let clearanceDeductions = []
        let reviewLength = "0"
        //////console.log("clearanceDG")
        //////console.log(clearanceDG)
        clearanceDG.forEach(x => {
            let isClearedSubmit = "0"
            if(x.employeeId === row.employeeId){
                x.clientList.forEach(i => {
                    //isClearedSubmit = i.isClearedSubmit
                    i.deducionList.forEach(j => {
                        let dedOb = {
                            "ClearanceDeductionId"  : j.id,
                            "Client"                : j.client,
                            "EmployeeId"            : j.employeeId,
                            "GroupBy"               : j.groupBy,
                            "Deduction"             : j.deduction,
                            "DeductionId"           : j.deductionId,
                            "DeductionType"         : j.deductionType,
                            "DeductiontypeId"       : j.deductiontypeId,
                            "EmployeeNo"        : j.employeeNo,
                            "ChargesAmount"     : j.chargesAmount,
                            "CollectionAmount"  :j.collectionAmount,
                            "Balance"      : j.balance,
                            "Remarks"   : j.remarks,
                            "CheckBox"   : j.checkBox,
                            "IsModified"   : j.checkBox,
                            "Priority"   : j.priority,
                            "DocNo"   : j.docNo,
                        }
                        clearanceDeductions.push(dedOb)
                    })
                })
                ////////////////console.log("clearanceDeductions")
                ////////////////console.log(clearanceDeductions.filter(x => x.CheckBox === "1").length)
                ////////////////console.log("deductionLength")
                ////////////////console.log(deductionLength)
                ////////////////console.log("deductionList")
                ////////////////console.log(deductionList)
                
                clearanceDG.map(function(item,i){
                    // let disabled = true
                    if(item.employeeNo===row.employeeNo)
                    {
                        if(deductionLength === clearanceDeductions.filter(x => x.CheckBox === "1").length){
                            item.reviewStatus = "1"
                            reviewLength = "1"
                            item.reviewStatusResults = "DONE"
                            isClearedSubmit = "1"
                        }else{
                            reviewLength = "0"
                            item.reviewStatus = "0"
                            item.reviewStatusResults = "NOT DONE"
                            isClearedSubmit = "0"
                        }
                        //item.reviewStatus = "1"
                        //item.reviewStatusResults = "DONE"
                        // item.isSelectedByAre = isSelect ? "1" : "0"
                        //  disable = isSelect ? false : true
                    }
                })
                let parentObj = {
                    "Id" : x.id,
                    "ClientId":x.clientId,
                    "ProfileId":x.profileId,
                    "EmployeeId":x.employeeId,
                    "PayPeriodId":x.payPeriodId,
                    "DateCreated":x.dateCreated,
                    "ClearanceDateMember":x.clearanceDateMember,
                    "ResignationDateMember":x.resignationDateMember,
                    "SubmissionDateMember":x.submissionDateMember,
                    "LastWorkingDateMember":x.lastWorkingDateMember,
                    "DateSubmitted":x.dateSubmitted,
                    "IsSSSNumberMember":x.isSSSNumberMember,
                    "IsTINNumberMember":x.isTINNumberMember,
                    "IsPHICNumberMember":x.isPHICNumberMember,
                    "IsHDMFNumberMember":x.isHDMFNumberMember,
                    "IsCashCardMember":x.isCashCardMember,
                    "IsValidIdMember":x.isValidIdMember,
                    "IsResignationLetterMember":x.isResignationLetterMember,
                    "IsTurnoverChecklistMember":x.isTurnoverChecklistMember,
                    "IsCOERequestFormMember":x.isCOERequestFormMember,
                    "IsTypeOfCashCardMember":x.isTypeOfCashCardMember, // cash card mode of payment
                    "PaycardTypeIdMember":x.paycardTypeIdMember,
                    "TypeOfCashCardMember":x.typeOfCashCardMember,
                    "CashCardNumberMember":x.cashCardNumberMember,
                    "IsCheckMember":x.isCheckMember,
                    "IsMlhuillierMember":x.isMlhuillierMember,
                    "IsPalawanMember":x.isPalawanMember,
                    "RemarksMember":x.remarksMember,
                    "NotesMember":x.notesMember,
                    "ReasonForLeaving":x.reasonForLeaving,
                    "DateForwardedToAmt": x.dateForwardedToAmt,
                    "DateReceivedFromAmt": x.dateReceivedFromAmt,
                    "IsEligible":x.isEligible,
                    "IsNotEligible":x.isNotEligible,
                    "SssNumber": x.sssNumber,
                    "TinNumber": x.tinNumber,
                    "PhicNumber": x.phicNumber,
                    "HdmfNumber": x.hdmfNumber,
                    "IsClearedClient":x.isClearedClient,
                    "IsEligibleClient":x.isEligibleClient,
                    "IsNonEligibleClient":x.isNonEligibleClient,
                    "RemarksClient":x.remarksClient,

                    "IsClearedRmt":x.isNonEligibleClient,
                    "IsReturnedRmt":x.isNonEligibleClient,
                    "ValidateByRmt":x.isNonEligibleClient,
                    "IsForwardToARERmt":x.isNonEligibleClient ,
                    "IsRetrunedServicesRmt":x.isNonEligibleClient,
                    "IsCheckedByRmt":x.isCheckedByRmt,
                    "RemarksRmt":x.remarksRmt,

                    "RemarksLrd":x.remarksLrd,
                    "ServicesRemarks":x.servicesRemarks ,

                    "IsClearanceStatus": x.isClearanceStatus,
                        
                    /* ADDITIONAL */
                    "DateUpdatedByClient": x.dateUpdatedByClient,
                    "UpdatedByClient":x.isUpdatedByClient,

                    "DateUpdatedByLRD":x.dateUpdatedByLRD,
                    "UpdatedByLRD":x.isUpdatedByLRD,

                    "DateUpdatedByRMT":x.dateUpdatedByRMT,
                    "UpdatedByRMT":x.isUpdatedByRMT,

                    "DateUpdatedByServices":x.dateUpdatedByServices,
                    "UpdatedByServices":x.isUpdatedByServices,

                    "DateUpdatedByARE":x.dateUpdatedByARE,
                    "UpdatedByARE":x.isUpdatedByARE,

                    "DateUpdatedByFinance":x.dateUpdatedByFinance,
                    "UpdatedByFinance":x.isUpdatedByFinance,
                    "DateSubmittedByStaff":x.dateSubmittedByStaff,
                    "DateSubmittedByLead":x.dateSubmittedByLead,
                    "SubmittedByStaff": x.isSubmittedByStaff,
                    "SubmittedByLead":x.isSubmittedByLead,

                    "DateReturnedByClient":x.dateReturnedByClient,
                    "ReturnedByClient":x.isReturnedByClient,
                    "DateReturnedByLRD":x.dateReturnedByLRD,
                    "ReturnedByLRD":x.isReturnedByLRD,
                    "DateReturnedByRMT":x.dateReturnedByRMT,
                    "ReturnedByRMT":x.isReturnedByRMT,
                    "DateReturnedByServices":x.dateReturnedByServices,
                    "ReturnedByServices":x.isReturnedByServices,
                    "DateReturnedByARE":x.dateReturnedByARE,
                    "ReturnedByARE":x.isReturnedByARE,
                    "DateReturnedByFinance":x.dateReturnedByFinance,
                    "ReturnedByFinance":x.isReturnedByFinance,
                    
                    "DateSettle"        : x.dateSettle,
                    "IsSettleWithDole"  :  x.isSettleWithDole,

                    "DateReceivedByAdmin" : x.dateReceivedByAdmin,
                    "IsCash" : x.isCash,
                    "BatchNumber" : x.batchNumber,

                    
                    // 21/07/2023
                    "ParamountId" : x.paramountId,
                    "LastSalary" : x.lastSalary,
                    "Incentives" : x.incentives,
                    "Sil" : x.sil,
                    "ThirteenthMonth" : x.thirteenthMonth,
                    "Inclusions" : x.inclusions,
                    "GrossPay" : x.grossPay,
                    "NetPay" : x.netPay,
                    "PayPeriodId" : x.payPeriodId,
                    "PeriodCovered" : x.periodCovered,
                    "IsModifiedByARE" : x.isDeleted,
                    "SeparationPay" : x.separationPay,
                    "ReviewStatus" : reviewLength,
                    "AreRemarks" : x.areRemarks,
                    "PayrollRemarks" : x.payrollRemarks,
                    "FinanceRemarks" : x.financeRemarks,
                    "DateReturnedByPayroll" : x.dateReturnedByPayroll,
                    "TotalBalance" : x.totalBalance,
                    "IsClearedSubmit" : isClearedSubmit,
                    "PayrollPeriods" : x.payrollPeriods,
                    "IsGenerated" : x.isGenerated,
                    "IsFinalized" : x.isFinalized,
                    "BatchPayroll" : x.batchPayroll,
                    "Adjustment" : x.adjustment,
                    "OtherPayroll" : x.otherPayroll,
                    "Cbu" : x.cbu,
                    "Dividend" : x.dividend,
                    "CompletionDate" : x.completionDate,
                    "TotalInclusion" : x.totalInclusion,
                    "DateHired" : x.dateHired,
                    "DateResigned" : x.dateResigned,
                    
                    "IsUpdatedByClient" : x.isUpdatedByClient,
                    "IsUpdatedByLRD" : x.isUpdatedByLRD,
                    "IsUpdatedByRMT" : x.isUpdatedByRMT,
                    "IsUpdatedByServices" : x.isUpdatedByServices,
                    "IsUpdatedByARE" : x.isUpdatedByARE,
                    "IsUpdatedByFinance" : x.isUpdatedByFinance,
                    "IsReturnedByClient" : x.isReturnedByClient,
                    "IsReturnedByLRD" : x.isReturnedByLRD,
                    "IsReturnedByRMT" : x.isReturnedByRMT,
                    "IsReturnedByServices" : x.isReturnedByServices,
                    "IsReturnedByARE" : x.isReturnedByARE,
                    "IsReturnedByFinance" : x.isReturnedByFinance,
                    
                    "IsParent" : x.isParent,
                    "IsChild" : x.isChild,
                    "ParentId" : x.parentId,
                    "DateSubmittedToPayroll" : x.dateSubmittedToPayroll,
                    "IsAre" : x.isAre,
                    "IsPayroll" : x.isPayroll,
                    "LastModifiedBy" : x.lastModifiedBy,
                    "ActualPayCard" : x.actualPayCard,

                    "clearanceDetails" :x.clearanceDetails,
                    "servicesAttachments" : x.servicesAttachments,
                    "clearanceDeductions" : clearanceDeductions
                }
                parentArray.push(parentObj)
            }
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :parentArray
        }
        //////console.log("Submit param Select Yes")
        //////console.log(param)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // this.GetDataGrid()
            this.setState({
                isloading   :   false,
                isshow      :   false,
            });
            this.GetDataGrid();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleChangeSubmittedDateFromPayroll = date =>{
        this.setState({ submittedDateFromPayroll: date, isshow:false , })
    }
    handleChangeSubmittedDateToPayroll = date =>{
        this.setState({ submittedDateToPayroll: date, isshow:false , })
    }

    handleClickSearch = async() => {
        this.setState({
            trainingGrid:[]
        })
        if(this.state.batchNumber === ""){
            this.GetFilterData();
        }else {
            this.GetFilterDataByBatchNumber();
        }
    }

    GetFilterData = async() => {
        this.setState({
            isloading   :   true,
        })
        ////console.log("submittedDateFromPayroll")
        ////console.log( (this.state.submittedDateFromPayroll) ? moment(this.state.submittedDateFromPayroll).format('MM/DD/YYYY') : "")
        ////console.log("submittedDateToPayroll")
        ////console.log(this.state.submittedDateFromPayroll ? moment(this.state.submittedDateToPayroll).format('MM/DD/YYYY') : "")
        let submittedDateFromPayroll = this.state.submittedDateFromPayroll ? moment(this.state.submittedDateFromPayroll).format('MM/DD/YYYY') : ""
        let submittedDateToPayroll = this.state.submittedDateToPayroll ? moment(this.state.submittedDateToPayroll).format('MM/DD/YYYY') : ""
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   this.state.statusId,
            "FromDateCreated"       :   this.state.fromDateCreated,
            "ToDateCreated"         :   this.state.toDateCreated,
            "FromClearanceDate"     :   this.state.fromClearanceDate,
            "ToClearanceDate"       :   this.state.toClearanceDate,
            "FromDateReceive"       :   this.state.fromDateReceived,
            "ToDateReceive"         :   this.state.toDateReceived,
            "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
            "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
            //"SubmittedDateFromPayroll"   :   this.state.submittedDateFromPayroll,
            //"SubmittedDateToPayroll"     :  this.state.submittedDateToPayroll,
            "SubmittedDateFromPayroll"   :   submittedDateFromPayroll,
            "SubmittedDateToPayroll"     :  submittedDateToPayroll,
            "ReviewStatusId"     :   this.state.reviewStatusId,
        };
        ////console.log("param")
        ////console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            //let filterData = data.filter( x => x.isClearanceStatus === "6" || x.isPayroll === "1")
            let data = res.data.clearance.filter( x => x.isClearanceStatus === "6");
            ////console.log("GetClearance xx")
            ////console.log(data)
            let parentArray = []
            let reviewStatus = "0"
            let reviewStatusResults = ""
            data.forEach(itm => {
                let clientArr = []
                let deductionArr = []
                itm.clearanceDetails.forEach(clearanceDetails => {
                    let deductionArr = []
                    itm.clearanceDeductions.forEach(subsubitm => {
                        if(clearanceDetails.companyName === subsubitm.client){
                            let objDed = {
                                "balance" : subsubitm.balance,
                                "chargesAmount" : subsubitm.chargesAmount,
                                "checkBox" : subsubitm.checkBox,
                                "id" : subsubitm.clearanceDeductionId,
                                "collectionAmount" : subsubitm.collectionAmount,
                                "deduction" : subsubitm.deduction,
                                "deductionId" : subsubitm.deductionId,
                                "deductionType" : subsubitm.deductionType,
                                "deductiontypeId" : subsubitm.deductiontypeId,
                                "employeeId" : subsubitm.employeeId,
                                "employeeNo" : subsubitm.employeeNo,
                                "groupBy" : subsubitm.groupBy,
                                "headerId" : subsubitm.headerId,
                                "isModified" : subsubitm.isModified,
                                "remarks" : subsubitm.remarks,
                                "client" : subsubitm.client,
                                "priority" : subsubitm.priority
                            }
                            deductionArr.push(objDed)
                        }
                    })
                    this.setState({
                        dedArrLength : deductionArr.length
                    })
                    let clientObj = {
                        "id" : clearanceDetails.id,
                        "client" : clearanceDetails.companyName,
                        "contractDateStart" : clearanceDetails.contractDateStart,
                        "contractDateEnd" : clearanceDetails.contractDateEnd,
                        "dateHired" : clearanceDetails.dateHired,
                        "dateResigned" : clearanceDetails.dateResigned,
                        "isClearedSubmit" : deductionArr.length === 0 ? "1" : itm.isClearedSubmit,
                        "deducionList" : deductionArr
                    }
                    clientArr.push(clientObj)
                })

                ////////////////console.log("clientArr xx")
                ////////////////console.log(clientArr)
                let parentObj = {
                    "id" : itm.id,
                    "areRemarks" : itm.areRemarks,
                    "batchNumber" : itm.batchNumber,
                    "cashCardNumberMember" : itm.cashCardNumberMember,
                    "clearanceDateMember" : itm.clearanceDateMember,
                    "clearanceStatus" : itm.clearanceStatus,
                    "clearedSubmit" : itm.clearedSubmit,
                    "clientId" : itm.clientId,
                    "clientName" : itm.clientName,
                    "closingRemarks" : itm.closingRemarks,
                    "createdBy" : itm.createdBy,
                    "createdByName" : itm.createdByName,
                    "createdDate" : itm.createdDate,
                    "cycleDays" : itm.cycleDays,
                    "dateCreated" : itm.dateCreated,
                    "dateForwardedToAmt" : itm.dateForwardedToAmt,
                    "dateOfBirth" : itm.dateOfBirth,
                    "dateReceivedByAdmin" : itm.dateReceivedByAdmin,
                    "dateReceivedFromAmt" : itm.dateReceivedFromAmt,
                    "dateReturnedByARE" : itm.dateReturnedByARE,
                    "dateReturnedByClient" : itm.dateReturnedByClient,
                    "dateReturnedByFinance" : itm.dateReturnedByFinance,
                    "dateReturnedByLRD" : itm.dateReturnedByLRD,
                    "dateReturnedByPayroll" : itm.dateReturnedByPayroll,
                    "dateReturnedByRMT" : itm.dateReturnedByRMT,
                    "dateReturnedByServices" : itm.dateReturnedByServices,
                    "dateSettle" : itm.dateSettle,
                    "dateStart" : itm.dateStart,
                    "dateSubmitted" : itm.dateSubmitted,
                    "dateSubmittedByLead" : itm.dateSubmittedByLead,
                    "dateSubmittedByStaff" : itm.dateSubmittedByStaff,
                    "dateUpdatedByARE" : itm.dateUpdatedByARE,
                    "dateUpdatedByClient" : itm.dateUpdatedByClient,
                    "dateUpdatedByFinance" : itm.dateUpdatedByFinance,
                    "dateUpdatedByLRD" : itm.dateUpdatedByLRD,
                    "dateUpdatedByRMT" : itm.dateUpdatedByRMT,
                    "dateUpdatedByServices" : itm.dateUpdatedByServices,
                    "employeeId" : itm.employeeId,
                    "employeeName" : itm.employeeName,
                    "employeeNo" : itm.employeeNo,
                    "finalized" : itm.finalized,
                    "financeRemarks" : itm.financeRemarks,
                    "firstName" : itm.firstName,
                    "generation" : itm.generation,
                    "grossPay" : itm.grossPay,
                    "hdmfNumber" : itm.hdmfNumber,
                    "incentives" : itm.incentives,
                    "inclusions" : itm.inclusions,
                    "isCOERequestFormMember" : itm.isCOERequestFormMember,
                    "isCash" : itm.isCash,
                    "isCashCardMember" : itm.isCashCardMember,
                    "isCheckMember" : itm.isCheckMember,
                    "isCheckedByRmt" : itm.isCheckedByRmt,
                    "isClearanceStatus" : itm.isClearanceStatus,
                    "isClearedClient" : itm.isClearedClient,
                    "isClearedRmt" : itm.isClearedRmt,
                    "isDeleted" : itm.isDeleted,
                    "isEligible" : itm.isEligible,
                    "isEligibleClient" : itm.isEligibleClient,
                    "isFinalized" : itm.isFinalized,
                    "isForwardToARERmt" : itm.isForwardToARERmt,
                    "isGenerated" : itm.isGenerated,
                    "isHDMFNumberMember" : itm.isHDMFNumberMember,
                    "isMlhuillierMember" : itm.isMlhuillierMember,
                    "isModified" : itm.isModified,
                    "isModifiedByARE" : itm.isModifiedByARE,
                    "isNonEligibleClient" : itm.isNonEligibleClient,
                    "isNotEligible" : itm.isNotEligible,
                    "isPHICNumberMember" : itm.isPHICNumberMember,
                    "isPalawanMember" : itm.isPalawanMember,
                    "isResignationLetterMember" : itm.isResignationLetterMember,
                    "isRetrunedServicesRmt" : itm.isRetrunedServicesRmt,
                    "isReturnedRmt" : itm.isReturnedRmt,
                    "isSSSNumberMember" : itm.isSSSNumberMember,
                    "isSettleWithDole" : itm.isSettleWithDole,
                    "isTINNumberMember" : itm.isTINNumberMember,
                    "isTurnoverChecklistMember" : itm.isTurnoverChecklistMember,
                    "isTypeOfCashCardMember" : itm.isTypeOfCashCardMember,
                    "isValidIdMember" : itm.isValidIdMember,

                    "lastName" : itm.lastName,
                    "lastSalary" : itm.lastSalary,
                    "lastWorkingDateMember" : itm.lastWorkingDateMember,
                    "locationName" : itm.locationName,
                    "middleName" : itm.middleName,
                    "modifiedBy" : itm.modifiedBy,
                    "modifiedByName" : itm.modifiedByName,
                    "modifiedDate" : itm.modifiedDate,
                    "netPay" : itm.netPay,
                    "notesMember" : itm.notesMember,
                    "paramountId" : itm.paramountId,
                    "payPeriodId" : itm.payPeriodId,
                    "paycardTypeIdMember" : itm.paycardTypeIdMember,
                    "payrollPeriods" : itm.payrollPeriods,
                    "payrollRemarks" : itm.payrollRemarks,
                    "periodCovered" : itm.periodCovered,
                    "phicNumber" : itm.phicNumber,
                    "positionId" : itm.positionId,
                    "positionName" : itm.positionName,
                    "profileId" : itm.profileId,
                    "reasonForLeaving" : itm.reasonForLeaving,
                    "reasonForLeavingId" : itm.reasonForLeavingId,
                    "remarksClient" : itm.remarksClient,
                    "remarksLrd" : itm.remarksLrd,
                    "remarksMember" : itm.remarksMember,
                    "remarksRmt" : itm.remarksRmt,
                    "resignationDateMember" : itm.resignationDateMember,
                    "returnedByARE" : itm.returnedByARE,
                    "returnedByClient" : itm.returnedByClient,
                    "returnedByFinance" : itm.returnedByFinance,
                    "returnedByLRD" : itm.returnedByLRD,
                    "returnedByRMT" : itm.returnedByRMT,
                    "returnedByServices" : itm.returnedByServices,
                    //"reviewStatus" : this.state.dedArrLength === 0 ? "1" : itm.reviewStatus,
                    //"reviewStatusResults" : this.state.dedArrLength === 0 ? "DONE" : itm.reviewStatusResults,
                    "reviewStatus" : itm.reviewStatus,
                    "reviewStatusResults" : itm.reviewStatusResults,
                    "separationPay" : itm.separationPay,
                    "servicesRemarks" : itm.servicesRemarks,
                    "sil" : itm.sil,
                    "sssNumber" : itm.sssNumber,
                    "submissionDateMember" : itm.submissionDateMember,
                    "submitted" : itm.submitted,
                    "submittedByLead" : itm.submittedByLead,
                    "submittedByStaff" : itm.submittedByStaff,
                    "suffixName" : itm.suffixName,
                    "thirteenthMonth" : itm.thirteenthMonth,
                    "tinNumber" : itm.tinNumber,
                    "totalBalance" : itm.totalBalance,
                    "typeOfCashCardMember" : itm.typeOfCashCardMember,
                    "updatedByARE" : itm.updatedByARE,
                    "updatedByClient" : itm.updatedByClient,
                    "updatedByFinance" : itm.updatedByFinance,
                    "updatedByLRD" : itm.updatedByLRD,
                    "updatedByRMT" : itm.updatedByRMT,
                    "updatedByServices" : itm.updatedByServices,
                    "validateByRmt" : itm.validateByRmt,

                    "servicesAttachments" : itm.servicesAttachments,
                    "clearanceDetails" : itm.clearanceDetails,
                    "clientList" : clientArr,
                    "clearanceDeductions" : itm.clearanceDeductions,
                        
                    "isUpdatedByClient" : itm.isUpdatedByClient,
                    "isUpdatedByLRD" : itm.isUpdatedByLRD,
                    "isUpdatedByRMT" : itm.isUpdatedByRMT,
                    "isUpdatedByServices" : itm.isUpdatedByServices,
                    "isUpdatedByARE" : itm.isUpdatedByARE,
                    "isUpdatedByFinance" : itm.isUpdatedByFinance,
                    "isReturnedByClient" : itm.isReturnedByClient,
                    "isReturnedByLRD" : itm.isReturnedByLRD,
                    "isReturnedByRMT" : itm.isReturnedByRMT,
                    "isReturnedByServices" : itm.isReturnedByServices,
                    "isReturnedByARE" : itm.isReturnedByARE,
                    "isReturnedByFinance" : itm.isReturnedByFinance,
                    "isSubmittedByStaff": itm.isSubmittedByStaff,
                    "isSubmittedByLead":itm.isSubmittedByLead,
                    
                    "BatchPayroll" : itm.batchPayroll,
                    "Adjustment" : itm.adjustment,
                    "OtherPayroll" : itm.otherPayroll,
                    "Cbu" : itm.cbu,
                    "Dividend" : itm.dividend,
                    "CompletionDate" : itm.completionDate,
                    "totalInclusion" : itm.totalInclusion,
                    "dateHired" : itm.dateHired,
                    "dateResigned" : itm.dateResigned,
                    "isParent" : itm.isParent,
                    "isChild" : itm.isChild,
                    "parentId" : itm.parentId,
                    "dateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                    "isAre" : itm.isAre,
                    "isPayroll" : itm.isPayroll,
                    "lastModifiedBy" : itm.lastModifiedBy,
                    "actualPayCard" : itm.actualPayCard,
                }
                parentArray.push(parentObj)
            })
            ////////////////console.log("parentArray")
            ////////////////console.log(parentArray)
            this.setState({
                isloading   :   false,
                trainingGrid : parentArray
            })
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }


    GetFilterDataByBatchNumber = async() => {
        this.setState({
            isloading   :   true,
        })
        let submittedDateFromPayroll = this.state.submittedDateFromPayroll ? moment(this.state.submittedDateFromPayroll).format('MM/DD/YYYY') : ""
        let submittedDateToPayroll = this.state.submittedDateToPayroll ? moment(this.state.submittedDateToPayroll).format('MM/DD/YYYY') : ""
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   this.state.statusId,
            "FromDateCreated"       :   this.state.fromDateCreated,
            "ToDateCreated"         :   this.state.toDateCreated,
            "FromClearanceDate"     :   this.state.fromClearanceDate,
            "ToClearanceDate"       :   this.state.toClearanceDate,
            "FromDateReceive"       :   this.state.fromDateReceived,
            "ToDateReceive"         :   this.state.toDateReceived,
            "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
            "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
            "SubmittedDateFromPayroll"   :   submittedDateFromPayroll,
            "SubmittedDateToPayroll"     :  submittedDateToPayroll,
            "ReviewStatusId"     :   this.state.reviewStatusId,
            //"BatchNumber"           :   this.state.batchNumber === "" ? "" : this.state.batchNumber,
            // "ReasonForLeaving"      :   this.state.reason === "" ? "" : this.state.reason,
        };
        ////console.log("param batchNumber")
        ////console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            //let data = res.data.clearance.filter ( x => x.isClearanceStatus === "6" || x.isPayroll === "1" && x.batchNumber === this.state.batchNumber);
            let data = res.data.clearance.filter ( x => x.batchNumber === this.state.batchNumber);
            ////////////////console.log("GetClearance xx batchNumber")
            ////////////////console.log(data)
            let parentArray = []
            let reviewStatus = "0"
            let reviewStatusResults = ""
            data.forEach(itm => {
                let clientArr = []
                let deductionArr = []
                itm.clearanceDetails.forEach(clearanceDetails => {
                    let deductionArr = []
                    itm.clearanceDeductions.forEach(subsubitm => {
                        if(clearanceDetails.companyName === subsubitm.client){
                            let objDed = {
                                "balance" : subsubitm.balance,
                                "chargesAmount" : subsubitm.chargesAmount,
                                "checkBox" : subsubitm.checkBox,
                                "id" : subsubitm.clearanceDeductionId,
                                "collectionAmount" : subsubitm.collectionAmount,
                                "deduction" : subsubitm.deduction,
                                "deductionId" : subsubitm.deductionId,
                                "deductionType" : subsubitm.deductionType,
                                "deductiontypeId" : subsubitm.deductiontypeId,
                                "employeeId" : subsubitm.employeeId,
                                "employeeNo" : subsubitm.employeeNo,
                                "groupBy" : subsubitm.groupBy,
                                "headerId" : subsubitm.headerId,
                                "isModified" : subsubitm.isModified,
                                "remarks" : subsubitm.remarks,
                                "client" : subsubitm.client,
                                "priority" : subsubitm.priority
                            }
                            deductionArr.push(objDed)
                        }
                    })
                    this.setState({
                        dedArrLength : deductionArr.length
                    })
                    let clientObj = {
                        "id" : clearanceDetails.id,
                        "client" : clearanceDetails.companyName,
                        "isClearedSubmit" : deductionArr.length === 0 ? "1" : itm.isClearedSubmit,
                        "deducionList" : deductionArr
                    }
                    clientArr.push(clientObj)
                })

                ////////////////console.log("clientArr xx batchNumber")
                ////////////////console.log(clientArr)
                let parentObj = {
                    "id" : itm.id,
                    "areRemarks" : itm.areRemarks,
                    "batchNumber" : itm.batchNumber,
                    "cashCardNumberMember" : itm.cashCardNumberMember,
                    "clearanceDateMember" : itm.clearanceDateMember,
                    "clearanceStatus" : itm.clearanceStatus,
                    "clearedSubmit" : itm.clearedSubmit,
                    "clientId" : itm.clientId,
                    "clientName" : itm.clientName,
                    "closingRemarks" : itm.closingRemarks,
                    "createdBy" : itm.createdBy,
                    "createdByName" : itm.createdByName,
                    "createdDate" : itm.createdDate,
                    "cycleDays" : itm.cycleDays,
                    "dateCreated" : itm.dateCreated,
                    "dateForwardedToAmt" : itm.dateForwardedToAmt,
                    "dateOfBirth" : itm.dateOfBirth,
                    "dateReceivedByAdmin" : itm.dateReceivedByAdmin,
                    "dateReceivedFromAmt" : itm.dateReceivedFromAmt,
                    "dateReturnedByARE" : itm.dateReturnedByARE,
                    "dateReturnedByClient" : itm.dateReturnedByClient,
                    "dateReturnedByFinance" : itm.dateReturnedByFinance,
                    "dateReturnedByLRD" : itm.dateReturnedByLRD,
                    "dateReturnedByPayroll" : itm.dateReturnedByPayroll,
                    "dateReturnedByRMT" : itm.dateReturnedByRMT,
                    "dateReturnedByServices" : itm.dateReturnedByServices,
                    "dateSettle" : itm.dateSettle,
                    "dateStart" : itm.dateStart,
                    "dateSubmitted" : itm.dateSubmitted,
                    "dateSubmittedByLead" : itm.dateSubmittedByLead,
                    "dateSubmittedByStaff" : itm.dateSubmittedByStaff,
                    "dateUpdatedByARE" : itm.dateUpdatedByARE,
                    "dateUpdatedByClient" : itm.dateUpdatedByClient,
                    "dateUpdatedByFinance" : itm.dateUpdatedByFinance,
                    "dateUpdatedByLRD" : itm.dateUpdatedByLRD,
                    "dateUpdatedByRMT" : itm.dateUpdatedByRMT,
                    "dateUpdatedByServices" : itm.dateUpdatedByServices,
                    "employeeId" : itm.employeeId,
                    "employeeName" : itm.employeeName,
                    "employeeNo" : itm.employeeNo,
                    "finalized" : itm.finalized,
                    "financeRemarks" : itm.financeRemarks,
                    "firstName" : itm.firstName,
                    "generation" : itm.generation,
                    "grossPay" : itm.grossPay,
                    "hdmfNumber" : itm.hdmfNumber,
                    "incentives" : itm.incentives,
                    "inclusions" : itm.inclusions,
                    "isCOERequestFormMember" : itm.isCOERequestFormMember,
                    "isCash" : itm.isCash,
                    "isCashCardMember" : itm.isCashCardMember,
                    "isCheckMember" : itm.isCheckMember,
                    "isCheckedByRmt" : itm.isCheckedByRmt,
                    "isClearanceStatus" : itm.isClearanceStatus,
                    "isClearedClient" : itm.isClearedClient,
                    "isClearedRmt" : itm.isClearedRmt,
                    "isDeleted" : itm.isDeleted,
                    "isEligible" : itm.isEligible,
                    "isEligibleClient" : itm.isEligibleClient,
                    "isFinalized" : itm.isFinalized,
                    "isForwardToARERmt" : itm.isForwardToARERmt,
                    "isGenerated" : itm.isGenerated,
                    "isHDMFNumberMember" : itm.isHDMFNumberMember,
                    "isMlhuillierMember" : itm.isMlhuillierMember,
                    "isModified" : itm.isModified,
                    "isModifiedByARE" : itm.isModifiedByARE,
                    "isNonEligibleClient" : itm.isNonEligibleClient,
                    "isNotEligible" : itm.isNotEligible,
                    "isPHICNumberMember" : itm.isPHICNumberMember,
                    "isPalawanMember" : itm.isPalawanMember,
                    "isResignationLetterMember" : itm.isResignationLetterMember,
                    "isRetrunedServicesRmt" : itm.isRetrunedServicesRmt,
                    "isReturnedRmt" : itm.isReturnedRmt,
                    "isSSSNumberMember" : itm.isSSSNumberMember,
                    "isSettleWithDole" : itm.isSettleWithDole,
                    "isTINNumberMember" : itm.isTINNumberMember,
                    "isTurnoverChecklistMember" : itm.isTurnoverChecklistMember,
                    "isTypeOfCashCardMember" : itm.isTypeOfCashCardMember,
                    "isValidIdMember" : itm.isValidIdMember,
                    "lastName" : itm.lastName,
                    "lastSalary" : itm.lastSalary,
                    "lastWorkingDateMember" : itm.lastWorkingDateMember,
                    "locationName" : itm.locationName,
                    "middleName" : itm.middleName,
                    "modifiedBy" : itm.modifiedBy,
                    "modifiedByName" : itm.modifiedByName,
                    "modifiedDate" : itm.modifiedDate,
                    "netPay" : itm.netPay,
                    "notesMember" : itm.notesMember,
                    "paramountId" : itm.paramountId,
                    "payPeriodId" : itm.payPeriodId,
                    "paycardTypeIdMember" : itm.paycardTypeIdMember,
                    "payrollPeriods" : itm.payrollPeriods,
                    "payrollRemarks" : itm.payrollRemarks,
                    "periodCovered" : itm.periodCovered,
                    "phicNumber" : itm.phicNumber,
                    "positionId" : itm.positionId,
                    "positionName" : itm.positionName,
                    "profileId" : itm.profileId,
                    "reasonForLeaving" : itm.reasonForLeaving,
                    "reasonForLeavingId" : itm.reasonForLeavingId,
                    "remarksClient" : itm.remarksClient,
                    "remarksLrd" : itm.remarksLrd,
                    "remarksMember" : itm.remarksMember,
                    "remarksRmt" : itm.remarksRmt,
                    "resignationDateMember" : itm.resignationDateMember,
                    "returnedByARE" : itm.returnedByARE,
                    "returnedByClient" : itm.returnedByClient,
                    "returnedByFinance" : itm.returnedByFinance,
                    "returnedByLRD" : itm.returnedByLRD,
                    "returnedByRMT" : itm.returnedByRMT,
                    "returnedByServices" : itm.returnedByServices,
                    //"reviewStatus" : this.state.dedArrLength === 0 ? "1" : itm.reviewStatus,
                    //"reviewStatusResults" : this.state.dedArrLength === 0 ? "DONE" : itm.reviewStatusResults,
                    "reviewStatus" : itm.reviewStatus,
                    "reviewStatusResults" : itm.reviewStatusResults,
                    "separationPay" : itm.separationPay,
                    "servicesRemarks" : itm.servicesRemarks,
                    "sil" : itm.sil,
                    "sssNumber" : itm.sssNumber,
                    "submissionDateMember" : itm.submissionDateMember,
                    "submitted" : itm.submitted,
                    "submittedByLead" : itm.submittedByLead,
                    "submittedByStaff" : itm.submittedByStaff,
                    "suffixName" : itm.suffixName,
                    "thirteenthMonth" : itm.thirteenthMonth,
                    "tinNumber" : itm.tinNumber,
                    "totalBalance" : itm.totalBalance,
                    "typeOfCashCardMember" : itm.typeOfCashCardMember,
                    "updatedByARE" : itm.updatedByARE,
                    "updatedByClient" : itm.updatedByClient,
                    "updatedByFinance" : itm.updatedByFinance,
                    "updatedByLRD" : itm.updatedByLRD,
                    "updatedByRMT" : itm.updatedByRMT,
                    "updatedByServices" : itm.updatedByServices,
                    "validateByRmt" : itm.validateByRmt,

                    "servicesAttachments" : itm.servicesAttachments,
                    "clearanceDetails" : itm.clearanceDetails,
                    "clientList" : clientArr,
                    "clearanceDeductions" : itm.clearanceDeductions,
                        
                    "isUpdatedByClient" : itm.isUpdatedByClient,
                    "isUpdatedByLRD" : itm.isUpdatedByLRD,
                    "isUpdatedByRMT" : itm.isUpdatedByRMT,
                    "isUpdatedByServices" : itm.isUpdatedByServices,
                    "isUpdatedByARE" : itm.isUpdatedByARE,
                    "isUpdatedByFinance" : itm.isUpdatedByFinance,
                    "isReturnedByClient" : itm.isReturnedByClient,
                    "isReturnedByLRD" : itm.isReturnedByLRD,
                    "isReturnedByRMT" : itm.isReturnedByRMT,
                    "isReturnedByServices" : itm.isReturnedByServices,
                    "isReturnedByARE" : itm.isReturnedByARE,
                    "isReturnedByFinance" : itm.isReturnedByFinance,
                    "isSubmittedByStaff": itm.isSubmittedByStaff,
                    "isSubmittedByLead":itm.isSubmittedByLead,
                    
                    "batchPayroll" : itm.batchPayroll,
                    "adjustment" : itm.adjustment,
                    "otherPayroll" : itm.otherPayroll,
                    "cbu" : itm.cbu,
                    "dividend" : itm.dividend,
                    "completionDate" : itm.completionDate,
                    "totalInclusion" : itm.totalInclusion,
                    "dateHired" : itm.dateHired,
                    "dateResigned" : itm.dateResigned,
                    "isParent" : itm.isParent,
                    "isChild" : itm.isChild,
                    "parentId" : itm.parentId,
                    "dateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                    "isAre" : itm.isAre,
                    "isPayroll" : itm.isPayroll,
                    "lastModifiedBy" : this.state.userinfo.fullName,
                    "actualPayCard" : itm.actualPayCard,
                }
                parentArray.push(parentObj)
            })
            //////console.log("parentArray selection grid")
            //////console.log(parentArray)
            this.setState({
                isloading   :   false,
                trainingGrid : parentArray
            })
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeRemarksAre = (e) => {
        this.setState({remarksAre:e.target.value.toUpperCase(), isshow:false})
    }

    handleClickReturn = async() => {
        this.setState({
            isloading   :   true
        });
        if(this.state.remarksAre === ""){
            this.setState( {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please Input Remarks",
                fade            :   true
            })
        }
        
        let finalArr = []
        //////////////////console.log("this.state.trainingGrid")
        //////////////////console.log(this.state.trainingGrid)
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        for(let x = 0; x < this.state.trainingGrid.length; x++){
            if(this.state.trainingGrid[x]["isSelectedByAre"] === "1"){
                let finalObjListArray = {
                    "Id" : this.state.trainingGrid[x]["id"],
                    "ClientId":this.state.trainingGrid[x]["clientId"],
                    "ProfileId":this.state.trainingGrid[x]["profileId"],
                    "EmployeeId":this.state.trainingGrid[x]["employeeId"],
                    "PayPeriodId":this.state.trainingGrid[x]["payPeriodId"],
                    "DateCreated":this.state.trainingGrid[x]["dateCreated"],
                    "ClearanceDateMember":this.state.trainingGrid[x]["clearanceDateMember"],
                    "ResignationDateMember":this.state.trainingGrid[x]["resignationDateMember"],
                    "SubmissionDateMember":this.state.trainingGrid[x]["submissionDateMember"],
                    "LastWorkingDateMember":this.state.trainingGrid[x]["lastWorkingDateMember"],
                    "DateSubmitted":this.state.trainingGrid[x]["dateSubmitted"],
                    "IsSSSNumberMember":this.state.trainingGrid[x]["isSSSNumberMember"],
                    "IsTINNumberMember":this.state.trainingGrid[x]["isTINNumberMember"],
                    "IsPHICNumberMember":this.state.trainingGrid[x]["isPHICNumberMember"],
                    "IsHDMFNumberMember":this.state.trainingGrid[x]["isHDMFNumberMember"],
                    "IsCashCardMember":this.state.trainingGrid[x]["isCashCardMember"],
                    "IsValidIdMember":this.state.trainingGrid[x]["isValidIdMember"],
                    "IsResignationLetterMember":this.state.trainingGrid[x]["isResignationLetterMember"],
                    "IsTurnoverChecklistMember":this.state.trainingGrid[x]["isTurnoverChecklistMember"],
                    "IsCOERequestFormMember":this.state.trainingGrid[x]["isCOERequestFormMember"],
                    "IsTypeOfCashCardMember":this.state.trainingGrid[x]["isTypeOfCashCardMember"], // cash card mode of payment
                    "PaycardTypeIdMember":this.state.trainingGrid[x]["paycardTypeIdMember"],
                    "TypeOfCashCardMember":this.state.trainingGrid[x]["typeOfCashCardMember"],
                    "CashCardNumberMember":this.state.trainingGrid[x]["cashCardNumberMember"],
                    "IsCheckMember":this.state.trainingGrid[x]["isCheckMember"],
                    "IsMlhuillierMember":this.state.trainingGrid[x]["isMlhuillierMember"],
                    "IsPalawanMember":this.state.trainingGrid[x]["isPalawanMember"],
                    "RemarksMember":this.state.trainingGrid[x]["remarksMember"],
                    "NotesMember":this.state.trainingGrid[x]["notesMember"],
                    "ReasonForLeaving":this.state.trainingGrid[x]["reasonForLeaving"],
                    "DateForwardedToAmt": this.state.trainingGrid[x]["dateForwardedToAmt"],
                    "DateReceivedFromAmt": this.state.trainingGrid[x]["dateReceivedFromAmt"],
                    "IsEligible":this.state.trainingGrid[x]["isEligible"],
                    "IsNotEligible":this.state.trainingGrid[x]["isNotEligible"],
                    "SssNumber": this.state.trainingGrid[x]["sssNumber"],
                    "TinNumber": this.state.trainingGrid[x]["tinNumber"],
                    "PhicNumber": this.state.trainingGrid[x]["phicNumber"],
                    "HdmfNumber": this.state.trainingGrid[x]["hdmfNumber"],
                    "IsClearedClient":this.state.trainingGrid[x]["isClearedClient"],
                    "IsEligibleClient":this.state.trainingGrid[x]["isEligibleClient"],
                    "IsNonEligibleClient":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "RemarksClient":this.state.trainingGrid[x]["remarksClient"],

                    "IsClearedRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "IsReturnedRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "ValidateByRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "IsForwardToARERmt":this.state.trainingGrid[x]["isNonEligibleClient"] ,
                    "IsRetrunedServicesRmt":this.state.trainingGrid[x]["isNonEligibleClient"],
                    "IsCheckedByRmt":this.state.trainingGrid[x]["isCheckedByRmt"],
                    "RemarksRmt":this.state.trainingGrid[x]["remarksRmt"],

                    "RemarksLrd":this.state.trainingGrid[x]["remarksLrd"],
                    "ServicesRemarks":this.state.trainingGrid[x]["servicesRemarks"] ,

                    "IsClearanceStatus": "5",
                        
                    "DateUpdatedByClient": this.state.trainingGrid[x]["dateUpdatedByClient"],
                    "UpdatedByClient":this.state.trainingGrid[x]["isUpdatedByClient"],

                    "DateUpdatedByLRD":this.state.trainingGrid[x]["dateUpdatedByLRD"],
                    "UpdatedByLRD":this.state.trainingGrid[x]["isUpdatedByLRD"],

                    "DateUpdatedByRMT":this.state.trainingGrid[x]["dateUpdatedByRMT"],
                    "UpdatedByRMT":this.state.trainingGrid[x]["isUpdatedByRMT"],

                    "DateUpdatedByServices":"",
                    "UpdatedByServices":"",
                    "BatchNumber" : "",

                    "DateUpdatedByARE":moment(this.state.datenow).format('MM/DD/YYYY'),
                    "UpdatedByARE":this.state.userinfo.userId,

                    "DateUpdatedByFinance":this.state.trainingGrid[x]["dateUpdatedByFinance"],
                    "UpdatedByFinance":this.state.trainingGrid[x]["isUpdatedByFinance"],
                    "DateSubmittedByStaff":this.state.trainingGrid[x]["dateSubmittedByStaff"],
                    "SubmittedByStaff": this.state.trainingGrid[x]["isSubmittedByStaff"],
                    "DateSubmittedByLead":this.state.trainingGrid[x]["dateSubmittedByLead"],
                    "SubmittedByLead":this.state.trainingGrid[x]["isSubmittedByLead"],

                    "DateReturnedByClient":this.state.trainingGrid[x]["dateReturnedByClient"],
                    "ReturnedByClient":this.state.trainingGrid[x]["isReturnedByClient"],
                    "DateReturnedByLRD":this.state.trainingGrid[x]["dateReturnedByLRD"],
                    "ReturnedByLRD":this.state.trainingGrid[x]["isReturnedByLRD"],
                    "DateReturnedByRMT":this.state.trainingGrid[x]["dateReturnedByRMT"],
                    "ReturnedByRMT":this.state.trainingGrid[x]["isReturnedByRMT"],
                    "DateReturnedByServices":this.state.trainingGrid[x]["dateReturnedByServices"],
                    "ReturnedByServices":this.state.trainingGrid[x]["isReturnedByServices"],

                    "DateReturnedByARE":moment(this.state.datenow).format('MM/DD/YYYY'),
                    "ReturnedByARE":this.state.userinfo.userId,
                    "AreRemarks" : this.state.remarksAre,

                    "DateReturnedByFinance":this.state.trainingGrid[x]["dateReturnedByFinance"],
                    "ReturnedByFinance":this.state.trainingGrid[x]["isReturnedByFinance"],
                    
                    "DateSettle"        : this.state.trainingGrid[x]["dateSettle"],
                    "IsSettleWithDole"  :  this.state.trainingGrid[x]["isSettleWithDole"],

                    "DateReceivedByAdmin" : this.state.trainingGrid[x]["dateReceivedByAdmin"],
                    "IsCash" : this.state.trainingGrid[x]["isCash"],
                    "IsDeleted" : "0",
                    
                    // 21/07/2023
                    "ParamountId" : this.state.trainingGrid[x]["paramountId"],
                    "LastSalary" : this.state.trainingGrid[x]["lastSalary"],
                    "Incentives" : this.state.trainingGrid[x]["incentives"],
                    "Sil" : this.state.trainingGrid[x]["sil"],
                    "ThirteenthMonth" : this.state.trainingGrid[x]["thirteenthMonth"],
                    "Inclusions" : this.state.trainingGrid[x]["inclusions"],
                    "GrossPay" : this.state.trainingGrid[x]["grossPay"],
                    "NetPay" : this.state.trainingGrid[x]["netPay"],
                    "PayPeriodId" : this.state.trainingGrid[x]["payPeriodId"],
                    "PeriodCovered" : this.state.trainingGrid[x]["periodCovered"],
                    "IsModifiedByARE" : this.state.trainingGrid[x]["isDeleted"],
                    "SeparationPay" : this.state.trainingGrid[x]["separationPay"],
                    "ReviewStatus" : "0",
                    "PayrollRemarks" : this.state.trainingGrid[x]["payrollRemarks"],
                    "FinanceRemarks" : this.state.trainingGrid[x]["financeRemarks"],
                    "DateReturnedByPayroll" : this.state.trainingGrid[x]["dateReturnedByPayroll"],
                    "TotalBalance" : this.state.trainingGrid[x]["totalBalance"],
                    "IsClearedSubmit" : "0",
                    "PayrollPeriods" : this.state.trainingGrid[x]["payrollPeriods"],
                    "IsGenerated" : this.state.trainingGrid[x]["isGenerated"],
                    "IsFinalized" : this.state.trainingGrid[x]["isFinalized"],
                    
                    "BatchPayroll" : this.state.trainingGrid[x]["batchPayroll"],
                    "Adjustment" : this.state.trainingGrid[x]["adjustment"],
                    "OtherPayroll" : this.state.trainingGrid[x]["otherPayroll"],
                    "Cbu" : this.state.trainingGrid[x]["cbu"],
                    "Dividend" : this.state.trainingGrid[x]["dividend"],
                    "CompletionDate" : this.state.trainingGrid[x]["completionDate"],
                    "TotalInclusion" : this.state.trainingGrid[x]["totalInclusion"],
                    "DateHired" : this.state.trainingGrid[x]["dateHired"],
                    "DateResigned" : this.state.trainingGrid[x]["dateResigned"],
                    "IsParent" : this.state.trainingGrid[x]["isParent"],
                    "IsChild" : this.state.trainingGrid[x]["isChild"],
                    "ParentId" : this.state.trainingGrid[x]["parentId"],
                    "DateSubmittedToPayroll" : "",
                    "IsAre" : "0",
                    "IsPayroll" : "0",
                    "LastModifiedBy" : this.state.userinfo.fullName,
                    "ActualPayCard" : this.state.trainingGrid[x]["actualPayCard"],

                    "servicesAttachments" : this.state.trainingGrid[x]["servicesAttachments"],
                    "clearanceDeductions":this.state.trainingGrid[x]["clearanceDeductions"],
                    "clearanceDetails" :this.state.trainingGrid[x]["clearanceDetails"],
                }
                finalArr.push(finalObjListArray)
            }
        }
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :finalArr
        }
        //////console.log("Submit param")
        //////console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // this.GetDataGrid()
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                isDisabledButton : true
            });
            //this.RefreshPage();
            this.GetDataGrid();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    render() {
        const { ExportCSVButton } = CSVExport;
        let trainingColumn = [
            {
                dataField: 'batchNumber',
                text: 'Batch Number',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'employeeNo',
                text: 'Employee Number',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'employeeName',
                text: 'Employee',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'dateStart',
                text: 'Date Start',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'lastWorkingDateMember',
                text: 'Last Working Date',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'reasonForLeaving',
                text: 'Reason',
                editable: false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.reasonForLeaving === "DOLE CASES"){
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%", color : "red"}}>
                                DOLE CASES
                            </Form.Label>   
                        )
                    } else {
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%"}}>
                                {row.reasonForLeaving}
                            </Form.Label>  
                            /* <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px', marginTop : "3%"}}
                                variant="link" onClick={e => this.handleEditClick(row)}
                            >VIEW ATD</Button> */

                        )
                    }
                        
                },
                headerStyle : () => {
                    return { width  : "5%", textAlign : "center" };
                },
                searchable: false
            },
            {
                dataField: 'dateReturnedByPayroll',
                text: 'Date Return',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'payrollRemarks',
                text: 'Payroll Return Reasons',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'cycleDays',
                text: 'Cycle Day(s)',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'createdByName',
                text: 'Created By',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'isCheckedByRmt',
                text: 'ATD',
                editable: false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.isCheckedByRmt === "1"){
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%", color : "red"}}>
                                NO
                            </Form.Label>   
                        )
                    } else {
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%"}}>
                                YES
                            </Form.Label>  
                            /* <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px', marginTop : "3%"}}
                                variant="link" onClick={e => this.handleEditClick(row)}
                            >VIEW ATD</Button> */

                        )
                    }
                        
                },
                headerStyle : () => {
                    return { width  : "6.66%", textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'totalBalance',
                text: 'Total Deduction',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'reviewStatusResults',
                text: 'Review Result',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'dateSubmittedToPayroll',
                text: 'Date Submitted To Payroll',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'isParent',
                text: 'PARENT',
                editable: false,
                style:{width: "100%"},
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            nonSelectable: this.state.trainingGrid.filter(r => r.isAre === "1"),
            onSelect: (row, isSelect, rowIndex, e) => {
                let _selectedSubId = this.state.selectedSubId
                let disable = true
                this.state.trainingGrid.map(function(item,i){
                    // let disabled = true
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isSelectedByAre = isSelect ? "1" : "0"
                         disable = isSelect ? false : true
                    }
                })
                this.setState({isDisabledButton:disable})

            },
            onSelectAll: (isSelected, row) => {
                let disable = true
                if (isSelected) {
                    this.state.trainingGrid.filter(r => r.isAre === "0").forEach(item =>{
                        item.isSelectedByAre = isSelected ? "1" : "0"
                        disable = isSelected ? false : true
                        this.setState({
                            isDisabledButton : false,
                        })
                    })
                    return this.state.trainingGrid.filter(x => x.isAre === "0").map(row => row.id);
                } else {
                    this.setState({
                        isDisabledButton : true,
                    })
                }

            }
        };
        const rowStyle = (row, rowIndex) => {
            if(row.isAre==="1"){
                return { backgroundColor: '#FFE156', color: '#000'}

            }
        }
        let expandRow = {
            renderer: row => (
              <div>
                  <BootstrapTable
                    keyField = "id"
                    data = { row.clientList}
                    columns = { clientCols }
                    pagination={ paginationFactory({sizePerPageRenderer}) }
                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedClient(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                    rowClasses="noser-table-row-class no-checkbox"
                    striped
                    hover
                    condensed
                    //selectRow={selectRowSubCols}
                    expandRow={ deductionRow }
                />
              </div>
            ),
            showExpandColumn: true,
            //nonExpandable: this.state.deductionEmptyArr
        };
        let clientCols = [
            {
                dataField: 'client',
                text: 'Client',
                editable : false,
                style:{width: "35%"},
            },  
            {
                dataField: 'dateHired',
                text: 'Date Start',
                editable : false,
                style:{width: "15%"},
            }, 
            {
                dataField: 'dateResigned',
                text: 'Date End',
                editable : false,
                style:{width: "15%"},
            }, 
            {
                dataField: 'isClearedSubmit',
                text: 'Cleared',
                // editable : true,
                formatter: (cell, row) => {
                    if(row.isClearedSubmit!='' && row.isClearedSubmit!=null){
                        if(this.state.tblClearedArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblClearedArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblClearedArrLst
                },
                headerStyle: () => {
                    return { width: "35%"};
                },
                style:{width: "35%"},
            },
        ]

        let deductionRow = {
            renderer: row => (
              <div>
                  <BootstrapTable
                    keyField = "id"
                    data = { row.deducionList}
                    columns = { deductionCols }
                    pagination={ paginationFactory({sizePerPageRenderer}) }
                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, rows) => {
                            this.GridDataModifiedDeduction(oldValue, newValue, row, row.employeeNo)
                            }
                        })
                    }
                    rowClasses="noser-table-row-class no-checkbox"
                    striped
                    hover
                    condensed
                    selectRow={selectDeductionRows}
                />
              </div>
            ),
            showExpandColumn: true,
            //nonExpandable: this.state.deductionEmptyArr
        };
        let deductionCols = [
            {
                dataField: 'deductionType',
                text: 'Deduction Type',
                editable : false,
                headerStyle: () => {
                    return { width: "15%"};
                },
                style:{width: "15%"},
            },
            {
                dataField: 'deduction',
                text: 'Deduction',
                editable : false,
                headerStyle: () => {
                    return { width: "15%"};
                },
                style:{width: "15%"},
            },
            {
                dataField: 'chargesAmount',
                text: 'Total Charges',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "15%"};
                },
                style:{textAlign:'right', width: "15%"},
            },
            {
                dataField: 'collectionAmount',
                text: 'Total Collection',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "15%"};
                },
                style:{textAlign:'right', width: "15%"},
            },
            {
                dataField: 'balance',
                text: 'Total Balance',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "15%"};
                },
                style:{textAlign:'right', width: "15%"},
                //formatter: amountFormatter
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable : true,
                headerStyle: () => {
                    return { width: "15%"};
                },
                style:{width: "15%"},
            },
            {
                dataField: 'checkBox',
                text: 'REVIEWED',
                // editable : true,
                formatter: (cell, row) => {
                    if(row.checkBox!='' && row.checkBox!=null){
                        if(this.state.tblReviewedArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblReviewedArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblReviewedArrLst
                },
                headerStyle: () => {
                    return { width: "10%"};
                },
                style:{width: "10%"},
            },
            // {
            //     dataField: 'action',
            //     text: 'ACTION',
            //     headerStyle: () => {
            //         return { width: "10%" };
            //     },
            //     editable:false,
            //     formatter   :   (cell, row, isSelect) => {
            //         return (
            //             <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
            //                 variant="link" onClick={e => this.handleClickedDone(row)}
            //             >DONE</Button>
            //             );
            //     },
            // }  
        ]
        
        let exportColumn = [
            {
                dataField: 'batchNumber',
                text: 'Batch Number',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'client',
                text: 'Client',
                editable : false,
                headerStyle: () => {
                    return { width: "25%"};
                },
                style:{width: "25%"},
            },
            {
                dataField: 'employeeNo',
                text: 'Employee Number',
                editable: false,
                style:{width: "100%"},
            },
            {
                dataField: 'employeeName',
                text: 'Employee Name',
                editable : false,
                headerStyle: () => {
                    return { width: "25%"};
                },
                style:{width: "25%"},
            },
            {
                dataField: 'deduction',
                text: 'Deduction',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "25%"};
                },
                style:{textAlign:'right', width: "25%"},
            },
            {
                dataField: 'chargesAmount',
                text: 'Charges Amount',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "25%"};
                },
                style:{textAlign:'right', width: "25%"},
            },
        ]
        let selectDeductionRows = {
            mode: 'checkBox',
            clickToSelectAndEditCell: false,
            //selected : this.state.selectedHeaderId,
        };
        
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; AR-E MODULE</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLIENT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.client}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder='SELECT CLIENT'
                                            //selected={[this.state.clientName]}
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    EMPLOYEE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.employee}
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder='SELECT EMPLOYEE'
                                            //defaultSelected={[this.state.employeeName]}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    REASON FOR LEAVING
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.refReason}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedReason}
                                            options={this.state.reasonDDL}
                                            placeholder='Select reason for leaving'

                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        Status
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.name}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeStatus}
                                            options={this.state.statusDDL} 
                                            placeholder='SELECT STATUS'
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    From Date Created
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.fromDateCreated}
                                            onChange={this.handleChangeFromDateCreated}
                                            // minDate={this.minDate}
                                            value={this.props.fromDateCreated}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    To Date Created
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.toDateCreated}
                                            onChange={this.handleChangeToDateCreated}
                                            // minDate={this.minDate}
                                            value={this.props.toDateCreated}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    From Clearance Date
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.fromClearanceDate}
                                            onChange={this.handleChangeFromClearanceDate}
                                            // minDate={this.minDate}
                                            value={this.props.fromClearanceDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    To Clearance Date
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.toClearanceDate}
                                            onChange={this.handleChangeToClearanceDate}
                                            // minDate={this.minDate}
                                            value={this.props.toClearanceDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    From Date Received
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.fromDateReceived}
                                            onChange={this.handleChangeFromDateReceived}
                                            // minDate={this.minDate}
                                            value={this.props.fromDateReceived}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    To Date Received
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.toDateReceived}
                                            onChange={this.handleChangeToDateReceived}
                                            // minDate={this.minDate}
                                            value={this.props.toDateReceived}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    From Last Working Date
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.fromLastWorkingDate}
                                            onChange={this.handleChangeFromLastWorkingDate}
                                            // minDate={this.minDate}
                                            value={this.props.fromLastWorkingDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    To Last Working Date
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.toLastWorkingDate}
                                            onChange={this.handleChangeToLastWorkingDate}
                                            // minDate={this.minDate}
                                            value={this.props.toLastWorkingDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>                                    
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        RESiGNATION DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='resignationDate'
                                            selected={this.state.resignationDate}
                                            onChange={this.handleChangeResignationDate}
                                            minDate={this.minDate}
                                            value={this.props.resignationDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT RESIGNATION DATE'
                                            wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        BATCH NUMBER
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.refBatch}
                                            labelKey='batchNumber'
                                            id="basic-example"
                                            onChange={this.handleChangedBatchNumber}
                                            options={this.state.filteredBatch}
                                            placeholder='Select Batch number'

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Date To Submitted Payroll From
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.submittedDateFromPayroll}
                                            onChange={this.handleChangeSubmittedDateFromPayroll}
                                            // minDate={this.minDate}
                                            value={this.props.submittedDateFromPayroll}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Date To Submitted Payroll To
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.submittedDateToPayroll}
                                            onChange={this.handleChangeSubmittedDateToPayroll}
                                            // minDate={this.minDate}
                                            value={this.props.submittedDateToPayroll}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        Review Status
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.name}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeReviewStatus}
                                            options={this.state.reviewStatusDDL} 
                                            placeholder='SELECT Review Status'
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar className="mt-5">
                                    <Button className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickRefresh }>
                                        Update From ARE
                                    </Button> 
                                    <Button variant="primary" className="noser-button-mr1 noser-button" onClick={ this.handleClickSearch }>
                                        Search
                                    </Button>
                                    {/* <Button variant="info" className="noser-button" >
                                        <CSVLink style={{ color : "white" }} onClick ={this.GridExcellReports} filename= "Batch_Clearance.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                                    </Button> */}
                                </ButtonToolbar>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    REMARKS
                                    </Form.Label>
                                    <Col sm="2">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Please input remarks"
                                            autoComplete="off" 
                                            name="remarksAre"
                                            value={this.state.remarksAre}
                                            onChange={this.handleChangeRemarksAre}
                                        />
                                    </Col>
                                </Form.Group>
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.exportDataGrid }
                                    columns={ exportColumn }
                                    exportCSV={ {
                                        fileName: "Employee Clearance-" + moment(new Date()).format('MMMM/DD/YYYY h:mm:ss a') + ".csv",
                                    } }
                                    >
                                    {
                                        props => (
                                            <div className="mt-2">
                                                <BootstrapTable
                                                    { ...props.baseProps } 
                                                    keyField = "id"
                                                    data = { this.state.trainingGrid }
                                                    columns = { trainingColumn }
                                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'dbclick',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModified(oldValue, newValue, row.id, column)
                                                            }
                                                        })
                                                    }
                                                    selectRow = { selectRow }
                                                    expandRow={ expandRow }
                                                    rowStyle={ rowStyle }
                                                />
                                                <ButtonToolbar className="mt-3" sm={12}>
                                                    {/* <Button disabled={this.state.isDisabledButton} className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickSubmitReview }>
                                                        Submit Review
                                                    </Button>
                                                    <Button disabled={this.state.isDisabledButton} className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickDoneReview }>
                                                        Done Review
                                                    </Button> */}
                                                    <ExportCSVButton className="btn btn-info" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                                    &nbsp;      
                                                    <Button disabled={this.state.isDisabledButton} className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickSubmit }>
                                                        submit
                                                    </Button>
                                                    <Button disabled={this.state.isDisabledButton} className="noser-button-mr1 noser-button" onClick={this.handleClickReturn } variant="info">
                                                        return
                                                    </Button>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }
}

export  default BatchClearanceAre
