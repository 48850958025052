
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { GetRequest, PostRequest } from '../../noser.dataaccess'
import {CSVLink, CSVDownload} from 'react-csv';
class DeductionBreakdown2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            batchNumber : props.data.data.batchNumber,
            employeeName : props.data.data.employeeName,
            employeeNo : props.data.data.employeeNo,
            gross : props.data.data.inclusions === "" ? "0" : props.data.data.inclusions,
            deductionTmp : /* props.data.data.grossPay < props.data.data.totalBalance ? */ props.data.data.clearanceDeductions /* : [] */,
            deductionBreakdown : [],
            reviewDDL    :   [
                { "id" : "0", "label" : "FULL", "value" : "FULL", "subValue" : "F" },
                { "id" : "1", "label" : "PARTIAL",  "value" : "PARTIAL", "subValue" : "P" },
                { "id" : "2", "label" : "NOT PAID",  "value" : "NOT PAID", "subValue" : "NP" },
            ],
            gridDataExcel: []
        } 
        // console.log("props")
        // console.log(props.data)   
        // let dataDeductns = props.data.data.grossPay < props.data.data.totalBalance ? props.data.data.clearanceDeductions : []

        // // // console.log("dataDeductns")
        // // // console.log(dataDeductns) 
    }
    componentDidMount(){
        // let obj = []   
        // // console.log("componentdidmount")
    }
    showInfo = async() => {
        if (this.state.employeeName===""){
            this.setState({ popupVisible: false});
        }
        else{
            let gridData = []
            let grossPay = parseFloat(String(this.state.gross).replace(',','').replace(',','').replace(',','')) 
            console.log("this.state.deductionTmp")
            console.log(this.state.deductionTmp)
            this.state.deductionTmp.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1).forEach((itm,idx) =>{
                
                let today = new Date()
                itm.id = idx
                itm.balance = itm.balance.replace(',','').replace(',','')
                    
                // // console.log("grossPay")
                // // console.log(grossPay)
                if(grossPay>=parseFloat(itm.balance)){
                    // // console.log("1")
                    grossPay = grossPay - parseFloat(itm.balance)
                    gridData.push({
                        id : idx,
                        deductionType : itm.deductionType,
                        deduction : itm.deduction,
                        chargesAmount : itm.balance,
                        collectionAmount : itm.collectionAmount,
                        amountPaid : itm.chargesAmount,
                        paidStatus : "FULL",
                        balance : "0",
                    })
                }
                else if(grossPay < parseFloat(itm.balance) && grossPay > 0){
                    
                    // // console.log("2")
                    grossPay = grossPay - parseFloat(itm.balance)
                    gridData.push({
                        id : idx,
                        deductionType : itm.deductionType,
                        deduction : itm.deduction,
                        chargesAmount : itm.balance,
                        collectionAmount : itm.collectionAmount,
                        amountPaid : (parseFloat(itm.balance) - (grossPay*-1)).toFixed(2),
                        paidStatus : "PARTIAL",
                        balance : (grossPay*-1).toFixed(2),
                    })
                }
                else{
                    // // console.log("3")
                    gridData.push({
                        id : idx,
                        deductionType : itm.deductionType,
                        deduction : itm.deduction,
                        chargesAmount : itm.balance,
                        collectionAmount : itm.collectionAmount,
                        amountPaid : "0",
                        paidStatus : "NOT PAID",
                        balance : itm.balance,
                    })
                }
            })
            
            
    
            
            // for computation of beginning gross
            let _dataList = []
            let begGross = parseFloat(String(this.state.gross).replace(',','').replace(',','').replace(',','')) 
            let isDeducted = 0
            gridData.forEach((itm,idx) =>{
                if(isDeducted === 0){
                    console.log("1")
                    _dataList.push({
                        id : itm.id,
                        deductionType : itm.deductionType,
                        deduction : itm.deduction,
                        chargesAmount : itm.chargesAmount,
                        collectionAmount : itm.collectionAmount,
                        amountPaid : itm.amountPaid,
                        paidStatus : itm.paidStatus,
                        balance : itm.balance,
                        grossPay : (begGross).toFixed(2)
                    })
                    isDeducted = 1
                    // console.log("begGross if loop")
                    // console.log(begGross)
                    begGross = parseFloat(begGross) - parseFloat(itm.amountPaid)
                }
                else{
                    
                    // console.log("2")
                    // console.log("begGross")
                    // console.log(begGross)
                    // console.log("itm.amountPaid")
                    // console.log(itm.amountPaid)
                    _dataList.push({
                        id : itm.id,
                        deductionType : itm.deductionType,
                        deduction : itm.deduction,
                        chargesAmount : itm.chargesAmount,
                        collectionAmount : itm.collectionAmount,
                        amountPaid : itm.amountPaid,
                        paidStatus : itm.paidStatus,
                        balance : itm.balance,
                        grossPay : parseFloat(begGross) < 0 ? "0" : (begGross).toFixed(2)
                    })
                    begGross = parseFloat(begGross) - parseFloat(itm.amountPaid)
                    // if(parseFloat(itm.balance) > 0){
                    //     isDeducted = 2
                    // }
                    // else {
                    //     isDeducted = 1
                    // }
                    
                    // console.log("start")
                    // console.log("begGross else if loop")
                    // console.log(begGross)
                    // console.log("amountPaid")
                    // console.log(itm.chargesAmount)
                    // console.log("end")
                }
                // else{
                //     console.log("3")
                //     _dataList.push({
                //         id : itm.id,
                //         deductionType : itm.deductionType,
                //         deduction : itm.deduction,
                //         chargesAmount : itm.chargesAmount,
                //         collectionAmount : itm.collectionAmount,
                //         amountPaid : itm.amountPaid,
                //         paidStatus : itm.paidStatus,
                //         balance : itm.balance,
                //         grossPay : "0"
                //     })
                // }
            })
            this.setState({ popupVisible: true, deductionBreakdown : _dataList});
        }
    }
    hideInfo = ()=> {
        // this.state.popupVisible=false
        this.setState({  popupVisible: false,})
    }
    handleSubmit = () =>{
        // this.setState({isloading:true})
        
        // let _dataArray = this.state.trainingGrid.filter( x => x.isDeleted === "1" || x.isSelected === "1")
        // _dataArray.forEach(itm => {
        // // // console.log(parseFloat(String(this.state.gross).replace(',','').replace(',','').replace(',','').replace(',','')))
        // // // console.log(parseFloat(this.state.forDeductions) > parseFloat(String(this.state.gross).replace(',','').replace(',','').replace(',','').replace(',','')))
        if ( parseFloat(this.state.forDeductions) > parseFloat(String(this.state.gross).replace(',','').replace(',','').replace(',','').replace(',','')) ){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Beginning gross doesn't match the total deduction!",
                fade            :   true
            })
            return
        }else{
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :    "Successfully submitted",
                fade            :   true
            })
        }
    }
    handleClickExport = () =>{
        let newGridData = []
        let dataSelected = this.state.deductionBreakdown.filter( x=> x.isDeleted === "1")
        let _isSelected = []
        if(dataSelected.length === 0){
            _isSelected = this.state.deductionBreakdown.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? -1 : 1)
        }
        else{
            _isSelected = dataSelected.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1)
        }
        
        for (let i = 0; i < _isSelected.length; i++){
            let obj = {
                "EMPLOYEE NAME"         :   this.state.employeeName,
                "EMPLOYEE NO"           :   this.state.employeeNo ,
                "BATCH NUMBER"          :   this.state.batchNumber,
                "BEGINNING GROSS"       :   _isSelected[i]["grossPay"],
                "DEDUCTION"             :   _isSelected[i]["deduction"],
                "DEDUCTIONS TYPE"       :   _isSelected[i]["deductionType"],
                "AMOUNT"                :   _isSelected[i]["chargesAmount"],
                "AMOUNT PAID"           :   _isSelected[i]["amountPaid"],
                "PAID STATUS"           :   _isSelected[i]["paidStatus"],
                "BALANCE"               :   _isSelected[i]["balance"],
            }
            newGridData.push(obj)
        }
        this.setState({gridDataExcel : newGridData.sort((a, b) => parseFloat(a.PRIORITY) > parseFloat(b.PRIORITY) ? -1 : 1)})

    }
    GridDataModified(oldValue, newValue, id, column) {
        let _disabled = true
        this.state.deductionBreakdown.forEach(item => {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                if(column === 'amountPaid'){
                    // let _amountPaidTmp = parseFloat(item.amountPaid)
                    // if(parseFloat(item.amountPaid) > 0){
                    //     let _amountPaid = item.amountPaid.replace(",","").replace(",","").replace(",","").replace(",","")
                    //     item.amountPaid = parseFloat(_amountPaid).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    // }
                }
            }
        })
        this.setState({ disabled : _disabled})
    }
    render(){
        const nf = new Intl.NumberFormat();
        const breakdownColumn = [
            {
                dataField   : 'deduction',
                text        : 'DEDUCTION',
                editable : false,
                headerStyle : () => {
                    return { width  : "20%" };
                },
                // footer: '',
            },
            {
                dataField   : 'deductionType',
                text        : 'DEDUCTIONS TYPE',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                // footer: '',
            },
            {
                dataField   : 'grossPay',
                text        : 'BEGINNING GROSS',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                style:{textAlign:'right'},
                // footer: '',
            },
            {
                dataField   : 'chargesAmount',
                text        : 'AMOUNT',
                editable : false,
                headerStyle : () => {
                    return { width  : "20%" };
                },
                style:{textAlign:'right'},
                // footer: '',
            },
            {
                dataField   : 'amountPaid',
                text        : 'AMOUNT PAID',
                editable : true,
                headerStyle : () => {
                    return { width  : "20%" };
                },
                style:{textAlign:'right'},
                // footer: 'FOR DEDUCTION',
                // footerStyle: {
                //     // textAlign : "right"
                // }
            },
            {
                dataField   : 'paidStatus',
                text        : 'PAID STATUS',
                editable : true,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter: (cell, row) => {
                    if(row.paidStatus!='' && row.paidStatus!=null){
                        if(this.state.reviewDDL.filter(x => x.value == cell).length==0){
                            return ""
                        }
                        else{
                            return this.state.reviewDDL.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.reviewDDL
                },
                style:{textAlign:'right'},
                // footer: nf.format(this.state.forDeductions),
                // footerStyle: {
                //     textAlign : "right"
                // }
            },
            {
                dataField   : 'balance',
                text        : 'BALANCES',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
            },
            
        ]
        
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.deductionBreakdown.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        // item.isSelected = isSelect ? "1" : "0"
                    }
                })
                let SelectdTmp = this.state.deductionBreakdown.filter(x => x.isDeleted === "1")
                let _forDeduct = SelectdTmp.reduce((total, currentValue) => total + parseFloat(String(currentValue.amountPaid).replace(',','').replace(',','').replace(',','')),0)
                this.setState({forDeductions : _forDeduct})
            }
        };

        // const rowEvents = {
        //     onClick: (e, row, rowIndex) => {

        //     }
        // };
        const customizedStyle = { width: '100%', backgroundColor: 'red', borderColor: 'black' };  
    return (  
        <div style={{ overflow: 'auto'}}>
            <Button  
                style={customizedStyle}  
                type="button"  
                variant="success"
                onClick={() => this.showInfo()}
            >  
                view 
            </Button>  
            <Popup style={{fontSize:"16px", fontWeight : "bolder"}}
                visible={this.state.popupVisible}
                onHiding={this.hideInfo}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                //title="Deduction Breakdown"
                //container=".dx-viewport"
                width='auto'
                height='auto'
                >
                <Position
                    at="center"
                    my="center"
                    of={this.state.positionOf}
                    collision="fit"
                />
                <div>
                <Card.Header>Deduction Breakdown</Card.Header>
                
                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                    <div className={this.state.color}></div> 
                    {this.state.message}
                </Alert>
                <Form.Group as={Row} controlId="formPlaintextEmail" className='mt-2'>
                    <Col sm="3">
                        <Form.Label style={{fontWeight : "bold"}}>
                        EMPLOYEE NAME:
                        </Form.Label>
                    </Col>
                    <Col sm="2">
                        <Form.Label  style={{fontWeight : "bold"}}>
                        :
                        </Form.Label>
                    </Col>
                    <Col sm="7">
                    <Form.Label style={{fontWeight : "bold", color : "blue", fontStyle : 'italic'}}>
                        {this.state.employeeName}
                    </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col sm="3">
                        <Form.Label style={{fontWeight : "bold"}}>
                            EMPLOYEE NO.:
                        </Form.Label>
                    </Col>
                    <Col sm="2">
                        <Form.Label  style={{fontWeight : "bold"}} >
                        :
                        </Form.Label>
                    </Col>
                    <Col sm="7">
                    <Form.Label style={{fontWeight : "bold", color : "blue", fontStyle : 'italic'}}>
                        {this.state.employeeNo}
                    </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col sm="3">
                        <Form.Label style={{fontWeight : "bold"}}>
                        BATCH NO.:
                        </Form.Label>
                    </Col>
                    <Col sm="2">
                        <Form.Label  style={{fontWeight : "bold"}}>
                        :
                        </Form.Label>
                    </Col>
                    <Col sm="7">
                    <Form.Label style={{fontWeight : "bold", color : "blue", fontStyle : 'italic'}}>
                        {this.state.batchNumber}
                    </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                        BEGINNING GROSS
                    </Form.Label>
                    <Col sm="2">
                        <Form.Label  style={{fontWeight : "bold"}}>
                        :
                        </Form.Label>
                    </Col>
                    <Form.Label column sm="7" style={{fontWeight : "bolder", color : "red", fontStyle: 'italic'}}>
                        {this.state.gross === '' ? "" : this.state.gross}
                    </Form.Label>
                </Form.Group>
                    <div className="mt-1" style={{ height : 400, overflow:"auto"}}>
                        <BootstrapTable
                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                            keyField = "id"
                            style={{width : '100%'}}
                            data = { this.state.deductionBreakdown }
                            columns = { breakdownColumn }
                            pagination={ paginationFactory({sizePerPageRenderer}) }
                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                            rowClasses="noser-table-row-class"
                            striped
                            hover
                            condensed
                            cellEdit = { cellEditFactory({
                                mode: 'dbclick',
                                blurToSave: true,
                                afterSaveCell: (oldValue, newValue, row, column) => {
                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)}})
                            }
                            // rowEvents={ rowEvents }
                            selectRow = { selectRow }
                        />
                        <ButtonToolbar sm={12} className="mt-3">
                            <Button variant="success" disabled = {this.state.disabled} className="ml-auto noser-button-mr1 noser-button" onClick={ this.handleSubmit }>
                                OK
                            </Button>
                            <Button variant="info" className="noser-button">
                                <CSVLink style={{ color : "white" }}  onClick={ this.handleClickExport } filename= "DeductionBreakdown.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                            </Button>
                        </ButtonToolbar>
                    </div>
                </div>
            </Popup>
            {/* <CreateRole 
                show={false}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            /> */}
            </div>
            
        )
    }
}
export default DeductionBreakdown2;
          