import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker,
    Accordion
}
from '../../noser-hris-component';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { GetRequest, PostRequest } from '../../noser.dataaccess'
let ref = React.createRef();

class ClearanceFinanceAre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            navigate        :   false,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            openClientModal :   false,
            openPositionModal:  false,
            disabled        :   true,
            clientId        :   "",
            trainingId      :   "",
            trainingDDL     :   [],
            payrollDataArray    :   [],
            clientDDL       :   [],
            employeeDDL     :   [],
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            gridDataExcel   :   [],
            isshowExpand    :   false,
            reasonDDL: [],
            reasonId: "",
            reason: "",
            reason: "",
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            memberNotes:"",
            memberDDL:[],
            dateCreated: new Date(),
            dateSubmitted: "",
            clearanceStatus:"",
            // END MEMBER FIELDS


            //from edit details
            employeeName:"",
            fromDateCreated : "",
            toDateCreated : "",
            fromClearanceDate:"",
            toClearanceDate:"",
            fromDateReceived:"",
            toDateReceived:"",
            fromLastWorkingDate:"",
            toLastWorkingDate:"",
            statusId : "",
            statusDDL : [],
            toLastWorkingDate : "",
            fromLastWorkingDate : "",
            toDateReceived : "",
            fromDateReceived :"",
            toClearanceDate : "",
            fromClearanceDate : "",
            toDateCreated : "",
            fromDateCreated : "",
            datenow : new Date(),
            batchNumber : "",
            filteredBatch : [],
            selectedId : [],
            _tmpData : [],
            dataListArr:[],
            collectionArray:[],

            chargesArray : [],
            totalBalanceArr : [],
            payrollRemarks : "",
            financeRemarks : "",
            clearanceArray : [],
            dividendLst : [],
            testnumber : 123456789,
            payrollDataArray : [],
            phoneNumber : "",
            memberName : "",
            drafterNameDDL : [],
            isMRs : true,
            isMrs : "Ms",
            dateCompleted: new Date(),
            drafterList : [],
            
            dateCompletedTmp : "",
            dateOfPaymentTmp : "",
            AreList : [],
            AreInlcusion :[],
            SLinclusion : [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        let sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetDividend()
    };
    
    GetClient = async() =>{
        this.setState({isloading:true})
        
        // console.log("get client")
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" ||
                        this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            let data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    // // // console.log("access.controlAccessDetails")
                    // // // console.log(access.controlAccessDetails)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,})
            }
            else{
                this.setState({clientDDL : data.clients,})
            }
            // // // console.log("clientDDL")
            // // // console.log(this.state.clientDDL)
        })
        .catch(error=>{
            console.log("line 150 error")
            console.log(error)
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDisengagementMode();
        this.GetDataGrid()
    };
    GetDrafterNames = async() => {
        this.setState({isloading:true})
        
        // console.log("get drafter names")
        let getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "1217",
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  getParams)
        .then(res => {
            let data = res.data
            let drafterDDL = data.profiles
            // console.log("drafterDDL")
            // console.log(drafterDDL)
            let drafterList = []
            drafterDDL.forEach((itm, idx) => {
                drafterList.push({
                    "value" : itm.employeeName,
                    "label" : itm.employeeName,
                })
            })
            this.setState({ drafterNameDDL : drafterList, drafterList: drafterDDL})
        })
        .catch(error=>{
            console.log("line 191 error")
            console.log(error)
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        // this.GetClient()
        // // console.log("this.state.drafterDDL")
        // // console.log(this.state.drafterDDL)
    };
    GetEmployees = async() => {
        this.setState({isloading:true})
        
        // console.log("get employees")
        let getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.userinfo.roleId==="1" ||
                                this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  getParams)
        .then(res => {
            let data = res.data
            // let drafterDDL = res.data.profiles.filter(x=>x.clientId === "1217")
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                // // console.log("if")
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        // arr = data.profiles.filter(f => brr.some(item => item.employeeId === f.employeeId))
                        // arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                        // // // console.log("data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId")
                        // // // console.log(data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId)))
                        this.setState({employeeDDL : data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId)),})
                    }
                    
                }
            }
            else{
                // // // console.log("else 3")
                this.setState({employeeDDL : data.profiles,})
            }
        })
        .catch(error=>{
            console.log("line 245 error")
            console.log(error)
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetClient()
    };
    handleClientChanged = (e) => {
        if(e.length === 0) {
            this.state.clientId=""
            return
        }
        this.state.clientId=e[0].id
        this.setState({isshow:false})

    };
    handleClickSearch = async() => {
        let arrLst = []
        let arrid = []
        if (this.state.batchNumber !== ""){
            this.state.dataListArr.forEach(itm =>{
                this.GetChargesAmount(itm, itm.employeeNo, itm.employeeName)
            })
        }
        else {
            this.setState({isloading:true})
            let param = {
                "IpAddress"             :   "0.0.0.0",
                "UserId"                :   this.state.userinfo.userId,
                "ClientId"              :   this.state.clientId,
                "ProfileId"             :   this.state.profileId,
                "ClearanceStatusId"     :   this.state.statusId,
                "FromDateCreated"       :   this.state.fromDateCreated,
                "ToDateCreated"         :   this.state.toDateCreated,
                "FromClearanceDate"     :   this.state.fromClearanceDate,
                "ToClearanceDate"       :   this.state.toClearanceDate,
                "FromDateReceive"       :   this.state.fromDateReceived,
                "ToDateReceive"         :   this.state.toDateReceived,
                "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
                "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
                "BatchNumber"           :   this.state.batchNumber === "" ? "" : this.state.batchNumber,
                "SubmittedDateFromPayroll" : "",
                "SubmittedDateToPayroll" : "",
                "ReviewStatusId" : "",
            };
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
            .then(res => {
                let data = res.data.clearance;
                let _trainingFIlter = data
                if(this.state.reason === ""){
                    this.setState({
                        payrollDataArray : _trainingFIlter.filter(x=>parseFloat(x.isClearanceStatus) >=8),
                        isloading : false
                    })
                }else {
                    this.setState({
                        payrollDataArray : _trainingFIlter.filter(x => x.reasonForLeaving === this.state.reason),
                        isloading : false
                    })
                }
                if(data.status==="0"){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
            })
            .catch(error=>{
                console.log("line 325 error")
                console.log(error)
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        }
    };
    GetDividend = async() =>{
        // try{
        this.setState({ isloading: true })
        console.log("getsl") 
        let _filterData = {"isDeleted" : "0", "isInclusion" : "1", "isSubmittedToPayroll" : "1", "isClearance" : "0"/* , "employeeId" : "91060" */}
        let resp = await GetRequest({
            url : AppConfiguration.Setting().noserareapi + "action/get",
            params : {
                "dbname"      :   AppConfiguration.Setting().noseraredb,
                "_collection" : "InclusionLedger",
                "filter_data" : _filterData
            }    
        })
        console.log("resp")
        console.log(resp)

        if(resp.status==="1"){
            const data = resp.ResultList
            this.setState({ dividendLst : resp.ResultList, AreInlcusion : resp.ResultList.filter(x=>x.isInclusion==="1" && x.inclusion!=="CBU")})
        } 
        else {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   resp.message,
                fade        :   true
            });
            return
        }
        this.GetAreSL()
        this.getStatusClearance()
        this.GetEmployees()
        this.GetDrafterNames()
    };
    GetAreSL = async() =>{
        // try{
            
        // console.log("getsl")
        let _filterData = {"isDeleted" : "0", "isClearance" : "1"}
        let resp = await GetRequest({
                url : AppConfiguration.Setting().noserareapi + "action/get",
                params : {
                            "dbname"      :   AppConfiguration.Setting().noseraredb,
                            "_collection" : "SubsidiaryLedgerStaging",
                            "filter_data" : _filterData
                        }    
        })
            // // console.log("resp")
            // // console.log(resp)

        if(resp.status==="1"){
            const data = resp.ResultList
            this.setState({ AreList : resp.ResultList})
        } 
        else {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   resp.message,
                fade        :   true
            });
            return
        }
        // this.GetDataGrid()
    };
    GetDataGrid = async() => {
        // console.log("getdatagrid")
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   "",
            "UserId"            :   "35954",
            "ProfileId"            :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            const nf = new Intl.NumberFormat();
            let modifyData =[]
            let filterData = data.filter( x=> parseFloat(x.isClearanceStatus) >= 8 && parseFloat(String(x.netPay).replace(',','')) <= 0)
            
            if(data.length > 0) {
                filterData.sort((a, b) => a.clearanceDateMember < b.clearanceDateMember ? 1 : -1).map(function(itm,idx){
                    modifyData.push({
                        id : itm.id,
                        batchNumber : itm.batchNumber,
                        clientName : itm.clientName,
                        employeeNo : itm.employeeNo,
                        employeeName : itm.employeeName,
                        reasonForLeaving : itm.reasonForLeaving,
                        dateStart : itm.dateStart,
                        lastWorkingDateMember : itm.lastWorkingDateMember,
                        cashCardNumberMember : itm.cashCardNumberMember,
                        cbu : itm.cbu,
                        dividend : itm.dividend,
                        lastSalary : itm.lastSalary,
                        adjustment : itm.adjustment,
                        otherPayroll : itm.otherPayroll,
                        thirteenthMonth : itm.thirteenthMonth,
                        sil : itm.sil,
                        separationPay :itm.separationPay,
                        incentives : itm.incentives,
                        grossPay : itm.grossPay,
                        inclusions : itm.inclusions,
                        totalInclusion : itm.totalInclusion,
                        totalBalance : itm.totalBalance,
                        netPay : itm.netPay,
                        // noAtm : itm.noAtm,

                        batchPayroll : itm.batchPayroll,
                        typeOfCashCardMember : itm.typeOfCashCardMember,
                        areRemarks : itm.areRemarks,
                        clearanceDateMember : itm.clearanceDateMember,
                        clearanceDeductions : itm.clearanceDeductions,
                        clearanceDetails : itm.clearanceDetails,
                        clearanceStatus : itm.clearanceStatus,
                        clearedSubmit : itm.clearedSubmit,
                        clientId : itm.clientId,
                        closingRemarks : itm.closingRemarks,
                        createdBy : itm.createdBy,
                        createdByName : itm.createdByName,
                        createdDate : itm.createdDate,
                        cycleDays : itm.cycleDays,
                        dateCreated : itm.dateCreated,
                        dateForwardedToAmt : itm.dateForwardedToAmt,
                        dateOfBirth : itm.dateOfBirth,
                        dateReceivedByAdmin : itm.dateReceivedByAdmin,
                        dateReceivedFromAmt : itm.dateReceivedFromAmt,
                        dateReturnedByARE : itm.dateReturnedByARE,
                        dateReturnedByClient : itm.dateReturnedByClient,
                        dateReturnedByFinance : itm.dateReturnedByFinance,
                        dateReturnedByLRD : itm.dateReturnedByLRD,
                        dateReturnedByPayroll : itm.dateReturnedByPayroll,
                        dateReturnedByRMT : itm.dateReturnedByRMT,
                        dateReturnedByServices : itm.dateReturnedByServices,
                        dateSettle : itm.dateSettle,
                        dateSubmitted : itm.dateSubmitted,
                        dateSubmittedByLead : itm.dateSubmittedByLead,
                        dateSubmittedByStaff : itm.dateSubmittedByStaff,
                        dateUpdatedByARE : itm.dateUpdatedByARE,
                        dateUpdatedByClient : itm.dateUpdatedByClient,
                        dateUpdatedByFinance : itm.dateUpdatedByFinance,
                        dateUpdatedByLRD : itm.dateUpdatedByLRD,
                        dateUpdatedByRMT : itm.dateUpdatedByRMT,
                        dateUpdatedByServices : itm.dateUpdatedByServices,
                        employeeId : itm.employeeId,
                        finalized : itm.finalized,
                        financeRemarks : itm.financeRemarks,
                        firstName : itm.firstName,
                        generation : itm.generation,
                        // grossPay : itm.grossPay,
                        hdmfNumber : itm.hdmfNumber,
                        id : itm.id,
                        isCOERequestFormMember : itm.isCOERequestFormMember,
                        isCash : itm.isCash,
                        isCashCardMember : itm.isCashCardMember,
                        isCheckMember : itm.isCheckMember,
                        isCheckedByRmt : itm.isCheckedByRmt,
                        isClearanceStatus : itm.isClearanceStatus,
                        isClearedClient : itm.isClearedClient,
                        isClearedRmt : itm.isClearedRmt,
                        isClearedSubmit : itm.isClearedSubmit,
                        isDeleted : itm.isDeleted,
                        isEligible : itm.isEligible,
                        isEligibleClient : itm.isEligibleClient,
                        isFinalized : itm.isFinalized,
                        isForwardToARERmt : itm.isForwardToARERmt,
                        isGenerated : itm.isGenerated,
                        isHDMFNumberMember : itm.isHDMFNumberMember,
                        isMlhuillierMember : itm.isMlhuillierMember,
                        isModified : itm.isModified,
                        isModifiedByARE : itm.isModifiedByARE,
                        isNonEligibleClient : itm.isNonEligibleClient,
                        isNotEligible : itm.isNotEligible,
                        isPHICNumberMember : itm.isPHICNumberMember,
                        isPalawanMember : itm.isPalawanMember,
                        isResignationLetterMember : itm.isResignationLetterMember,
                        isRetrunedServicesRmt : itm.isRetrunedServicesRmt,
                        isReturnedRmt : itm.isReturnedRmt,
                        isSSSNumberMember : itm.isSSSNumberMember,
                        isSettleWithDole : itm.isSettleWithDole,
                        isTINNumberMember : itm.isTINNumberMember,
                        isTurnoverChecklistMember : itm.isTurnoverChecklistMember,
                        isTypeOfCashCardMember : itm.isTypeOfCashCardMember,
                        isValidIdMember : itm.isValidIdMember,
                        lastName : itm.lastName,
                        locationId : itm.locationId,
                        locationName : itm.locationName,
                        middleName : itm.middleName,
                        modifiedBy : itm.modifiedBy,
                        modifiedByName : itm.modifiedByName,
                        modifiedDate : itm.modifiedDate,
                        // netPay : itm.netPay,
                        notesMember : itm.notesMember,
                        paramountId : itm.paramountId,
                        payPeriodId : itm.payPeriodId,
                        paycardTypeIdMember : itm.paycardTypeIdMember,
                        payrollPeriods : itm.payrollPeriods,
                        payrollRemarks : itm.payrollRemarks,
                        periodCovered : itm.periodCovered,
                        phicNumber : itm.phicNumber,
                        positionId : itm.positionId,
                        positionName : itm.positionName,
                        profileId : itm.profileId,
                        reasonForLeaving : itm.reasonForLeaving,
                        reasonForLeavingId : itm.reasonForLeavingId,
                        remarksClient : itm.remarksClient,
                        remarksLrd : itm.remarksLrd,
                        remarksMember : itm.remarksMember,
                        remarksRmt : itm.remarksRmt,
                        resignationDateMember : itm.resignationDateMember,
                        returnedByARE : itm.returnedByARE,
                        returnedByClient : itm.returnedByClient,
                        returnedByFinance : itm.returnedByFinance,
                        returnedByLRD : itm.returnedByLRD,
                        returnedByRMT : itm.returnedByRMT,
                        returnedByServices : itm.returnedByServices,
                        reviewStatus : itm.reviewStatus,
                        reviewStatusResults : itm.reviewStatusResults,
                        rowIndex : itm.rowIndex,
                        servicesAttachments : itm.servicesAttachments,
                        servicesRemarks : itm.servicesRemarks,
                        // sil : itm.sil,
                        sssNumber : itm.sssNumber,
                        submissionDateMember : itm.submissionDateMember,
                        submitted : itm.submitted,
                        submittedByLead : itm.submittedByLead,
                        submittedByStaff : itm.submittedByStaff,
                        suffixName : itm.suffixName,
                        timeStamp : itm.timeStamp,
                        tinNumber : itm.tinNumber,
                        // totalBalance : itm.totalBalance,
                        updatedByARE : itm.updatedByARE,
                        updatedByClient : itm.updatedByClient,
                        updatedByFinance : itm.updatedByFinance,
                        updatedByLRD : itm.updatedByLRD,
                        updatedByRMT : itm.updatedByRMT,
                        updatedByServices : itm.updatedByServices,
                        validateByRmt : itm.validateByRmt,
                        batchPayroll : itm.batchPayroll,
                        dateHired : itm.dateHired,
                        contractDateStart : itm.contractDateStart,
                        contractDateEnd : itm.contractDateEnd,
                        drafterName : "",
                        drafterNumber : "",
                        authorizePersonel : "",
                        parentId : itm.parentId,
                
                        dateSubmittedToPayroll : itm.dateSubmittedToPayroll,
                        isAre : itm.isAre,
                        isPayroll : itm.isPayroll,
                        financePayoutDate : itm.financePayoutDate,
                        isUpdatedByARE : itm.isUpdatedByARE,
                        completionDate : itm.completionDate,
                        settlementFee : itm.settlementFee
                    })
                })
                this.setState({
                    payrollDataArray : modifyData.filter(f => this.state.clientDDL.some(item => item.id === f.clientId)),
                    // clearanceArray : filterData.filter( x=>parseFloat(String(x.netPay).replace(',','')) < 0).sort((a, b) => new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1),
                    isloading: false
                })
                // // console.log('filterData.filter(f => this.state.clientDDL.some(item => item.id === f.clientId)),')
                // // console.log(filterData.filter(f => this.state.clientDDL.some(item => item.id === f.clientId)),)
            }
            console.log("modifyData")
            console.log(modifyData)
            // this.setState({isloading:false});

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            // this.GetPayrollEmployee(filterData)
            
        // // console.log("employeeDDL")
        // // console.log(this.state.employeeDDL)
        })
        .catch(error=>{
            console.log("line 627 error")
            console.log(error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    // GetPayrollEmployee = async(ItemList) =>{
        
    //     // console.log("get payroll emp")
    //     let dataTmp = []
    //     for ( let i = 0; i < ItemList.length; i++ ) {
    //         let payrollParams = {
    //             "IpAddress":"0.0.0.0",
    //             "ClientId":ItemList[i].clientId,
    //             "UserId":this.state.userinfo.userId,
    //             "PayPeriodId":ItemList[i].payPeriodId,
    //             "EmployeeId":ItemList[i].employeeId,
    //             "IsProcessed":"1",
    //             "IsOffCycle":"0"
    //         };
    //         await axios
    //         .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetEmployeePayrolls", payrollParams)
    //         .then(res => {
    //             let data = res.data;
    //             this.setState({ isloading : false})
    //         })
    //         .catch(error=>{
    //             this.setState(  {
    //                 isloading       :   false,
    //                 alerttype       :   "Error!",
    //                 isshow          :   true,
    //                 color           :   "danger",
    //                 message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //                 fade            :   true
    //             })
    //         })
    //     }
    // };
    getStatusClearance = async() =>{
        
        // console.log("get status clearance")
        let statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0040"
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
        .then(res => {
            let data = res.data;
            this.setState({
                statusDDL : res.data.dataReferences
            })

        })
        .catch(error=>{
            console.log("line 690 error")
            console.log(error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetDisengagementMode = async() => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            let data = res.data.disengagementMode;
            this.setState({
                reasonDDL: data,
            });

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            console.log("line 729 error")
            console.log(error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };




    GridDataModified(oldValue, newValue, id, column,) {
        let disable = true
        this.state.payrollDataArray.forEach(item => {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
            }
        })
    };
    GridExcellReports = () =>{
        let newGridData = []
        let dataSelected = this.state.payrollDataArray.filter( x=> x.isDeleted === "1")
        let _isSelected = []
        if(dataSelected.length === 0){
            _isSelected = this.state.payrollDataArray
        }else{
            _isSelected = dataSelected
        }
        for (let i = 0; i < _isSelected.length; i++){
            let obj = {
                "EMPLOYEE NAME"         :   _isSelected[i]["employeeName"],
                "CLIENT NAME"           :   _isSelected[i]["clientName"],
                "BRANCH / LOCATION"     :   _isSelected[i]["locationName"],
                "DATE START"            :   _isSelected[i]["dateStart"],
                "LAST WORKING DATE"     :   _isSelected[i]["lastWorkingDateMember"],
                "CLEARANCE DATE"        :   _isSelected[i]["clearanceDateMember"],
                "STATUS"                :   _isSelected[i]["clearanceStatus"],
                "COMPLETION DATE"       :   _isSelected[i][""],
                "CYCLE DAYS"            :   _isSelected[i]["cycleDays"],
                "FULL NAME"             :   _isSelected[i]["createdByName"],
                "FULL NAME (UPDATEDBY )" :   _isSelected[i]["modifiedByName"],
            }
            newGridData.push(obj)
        }
        this.setState({gridDataExcel : newGridData})
    };

    /* Romel */



    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false , })
    };
    handleChangeClearanceDate = date =>{
        this.setState({ clearanceDate: date, isshow:false , })
    };
    handleChangeSubmissionDate = date =>{
        this.setState({ submissionDate: date, isshow:false , })
    };
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    };
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    };
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false , })
    };
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false , })
    };
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false , })
    };
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false , })
    };
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false , })
    };
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false , })
    };
    handleChangeFromDateCreated = date =>{
        this.setState({ fromDateCreated: date, isshow:false , })
    };
    handleChangeToDateCreated = date =>{
        this.setState({ toDateCreated: date, isshow:false , })
    };
    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,

        })
        this.GetEmployees();
        // this.refBatch.current.clear()
    };
    handleChangedBatchNumber = async(e) =>{
        if(e.length == 0) {
            this.state.batchNumber   =   ""
            this.setState({ payrollDataArray : this.state._tmpData, selectedId : [], batchNumber : "" })
        } else{
            this.state.batchNumber   =   e[0].name
            this.setState({
                isshow:false,
            })
        }
        if (e.length !== 0){
            this.Selectbatch(e[0].batchNumber)
        }
    };
    handleClickTest = () => {
        let arrLst = []
        this.state.dataListArr.forEach(x => {
            let listObj = {
                "client" : x.client,
                "employeeName" : x.employeeName,
                "deductionDetails" : this.state.collectionArray,
            }
            arrLst.push(listObj)
        })
    };
    handleChangeEmployee = (e) => {

        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.contractDateStart  =   ""
            this.state.phicNo = ""
            this.state.sssNo = ""
            this.state.tinNo = ""
            this.state.pagibigNo = ""
        } 
        else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.phicNo       =   e[0].phicNumber
            this.state.sssNo        =   e[0].sssNumber
            this.state.tinNo        =   e[0].tinNumber
            this.state.pagibigNo    =   e[0].hdmfNumber
        }
        this.setState({  })
        this.getEmployeeClearance();
    };
    getEmployeeClearance = async() =>{
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.userinfo.clientId,
            "ProfileId"             :   this.state.userinfo.profileId,
            "DisengagementModeId"   :   this.state.disengagementModeId,
            "ClearanceDate"         :   this.state.clearanceDate,
            "ResignationDate"       :   this.state.resignationDate,
            "SubmissionDate"        :   this.state.submissionDate,
            "LastWorkingDate"       :   this.state.workingDate,
            "ReasonForLeaving"      :   this.state.reason
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            console.log("line 932 error")
            console.log(error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleChangedReason = (e) => {
        if (e.length === 0){
            this.setState({ reason : "" })
        } else {
            this.setState({ reason : e[0].name })
        }
    };
    handleClickRefresh = () =>{
        window.location.reload(true)
    };
    handleClickDelete = async() =>{
        this.setState({isloading : true })
        let param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "payrollFiles":[
                {
                    "Id" : "",
                    "IsClearance":"1" // --> default 1
                }
            ]
        };
        await axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Accounting/DeletePayrollFiles",  param
        )
            .then(res => {
            let data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            this.GetDataGrid();

        })
        .catch(error=>{
            console.log("line 985 error")
            console.log(error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleClickClose = async() => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let _dateCompleted = (this.state.dateCompleted) ? moment(this.state.dateCompleted).format('MM/DD/YYYY') : ""
        let arrLst = []
        let dedArr = []

        let _fltrAttchment = this.state.payrollDataArray.filter( x => x.isDeleted === "1" || x.isSelected === "1")
        console.log("_fltrAttchment")
        console.log(_fltrAttchment)
        if (_fltrAttchment.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select atleast 1 clearance to process.",
                fade            :   true
            })
            return
        }
        let dateCompletedTmp = moment(this.state.dateCompletedTmp) ? moment(this.state.dateCompletedTmp).format('MM/DD/YYYY') : ""
        if(dateCompletedTmp===""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select date completed.",
                fade            :   true
            })
            return
        }
        let dateOfPayment = (this.state.dateOfPaymentTmp) ? moment(this.state.dateOfPaymentTmp).format('MM/DD/YYYY') : ""
        if(dateOfPayment===""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select date of payment.",
                fade            :   true
            })
            return
        }
        
        _fltrAttchment.filter(x=>x.completionDate==="").forEach(itm => {
            let _userId = this.state.userinfo.userId
            // if(moment(this.state.dateCompletedTmp).format("MM/DD/YYYY") > moment(date).format("MM/DD/YYYY")){
            //     this.setState({
            //         isloading       :   false,
            //         alerttype       :   "Error!",
            //         isshow          :   true,
            //         color           :   "danger",
            //         message         :    "Invalid selection of Date Completed. (future date)",
            //         fade            :   true,
            //     })
            //     return arrLst = []
            // }
            // else if( moment(this.state.dateCompletedTmp).format("MM/DD/YYYY") < moment(itm.dateSubmittedToPayroll).format("MM/DD/YYYY")){
            //     this.setState({
            //         isloading       :   false,
            //         alerttype       :   "Error!",
            //         isshow          :   true,
            //         color           :   "danger",
            //         message         :    "Invalid selection of Date Completed.",
            //         fade            :   true,
            //     })
            //     return arrLst = []
            // }
            // else {
                arrLst.push({
                    "Id" : itm.id,
                    "ClientId":itm.clientId,
                    "ProfileId":itm.profileId,
                    "EmployeeId":itm.employeeId,
                    "PayPeriodId":itm.payPeriodId,
                    "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                    "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                    "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                    "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                    "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                    "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                    "IsSSSNumberMember":itm.isSSSNumberMember,
                    "IsTINNumberMember":itm.isTINNumberMember,
                    "IsPHICNumberMember":itm.isPHICNumberMember,
                    "IsHDMFNumberMember":itm.isHDMFNumberMember,
                    "IsCashCardMember":itm.isCashCardMember,
                    "IsValidIdMember":itm.isValidIdMember,
                    "IsResignationLetterMember":itm.isResignationLetterMember,
                    "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                    "IsCOERequestFormMember":itm.isCOERequestFormMember,
                    "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                    "PaycardTypeIdMember":itm.paycardTypeIdMember,
                    "TypeOfCashCardMember":itm.typeOfCashCardMember,
                    "CashCardNumberMember":itm.cashCardNumberMember,
                    "IsCheckMember":itm.isCheckMember,
                    "IsMlhuillierMember":itm.isMlhuillierMember,
                    "IsPalawanMember":itm.isPalawanMember,
                    "RemarksMember":itm.remarksMember,
                    "NotesMember":itm.notesMember,
                    "ReasonForLeaving":itm.reasonForLeaving,
                    "DateForwardedToAmt": itm.dateForwardedToAmt,
                    "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                    "IsEligible":itm.isEligible,
                    "IsNotEligible":itm.isNotEligible,
                    "SssNumber": itm.sssNumber,
                    "TinNumber": itm.tinNumber,
                    "PhicNumber": itm.phicNumber,
                    "HdmfNumber": itm.hdmfNumber,
                    "IsClearedClient":itm.isClearedClient,
                    "IsEligibleClient":itm.isEligibleClient,
                    "IsNonEligibleClient":itm.isNonEligibleClient,
                    "RemarksClient":itm.remarksClient,
                    "IsClearedRmt":itm.isNonEligibleClient,
                    "IsReturnedRmt":itm.isNonEligibleClient,
                    "ValidateByRmt":itm.isNonEligibleClient,
                    "IsForwardToARERmt":itm.isNonEligibleClient ,
                    "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                    "IsCheckedByRmt":itm.isCheckedByRmt,
                    "RemarksRmt":itm.remarksRmt,
                    "RemarksLrd":itm.remarksLrd,
                    "ServicesRemarks":itm.servicesRemarks ,
                    "IsClearanceStatus": parseFloat(String(itm.netPay).replace(',',''))===0?"10":"9",
                    "clearanceDetails" :itm.clearanceDetails,
                    /* ADDITIONAL */
                    "DateUpdatedByClient": itm.dateUpdatedByClient,
                    "UpdatedByClient":_userId,
                    "DateUpdatedByLRD":itm.dateUpdatedByLRD,
                    "UpdatedByLRD":_userId,
                    "DateUpdatedByRMT":itm.dateUpdatedByRMT,
                    "UpdatedByRMT":_userId,
                    "DateUpdatedByServices":itm.dateUpdatedByServices,
                    "UpdatedByServices":_userId,
                    "DateUpdatedByARE":itm.dateUpdatedByARE,
                    "UpdatedByARE":itm.isUpdatedByARE,
                    "DateUpdatedByFinance":itm.dateUpdatedByFinance,
                    "UpdatedByFinance":_userId,
                    "DateSubmittedByStaff":itm.dateSubmittedByStaff,
                    "SubmittedByStaff": _userId,
                    "DateSubmittedByLead":itm.dateSubmittedByLead,
                    "SubmittedByLead":_userId,
                    "DateReturnedByClient":itm.dateReturnedByClient,
                    "ReturnedByClient":_userId,
                    "DateReturnedByLRD":itm.dateReturnedByLRD,
                    "ReturnedByLRD":_userId,
                    "DateReturnedByRMT":itm.dateReturnedByRMT,
                    "ReturnedByRMT":_userId,
                    "DateReturnedByServices":itm.dateReturnedByServices,
                    "ReturnedByServices":_userId,
                    "DateReturnedByARE":itm.dateReturnedByARE,
                    "ReturnedByARE":_userId,
                    "DateReturnedByFinance":itm.dateReturnedByFinance,
                    "ReturnedByFinance":_userId,
                    "DateSettle"        : itm.dateSettle,
                    "IsSettleWithDole"  :  itm.isSettleWithDole,
                    "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                    "IsCash" : itm.isCash,
                    "BatchNumber" : itm.batchNumber,
                    "servicesAttachments" : itm.servicesAttachments,
                    "clearanceDeductions":itm.clearanceDeductions,
                    // 21/07/2023
                    "ParamountId" : itm.paramountId,
                    "LastSalary" : itm.lastSalary,
                    "Incentives" : itm.incentives,
                    "Sil" : itm.sil,
                    "ThirteenthMonth" : itm.thirteenthMonth,
                    "Inclusions" : itm.inclusions,
                    "GrossPay" : itm.grossPay,
                    "NetPay" : itm.netPay,
                    "totalBalance" : itm.totalBalance,
                    "PeriodCovered" : itm.periodCovered,
                    "IsModifiedByARE" : "0",
                    "SeparationPay" : itm.separationPay,
                    "ReviewStatus" : "0",
                    "DateReturnedByPayroll" : "",
                    "AreRemarks" : itm.areRemarks,
                    "PayrollRemarks" : itm.payrollRemarks,
                    "FinanceRemarks" : itm.financeRemarks,
                    "IsClearedSubmit" : "0",
                    "IsGenerated" : itm.isGenerated,
                    "IsFinalized" : itm.isFinalized,
                    // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                    // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                    "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                    "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                    'BatchPayroll' : itm.batchPayroll,
                    "Adjustment" : typeof itm.adjustment === "undefined" ? "" : itm.adjustment,
                    "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : itm.otherPayroll,
                    "Cbu" : typeof itm.cbu === "undefined" ? "" : itm.cbu,
                    "Dividend" : typeof itm.dividend === "undefined" ? "" : itm.dividend,
                    "IsParent" : itm.isParent,
                    "IsChild" : itm.isChild,
                    "ParentId" : itm.parentId,
                    "SettlementFee" : itm.settlementFee,
                    "CompletionDate" : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY hh:mm:ss"),
                    "DateOfPayment" : moment(this.state.dateOfPaymentTmp).format("MM/DD/YYYY"),
                    "FinancePayoutDate" : moment(new Date()).format("MM/DD/YYYY"),
                    "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                    "IsAre" : itm.isAre,
                    "IsPayroll" : itm.isPayroll,
                    "LastModifiedBy" : this.state.userinfo.fullName
                    /* END */
                })
            // }
            this.setState({ profileId : itm.employeeNo })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }
        console.log("Submit param clearance")
        console.log(param)

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            if(res.data.status==="1"){
                console.log("EditClearance res.data.status success")
                console.log(data)
                this.AddAres(arrLst)
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + res.data.message,
                    fade        :   true
                })
            }
        })
        .catch(error=>{
            console.log("line 1236 error")
            console.log(error)
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        // this.AddAreInlcusion(arrLst)
    };
    AddAres = async(dataList) =>{
        let fltrDataArry = this.state.payrollDataArray.filter( x => x.isDeleted === "1" || x.isSelected === "1")
        // console.log("fltrDataArry")
        // console.log(fltrDataArry)

        let arrList = []
        let partialBalance = "0"
        let partialChargesAmount = "0"
        fltrDataArry.forEach((itm,idx)=>{
            let _tmpEmployee = String(itm.employeeName).replace(',','')
           
            let grossPay = parseFloat(String(itm.inclusions).replace(',','').replace(',','').replace(',','')) 
            itm.clearanceDeductions.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1).forEach((ded,indx)=>{
                let _credit = parseFloat(String(ded.balance).replace(',',''))
                itm.balance = ded.balance.replace(',','').replace(',','')

                if(grossPay>=parseFloat(itm.balance)){
                    grossPay = grossPay - parseFloat(itm.balance)
                    arrList.push({
                        "amortization"      : "",
                        "batchNumber"       : "",
                        "client"            : itm.clientName,
                        "clientId"          : itm.clientId,
                        "referenceNo"       : "",
                        "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
                        "createdby"         : String(this.state.userinfo.fullName).replace(',',''),
                        "credit"            : (itm.balance).toString(),
                        "cutOffDate"        : "",
                        "dateStart"         : "",
                        "debit"             : "0.00",
                        "deduction"         : ded.deduction,
                        "deductionId"       : ded.deductionId,
                        "deductionStatus"   : "",
        
                        "deductionType"     : ded.deductionType,
                        "deductionTypeId"   : ded.deductiontypeId,
                        "docNo"             : ded.docNo,
                        "employeeId"        : itm.employeeId,
                        "employeeName"      : _tmpEmployee,
                        "employeeNo"        : itm.employeeNo,
                        "groupName"         : "",
                        "groupNameId"       : "",
                        "hris"              : "HRIS",
                        "hrisId"            : "1",
                        "isDeleted"         : "0",
                        "isDraft"           : "0",
                        "isModified"        : "0",
                        "isPosted"          : "0",
                        "isPaid"            : "1",
                        "modifiedby"        : this.state.userinfo.userId,
                        "modifieddate"      : moment(new Date()).format('MM/DD/YYYY hh:mm'),
                        "numberOfDeduction" : "",
        
                        "payMode"           : "",
                        "payOutDate"        : "",
                        "payrollDate"       : "",
                        "remarks"           : "CLEARED",
                        "scheddeduction"    : "",
                        "seqNo"             : "",
                        "status"            : "",
                        "transactionDate"   : moment(new Date()).format("MM/DD/YYYY"),
                        "upload"            : "UPLOAD",
                        "isModified"        : "0", 
                        "isInclusion"       : "",
                        "inclusion"         : "",
                        "isMF"              : "",
                        "mf"                : "",
                        "priority"          : ded.priority,
                        "isClearance"       : "1",
                        "dateFinalized"     : moment(new Date()).format("MM/DD/YYYY"),
                        "dateSubmittedClearance"    : itm.createdDate,
                        "financePayoutDate"         : moment(new Date()).format("MM/DD/YYYY"),
                        "dateSubmittedToPayroll"    : itm.dateSubmittedToPayroll,
                    })

                }
                else if(grossPay < parseFloat(itm.balance) && grossPay > 0){
                    grossPay = grossPay - parseFloat(itm.balance)
                    partialBalance = ((parseFloat(itm.balance) - (grossPay*-1)).toFixed(2))
                    partialChargesAmount = parseFloat(itm.balance).toFixed(2)
                    arrList.push({
                        "amortization"      : "",
                        "batchNumber"       : "",
                        "client"            : itm.clientName,
                        "clientId"          : itm.clientId,
                        "referenceNo"       : "",
                        "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
                        "createdby"         : String(this.state.userinfo.fullName).replace(',',''),
                        "credit"            : ((parseFloat(itm.balance) - (grossPay*-1)).toFixed(2)).toString(),
                        "cutOffDate"        : "",
                        "dateStart"         : "",
                        "debit"             : "0.00",
                        "deduction"         : ded.deduction,
                        "deductionId"       : ded.deductionId,
                        "deductionStatus"   : "",
        
                        "deductionType"     : ded.deductionType,
                        "deductionTypeId"   : ded.deductiontypeId,
                        "docNo"             : ded.docNo,
                        "employeeId"        : itm.employeeId,
                        "employeeName"      : _tmpEmployee,
                        "employeeNo"        : itm.employeeNo,
                        "groupName"         : "",
                        "groupNameId"       : "",
                        "hris"              : "HRIS",
                        "hrisId"            : "1",
                        "isDeleted"         : "0",
                        "isDraft"           : "0",
                        "isModified"        : "0",
                        "isPosted"          : "0",
                        "isPaid"            : "1",
                        "modifiedby"        : this.state.userinfo.userId,
                        "modifieddate"      : moment(new Date()).format('MM/DD/YYYY hh:mm'),
                        "numberOfDeduction" : "",
        
                        "payMode"           : "",
                        "payOutDate"        : "",
                        "payrollDate"       : "",
                        "remarks"           : "PARTIAL",
                        "scheddeduction"    : "",
                        "seqNo"             : "",
                        "status"            : "",
                        "transactionDate"   : moment(new Date()).format("MM/DD/YYYY"),
                        "upload"            : "UPLOAD",
                        "isModified"        : "0", 
                        "isInclusion"       : "",
                        "inclusion"         : "",
                        "isMF"              : "",
                        "mf"                : "",
                        "priority"          : ded.priority,
                        "isClearance"       : "1",
                        "dateFinalized"     : moment(new Date()).format("MM/DD/YYYY"),
                        "dateSubmittedClearance"    : itm.createdDate,
                        "financePayoutDate"         : moment(new Date()).format("MM/DD/YYYY"),
                        "dateSubmittedToPayroll"    : itm.dateSubmittedToPayroll,
                    })
                    if((partialChargesAmount - partialBalance).toFixed(2).toString() !== 0){
                        arrList.push({
                            "amortization"      : "",
                            "batchNumber"       : "",
                            "client"            : itm.clientName,
                            "clientId"          : itm.clientId,
                            "referenceNo"       : "",
                            "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
                            "createdby"         : String(this.state.userinfo.fullName).replace(',',''),
                            "credit"            : (partialChargesAmount - partialBalance).toFixed(2).toString(),
                            "cutOffDate"        : "",
                            "dateStart"         : "",
                            "debit"             : "0.00",
                            "deduction"         : ded.deduction,
                            "deductionId"       : ded.deductionId,
                            "deductionStatus"   : "",
            
                            "deductionType"     : ded.deductionType,
                            "deductionTypeId"   : ded.deductiontypeId,
                            "docNo"             : ded.docNo,
                            "employeeId"        : itm.employeeId,
                            "employeeName"      : _tmpEmployee,
                            "employeeNo"        : itm.employeeNo,
                            "groupName"         : "",
                            "groupNameId"       : "",
                            "hris"              : "HRIS",
                            "hrisId"            : "1",
                            "isDeleted"         : "0",
                            "isDraft"           : "0",
                            "isModified"        : "0",
                            "isPosted"          : "0",
                            "isPaid"            : "1",
                            "modifiedby"        : this.state.userinfo.userId,
                            "modifieddate"      : moment(new Date()).format('MM/DD/YYYY hh:mm'),
                            "numberOfDeduction" : "",
            
                            "payMode"           : "",
                            "payOutDate"        : "",
                            "payrollDate"       : "",
                            "remarks"           : "TRANSFER RECLASS",
                            "scheddeduction"    : "",
                            "seqNo"             : "",
                            "status"            : "",
                            "transactionDate"   : moment(new Date()).format("MM/DD/YYYY"),
                            "upload"            : "UPLOAD",
                            "isModified"        : "0", 
                            "isInclusion"       : "",
                            "inclusion"         : "",
                            "isMF"              : "",
                            "mf"                : "",
                            "priority"          : ded.priority,
                            "isClearance"       : "1",
                            "dateFinalized"     : moment(new Date()).format("MM/DD/YYYY"),
                            "dateSubmittedClearance"    : itm.createdDate,
                            "financePayoutDate"         : moment(new Date()).format("MM/DD/YYYY"),
                            "dateSubmittedToPayroll"    : itm.dateSubmittedToPayroll,
                        })
                    }
                }
                else{
                    arrList.push({
                        "amortization"      : "",
                        "batchNumber"       : "",
                        "client"            : itm.clientName,
                        "clientId"          : itm.clientId,
                        "referenceNo"       : "",
                        "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
                        "createdby"         : String(this.state.userinfo.fullName).replace(',',''),
                        "credit"            : (_credit).toString(),
                        "cutOffDate"        : "",
                        "dateStart"         : "",
                        "debit"             : "0.00",
                        "deduction"         : ded.deduction,
                        "deductionId"       : ded.deductionId,
                        "deductionStatus"   : "",
        
                        "deductionType"     : ded.deductionType,
                        "deductionTypeId"   : ded.deductiontypeId,
                        "docNo"             : ded.docNo,
                        "employeeId"        : itm.employeeId,
                        "employeeName"      : _tmpEmployee,
                        "employeeNo"        : itm.employeeNo,
                        "groupName"         : "",
                        "groupNameId"       : "",
                        "hris"              : "HRIS",
                        "hrisId"            : "1",
                        "isDeleted"         : "0",
                        "isDraft"           : "0",
                        "isModified"        : "0",
                        "isPosted"          : "0",
                        "isPaid"            : "1",
                        "modifiedby"        : this.state.userinfo.userId,
                        "modifieddate"      : moment(new Date()).format('MM/DD/YYYY hh:mm'),
                        "numberOfDeduction" : "",
        
                        "payMode"           : "",
                        "payOutDate"        : "",
                        "payrollDate"       : "",
                        "remarks"           : "TRANSFER RECLASS",
                        "scheddeduction"    : "",
                        "seqNo"             : "",
                        "status"            : "",
                        "transactionDate"   : moment(new Date()).format("MM/DD/YYYY"),
                        "upload"            : "UPLOAD",
                        "isModified"        : "0", 
                        "isInclusion"       : "",
                        "inclusion"         : "",
                        "isMF"              : "",
                        "mf"                : "",
                        "priority"          : ded.priority,
                        "isClearance"       : "1",
                        "dateFinalized"     : moment(new Date()).format("MM/DD/YYYY"),
                        "dateSubmittedClearance"    : itm.createdDate,
                        "financePayoutDate"         : moment(new Date()).format("MM/DD/YYYY"),
                        "dateSubmittedToPayroll"    : itm.dateSubmittedToPayroll,
                    })
                }
            })
        })
        
        const params = {
            "dbname"      :  AppConfiguration.Setting().noseraredb,
            "_collection" : "SubsidiaryLedgerStaging",
            "doc_data"    : arrList
        }
        console.log("params deductions")
        console.log(params)

        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  params )
        .then(res =>{
            const data = res.data
            console.log("deduction res.data.status success")
            console.log(data)
        })
        .catch(error =>{
            console.log("line 1521 error")
            console.log(error)
            // this.setState({
            //     isloading   :   false,
            //     alerttype   :   "Error!",
            //     isshow      :   true,
            //     color       :   "danger",
            //     message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            //     fade        :   true
            // })
        })
        // this.AddAreInlcusion(dataList)
        this.AddAreInlcusion(dataList)
    };
    AddAreInlcusion = async(dataList) =>{
        let arrList = []
        let selectdData = this.state.payrollDataArray.filter(x=>x.isDeleted==="1")
        this.state.ledgerDG = this.state.dividendLst.filter(f => selectdData.some(item => item.employeeId === f.employeeId )) // inclusion ledger
        
        for(let x = 0; x < this.state.ledgerDG.length; x++){
            if(this.state.ledgerDG[x]["isFloating"] === "0" && this.state.ledgerDG[x]["status"] === "ACTIVE"){
                
                console.log("update")
                let dataTmp = this.state.ledgerDG.filter(x=>x.isFloating==="0")
                for(let x = 0; x < dataTmp.length; x++){
                    let filter_data = {"docNo" : dataTmp[x]["docNo"], "credit" : "0.00", "debit" : "0.00", "employeeNo" : dataTmp[x]["employeeNo"], "deductionId" : dataTmp[x]["deductionId"]}
                    let updateFields = {"dateSubmittedToPayroll" : dataTmp[x]["dateSubmittedToPayroll"], "credit" : dataTmp[x]["balanceAmount"], "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"), "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'), "isPaid" : "1",  "isClearance": "1", "remarks" : "CLOSED", "createdby" : this.state.userinfo.fullName, "createddate"   : moment(new Date()).format('MM/DD/YYYY')}
                    const updateParams = {
                        "dbname"        : AppConfiguration.Setting().noseraredb,
                        "_collection"   : "SubsidiaryLedgerStaging",
                        "filterfields"  : filter_data,
                        "updateFields"  : updateFields
                    }
                    console.log("updateParams");
                    console.log(updateParams);         
                    await axios
                    .post(AppConfiguration.Setting().noserareapi + "action/update_fields",  updateParams)
                    .then(res => {
                        const data = res.data 
                        console.log("inclusion data updateParams");
                        console.log(data);         
     
                    })
                    .catch(error => {
                        console.log("line 1565 error")
                        console.log(error)
                        this.setState({isloading   :   false,
                            alerttype   :   "Error!",
                            isshow      :   true,
                            color       :   "danger",
                            message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                            fade        :   true
                        })
                    })
                }
                // })
            } 
            else if(this.state.ledgerDG[x]["status"] ==="INACTIVE" && this.state.ledgerDG[x]["isFloating"] === "1"){
                console.log("else if")
                const obj = {
                    "seqNo"             : this.state.ledgerDG[x].seqNo,
                    "docNo"             : this.state.ledgerDG[x].docNo,
                    "referenceNo"       : this.state.ledgerDG[x].referenceNo,
                    "debit"             : "0.00",
                    "credit"            : String(this.state.ledgerDG[x].balanceAmount),
                    "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
    
                    "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
                    "payrollDate"       : moment(this.state.ledgerDG[x].payrollDate).format('MM/DD/YYYY'),
    
                    "employeeId"        : this.state.ledgerDG[x].employeeId,
                    "employeeName"      : this.state.ledgerDG[x].employeeName,
                    "employeeNo"        : this.state.ledgerDG[x].employeeNo,
    
                    "clientId"          : this.state.ledgerDG[x].clientId,
                    "client"            : this.state.ledgerDG[x].client,
                    "groupNameId"       : this.state.ledgerDG[x].groupNameId,
                    "groupName"         : this.state.ledgerDG[x].groupName,
    
                    "isInclusion"       : this.state.ledgerDG[x].isInclusion,
                    "inclusion"         : this.state.ledgerDG[x].inclusion,
                    "isMF"              : this.state.ledgerDG[x].isMF,
                    "mf"                : this.state.ledgerDG[x].mf,
                    "series"            : this.state.ledgerDG[x].series,
                    "priority"          : this.state.ledgerDG[x].priority,
    
                    "deductionId"       : this.state.ledgerDG[x].deductionId,
                    "deduction"         : this.state.ledgerDG[x].deduction,
                    "deductionTypeId"   : this.state.ledgerDG[x].deductionTypeId,
                    "deductionType"     : this.state.ledgerDG[x].deductionType,
                    "numberOfDeduction" : this.state.ledgerDG[x].numberOfDeduction,
                    "remarks"           : this.state.ledgerDG[x].remarks,
                    "batchNumber"       : this.state.ledgerDG[x].batchNumber,
                    "amortization"      : this.state.ledgerDG[x].amortization,
                    //"terms"             : this.state.ledgerDG[x].terms,
                    //"amount"      : this.state.ledgerDG[x].balanceAmount,
                    "hrisId"        : "1",
                    "hris"          :"HRIS",
                    "upload"   : this.state.ledgerDG[x].upload,
                    "scheddeduction"    : this.state.ledgerDG[x].scheddeduction,
                    //"payrollDate"       : "",
                    "deductionStatus"   : this.state.ledgerDG[x].deductionStatus,
                    "amortization"      : this.state.ledgerDG[x].amortization,
    
                    "isDraft"       : "0",
                    "isDeleted"     : "0",
                    "isPosted"      : "0",
                    "isPaid"        : "1",
                    "createdby"     : this.state.userinfo.fullName,
                    "createddate"   : moment(new Date()).format('MM/DD/YYYY'),
                    "modifiedby"    : this.state.userinfo.fullName,
                    "modifieddate"  : moment(new Date()).format('MM/DD/YYYY'),
                    "isModified"    : '0', 
                    
                    "payMode"       : this.state.ledgerDG[x].payMode,
                    "payType"       : this.state.ledgerDG[x].payType,
                    "cutOffDate"    : this.state.ledgerDG[x].cutOffDate,
                    "employeeId"    : this.state.ledgerDG[x].employeeId,
                    "profileId"     : this.state.ledgerDG[x].profileId,
                    "status"        : this.state.ledgerDG[x].status,
                    "clientId"      : this.state.ledgerDG[x].clientId,
                    "isBegBalance" : this.state.ledgerDG[x].isBegBalance,
                    "isLocked" : this.state.ledgerDG[x].isLocked,
                    "lock" : this.state.ledgerDG[x].lock,
                    "lockBy" : this.state.ledgerDG[x].lockBy,
                    "lockedDate" : this.state.ledgerDG[x].lockedDate,
                    "unLockedBy": this.state.ledgerDG[x].unLockedBy,
                    "unLockedDate": this.state.ledgerDG[x].unLockedDate,
                    "isSubmittedToPayroll": this.state.ledgerDG[x].isSubmittedToPayroll,
                    "dateSubmittedToPayroll" : this.state.ledgerDG[x].dateSubmittedByPayroll,
                    "dateSubmittedByPayroll" : this.state.ledgerDG[x].dateSubmittedByPayroll,
                    "isClearance": "1",
                    "financePayoutDate": moment(new Date()).format('MM/DD/YYYY'), // current date
                    "remarks" : "CLOSED"
                }
                arrList.push(obj)
            }
            else{
                console.log("else")
                const obj = {
                    "seqNo"             : this.state.ledgerDG[x].seqNo,
                    "docNo"             : this.state.ledgerDG[x].docNo,
                    "referenceNo"       : this.state.ledgerDG[x].referenceNo,
                    "debit"             : "0.00",
                    "credit"            : String(this.state.ledgerDG[x].balanceAmount),
                    "collectionDate"    : moment(this.state.dateCompletedTmp).format("MM/DD/YYYY"),
    
                    "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
                    "payrollDate"       : moment(this.state.ledgerDG[x].payrollDate).format('MM/DD/YYYY'),
    
                    "employeeId"        : this.state.ledgerDG[x].employeeId,
                    "employeeName"      : this.state.ledgerDG[x].employeeName,
                    "employeeNo"        : this.state.ledgerDG[x].employeeNo,
    
                    "clientId"          : this.state.ledgerDG[x].clientId,
                    "client"            : this.state.ledgerDG[x].client,
                    "groupNameId"       : this.state.ledgerDG[x].groupNameId,
                    "groupName"         : this.state.ledgerDG[x].groupName,
    
                    "isInclusion"       : this.state.ledgerDG[x].isInclusion,
                    "inclusion"         : this.state.ledgerDG[x].inclusion,
                    "isMF"              : this.state.ledgerDG[x].isMF,
                    "mf"                : this.state.ledgerDG[x].mf,
                    "series"            : this.state.ledgerDG[x].series,
                    "priority"          : this.state.ledgerDG[x].priority,
    
                    "deductionId"       : this.state.ledgerDG[x].deductionId,
                    "deduction"         : this.state.ledgerDG[x].deduction,
                    "deductionTypeId"   : this.state.ledgerDG[x].deductionTypeId,
                    "deductionType"     : this.state.ledgerDG[x].deductionType,
                    "numberOfDeduction" : this.state.ledgerDG[x].numberOfDeduction,
                    "remarks"           : this.state.ledgerDG[x].remarks,
                    "batchNumber"       : this.state.ledgerDG[x].batchNumber,
                    "amortization"      : this.state.ledgerDG[x].amortization,
                    //"terms"             : this.state.ledgerDG[x].terms,
                    //"amount"      : this.state.ledgerDG[x].balanceAmount,
                    "hrisId"        : "1",
                    "hris"          :"HRIS",
                    "upload"   : this.state.ledgerDG[x].upload,
                    "scheddeduction"    : this.state.ledgerDG[x].scheddeduction,
                    //"payrollDate"       : "",
                    "deductionStatus"   : this.state.ledgerDG[x].deductionStatus,
                    "amortization"      : this.state.ledgerDG[x].amortization,
    
                    "isDraft"       : "0",
                    "isDeleted"     : "0",
                    "isPosted"      : "0",
                    "isPaid"        : "1",
                    "createdby"     : this.state.userinfo.fullName,
                    "createddate"   : moment(new Date()).format('MM/DD/YYYY'),
                    "modifiedby"    : this.state.userinfo.fullName,
                    "modifieddate"  : moment(new Date()).format('MM/DD/YYYY'),
                    "isModified"    : '0', 
                    
                    "payMode"       : this.state.ledgerDG[x].payMode,
                    "payType"       : this.state.ledgerDG[x].payType,
                    "cutOffDate"    : this.state.ledgerDG[x].cutOffDate,
                    "employeeId"    : this.state.ledgerDG[x].employeeId,
                    "profileId"     : this.state.ledgerDG[x].profileId,
                    "status"        : this.state.ledgerDG[x].status,
                    "clientId"      : this.state.ledgerDG[x].clientId,
                    "isBegBalance" : this.state.ledgerDG[x].isBegBalance,
                    "isLocked" : this.state.ledgerDG[x].isLocked,
                    "lock" : this.state.ledgerDG[x].lock,
                    "lockBy" : this.state.ledgerDG[x].lockBy,
                    "lockedDate" : this.state.ledgerDG[x].lockedDate,
                    "unLockedBy": this.state.ledgerDG[x].unLockedBy,
                    "unLockedDate": this.state.ledgerDG[x].unLockedDate,
                    "isSubmittedToPayroll": this.state.ledgerDG[x].isSubmittedToPayroll,
                    "dateSubmittedToPayroll" : this.state.ledgerDG[x].dateSubmittedByPayroll,
                    "dateSubmittedByPayroll" : this.state.ledgerDG[x].dateSubmittedByPayroll,
                    "isClearance": "1",
                    "financePayoutDate": moment(new Date()).format('MM/DD/YYYY'), // current date
                    "remarks" : "CLOSED"
                }
                arrList.push(obj)
            }
        }
        console.log('arrList')
        console.log(arrList)
        if (arrList.length>0){
            const addParams = {
                "dbname"      :  AppConfiguration.Setting().noseraredb,
                "_collection" : "SubsidiaryLedgerStaging",
                "doc_data"    : arrList
            }
            console.log("params insert inclusion")
            console.log(addParams)
            axios
            .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
            .then(res =>{
                console.log("params inclusion response")
                console.log(res)
            })
            .catch(error =>{
                console.log("line 1756 error")
                console.log(error)
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,fade        :   true
                })
            })
        }
        this.UpdateUploadInc()
    };
    UpdateUploadInc = async(arrList) =>{
        // console.log("arrList")
        // console.log(arrList)
        let selectdData = this.state.payrollDataArray.filter(x=>x.isDeleted==="1")
        this.state.ledgerDG = this.state.dividendLst.filter(f => selectdData.some(item => item.employeeId === f.employeeId ))
        
        // console.log("filterDataList")
        // console.log(filterDataList)

        for(let x = 0; x < this.state.ledgerDG.length; x++){
            let filter_data = { 
                "docNo" : this.state.ledgerDG[x]["docNo"], 
                "client" : this.state.ledgerDG[x]["client"], 
                "employeeNo" : this.state.ledgerDG[x]["employeeNo"], 
                "deductionId" : this.state.ledgerDG[x]["deductionId"], 
                "dateSubmittedToPayroll" : ""
            }
            let updateFields = {"isClearance" : "1", "isSubmittedToPayroll" : "1" }
            const updateParams = {
                "dbname"        : AppConfiguration.Setting().noseraredb,
                "_collection"   : "SubsidiaryLedgerStaging",
                "filterfields"  : filter_data,
                "updateFields"  : updateFields
            }
            console.log("update header or charges");
            console.log(updateParams);         
            await axios
            .post(AppConfiguration.Setting().noserareapi + "action/update_fields",  updateParams)
            .then(res => {
                const data = res.data  
                console.log("inclusion update success 1");
                console.log(data);  
            })
            .catch(error => {
                console.log("line 1803 error")
                console.log(error)
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        }
        this.UpdateInclusionLdger()
    };
    UpdateInclusionLdger = async() =>{
        // console.log("arrList")
        // console.log(arrList)
        let selectdData = this.state.payrollDataArray.filter(x=>x.isDeleted==="1")
        this.state.ledgerDG = this.state.dividendLst.filter(f => selectdData.some(item => item.employeeId === f.employeeId ))
        
        // console.log("filterDataList")
        // console.log(filterDataList)

        for(let x = 0; x < this.state.ledgerDG.length; x++){
            let filter_data = { 
                "docNo" : this.state.ledgerDG[x]["docNo"], 
                "deductionId" : this.state.ledgerDG[x]["deductionId"], 
            }
            let updateFields = {"isClearance" : "1"}
            const updateParams = {
                "dbname"        : AppConfiguration.Setting().noseraredb,
                "_collection"   : "InclusionLedger",
                "filterfields"  : filter_data,
                "updateFields"  : updateFields
            }
            console.log("inclusion ledger update");
            console.log(updateParams);         
            await axios
            .post(AppConfiguration.Setting().noserareapi + "action/update_fields",  updateParams)
            .then(res => {
                const data = res.data  
                console.log("inclusion update success 2");
                console.log(data);  
            })
            .catch(error => {
                console.log("line 1848 error")
                console.log(error) 
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        }
        this.UpdateHris()
    };
    UpdateHris = async() =>{

        let fltrDataArry = this.state.payrollDataArray.filter( x => x.isDeleted === "1" || x.isSelected === "1")
        fltrDataArry.forEach((itm,idx)=>{
            let param = {
                "IpAddress":"0.0.0.0",
                "ClientId": this.state.userinfo.clientId, //--> session
                "UserId":this.state.userinfo.userId,
                "EmployeeId":itm.employeeId
            }
            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/UpdateClearanceStatus",  param)
            .then(res => {
                let data = res.data;
                console.log("UpdateClearanceStatus update success 1");
                console.log(data);
                this.setState({
                    dateCompletedTmp : "",
                    dateOfPaymentTmp : "",
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
                this.GetDataGrid();
            })
            .catch(error=>{
                console.log("line 1891 error")
                console.log(error) 
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        })
    };





    handleClickReturn = async(statusId) => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let arrLst = []
        // let dedArr = []

        let _slctdData = this.state.payrollDataArray.filter( x => x.isDeleted === "1")

        if (_slctdData.length === "0") {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select atleast 1 clearance to process.",
                fade            :   true
            })
            return
        }

        _slctdData.filter(x=>x.completionDate==="").forEach(itm => {
            // let _clearanceStatus = ""
            // if (itm.isClearanceStatus === "2"){
            //     if (itm.reasonForLeaving === "Dole Cases"){
            //         _clearanceStatus = "0"
            //     }else {
            //         _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
            //     }
            // }else{
            //     _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
            // }
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.cashCardNumberMember,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,

                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": "7",
                "clearanceDetails" :itm.clearanceDetails,

                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":this.state.userinfo.userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":this.state.userinfo.userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":this.state.userinfo.userId,

                "DateUpdatedByServices":date,
                "UpdatedByServices":this.state.userinfo.userId,

                "DateUpdatedByARE":itm.dateUpdatedByARE,
                "UpdatedByARE":itm.isUpdatedByARE,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":this.state.userinfo.userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": this.state.userinfo.userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":this.state.userinfo.userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":this.state.userinfo.userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":this.state.userinfo.userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":this.state.userinfo.userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":this.state.userinfo.userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":this.state.userinfo.userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":this.state.userinfo.userId,

                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,


                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : itm.lastSalary,
                "Incentives" : itm.incentives,
                "Sil" : itm.sil,
                "ThirteenthMonth" : itm.thirteenthMonth,
                "Inclusions" : itm.inclusions,
                "GrossPay" : itm.grossPay,
                "NetPay" : itm.netPay,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                "ReviewStatus" : "0",
                "DateReturnedByPayroll" : date,
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks !== "" ? itm.payrollRemarks : this.state.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                "IsClearedSubmit" : "0",
                "IsGenerated" : "0",
                "totalBalance" : itm.totalBalance,
                "IsFinalized" : "0",
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : itm.BatchPayroll,
                "Adjustment" : typeof itm.Adjustment === "undefined" ? "" : itm.Adjustment,
                "OtherPayroll" : typeof itm.OtherPayroll === "undefined" ? "" : itm.OtherPayroll,
                "Cbu" : typeof itm.cbu === "undefined" ? "" : itm.cbu,
                "Dividend" : typeof itm.dividend === "undefined" ? "" : itm.dividend,
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : itm.settlementFee,
                "CompletionDate" : itm.completionDate,
                "DateOfPayment" : itm.dateOfPayment,
                "FinancePayoutDate" : itm.financePayoutDate,
                "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                "IsAre" : itm.isAre,
                "IsPayroll" : itm.isPayroll,
                "LastModifiedBy" : this.state.userinfo.fullName
                /* END */

            })
            this.setState({ profileId : itm.profileId })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }
        // // // console.log("Return param")
        // // // console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // this.RefreshPage();
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            this.GetDataGrid();
        })
        .catch(error=>{
            console.log("line 2115 error")
            console.log(error) 
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false})
    };
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false})
    };
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false})
    };
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false})
    };
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false})
    };
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false})
    };
    handleChangedPayrollRemarks = (e) =>{
        this.setState({ financeRemarks : e.target.value.toUpperCase()})
    };
    handleChangeStatus = (e) =>{
        if (e.length === 0){
            this.state.statusId = ""
        } else {
            this.state.statusId = e[0].id
        }
    };


    onChangePhoneNumber =(e)=>{
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ phoneNumber : e.target.value})
         }
    };
    handleChangeInfos =(e)=>{
        if(e.length===0){
            this.state.memberName = ""
            return
        }
        this.setState({
            memberName: e[0].name
        })
    };
    
    handleClickDemandLetter =async()=>{
        // // console.log("this.state.isMrs")
        // // console.log(this.state.isMrs)
        let slctdData = this.state.payrollDataArray.filter(x=>x.isDeleted==="1")
        // // console.log("slctdData")
        // // console.log(slctdData)
        if(slctdData.length === 0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select atleast 1 to process.",
                fade        :   true
            })
        }
        else{
            this.handleDemandLetter()
        }
    };
    handleDemandLetter = () =>{
        sessionStorage.removeItem("clearanceData")
        var converter = require('number-to-words');
        // // converter.toWords(13);
        // // // console.log("converter.toWords(13)")
        // // // console.log(converter.toWords(13))

        let slctdData = this.state.payrollDataArray.filter(x=>x.isDeleted==="1")
        // // console.log("slctdData[0].drafterName")
        // // console.log(slctdData[0].drafterName)

        let x = 1242.90
        let amt = converter.toWords(String(slctdData[0].netPay).replace("-","").replace(",","")) + " pesos"
        let name = slctdData[0].employeeName.split(",")[0]
        let fullName = slctdData[0].employeeName
        let netPay = (String(slctdData[0].netPay).replace("-","").replace(",",""))
        let drafterName = typeof slctdData[0].authorizePersonel==="undefined" || slctdData[0].authorizePersonel==="" ?"Michelle Libao Cangmaong":slctdData[0].authorizePersonel
        let drafterNumber = slctdData[0].drafterNumber
        let authorizedPersonel = slctdData[0].drafterName === "" ? "" :  slctdData[0].drafterName
        

        let filterPosition = authorizedPersonel===""?"":this.state.drafterList.filter(x => x.employeeName === authorizedPersonel)
        // // console.log("filterPosition")
        // // console.log(filterPosition)
        let position = typeof filterPosition === "undefined" || filterPosition==="" ? "Labor Relations and Discipline" : filterPosition[0].position.toLowerCase();
        
        // // console.log("drafterName")
        // // console.log(drafterName)

        let obj = {
            "name" : name,
            "fullName" : fullName,
            "amount" : amt,
            "netPay" : netPay,
            "authorizedPersonel" : authorizedPersonel===""?"SHIELLA MARIE GUILLERMO":authorizedPersonel,
            "phoneNo"  : drafterNumber===""?"09171362212":drafterNumber,
            "member" : drafterName,
            "label" : this.state.isMrs,
            "position" : this.toUpper(position)
        }
        // // console.log("obj")
        // // console.log(obj)
        sessionStorage.setItem("clearanceData", JSON.stringify(obj))
        this.setState({ navigate: true})

    };
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    };
    onchangeMRs = (e) =>{
        // this.setState({ isCheckPHIC : e.target.checked })
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isMrs: "Ms" })
        } else {
            this.setState({ isMrs: "Mr" })
        }
    };
    toUpper(str) {
        return str
            .toLowerCase()
            .split(' ')
            .map(function(word) {
                return word[0].toUpperCase() + word.substr(1);
            })
            .join(' ');
    }
    
    handleChangeFromDateOfPaymentTmp = date =>{
        this.setState({ dateOfPaymentTmp: date, isshow:false , })
    }
    handleChangeToDateCompletedTmp = date =>{
        this.setState({ dateCompletedTmp: date, isshow:false , })
    }
    render() {

        const { navigate } = this.state;
        if(navigate){
            return <Redirect to="/DemandLetterClearance" push={true}/>
        };

        let { SearchBar, ClearSearchButton } = Search;
        let financeColumn = [
            {
                dataField: 'batchPayroll',
                text: 'BATCH NUMBER',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO.',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'netPay',
                text: 'NET PAY',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%"};
                },
                style:{textAlign : "right"}
            },
            {
                dataField: 'drafterName',
                text: 'DRAFTER NAME',
                editable: true,
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.drafterName!='' && row.drafterName!=null){
                        return this.state.drafterNameDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.drafterNameDDL
                }
            },
            {
                dataField: 'authorizePersonel',
                text: 'AUTHORIZE PERSONNEL',
                editable: true,
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.authorizePersonel!='' && row.authorizePersonel!=null){
                        return this.state.drafterNameDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.drafterNameDDL
                }
            },
            {
                dataField: 'drafterNumber',
                text: 'Contact Number',
                editable: true,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                validator: (newValue, row, column)=>{
                    if (this.IsDecimal(newValue)){
                        return {
                            valid: false,
                            message : "Invalid value."
                        }
                    }
                    if(newValue.split(".").length>2){
                        return {
                            valid: false
                        }
                    }
                    return true;
                }
            },
            {
                dataField: 'completionDate',    
                text: 'STATUS DATE',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            // {
            //     dataField: 'completionDate',
            //     text: 'DATE COMPLETED',
            //     editable: false,
            //     headerStyle : () => {
            //         return { width  : "10%" };
            //     }
            // },
            {
                dataField: 'proofOfPayment',
                text: 'PROOF OF PAYMENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: '',
                text: 'MODE OF PAYMENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if(row.typeOfCashCardMember !== "" && row.isCheckMember ==="0" && row.isCash  === "0"){
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                {row.typeOfCashCardMember}
                            </Form.Label>   
                        )
                    } 
                    else if (row.typeOfCashCardMember === "" && row.isCheckMember ==="1" && row.isCash  === "0") {
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                            CHECK
                            </Form.Label>   
                        )
                    } 
                    else if (row.typeOfCashCardMember === "" && row.isCheckMember ==="0" && row.isCash  === "1") {
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                            CASH
                            </Form.Label>  

                        )
                    }
                    else if (row.typeOfCashCardMember === "0" && row.isCheckMember ==="0" && row.isCash  === "0") {
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                YES
                            </Form.Label>  

                        )
                    }  
                }
            },
            {
                dataField: 'parentId',
                text: 'CLEARANCE TYPE',
                editable: false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                style:{textAlign:"left"},
                formatter   :   (cell, row, isSelect) => {
                    if(row.parentId === ""){
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                Parent
                            </Form.Label>   
                        )
                    } 
                    else{
                        return (
                            <Form.Label column sm="12" style={{textAlign:"left"}}>
                                Child
                            </Form.Label>   
                        )
                    }  
                },
            },
        ]

        let selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            // selected : this.state.selectedId,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.payrollDataArray.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        // console.log(item)
                    }
                })
            },
            onSelectAll: (isSelect, row) => {
                let isDisable = true
                this.state.payrollDataArray.map(function(itm){
                    itm.isDeleted = isSelect ? "1" : "0"
                })
            }
        };

    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; FINANCE ( NEGATIVE CLAIMS )</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div>
                                    {this.state.message}
                                </Alert>
                                <div>
                                <Accordion className="mt-2">
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                CLICK TO SEARCH
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CLIENT
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.client}
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangeClient}
                                                            options={this.state.clientDDL}
                                                            placeholder='SELECT CLIENT'
                                                            //selected={[this.state.clientName]}
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMPLOYEE
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.employee}
                                                            labelKey='employeeName'
                                                            id="basic-example"
                                                            onChange={this.handleChangeEmployee}
                                                            options={this.state.payrollDataArray}
                                                            placeholder='SELECT EMPLOYEE'
                                                            //defaultSelected={[this.state.employeeName]}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    REASON FOR LEAVING
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.refReason}
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangedReason}
                                                            options={this.state.reasonDDL}
                                                            placeholder='Select reason for leaving'
                                                            value = {this.state.reason}

                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        Status
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.name}
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangeStatus}
                                                            options={this.state.statusDDL}
                                                            placeholder='SELECT STATUS'
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Date Created
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromDateCreated}
                                                            onChange={this.handleChangeFromDateCreated}
                                                            value={this.props.fromDateCreated}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Date Created
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toDateCreated}
                                                            onChange={this.handleChangeToDateCreated}
                                                            value={this.props.toDateCreated}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Clearance Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromClearanceDate}
                                                            onChange={this.handleChangeFromClearanceDate}
                                                            value={this.props.fromClearanceDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Clearance Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toClearanceDate}
                                                            onChange={this.handleChangeToClearanceDate}
                                                            value={this.props.toClearanceDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Date Received
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromDateReceived}
                                                            onChange={this.handleChangeFromDateReceived}
                                                            value={this.props.fromDateReceived}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Date Received
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toDateReceived}
                                                            onChange={this.handleChangeToDateReceived}
                                                            value={this.props.toDateReceived}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Last Working Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromLastWorkingDate}
                                                            onChange={this.handleChangeFromLastWorkingDate}
                                                            value={this.props.fromLastWorkingDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Last Working Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toLastWorkingDate}
                                                            onChange={this.handleChangeToLastWorkingDate}
                                                            value={this.props.toLastWorkingDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail" className='mb-5'>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        RESiGNATION DATE
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='resignationDate'
                                                            selected={this.state.resignationDate}
                                                            onChange={this.handleChangeResignationDate}
                                                            minDate={this.minDate}
                                                            value={this.props.resignationDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT RESIGNATION DATE'
                                                            wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            <div style={{height : "50px"}}></div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                </div>
                                <ButtonToolbar className="mt-3">
                                    <Button className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickRefresh }>
                                        Refresh
                                    </Button>
                                    <Button variant="primary" className="noser-button-mr1 noser-button" onClick={ this.handleClickSearch }>
                                        Search
                                    </Button>
                                    {/* <Button variant="info" className="noser-button" >
                                        <CSVLink style={{ color : "white" }} onClick ={this.GridExcellReports} filename= "Batch_Clearance.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                                    </Button> */}
                                </ButtonToolbar>
                                <div className="mt-2">
                                    <Form.Check
                                        type='checkbox'
                                        id="isMRs"
                                        name='isMRs'
                                        label="Ms?"
                                        onChange={ e => this.onchangeMRs(e)  }
                                        checked = { this.state.isMRs }
                                        value={ this.state.isMRs }
                                        className='mt-2 no-text-transform-li mt-3'
                                        style={{ fontWeight : "bold" }}
                                    />
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.payrollDataArray.filter( x=> x.isFinalized === "1") }
                                        columns = { financeColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRow }
                                        // expandRow={ expandRow }
                                    />
                                
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DATE OF PAYMENT
                                        </Form.Label>
                                        <Col>
                                            <DatePicker
                                                ref='dateOfPaymentTmp'
                                                selected={this.state.dateOfPaymentTmp}
                                                onChange={this.handleChangeFromDateOfPaymentTmp}
                                                value={this.props.dateOfPaymentTmp}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                // placeholder='SELECT CLEARANCE DATE'
                                                // wrapperClassName="datepicker"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DATE COMPLETED
                                        </Form.Label>
                                        <Col>
                                            <DatePicker
                                                ref='dateCompletedTmp'
                                                selected={this.state.dateCompletedTmp}
                                                onChange={this.handleChangeToDateCompletedTmp}
                                                value={this.props.dateCompletedTmp}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                // placeholder='SELECT CLEARANCE DATE'
                                                // wrapperClassName="datepicker"
                                            />
                                        </Col>
                                    </Form.Group>
                                </div>
                                {

                                    this.state.userinfo.userId === "89655" /* || this.state.userinfo.userId === "39965" */ ?
                                    
                                    <ButtonToolbar sm={12}>
                                            {/* <Button onClick={ this.handleClickClose } className="ml-auto noser-button-mr1 noser-button" variant="info" >
                                                CLOSE
                                            </Button>
                                            <Button onClick={this.handleClickReturn } className="noser-button-mr1 noser-button"  variant="info">
                                                return
                                            </Button> */}
                                            <Button onClick={this.handleClickDemandLetter} className="noser-button" variant="info">
                                                demand letter
                                            </Button> 
                                    </ButtonToolbar>
                                    :
                                    <ButtonToolbar sm={12}>
                                        <Button onClick={ this.handleClickClose } /* disabled = {true} */ className="ml-auto noser-button-mr1 noser-button" variant="info" >
                                            CLOSE
                                        </Button>
                                        <Button onClick={this.handleClickReturn } className="noser-button-mr1 noser-button"  variant="info">
                                            return
                                        </Button>
                                        {/* <Button onClick={this.handleClickDemandLetter} className="noser-button" variant="info">
                                            demand letter
                                        </Button> */}
                                    </ButtonToolbar>
                                }
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}

export  default ClearanceFinanceAre