import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect, Tabs, Tab
}
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
let ref = React.createRef();
class ClearanceEdit extends Component {
    constructor(props) {
        super(props);
        this.client = React.createRef();
        this.employee = React.createRef();
        this.disengagementMode = React.createRef();
        this.refCashCard = React.createRef();
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            isshowClient : false,

            clientDDL: [],
            clientId: "",
            clientName:"",
            employeeDDL: [],
            profileId: "",
            employeeId: "",

            // START MEMBER FIELDS
            reasonDDL: [
                // {'id' : '', 'name' : 'Select Reason'},
                // {'id':'1','name':'Clearance'},
                // {'id':'2','name':'Clearance & Resignation'}
            ],
            reasonId: "",
            reason: "",
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            memberNotes:"",
            memberDDL:[],
            isCheckSSS:false,
            isCheckTIN:false,
            isCheckPHIC:false,
            isCheckHDMF:false,
            isCheckCashCard:false,
            isCheckValidId:false,
            isCheckResignation:false,
            isCheckTurnover:false,
            isCheckRequestForm:false,
            isRadioCashcard:false,
            isRadioCash:false,
            radioCashCardDDL:[],
            cashCardType:"",
            accountNumber:"",
            isRadioCheck:false,
            isRadioMlhuillier:false,
            isRadioPalawan:false,
            cashCardField:true,
            disableaftrsubmit : false,
            memberRemarks:"",
            dateCreated: new Date(),
            dateSubmitted: "",
            clearanceStatus:"",
            // END MEMBER FIELDS

            clientRemarks:"",
            clientTabDDL:[],
            lrdRemarks:"",
            lrdTabDDL:[],
            rmtDDL:[],
            serviceTabDDL:[],
            attachedDDL:[],
            attachedDDLLrd:[],

            disabledButtonMember:true,
            disabledCleintButton:true,
            disabledLrdButton:true,
            disabledRmtButton:true,
            disabledServicesButton:true,
            dateRecieved : "",
            dateForwarded : "",
            notEligible : false,
            eligible : false,
            isClearanceStatus : "",
            files : [],
            daysCount : "0",
            cycleDays : "",
            selectedItems : [],

            //from edit details
            employeeName:"",
            disabledButtonSave:true,
            datenow :   new Date(),
            employeeModified : "",

            _createdby : "",
            _createddate : "",
            _updatedbystaff : "",
            _updatedbystaffdate : "",
            _updatedbylead : "",
            _updatedbyleaddate : "",
            _submittedby : "",
            _submittedbydate : "",
            _returnedby : "",
            _returnedbydate : "",
            createdbynametmp : "",
            createdbydatetmp : "",
            modifieddatetmp : "",
            modifiedbyname : "",
            disabledTmp : true,
            disabledTmp1 : true,
            disabledTmp2 : true,
            disabledTmp3 : true,
            _returnedbyClient : "",
            _returnedbydateClient : "",
            _returnedbyLrd : "",
            _returnedbydateLrd : "",
            _returnedbyRmt : "",
            _returnedbydateRmt : "",
            _returnedbyServices: "",
            _returnedbydateServices : "",
            test : "",
            settledDate : "",
            isSetteldWithDole : false,
            filesLrd : "",
            dateReceivedByAdmin : "",
            _submittedByLead : "",
            _dateSubmittedByLead : "",
            _submittedByStaff : "",
            _dateSubmittedByStaff :"",
            _returnedby : "",
            _returnedbydate : "",
            rmtRemarks:"",
            lrdRemarks: "",
            servicesRemarks:"",
            emptystate : "",
            _dateServices : "",
            DataListClearance : [],
            totalBalance : "",
            submitted : "",
            servicesAttachments : [],
            isParamountId : false,
            clearanceId : "",
            isSetteldWithDole : false,
            iswithatd : "0",
            areRemarks : "",
            payrollRemarks : "",
            financeRemarks : "",
            paycardTypeIdMember : "",
            batchNumber : "",
            checkEligibility : false,
            contractDateStart : "",
            contractDateEnd : "",
            dateHired : "",
            dateResigned : "",
            checktypeahead : false,
            totalInclusion : "",
            isCashcardCheck : "0",
            isParent : "",
            isChild : "",
            parentId : "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clearanceeditdetails = JSON.parse(sessionStorage.getItem("clearanceeditdetails_"))
        this.state.key = parseInt(this.state.clearanceeditdetails.isClearanceStatus) === 1 ? 0 : parseInt(this.state.clearanceeditdetails.isClearanceStatus)
        this.onImageReplyChange = this.onImageReplyChange.bind(this);
        this.onImageReplyChangeLrd = this.onImageReplyChangeLrd.bind(this);
        this.fileInput = React.createRef();
        this.fileInputLrd = React.createRef();

    }

    componentDidMount(){
        this.forCheckingItems()

    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            // // console.log("data")
            // // console.log(data)
            this.setState({radioCashCardDDL:data.payCardTypes.filter(x=>x.id!=="3"&&x.id!=="5"&&x.id!=="7"&&x.id!=="8")});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        // // // console.log('this.state.cardtypeDDL')
        // // // console.log(this.state.cardtypeDDL)
        // this.GetDataClearance()
    }
    GridDataModified(oldValue, newValue, id, column) {
        // let disable = true
        this.state.attachedDDL.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
            }
        })
    }
    forCheckingItems = async() => {

        if(this.state.clearanceeditdetails.isClearanceStatus === "1"){
            this.setState({
                disabledCleintButton : true,
            })
        }else {
            this.setState({
                disabledCleintButton : false,
            })
        }

        // // // console.log('this.state.userinfo')
        // // // console.log(this.state.userinfo)
        console.log('this.state.clearanceeditdetails')
        console.log(this.state.clearanceeditdetails)

        this.setState({
            disabledButtonMember : false,
            payPeriodId : this.state.clearanceeditdetails.payPeriodId,
            clearanceStatus : this.state.clearanceeditdetails.clearanceStatus,
            batchNumber : this.state.clearanceeditdetails.batchNumber,
            isClearanceStatus : this.state.clearanceeditdetails.isClearanceStatus,
            accountNumber : this.state.clearanceeditdetails.cashCardNumberMember,
            paycardTypeIdMember : this.state.clearanceeditdetails.paycardTypeIdMember,
            isCashcardCheck : this.state.clearanceeditdetails.IsTypeOfCashCardMember,
            cycleDays : this.state.clearanceeditdetails.cycleDays,
            clearanceId : this.state.clearanceeditdetails.id,
            profileId : this.state.clearanceeditdetails.profileId,
            employeeId : this.state.clearanceeditdetails.employeeId,
            clientId : this.state.clearanceeditdetails.clientId,
            clientName : this.state.clearanceeditdetails.clientName,
            employeeName : this.state.clearanceeditdetails.employeeName,
            reason : this.state.clearanceeditdetails.reasonForLeaving,
            resignationDate : this.state.clearanceeditdetails.resignationDateMember !=="" ? new Date(this.state.clearanceeditdetails.resignationDateMember) : "",
            clearanceDate : this.state.clearanceeditdetails.clearanceDateMember !=="" ? new Date(this.state.clearanceeditdetails.clearanceDateMember) : "",
            dateCreated : this.state.clearanceeditdetails.dateCreated !=="" ? new Date(this.state.clearanceeditdetails.dateCreated) : "",
            submissionDate : this.state.clearanceeditdetails.submissionDateMember !=="" ? new Date(this.state.clearanceeditdetails.submissionDateMember) : "",
            workingDate : this.state.clearanceeditdetails.lastWorkingDateMember !=="" ? new Date(this.state.clearanceeditdetails.lastWorkingDateMember) : "",
            memberNotes : this.state.clearanceeditdetails.notesMember,
            dateForwarded : this.state.clearanceeditdetails.dateForwardedToAmt !=="" ? new Date(this.state.clearanceeditdetails.dateForwardedToAmt) : "",
            dateRecieved : this.state.clearanceeditdetails.dateReceivedFromAmt !=="" ? new Date(this.state.clearanceeditdetails.dateReceivedFromAmt) : "",
            lastName : this.state.clearanceeditdetails.lastName,
            locationName : this.state.clearanceeditdetails.locationName,
            firstName : this.state.clearanceeditdetails.firstName,
            positionName : this.state.clearanceeditdetails.positionName,
            middleName : this.state.clearanceeditdetails.middleName,
            client : this.state.clearanceeditdetails.clientName,
            tinNumber : this.state.clearanceeditdetails.tinNumber,
            sssNumber : this.state.clearanceeditdetails.sssNumber,
            phicNumber : this.state.clearanceeditdetails.phicNumber,
            hdmfNumber : this.state.clearanceeditdetails.hdmfNumber,
            cashCardType : this.state.clearanceeditdetails.typeOfCashCardMember,
            contractDateStart : this.state.clearanceeditdetails.dateStart !=="" ? new Date(this.state.clearanceeditdetails.dateStart) : "",

            isRadioCheck : this.state.clearanceeditdetails.isRadioCheck,
            isRadioMlhuillier : this.state.clearanceeditdetails.isRadioMlhuillier,
            isRadioPalawan : this.state.clearanceeditdetails.isRadioPalawan,
            isRadioCashcard : this.state.clearanceeditdetails.isRadioCashcard,
            eligible : this.state.clearanceeditdetails.isEligible === "1" ? true : false,
            notEligible : this.state.clearanceeditdetails.isNotEligible === "1" ? true : false,
            accountNumber : this.state.clearanceeditdetails.CashCardNumberMember,
            isCheckCashCard : this.state.clearanceeditdetails.CashCardNumberMember,
            employeeModified : this.state.clearanceeditdetails.CashCardNumberMember,
            createdbydatetmp : this.state.clearanceeditdetails.dateCreated,
            createdbynametmp : this.state.clearanceeditdetails.createdByName,
            modifieddatetmp : (this.state.clearanceeditdetails.modifiedDate) ? moment(this.state.clearanceeditdetails.modifiedDate).format('MM/DD/YYYY') : "",
            modifiedbyname : this.state.clearanceeditdetails.modifiedByName,

            _createdby : this.state.clearanceeditdetails.createdByName,
            _createddate : this.state.clearanceeditdetails.dateCreated,

            _submittedByLead : this.state.clearanceeditdetails.submittedByLead,
            _dateSubmittedByLead : this.state.clearanceeditdetails.dateSubmittedByLead,

            _submittedByStaff : this.state.clearanceeditdetails.submittedByStaff,
            _dateSubmittedByStaff : this.state.clearanceeditdetails.dateSubmittedByStaff,

            _returnedbydate: this.state.clearanceeditdetails.dateReturnedByClient,
            _returnedby : this.state.clearanceeditdetails.returnedByClient === "ADMIN TL, ADMIN TL " ? "" : this.state.clearanceeditdetails.returnedByClient,

            _dateServices : this.state.clearanceeditdetails.DateUpdatedByServices,

            dateSettle : this.state.clearanceeditdetails.dateSettle,
            isSetteldWithDole : this.state.clearanceeditdetails.isSettleWithDole === "1" ? true : false,

            dateReceivedByAdmin : this.state.clearanceeditdetails.dateReceivedByAdmin,

            totalBalance : this.state.clearanceeditdetails.totalBalance,
            submitted : this.state.clearanceeditdetails.submitted,
            isRadioCash : this.state.clearanceeditdetails.isCash === "1" ? true : false,


            
            updatedByARE : this.state.clearanceeditdetails.updatedByARE,
            updatedByClient : this.state.clearanceeditdetails.updatedByClient,
            updatedByFinance : this.state.clearanceeditdetails.updatedByFinance,
            updatedByLRD : this.state.clearanceeditdetails.updatedByLRD,
            updatedByRMT : this.state.clearanceeditdetails.updatedByRMT,
            updatedByServices : this.state.clearanceeditdetails.updatedByServices,
            returnedByARE : this.state.clearanceeditdetails.returnedByARE,
            returnedByClient : this.state.clearanceeditdetails.returnedByClient,
            returnedByFinance : this.state.clearanceeditdetails.returnedByFinance,
            returnedByLRD : this.state.clearanceeditdetails.returnedByLRD,
            returnedByRMT : this.state.clearanceeditdetails.returnedByRMT,
            returnedByServices : this.state.clearanceeditdetails.returnedByServices,
            checkEligibility : this.state.clearanceeditdetails.isEligible ==="1" || this.state.clearanceeditdetails.isNotEligible ==="1" ? true : false,
            
            contractDateStart : (this.state.clearanceeditdetails.contractDateStart) ? moment(this.state.clearanceeditdetails.contractDateStart).format('MM/DD/YYYY') : "",
            contractDateEnd : (this.state.clearanceeditdetails.contractDateEnd) ? moment(this.state.clearanceeditdetails.contractDateEnd).format('MM/DD/YYYY') : "",
            dateHired : this.state.clearanceeditdetails.dateHired,
            dateResigned : this.state.clearanceeditdetails.dateResigned,
            batchPayroll : this.state.clearanceeditdetails.batchPayroll,
            adjustment : this.state.clearanceeditdetails.adjustment,
            otherPayroll : this.state.clearanceeditdetails.otherPayroll,
            totalInclusion : this.state.clearanceeditdetails.totalInclusion,
            
                    
            dateSubmittedToPayroll : this.state.clearanceeditdetails.dateSubmittedToPayroll,
            isAre : this.state.clearanceeditdetails.isAre,
            isPayroll : this.state.clearanceeditdetails.isPayroll,


            // remarks 

            memberRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.remarksMember + " " + ":" + " " : this.state.clearanceeditdetails.remarksMember,
            clientRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.remarksClient + " " + ":" + " " :  this.state.clearanceeditdetails.remarksClient,
            lrdRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.remarksLrd + " " + ":" + " " :  this.state.clearanceeditdetails.remarksLrd,
            rmtRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.remarksRmt + " " + ":" + " " :  this.state.clearanceeditdetails.remarksRmt,
            servicesRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.servicesRemarks + " " + ":" + " " :  this.state.clearanceeditdetails.servicesRemarks,
            areRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.areRemarks + " " + ":" + " " :  this.state.clearanceeditdetails.areRemarks,
            payrollRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.payrollRemarks + " " + ":" + " " :  this.state.clearanceeditdetails.payrollRemarks,
            financeRemarks : this.state.userinfo.userId === "" ? this.state.clearanceeditdetails.remarksfinanceRemarksClient + " " + ":" + " " :  this.state.clearanceeditdetails.financeRemarks,
            
            isParent : this.state.clearanceeditdetails.isParent,
            isChild : this.state.clearanceeditdetails.isChild,
            parentId : this.state.clearanceeditdetails.parentId,
            financePayoutDate : this.state.clearanceeditdetails.financePayoutDate,
            dateOfPayment : this.state.clearanceeditdetails.dateOfPayment,

        })
        if (this.state.eligible === true || this.state.notEligible === true){
            this.setState({ checkEligibility : true })
        }
        let newDataTmp = []
        for ( let i = 0; i < this.state.clearanceeditdetails.servicesAttachments.length; i++ ) {
            let id = JSON.stringify(i+1)
            let newData = {
                'id' : id,
                'fileName' : this.state.clearanceeditdetails.servicesAttachments[i].fileName,
                'file' :this.state.clearanceeditdetails.servicesAttachments[i].file,
                'atd' :this.state.clearanceeditdetails.servicesAttachments[i].atd === null ? "NO" : this.state.clearanceeditdetails.servicesAttachments[i].atd,
                'employeeNo' : this.state.clearanceeditdetails.servicesAttachments[i].employeeNo ,
                'dateSubmitted' : this.state.clearanceeditdetails.servicesAttachments[i].dateSubmitted,
                'isModified' : this.state.clearanceeditdetails.servicesAttachments[i].isModified,
                'isDeleted' : "0"
            }
            newDataTmp.push(newData)
        }
        // console.log('newDataTmp')
        // console.log(newDataTmp)
        this.setState({ attachedDDL : newDataTmp})


        // START USER ACCESS
        if ( this.state.clearanceeditdetails.isClearanceStatus === "0" || this.state.clearanceeditdetails.isClearanceStatus === "1") {
            // MEMBER
            if (this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL03" || this.state.userinfo.firstName === "CMWTL04"  || this.state.userinfo.userId === "39965" || this.state.userinfo.employeeId === "91250"){
                this.setState({
                    disabledButtonMember : false,
                    disabledCleintButton : true,
                })
            }
            else{
                this.setState({
                    disabledCleintButton : true,
                })
            }
        } 
        else if ( this.state.clearanceeditdetails.isClearanceStatus === "2") {
            // CLIENT
            if(this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" ||  this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CLIENTTL01" || this.state.userinfo.firstName === "CLIENTTL02"  || this.state.userinfo.userId === "39965" || this.state.userinfo.employeeId === "91250"){
                this.setState({
                    disabledButtonMember : false,
                    disabledCleintButton : false,
                    isshowClient : true,
                })
            }
            else{
                this.setState({
                    disabledButtonMember : true,
                    disabledCleintButton : true,
                    isshowClient : true,
                })
            }
        } 
        else if (this.state.clearanceeditdetails.isClearanceStatus === "3") {
            // LRD
            if(this.state.userinfo.userId === "34024" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" || this.state.userinfo.firstName === "TEAMLEAD02" || this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.userId === "39965" || this.state.userinfo.employeeId === "91250" ){
                this.setState({
                    disabledButtonMember : false,
                    disabledCleintButton : false,
                    disabledsave : true,
                })
            }
            else{
                this.setState({
                    disabledButtonMember : true,
                    disabledCleintButton : true,
                    disabledsave : false,
                })
            }
        } 
        else if (this.state.clearanceeditdetails.isClearanceStatus === "4") {
            // RMT
            if(this.state.userinfo.userId === "36536" || this.state.userinfo.userId === "130303" || this.state.userinfo.userId === "40340" || this.state.userinfo.userId === "92659" || this.state.userinfo.userId === "45350" || this.state.userinfo.userId === "34022" || this.state.userinfo.userId === "42865" || this.state.userinfo.userId === "34005" || this.state.userinfo.lastName === "RMT01" || this.state.userinfo.firstName === "TLRMT02"  || this.state.userinfo.userId === "39965" || this.state.userinfo.employeeId === "91250"){
                this.setState({
                    disabledButtonMember : false,
                    disabledCleintButton : false,
                    disabledsave : true,
                })
            }
            else{
                this.setState({
                    disabledButtonMember : true,
                    disabledCleintButton : true,
                    disabledsave : true,
                })
            }
        } 
        else if (this.state.clearanceeditdetails.isClearanceStatus === "5") {
            // SERVICES 
            if(this.state.userinfo.userId === "91551" || this.state.userinfo.userId === "91460" || this.state.userinfo.userId === "34006" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" ||  this.state.userinfo.firstName === "APRILTL" || this.state.userinfo.userId === "89692" || this.state.userinfo.lastName === "SERVICES01" || this.state.userinfo.lastName === "SERVICES02" || this.state.userinfo.userId === "39965" || this.state.userinfo.employeeId === "91250"){
                this.setState({
                    disabledButtonMember : true,
                    disabledCleintButton : false,
                    disabledsave : true,
                })
            }
            else{
                this.setState({
                    disabledButtonMember : true,
                    disabledCleintButton : true,
                    disabledsave : true,
                })
            }
        } 
        else if (this.state.clearanceeditdetails.isClearanceStatus === "6") {
            // AR-E
            if(this.state.userinfo.userId === "89658" || this.state.userinfo.userId === "1" || this.state.userinfo.userId === "89734"){
                this.setState({
                    disabledButtonMember : true,
                    disabledCleintButton : true,
                    disabledsave : true,
                })
            }
            else{
                this.setState({
                    disabledButtonMember : true,
                    disabledCleintButton : true,
                    disabledsave : true,
                })
            }
        } 
        else if (this.state.clearanceeditdetails.isClearanceStatus === "9") {
            // finance completed 
            this.setState({
                disabledButtonMember : true,
                disabledCleintButton : true,
                disabledsave : true,
            })
        } 
        else if (this.state.clearanceeditdetails.isClearanceStatus === "10") {
            // closed
            this.setState({
                disabledButtonMember : true,
                disabledCleintButton : true,
                disabledsave : true,
            })
        }

        // END USER ACCESS

        if(this.state.clearanceeditdetails.isSSSNumberMember === "1") {

            this.setState ({ isCheckSSS : true })
        }
        else{
            this.setState ({ isCheckSSS : false })
        }
        
        if(this.state.clearanceeditdetails.isTINNumberMember === "1") {
            this.setState ({ isCheckTIN : true })
        }
        else{
            this.setState ({ isCheckTIN : false })
        } 

        if(this.state.clearanceeditdetails.isPHICNumberMember === "1") {
            this.setState ({ isCheckPHIC : true })
        }
        else{
            this.setState ({ isCheckPHIC : false })
        }

        if(this.state.clearanceeditdetails.isHDMFNumberMember === "1") {
            this.setState ({ isCheckHDMF : true })
        }
        else{
            this.setState ({ isCheckHDMF : false })
        }

        if(this.state.clearanceeditdetails.isCashCardMember === "1") {
            this.setState ({ isCheckCashCard : true })
        }
        else{
            this.setState ({ isCheckCashCard : false })
        }

        if(this.state.clearanceeditdetails.isValidIdMember === "1") {
            this.setState ({ isCheckValidId : true })
        }
        else{
            this.setState ({ isCheckValidId : false })
        }

        if(this.state.clearanceeditdetails.isResignationLetterMember === "1") {
            this.setState ({ isCheckResignation : true })
        }
        else{
            this.setState ({ isCheckResignation : false })
        }

        if(this.state.clearanceeditdetails.isTurnoverChecklistMember === "1") {
            this.setState ({ isCheckTurnover : true })
        }
        else{
            this.setState ({ isCheckTurnover : false })
        }

        if(this.state.clearanceeditdetails.isCOERequestFormMember === "1") {
            this.setState ({ isCheckRequestForm : true })
        }
        else{
            this.setState ({ isCheckRequestForm : false })
        }
        if (this.state.clearanceeditdetails.sssNumber === "00-0000000-0"){
            this.setState({ sssNumber : "", disabledTmp : false  })
        }

        if(this.state.clearanceeditdetails.isTypeOfCashCardMember === "1") {
            this.setState ({
                isRadioCashcard : true,
                cashCardType : this.state.clearanceeditdetails.typeOfCashCardMember,
                accountNumber : this.state.clearanceeditdetails.cashCardNumberMember
            })
        }
        else{
            this.setState ({ isRadioCashcard : false })
        }

        if(this.state.clearanceeditdetails.paramountId === "1") {
            this.setState ({ isParamountId : true })
        }
        else{
            this.setState ({ isParamountId : false })
        }

        if(this.state.clearanceeditdetails.isCheckMember === "1") {
            this.setState ({
                isRadioCheck : true
            })
        }
        else{
            this.setState ({ isRadioCheck : false })
        }

        if(this.state.clearanceeditdetails.isMlhuillierMember === "1") {
            this.setState ({
                isRadioMlhuillier : true
            })
        }
        else{
            this.setState ({ isRadioMlhuillier : false })
        }

        if(this.state.clearanceeditdetails.isPalawanMember === "1") {
            this.setState ({
                isRadioPalawan : true
            })
        }
        else{
            this.setState ({ isRadioPalawan : false })
        }

        if(this.state.clearanceeditdetails.isEligible === "1") {
            this.setState ({
                eligible : true
            })
        }
        else{
            this.setState ({ eligible : false })
        }

        if(this.state.clearanceeditdetails.isNotEligible === "1") {
            this.setState ({
                notEligible : true
            })
        }
        else{
            this.setState ({ notEligible : false })
        }

        if(this.state.clearanceeditdetails.sssNumber === "") {
            this.setState ({ isCheckSSS : false, disablesss : false })
        }
        else{
            this.setState ({ isCheckSSS : true, disablesss : true })
        }

        if(this.state.clearanceeditdetails.tinNumber === "" || this.state.clearanceeditdetails.tinNumber === "000-000-000-000") {
            this.setState ({ isCheckTIN : false, disabletin : false, tinNumber : "" })
        }
        else{
            this.setState ({ isCheckTIN : true, disabletin : true })
        }

        if( this.state.clearanceeditdetails.phicNumber === "") {
            this.setState ({ isCheckPHIC : false, disablephic : false })
        }
        else{
            this.setState ({ isCheckPHIC : true, disablephic : true })
        }

        if(this.state.clearanceeditdetails.hdmfNumber === "") {
            this.setState ({ isCheckHDMF : false, disablehdmf : false })
        }
        else{
            this.setState ({ isCheckHDMF : true, disablehdmf : true })
        }

        let _getId = []
        for(let x = 0; x < this.state.clearanceeditdetails.clearanceDetails.length; x++){
            let _id = JSON.stringify(x + 1)
            let obj = {
                "id"    :   _id,
                "branchName" : this.state.clearanceeditdetails.clearanceDetails[x]["branchName"],
                "clearingId" : this.state.clearanceeditdetails.clearanceDetails[x]["clearingId"],
                "companyName" : this.state.clearanceeditdetails.clearanceDetails[x]["companyName"],
                "headerId" : this.state.clearanceeditdetails.clearanceDetails[x]["headerId"],
                "periodCovered" : this.state.clearanceeditdetails.clearanceDetails[x]["periodCovered"],
                "position" : this.state.clearanceeditdetails.clearanceDetails[x]["position"],
                "profileId" : this.state.clearanceeditdetails.clearanceDetails[x]["profileId"],
                "reasonForLeaving" : this.state.clearanceeditdetails.clearanceDetails[x]["reasonForLeaving"],
                "salaryRate" : this.state.clearanceeditdetails.clearanceDetails[x]["salaryRate"],
                "isSelected"  :   "1",
                "isDeleted" : "0",
            }
            _getId.push(obj.id)

            this.state.memberDDL.push(obj)
            this.state.clientTabDDL.push(obj)
            this.state.lrdTabDDL.push(obj)
            this.state.rmtDDL.push(obj)
            this.state.serviceTabDDL.push(obj)
        }
        this.setState({ selectedItems : _getId })

        
        this.GetClient();
        this.GetData();
        this.GetEmployees();
        this.GetDataGrid()
        this.GetPayCardTypes()
    }
    GetClient = async() =>{
        this.setState({isloading:true})
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" ||
                        this.state.userinfo.roleId==="89654" ||
                        this.state.userinfo.roleId==="89657" ||
                        this.state.userinfo.roleId==="89658" ||
                        this.state.userinfo.roleId==="34009" ||
                        this.state.userinfo.roleId==="89092" ||
                        this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            let data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,isloading   :   false})
            }
            else{
                this.setState({clientDDL : data.clients,isloading   :   false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetData = async() => {
       this.setState({isloading:true})
       let param = {
           "IpAddress"         :   "0.0.0.0",
           "UserId"            :   this.state.userinfo.userId,
           "ClientId"          :   this.state.userinfo.clientId,
           "DisengagementId"   :   "",
       };
       await axios
       .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
       .then(res => {
           let data = res.data.disengagementMode;
           this.setState({
               reasonDDL            : data,
               isloading           : false,
           });
           if(data.status==="0"){
               this.setState(  {
                   isloading       :   false,
                   alerttype       :   "Error!",
                   isshow          :   true,
                   color           :   "danger",
                   message         :   data.message,
                   fade            :   true
               })
           }
       })
       .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleChangeClient = (e) => {
       if(e.length == 0) {
           this.state.clientId     =   ""
           this.state.clientName   =   ""
           return
       }
       this.state.clientId     =   e[0].id
       this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
       this.GetEmployees();
    }
    GetEmployees = () => {
        this.setState({isloading:true})
        let getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  getParams)
        .then(res => {
            let data = res.data
            this.setState({
                employeeDDL        :   data.profiles.filter(x=>x.status==="INACTIVE"),
                isloading           :   false,
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    }
    GetDataGrid = async() => {

        this.setState({isloading:true})
        let param = {
        "IpAddress"         :   "0.0.0.0",
        "ClientId"          :   "",
        "UserId"            :   "35954",
        "ProfileId"            :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            if(data.length > 0) {
                this.setState({DataListClearance : data})
            }
            if(data.length === 1){
                this.setState({
                    isDisabledDelete : false
                })
            }else {
                this.setState({
                    isDisabledDelete : true
                })
            }
            data.forEach(itm => {
                if(itm.isClearanceSubmitted === "1"){
                    this.setState({
                        isClearanceSubmitted : true,
                        dateCleared : new Date(itm.dateCleared),
                        dateSubmitted : new Date(itm.dateSubmitted),
                        remarks : itm.remarks
                    })
                }else {
                    this.setState({
                        isClearanceSubmitted : false,
                        dateCleared : new Date(itm.dateCleared),
                        dateSubmitted : new Date(itm.dateSubmitted),
                        remarks : itm.remarks
                    })
                }
            });
            this.setState({
                dataInfo    : data,
                isloading   : false,
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangeEmployee = (e) => {

        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.locationName =   ""
            this.state.contractDateStart  =   ""
            this.state.contractDateEnd  =   ""
            this.state.phicNumber = ""
            this.state.sssNumber = ""
            this.state.tinNumber = ""
            this.state.hdmfNumber = ""
            this.state.dateHired = ""
            this.state.dateResigned = ""
            // return
        } else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.phicNumber       =   e[0].phicNumber
            this.state.sssNumber        =   e[0].sssNumber
            this.state.tinNumber        =   e[0].tinNumber
            this.state.hdmfNumber    =   e[0].hdmfNumber
            this.state.dateHired    =   e[0].dateHired
            this.state.dateResigned    =   e[0].dateResigned
        }
        if(this.state.sssNo === "") {
            this.setState ({ isCheckSSS : false })
        }else{
            this.setState ({ isCheckSSS : true })
        }
        if(this.state.tinNo === "") {
            this.setState ({ isCheckTIN : false })
        }else{
            this.setState ({ isCheckTIN : true })
        }
        if(this.state.phicNo === "") {
            this.setState ({ isCheckPHIC : false })
        }else{
            this.setState ({ isCheckPHIC : true })
        }
        if(this.state.pagibigNo === "") {
            this.setState ({ isCheckHDMF : false })
        }else{
            this.setState ({ isCheckHDMF : true })
        }
        this.GetClearingClient(this.state.profileId, this.state.employeeId);
    }
    GetClearingClient = (profileId,employeeId) => {
         this.setState({isloading:true})
         let params = {
             "IpAddress"    :   "0.0.0.0",
             "ClientId"     :   this.state.userinfo.clientId,
             "UserId"       :   this.state.userinfo.userId,
             "ProfileId"    :   profileId,
             "EmployeeId"   :   employeeId
         }
         axios
         .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearingClient",  params)
         .then(res => {
             let data = res.data.employeeHistory
             this.setState({
                memberDDL        :   data,
                 isloading           :   false,
             })
         })
         .catch(error=>{
             this.setState({
                 isloading   :   false,
                 alerttype   :   "Error!",
                 isshow      :   true,
                 color       :   "danger",
                 message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                 fade        :   true
             })
         })

    }
    handleChangedReason = (e) => {
        this.setState({ reason: e.target.value,isshow:false, });
    }
    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false})
    }
    handleChangeSettledDateDate = date =>{
        this.setState({ dateSettled: date, isshow:false})
    }
    handleChangeClearanceDate = date =>{
        this.setState({ clearanceDate: date, isshow:false})
    }
    handleChangeSubmissionDate = date =>{
        this.setState({ submissionDate: date, isshow:false})
    }
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false})
    }
    handleChangedNotes = (e) =>{
        this.setState({ memberNotes : e.target.value.toUpperCase()})
    }
    handleChangeDateReceivedByAdmin = date =>{
        this.setState({ dateReceivedByAdmin: date, isshow:false})
    }
    onchangeCheckSSS (e){
        // this.setState({ isCheckSSS : e.target.checked })
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckSSS: true, disabledTmp : false })
        } else {
            this.setState({  isCheckSSS: false,disabledTmp: true })
        }
    }
    onchangeCheckSttldWdDole(e){
        this.setState({ [e.target.name] : e.target.checked })
    }
    onchangeCheckTIN (e){
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckTIN: true, disabledTmp1 : false })
        } else {
            this.setState({ isCheckTIN: false, disabledTmp1: true })
        }
    }
    onchangeCheckPHIC = (e) =>{
        // this.setState({ isCheckPHIC : e.target.checked })
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckPHIC: true, disabledTmp2 : false })
        } else {
            this.setState({ isCheckPHIC: false,disabledTmp2: true })
        }
    }
    onchangeCheckHDMF = (e) =>{
        // this.setState({ isCheckHDMF : e.target.checked })
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckHDMF: true, disabledTmp3 : false })
        } else {
            this.setState({ isCheckHDMF : false,disabledTmp3: true })
        }
    }
    onchangeCheckCashCard = (e) =>{
        this.setState({ isCheckCashCard : e.target.checked })
    }
    onchangeCheckValidId = (e) =>{
        this.setState({ isCheckValidId : e.target.checked })
    }
    onchangeCheckResignation = (e) =>{
        this.setState({ isCheckResignation : e.target.checked })
    }
    onchangeCheckTurnover = (e) =>{
        this.setState({ isCheckTurnover : e.target.checked })
    }
    onchangeCheckRequestForm = (e) =>{
        this.setState({ isCheckRequestForm : e.target.checked })
    }
    onchangeCheckParamountId = (e) =>{
        this.setState({ isParamountId : e.target.checked })
    }
    onChangeSss = (e) =>{
        this.setState({ sssNumber : e.target.value })
    }
    onChangeTin = (e) =>{
        this.setState({ tinNumber : e.target.value })
    }
    onChangePhic = (e) =>{
        this.setState({ phicNumber : e.target.value })
    }
    onChangeHdmf = (e) =>{
        this.setState({ hdmfNumber : e.target.value })
    }
    handleRadioCashcard = (e) =>{
        this.setState({ isRadioCashcard : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : false,
                isRadioCheck : false,
                isRadioMlhuillier : false,
                isRadioPalawan : false,
                isRadioCash : false,
                checktypeahead : true,
                isCashcardCheck : "1"
            })
        }
    }
    handleChangeRadioCashCard = (e) =>{
        if(e.length === 0){
            this.state.cashCardType=""
        return
        }
        // console.log("e[0]")
        // console.log(e[0])
        this.state.payCardTypeId = e[0].id
        this.state.cashCardType = e[0].name
        // this.state.accountNumber = e[0].accountNumber
    }
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleChangeAccountNumber = (e) =>{
        this.setState({ accountNumber : e.target.value })
    }
    handleRadioCheck = (e) =>{
        this.setState({ isRadioCheck : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioMlhuillier : false,
                isRadioCash : false,
                isRadioPalawan : false,
                accountNumber: "",
                cashCardId: "",
                isCashcardCheck : "0"
            })
        }
    }
    handleRadioMlhuillier = (e) =>{
        this.setState({ isRadioMlhuillier : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioCheck : false,
                isRadioCash : false,
                isRadioPalawan : false,
                accountNumber: "",
                cashCardId: "",
            })
        }
    }
    handleRadioPalawan = (e) =>{
        this.setState({ isRadioPalawan : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioCheck : false,
                isRadioCash : false,
                isRadioMlhuillier : false,
                accountNumber: "",
                cashCardId: "",
            })
        }
    }
    handleRadioCash = (e) =>{
        this.setState({ isRadioCash : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioCheck : false,
                isRadioMlhuillier : false,
                isRadioPalawan : false,
                accountNumber: "",
                cashCardId: "",
                isCashcardCheck : "0"
            })
        }
    }
    handleRadioEligible= (e) =>{
        this.setState({ eligible : e.target.checked, checkEligibility : true })
        if(e.target.checked === true){
            this.setState({
                notEligible : false,
                checkEligibility : true
            })
        }
    }
    handleRadioNotEligible= (e) =>{
        this.setState({ notEligible : e.target.checked, checkEligibility : true })
        if(e.target.checked === true){
            this.setState({
                eligible : false,
                checkEligibility : true
            })
        }
    }
    handleChangedMemeberRemarks = (e) =>{
        this.setState({ memberRemarks : e.target.value.toUpperCase()})
    }
    handleChangedClientRemarks = (e) =>{
        this.setState({ clientRemarks : e.target.value.toUpperCase()})
    }
    handleChangedLrdRemarks = (e) =>{
        this.setState({ lrdRemarks : e.target.value.toUpperCase()})
    }
    handleChangedRmtRemarks = (e) =>{
        this.setState({ rmtRemarks : e.target.value.toUpperCase()})
    }
    handleChangedServicesRemarks = (e) =>{
        this.setState({ servicesRemarks : e.target.value.toUpperCase()})
    }
    handleChangedAreRemarks = (e) =>{
        this.setState({ areRemarks : e.target.value.toUpperCase()})
    }
    handleChangedPayrollRemarks = (e) =>{
        this.setState({ payrollRemarks : e.target.value.toUpperCase()})
    }
    handleChangedFInanceRemarks = (e) =>{
        this.setState({ financeRemarks : e.target.value.toUpperCase()})
    }

    RefreshPage = async() => {
        this.setState({isloading:true})
        let param = {"IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            this.setState({
                isloading   : false,
            });
            data.forEach(itm => {
                this.setState({
                    clearanceId : itm.id,
                    clientId : itm.clientId,
                    profileId : itm.profileId,
                    employeeId : itm.employeeId,
                    dateCreated : itm.dateCreated,
                    dateSubmitted : itm.dateSubmitted,
                    clearanceStatus : itm.clearanceStatus,
                    isClearanceStatus : itm.isClearanceStatus,
                    memberNotes : itm.notesMember,
                    cashCardType : itm.typeOfCashCardMember,
                    accountNumber : itm.cashCardNumberMember,
                    memberRemarks : itm.remarksMember,
                    reason : itm.reasonForLeaving,
                    lrdRemarks : itm.remarksLrd,
                    rmtRemarks : itm.remarksRmt,
                    //memberDDL : itm.clearanceDetails,
                    clientTabDDL : itm.clearanceDetails,
                    lrdTabDDL : itm.clearanceDetails,
                    rmtDDL:itm.clearanceDetails,
                    serviceTabDDL:itm.clearanceDetails,
                })
                if(itm.resignationDateMember === ""){
                    this.setState({
                        resignationDate : ""
                    })
                }else {
                    this.setState({
                        resignationDate : new Date(itm.resignationDateMember)
                    })
                }
                if(itm.clearanceDateMember === ""){
                    this.setState({
                        clearanceDate : ""
                    })
                }else {
                    this.setState({
                        clearanceDate : new Date(itm.clearanceDateMember)
                    })
                }
                if(itm.submissionDateMember === ""){
                    this.setState({
                        submissionDate : ""
                    })
                }else {
                    this.setState({
                        submissionDate : new Date(itm.submissionDateMember)
                    })
                }
                if(itm.lastWorkingDateMember === ""){
                    this.setState({
                        workingDate : ""
                    })
                }else {
                    this.setState({
                        workingDate : new Date(itm.lastWorkingDateMember)
                    })
                }
                if(itm.isCashCardMember === "1"){
                    this.setState({
                        isCheckCashCard : true
                    })
                }else {
                    this.setState({
                        isCheckCashCard : false
                    })
                }
                if(itm.isValidIdMember === "1"){
                    this.setState({
                        isCheckValidId : true
                    })
                }else {
                    this.setState({
                        isCheckValidId : false
                    })
                }
                if(itm.isResignationLetterMember === "1"){
                    this.setState({
                        isCheckResignation : true
                    })
                }else {
                    this.setState({
                        isCheckResignation : false
                    })
                }
                if(itm.isTurnoverChecklistMember === "1"){
                    this.setState({
                        isCheckTurnover : true
                    })
                }else {
                    this.setState({
                        isCheckTurnover : false
                    })
                }
                if(itm.isCOERequestFormMember === "1"){
                    this.setState({
                        isCheckRequestForm : true
                    })
                }else {
                    this.setState({
                        isCheckRequestForm : false
                    })
                }
                if(itm.isTypeOfCashCardMember === "1"){
                    this.setState({
                        isRadioCashcard : true,
                        cashCardField : false,
                    })
                }else {
                    this.setState({
                        isRadioCashcard : false,
                        cashCardField : true,
                    })
                }
                if(itm.isCheckMember === "1"){
                    this.setState({
                        isRadioCheck : true,
                    })
                }else {
                    this.setState({
                        isRadioCheck : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isPalawanMember === "1"){
                    this.setState({
                        isRadioPalawan : true,
                    })
                }else {
                    this.setState({
                        isRadioPalawan : false,
                    })
                }
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }


    handleSearchClick = async() => {
        if(this.state.clientId === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select client.",
                fade            :   true
            })
            return
        }
        if(this.state.employeeId === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select employee.",
                fade            :   true
            })
            return
        }
        this.setState({isloading:true})
        let param = {"IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            this.setState({
                isloading   : false,
            });
            data.forEach(itm => {
                this.setState({
                    clearanceId : itm.id,
                    dateCreated : itm.dateCreated,
                    dateSubmitted : itm.dateSubmitted,
                    clearanceStatus : itm.clearanceStatus,
                    memberNotes : itm.notesMember,
                    cashCardType : itm.typeOfCashCardMember,
                    accountNumber : itm.cashCardNumberMember,
                    memberRemarks : itm.remarksMember,
                    reason : itm.reasonForLeaving,
                    lrdRemarks : itm.remarksLrd,
                    rmtRemarks : itm.remarksRmt,
                    dateForwarded : itm.dateForwardedToAmt === "" ? "" : new Date(itm.dateForwardedToAmt),
                    dateRecieved : itm.dateReceivedFromAmt === "" ? "" : new Date(itm.dateReceivedFromAmt),
                    isClearanceStatus : itm.isClearanceStatus,
                    cycleDays : itm.cycleDays,
                    memberDDL : itm.clearanceDetails,
                })
                if(itm.isClearanceStatus === "1"){
                    this.setState({
                        disabledButtonMember:false,
                    })
                }
                if(itm.isClearanceStatus === "2"){
                    this.setState({
                        disabledCleintButton:false,
                        clientTabDDL : itm.clearanceDetails,
                        returnStatus : "1",
                    })
                }else{
                    this.setState({
                        disabledCleintButton:true,
                        clientTabDDL:[],
                    })
                }
                if(itm.isClearanceStatus === "3"){
                    this.setState({
                        disabledLrdButton:false,
                        lrdTabDDL : itm.clearanceDetails,
                    })
                }else{
                    this.setState({
                        disabledLrdButton:true,
                        lrdTabDDL:[],
                    })
                }
                if(itm.isClearanceStatus === "4"){
                    this.setState({
                        disabledRmtButton:false,
                        rmtDDL:itm.clearanceDetails,
                    })
                }else{
                    this.setState({
                        disabledRmtButton:true,
                        rmtDDL:[],
                    })
                }
                if(itm.isClearanceStatus === "5"){
                    this.setState({
                        disabledServicesButton:false,
                        serviceTabDDL:itm.clearanceDetails,
                    })
                }else{
                    this.setState({
                        disabledServicesButton:true,
                        serviceTabDDL:[],
                    })
                }
                if(itm.resignationDateMember === ""){
                    this.setState({
                        resignationDate : ""
                    })
                }else {
                    this.setState({
                        resignationDate : new Date(itm.resignationDateMember)
                    })
                }
                if(itm.clearanceDateMember === ""){
                    this.setState({
                        clearanceDate : ""
                    })
                }else {
                    let startDate  = moment(itm.clearanceDateMember).format('MM/DD/YYYY');;
					let endDate  = moment(this.state.newDate).format('MM/DD/YYYY');
					let diffInMs   = new Date(endDate) - new Date(startDate)
					let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                    this.setState({
                        clearanceDate : new Date(itm.clearanceDateMember),
                        daysCount : diffInDays
                    })
                }
                if(itm.submissionDateMember === ""){
                    this.setState({
                        submissionDate : "",
                        daysCount : '0'
                    })
                }else {
                    this.setState({
                        submissionDate : new Date(itm.submissionDateMember)
                    })
                }
                if(itm.dateForwardedToAmt === ""){
                    this.setState({
                        dateForwarded : ""
                    })
                }else {
                    this.setState({
                        dateForwarded : new Date(itm.dateForwardedToAmt)
                    })
                }
                if(itm.dateReceivedFromAmt === ""){
                    this.setState({
                        dateRecieved : ""
                    })
                }else {
                    this.setState({
                        dateRecieved : new Date(itm.dateReceivedFromAmt)
                    })
                }
                if(itm.lastWorkingDateMember === ""){
                    this.setState({
                        workingDate : ""
                    })
                }else {
                    this.setState({
                        workingDate : new Date(itm.lastWorkingDateMember)
                    })
                }
                if(itm.isCashCardMember === "1"){
                    this.setState({
                        isCheckCashCard : true
                    })
                }else {
                    this.setState({
                        isCheckCashCard : false
                    })
                }
                if(itm.isValidIdMember === "1"){
                    this.setState({
                        isCheckValidId : true
                    })
                }else {
                    this.setState({
                        isCheckValidId : false
                    })
                }
                if(itm.isResignationLetterMember === "1"){
                    this.setState({
                        isCheckResignation : true
                    })
                }else {
                    this.setState({
                        isCheckResignation : false
                    })
                }
                if(itm.isTurnoverChecklistMember === "1"){
                    this.setState({
                        isCheckTurnover : true
                    })
                }else {
                    this.setState({
                        isCheckTurnover : false
                    })
                }
                if(itm.isCOERequestFormMember === "1"){
                    this.setState({
                        isCheckRequestForm : true
                    })
                }else {
                    this.setState({
                        isCheckRequestForm : false
                    })
                }
                if(itm.isTypeOfCashCardMember === "1"){
                    this.setState({
                        isRadioCashcard : true,
                        cashCardField : false,
                    })
                }else {
                    this.setState({
                        isRadioCashcard : false,
                        cashCardField : true,
                    })
                }
                if(itm.isCheckMember === "1"){
                    this.setState({
                        isRadioCheck : true,
                    })
                }else {
                    this.setState({
                        isRadioCheck : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isPalawanMember === "1"){
                    this.setState({
                        isRadioPalawan : true,
                    })
                }else {
                    this.setState({
                        isRadioPalawan : false,
                    })
                }
                if(itm.isEligible === "1"){
                    this.setState({
                        eligible : true,
                    })
                }else {
                    this.setState({
                        eligible : false,
                    })
                }
                if(itm.isNotEligible === "1"){
                    this.setState({
                        notEligible : true,
                    })
                }else {
                    this.setState({
                        notEligible : false,
                    })
                }
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleUploadClick = () =>{
        // alert('test')
        let _selectedFiles = this.state.attachedDDL
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""

        let newData = {
            'id' : String(parseFloat(this.state.attachedDDL.length + 1)),
            'fileName' : this.state.files,
            'file' :"",
            'atd' :"NO",
            'employeeNo' : this.state.employeeId ,
            'dateSubmitted' : date,
            'isModified' : "",
            'isDeleted' : "0"
        }
        _selectedFiles.push(newData)
        this.setState({ attachedDDL : _selectedFiles })
    }
    handleUploadClickLrd = () =>{
        let _selectedFiles = this.state.attachedDDLLrd

        let newData = {
            'id' : String(parseFloat(this.state.attachedDDLLrd.length + 1)),
            'fileName' : this.state.filesLrd,
            'isDeleted' : "0",
            'isModified' : "0",
        }
        _selectedFiles.push(newData)
        this.setState({ attachedDDLLrd : _selectedFiles })
    }
    handleDeleteClick = () => {

        let GridItemsContents = this.state.attachedDDL.filter(x=>x.isDeleted=="0")
        this.state.attachedDDL = []
        this.setState({attachedDDL:GridItemsContents})

    }
    handleDeleteClickLrd = () => {

        let GridItemsContents = this.state.attachedDDLLrd.filter(x=>x.isDeleted=="0")
        this.state.attachedDDLLrd = []
        this.setState({attachedDDLLrd:GridItemsContents})

    }


    handleClickSave = async() => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let dateCleared = moment(this.state.dateCreated).format('MM/DD/YYYY')
        let resignationDate = (this.state.resignationDate) ? moment(this.state.resignationDate).format('MM/DD/YYYY') : ""
        let clearanceDate = (this.state.clearanceDate) ? moment(this.state.clearanceDate).format('MM/DD/YYYY') : ""
        let submissionDate = (this.state.submissionDate) ? moment(this.state.submissionDate).format('MM/DD/YYYY') : ""
        let workingDate = (this.state.workingDate) ? moment(this.state.workingDate).format('MM/DD/YYYY') : ""
        // let dateSubmitted = (this.state.dateSubmitted) ? moment(this.state.dateSubmitted).format('MM/DD/YYYY') : ""
        let dateRecieved = (this.state.dateRecieved) ? moment(this.state.dateRecieved).format('MM/DD/YYYY') : ""
        let dateforwarded = (this.state.dateForwarded) ? moment(this.state.dateForwarded).format('MM/DD/YYYY') : ""
        let arrLst = []
        let arrLstServices = []
        if(this.state.isClearanceStatus === "5"){
            if(this.state.dateReceivedByAdmin===""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Date received by admin is required.",
                    fade            :   true
                })
                return
            }
        }
        if(this.state.isClearanceStatus === "1"){
            this.state.memberDDL.map(function(itm,i) {
                arrLst.push({
                    "ClearingId"        : itm.id,
                    "ProfileId"         : itm.profileId,
                    "CompanyName"       : itm.companyName,
                    "BranchName"        : itm.branchName,
                    "Position"          : itm.position,
                    "SalaryRate"        : itm.salaryRate,
                    "PeriodCovered"     : itm.periodCovered,
                    "ReasonForLeaving"  : itm.reasonForLeaving,
                    "ContractDateStart" : (itm.contractDateStart) ? moment(itm.contractDateStart).format('MM/DD/YYYY') : "",
                    "ContractDateEnd"   : (itm.contractDateEnd) ? moment(itm.contractDateEnd).format('MM/DD/YYYY') : "",
                    "DateHired"         : (itm.dateHired) ? moment(itm.dateHired).format('MM/DD/YYYY') : "",
                    "DateResigned"      : (itm.dateResigned) ? moment(itm.dateResigned).format('MM/DD/YYYY') : "",
                })
            })
        }else {
            this.state.memberDDL.map(function(itm,i) {
                if(itm.isSelected === "1"){
                    arrLst.push({
                        "ClearingId"        : itm.id,
                        "ProfileId"         : itm.profileId,
                        "CompanyName"       : itm.companyName,
                        "BranchName"        : itm.branchName,
                        "Position"          : itm.position,
                        "SalaryRate"        : itm.salaryRate,
                        "PeriodCovered"     : itm.periodCovered,
                        "ReasonForLeaving"  : itm.reasonForLeaving,
                        "ContractDateStart" : (itm.contractDateStart) ? moment(itm.contractDateStart).format('MM/DD/YYYY') : "",
                        "ContractDateEnd"   : (itm.contractDateEnd) ? moment(itm.contractDateEnd).format('MM/DD/YYYY') : "",
                        "DateHired"         : (itm.dateHired) ? moment(itm.dateHired).format('MM/DD/YYYY') : "",
                        "DateResigned"      : (itm.dateResigned) ? moment(itm.dateResigned).format('MM/DD/YYYY') : "",
                    })
                }
            })
        }
        if(arrLst.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select clearing client!",
                fade            :   true
            })
            return
        }
        if(this.state.isClearanceStatus === "2"){
            if(this.state.checkEligibility === false  ){
                // // consolelog("walang laman")
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "please select ELIGIBILITY.",
                    fade            :   true
                })
                return
            }
        }
        if(this.state.isClearanceStatus === "0"){
            if(this.state.memberRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "1"){
            if(this.state.memberRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "5"){
            if(this.state.attachedDDL.length === 0){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Attachment of scanned clearance is required",
                    fade            :   true
                })
                return
            }
            else{
                let _isWithAtd = ""
                this.state.attachedDDL.map(function(itm,i) {
                    _isWithAtd = itm.atd
                    arrLstServices.push({
                        "FileName"          : itm.fileName,
                        "File"              : itm.file,
                        "Atd"               : itm.atd,
                        "EmployeeNo"        : itm.employeeNo,
                        "DateSubmitted"     : itm.dateSubmitted,
                        "IsModified"        : itm.isModified,
                    })
                })
                this.state.iswithatd = _isWithAtd === "NO" ? "1" : "2"
            }
        }
        if (this.state.isCashcardCheck === "1") {
            if(this.state.payCardTypeId === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please select paycard",
                    fade            :   true
                })
                return
            }
            if(this.state.payCardTypeId === "2" ){
                if(this.state.accountNumber.length !== 16){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "BDO Account number invalid.",
                        fade            :   true
                    })
                    return
                }
            }
            else if(this.state.payCardTypeId === "6"){
                if(this.state.accountNumber.length !== 13){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "Metrobank Account number invalid.",
                        fade            :   true
                    })
                    return
                }
            }
            else if (this.state.payCardTypeId === "14"){
                if(this.state.accountNumber.length !== 12){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "Union Bank Account number invalid.",
                        fade            :   true
                    })
                    return
                }
            }
        }
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance" : [
                {
                    "Id" : this.state.clearanceId,
                    "ClientId":this.state.clientId,
                    "ProfileId":this.state.profileId,
                    "EmployeeId":this.state.employeeId,
                    "PayPeriodId":this.state.payPeriodId,
                    "DateCreated":dateCleared,
                    "ClearanceDateMember":clearanceDate,
                    "ResignationDateMember":resignationDate,
                    "SubmissionDateMember":submissionDate ,
                    "LastWorkingDateMember":workingDate ,
                    "DateSubmitted":moment(new Date()).format('MM/DD/YYYY'),
                    "IsSSSNumberMember":this.state.isCheckSSS === true ? "1" : "0",
                    "IsTINNumberMember":this.state.isCheckTIN === true ? "1" : "0",
                    "IsPHICNumberMember":this.state.isCheckPHIC === true ? "1" : "0",
                    "IsHDMFNumberMember":this.state.isCheckHDMF === true ? "1" : "0",
                    "IsCashCardMember":this.state.isCheckCashCard === true ? "1" : "0",
                    "IsValidIdMember":this.state.isCheckValidId === true ? "1" : "0",
                    "IsResignationLetterMember":this.state.isCheckResignation === true ? "1" : "0",
                    "IsTurnoverChecklistMember":this.state.isCheckTurnover === true ? "1" : "0",
                    "IsCOERequestFormMember":this.state.isCheckRequestForm === true ? "1" : "0",
                    "IsTypeOfCashCardMember":this.state.isRadioCashcard === true ? "1" : "0" , // cash card mode of payment
                    "PaycardTypeIdMember": this.state.payCardTypeId,
                    "TypeOfCashCardMember":this.state.cashCardType,
                    "CashCardNumberMember":this.state.accountNumber === "" || typeof this.state.accountNumber === "undefined" ? "" : this.state.accountNumber,
                    "IsCheckMember":this.state.isRadioCheck === true ? "1" : "0"  ,
                    "IsMlhuillierMember":this.state.isRadioMlhuillier === true ? "1" : "0",
                    "IsPalawanMember":this.state.isRadioPalawan === true ? "1" : "0",
                    "RemarksMember":this.state.memberRemarks,
                    "NotesMember":this.state.memberNotes,
                    "ReasonForLeaving":this.state.reason,
                    "DateForwardedToAmt": dateforwarded,
                    "DateReceivedFromAmt": dateRecieved,
                    "IsEligible":this.state.eligible === true ? "1" : "0",
                    "IsNotEligible":this.state.notEligible === true ? "1" : "0",
                    "SssNumber": this.state.sssNumber,
                    "TinNumber": this.state.tinNumber,
                    "PhicNumber": this.state.phicNumber,
                    "HdmfNumber": this.state.hdmfNumber,

                    "IsClearedClient":"0"    ,
                    "IsEligibleClient":"0"    ,
                    "IsNonEligibleClient":"0"    ,
                    "RemarksClient":this.state.clientRemarks,

                    "IsClearedRmt":"0"    ,
                    "IsReturnedRmt":"0"  ,
                    "ValidateByRmt":"0"  ,
                    "IsForwardToARERmt":"0"   ,
                    "IsRetrunedServicesRmt":"0" ,
                    "IsCheckedByRmt":this.state.iswithatd === "0" ? "0" : this.state.iswithatd,
                    "RemarksRmt":this.state.rmtRemarks,

                    "RemarksLrd":this.state.lrdRemarks,
                    "ServicesRemarks":this.state.servicesRemarks,

                    "IsClearanceStatus": this.state.isClearanceStatus,
                    "clearanceDetails" :arrLst,

                    /* ADDITIONAL */
                    "DateUpdatedByClient": this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? date : "",
                    "UpdatedByClient":this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByLRD":this.state.isClearanceStatus === "3" ? date : "",
                    "UpdatedByLRD":this.state.isClearanceStatus === "3" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByRMT":this.state.isClearanceStatus === "4" ? date : "",
                    "UpdatedByRMT":this.state.isClearanceStatus === "4" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByServices":this.state.isClearanceStatus === "5" ? date : this.state._dateServices,
                    "UpdatedByServices":this.state.isClearanceStatus === "5" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByARE":"",
                    "UpdatedByARE":"",

                    "DateUpdatedByFinance":this.state.isClearanceStatus === "8" ? date : "",
                    "UpdatedByFinance":this.state.isClearanceStatus === "8" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateSubmittedByStaff":date,
                    "SubmittedByStaff":this.state.userinfo.userId,

                    "DateSubmittedByLead":date,
                    "SubmittedByLead":this.state.userinfo.userId,

                    "DateReturnedByClient":"",
                    "ReturnedByClient":this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByLRD":"",
                    "ReturnedByLRD":this.state.isClearanceStatus === "3" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByRMT":"",
                    "ReturnedByRMT":this.state.isClearanceStatus === "4" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByServices":"",
                    "ReturnedByServices":this.state.isClearanceStatus === "5" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByARE":"",
                    "ReturnedByARE":this.state.isClearanceStatus === "6" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByFinance":"",
                    "ReturnedByFinance":this.state.isClearanceStatus === "8" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    // 13/06/2023 additional
                    "DateSettle"        : "",
                    "IsSettleWithDole"  :  this.state.isSettleWithDole === true ? "1" : "0",
                    "IsCash":  this.state.isRadioCash === true ? "1" : "0",
                    "TotalBalance" : this.state.totalBalance,
                    "Submitted" : this.state.submitted,
                    "servicesAttachments" : arrLstServices.length === 0 ? [] : arrLstServices, // this.state.isClearanceStatus === "5" ? arrLstServices : [],
                    "ClosingRemarks" : "NOT CLEARED",

                    
                    "ParamountId" : this.state.isParamountId === true ? "1" : "0",
                    "LastSalary" : "",
                    "Incentives" : "",
                    "Sil" : "",
                    "ThirteenthMonth" : "",
                    "Inclusions" : "",
                    "GrossPay" : "",
                    "NetPay" : "",
                    "PeriodCovered" : "",
                    "IsModifiedByARE" : "0",
                    "SeparationPay" : "",
                    "ReviewStatus" : "0",
                    "DateReturnedByPayroll" : "",
                    "AreRemarks" : this.state.areRemarks,
                    "PayrollRemarks" : this.state.payrollRemarks,
                    "FinanceRemarks" : this.state.financeRemarks,
                    "BatchNumber" : this.state.batchNumber,
                    "IsClearedSubmit" : "0",
                    "IsGenerated" : "0",
                    "IsFinalized" : "0",
                    // "ContractDateStart"  : this.state.contractDateStart,
                    // "ContractDateEnd"  : this.state.contractDateEnd,
                    "DateResigned" : this.state.dateResigned,
                    "DateHired" : this.state.dateHired,
                    "BatchPayroll" : this.state.batchPayroll,
                    "Adjustment" : this.state.adjustment,
                    "OtherPayroll" : this.state.otherPayroll,
                    "TotalInclusion" : this.state.totalInclusion,
                    "IsParent" : this.state.isParent,
                    "IsChild" : this.state.isChild,
                    "ParentId" : this.state.parentId,
                    "DateSubmittedToPayroll" : "",
                    "IsAre" : "0",
                    "IsPayroll" : "0",
                    "LastModifiedBy" : this.state.userinfo.fullName
                    /* END */
                }
            ]
        }
        // console.log("save click")
        // console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // // console.log("res.data")
            // // console.log(data)
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleClickSubmit = async() => {

        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let dateCleared = moment(this.state.dateCreated).format('MM/DD/YYYY')
        let resignationDate = (this.state.resignationDate) ? moment(this.state.resignationDate).format('MM/DD/YYYY') : ""
        let clearanceDate = (this.state.clearanceDate) ? moment(this.state.clearanceDate).format('MM/DD/YYYY') : ""
        let submissionDate = (this.state.submissionDate) ? moment(this.state.submissionDate).format('MM/DD/YYYY') : ""
        let workingDate = (this.state.workingDate) ? moment(this.state.workingDate).format('MM/DD/YYYY') : ""
        // let dateSubmitted = (this.state.dateSubmitted) ? moment(this.state.dateSubmitted).format('MM/DD/YYYY') : ""
        let dateRecieved = (this.state.dateRecieved) ? moment(this.state.dateRecieved).format('MM/DD/YYYY') : ""
        let dateforwarded = (this.state.dateForwarded) ? moment(this.state.dateForwarded).format('MM/DD/YYYY') : ""
        let dateSettled = (this.state.dateSettled) ? moment(this.state.dateSettled).format('MM/DD/YYYY') : ""
        let dateAdmin = (this.state.dateReceivedByAdmin) ? moment(this.state.dateReceivedByAdmin).format('MM/DD/YYYY') : ""
        let arrLst = []
        let arrLstServices = []
        // // console.log("this.state.attahchDDL")
        // // console.log(this.state.attachedDDL)

        if(this.state.isClearanceStatus === "1"){
            this.state.memberDDL.map(function(itm,i) {
                arrLst.push({
                    "ClearingId"        : itm.id,
                    "ProfileId"         : itm.profileId,
                    "CompanyName"       : itm.companyName,
                    "BranchName"        : itm.branchName,
                    "Position"          : itm.position,
                    "SalaryRate"        : itm.salaryRate,
                    "PeriodCovered"     : itm.periodCovered,
                    "ReasonForLeaving"  : itm.reasonForLeaving,
                    "ContractDateStart" : (itm.contractDateStart) ? moment(itm.contractDateStart).format('MM/DD/YYYY') : "",
                    "ContractDateEnd"   : (itm.contractDateEnd) ? moment(itm.contractDateEnd).format('MM/DD/YYYY') : "",
                    "DateHired"         : (itm.dateHired) ? moment(itm.dateHired).format('MM/DD/YYYY') : "",
                    "DateResigned"      : (itm.dateResigned) ? moment(itm.dateResigned).format('MM/DD/YYYY') : "",
                })
            })
        }
        else {
            this.state.memberDDL.map(function(itm,i) {
                if(itm.isSelected === "1"){
                    arrLst.push({
                        "ClearingId"        : itm.id,
                        "ProfileId"         : itm.profileId,
                        "CompanyName"       : itm.companyName,
                        "BranchName"        : itm.branchName,
                        "Position"          : itm.position,
                        "SalaryRate"        : itm.salaryRate,
                        "PeriodCovered"     : itm.periodCovered,
                        "ReasonForLeaving"  : itm.reasonForLeaving,
                        "ContractDateStart" : (itm.contractDateStart) ? moment(itm.contractDateStart).format('MM/DD/YYYY') : "",
                        "ContractDateEnd"   : (itm.contractDateEnd) ? moment(itm.contractDateEnd).format('MM/DD/YYYY') : "",
                        "DateHired"         : (itm.dateHired) ? moment(itm.dateHired).format('MM/DD/YYYY') : "",
                        "DateResigned"      : (itm.dateResigned) ? moment(itm.dateResigned).format('MM/DD/YYYY') : "",
                    })
                }
            })
        }

        if(arrLst.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select clearing client!",
                fade            :   true
            })
            return
        }

        let ClearanceStatus = ""

        if(this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1"){
            if(this.state.memberRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "2"){
            if(this.state.clientRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "3"){
            if(this.state.lrdRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "2"){
            if(this.state.checkEligibility === false  ){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "please select ELIGIBILITY.",
                    fade            :   true
                })
                return
            }
        }
        if(this.state.isClearanceStatus === "4"){
            if(this.state.rmtRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "5"){
            if(this.state.servicesRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
            if(this.state.attachedDDL.length === 0){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Attachment of scanned clearance is required",
                    fade            :   true
                })
                return
            }else{
                this.state.attachedDDL.map(function(itm,i) {
                    arrLstServices.push({
                        "FileName"          : itm.fileName,
                        "File"              : itm.file,
                        "ATD"               : itm.atd,
                        "EmployeeNo"        : itm.employeeNo,
                        "DateSubmitted"     : itm.dateSubmitted,
                        "IsModified"        : itm.isModified,
                    })
                })
            }
        }
        if (this.state.isSetteldWithDole === true) {
            if (this.state.dateSettled === "" ){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Date settled required!",
                    fade            :   true
                })
            }
        }
        let rmtRemarks = "" 
        let lrdRmrks = ""
        if(this.state.isClearanceStatus === "2"){
            if (this.state.reason === "DOLE CASES"){
                if (    this.state.userinfo.userId === "89692" 
                        || this.state.userinfo.userId === "34024"
                        || this.state.createdbynametmp === "LDTEAMLEADO1, LDTEAMLEADO1 LDTEAMLEADO1" 
                        || this.state.createdbynametmp === "CMWTL03, CMWTL03 CMWTL03"
                        || this.state.userinfo.firstName === "CLIENTTL01"
                        || this.state.userinfo.firstName === "CMWTL04"
                        || this.state.userinfo.firstName === "LDTEAMLEADO1"
                    ){
                    ClearanceStatus = "4"
                }
                else {
                    if (this.state.isSetteldWithDole === true){
                        ClearanceStatus ="10"
                    }
                    else {
                        ClearanceStatus = "3"
                    }
                }
                // this.setState({ lrdRemarks : this.state.clientRemarks})
                // lrdRmrks = this.state.clientRemarks
            } 
            else {
                if(this.state.userinfo.userId==="91118"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "Access Denied!.",
                        fade            :   true
                    })
                    return
                }
                else {
                    ClearanceStatus = "4"
                }
                // this.setState({ rmtRemarks : this.state.clientRemarks})
                // rmtRemarks = this.state.clientRemarks
            }
        } 
        else if(this.state.isClearanceStatus === "3"){
            if (this.state.isSetteldWithDole === true){
                ClearanceStatus ="9"
            } 
            else {
                ClearanceStatus = String(parseFloat(this.state.isClearanceStatus)  + 1)
            }
        } 
        else {
            ClearanceStatus = String(parseFloat(this.state.isClearanceStatus) + 1)
        }
        if (this.state.isCashcardCheck === "1") {
            if(this.state.payCardTypeId === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please select paycard",
                    fade            :   true
                })
                return
            }
        }
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance" : [
                {
                    "Id" : this.state.clearanceId,
                    "ClientId":this.state.clientId,
                    "PayPeriodId":this.state.payPeriodId,
                    "ProfileId":this.state.profileId,
                    "EmployeeId":this.state.employeeId,
                    "DateCreated":dateCleared,
                    "ClearanceDateMember":clearanceDate,
                    "ResignationDateMember":resignationDate,
                    "SubmissionDateMember":submissionDate ,
                    "LastWorkingDateMember":workingDate ,
                    "DateSubmitted":moment(new Date()).format('MM/DD/YYYY'),
                    "IsSSSNumberMember":this.state.isCheckSSS === true ? "1" : "0",
                    "IsTINNumberMember":this.state.isCheckTIN === true ? "1" : "0",
                    "IsPHICNumberMember":this.state.isCheckPHIC === true ? "1" : "0",
                    "IsHDMFNumberMember":this.state.isCheckHDMF === true ? "1" : "0",
                    "IsCashCardMember":this.state.isCheckCashCard === true ? "1" : "0",
                    "IsValidIdMember":this.state.isCheckValidId === true ? "1" : "0",
                    "IsResignationLetterMember":this.state.isCheckResignation === true ? "1" : "0",
                    "IsTurnoverChecklistMember":this.state.isCheckTurnover === true ? "1" : "0",
                    "IsCOERequestFormMember":this.state.isCheckRequestForm === true ? "1" : "0",
                    "IsTypeOfCashCardMember":this.state.isRadioCashcard === true ? "1" : "0" , // cash card mode of payment
                    "PaycardTypeIdMember":this.state.paycardTypeIdMember,
                    "TypeOfCashCardMember":this.state.cashCardType,
                    "CashCardNumberMember":this.state.accountNumber === "" || typeof this.state.accountNumber === "undefined" ? "" : this.state.accountNumber,
                    "IsCheckMember":this.state.isRadioCheck === true ? "1" : "0"  ,
                    "IsMlhuillierMember":this.state.isRadioMlhuillier === true ? "1" : "0",
                    "IsPalawanMember":this.state.isRadioPalawan === true ? "1" : "0",
                    "NotesMember":this.state.memberNotes,
                    //"ClearingClient":this.state.memberDDL,
                    "ReasonForLeaving":this.state.reason,
                    "DateForwardedToAmt": dateforwarded,
                    "DateReceivedFromAmt": dateRecieved,
                    "IsEligible":this.state.eligible === true ? "1" : "0",
                    "IsNotEligible":this.state.notEligible === true ? "1" : "0",
                    "SssNumber": this.state.sssNumber,
                    "TinNumber": this.state.tinNumber,
                    "PhicNumber": this.state.phicNumber,
                    "HdmfNumber": this.state.hdmfNumber,

                    "IsClearedClient":"0"    ,
                    "IsEligibleClient":"0"    ,
                    "IsNonEligibleClient":"0"    ,

                    "IsClearedRmt":"0"    ,
                    "IsReturnedRmt":"0"  ,
                    "ValidateByRmt":"0"  ,
                    "IsForwardToARERmt":"0"   ,
                    "IsRetrunedServicesRmt":"0" ,
                    "IsCheckedByRmt":this.state.iswithatd === "0" ? "0" : this.state.iswithatd,

                    "RemarksMember":this.state.memberRemarks,
                    "RemarksClient":this.state.clientRemarks,
                    "RemarksRmt":this.state.rmtRemarks,
                    "RemarksLrd":this.state.lrdRemarks,
                    "ServicesRemarks":this.state.servicesRemarks ,
                    "AreRemarks" : this.state.areRemarks,
                    "PayrollRemarks" : this.state.payrollRemarks,
                    "FinanceRemarks" : this.state.financeRemarks,

                    "IsClearanceStatus": ClearanceStatus,
                    "clearanceDetails" :arrLst,

                    /* ADDITIONAL */
                    "DateUpdatedByClient": this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? date : "",
                    "UpdatedByClient":this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByLRD":this.state.isClearanceStatus === "3" ? date : "",
                    "UpdatedByLRD":this.state.isClearanceStatus === "3" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByRMT":this.state.isClearanceStatus === "4" ? date : "",
                    "UpdatedByRMT":this.state.isClearanceStatus === "4" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByServices":this.state.isClearanceStatus === "5" ? date : this.state._dateServices,
                    "UpdatedByServices":this.state.isClearanceStatus === "5" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByARE":"",
                    "UpdatedByARE":"",

                    "DateUpdatedByFinance":this.state.isClearanceStatus === "8" ? date : "",
                    "UpdatedByFinance":this.state.isClearanceStatus === "8" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateSubmittedByStaff":date,
                    "SubmittedByStaff":this.state.userinfo.userId,

                    "DateSubmittedByLead":date,
                    "SubmittedByLead":this.state.userinfo.userId,

                    "DateReturnedByClient":"",
                    "ReturnedByClient":this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByLRD":"",
                    "ReturnedByLRD":this.state.isClearanceStatus === "3" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByRMT":"",
                    "ReturnedByRMT":this.state.isClearanceStatus === "4" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByServices":"",
                    "ReturnedByServices":this.state.isClearanceStatus === "5" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByARE":"",
                    "ReturnedByARE":this.state.isClearanceStatus === "6" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByFinance":"",
                    "ReturnedByFinance":this.state.isClearanceStatus === "8" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    // 13/06/2023 additional
                    "DateSettle"        : typeof dateSettled === "undefined" ? "" : dateSettled,
                    "IsSettleWithDole"  :  this.state.isSetteldWithDole === true ? "1" : "0",

                    "DateReceivedByAdmin" : dateAdmin,
                    "IsCash":  this.state.isRadioCash === true ? "1" : "0",
                    "TotalBalance" : this.state.totalBalance,
                    "Submitted" : this.state.submitted,
                    "servicesAttachments" : this.state.attachedDDL.length===0?[]:arrLstServices,
                    "ClosingRemarks" : this.state.isSetteldWithDole === true ? "CLEARED" : "NOT CLEARED",

                    
                    "ParamountId" : this.state.isParamountId === true ? "1" : "0",
                    "LastSalary" : "",
                    "Incentives" : "",
                    "Sil" : "",
                    "ThirteenthMonth" : "",
                    "Inclusions" : "",
                    "GrossPay" : "",
                    "NetPay" : "",
                    "PeriodCovered" : "",
                    "IsModifiedByARE" : "0",
                    "SeparationPay" : "",
                    "ReviewStatus" : "0",
                    "DateReturnedByPayroll" : "",
                    "IsClearedSubmit" : "0",
                    "IsGenerated" : "0",
                    "IsFinalized" : "0",
                    // "ContractDateStart"  : this.state.contractDateStart,
                    // "ContractDateEnd"  : this.state.contractDateEnd,
                    "DateResigned" : this.state.dateResigned,
                    "DateHired" : this.state.dateHired,
                    "BatchPayroll" : this.state.batchPayroll,
                    "Adjustment" : this.state.adjustment,
                    "OtherPayroll" : this.state.otherPayroll,
                    "TotalInclusion" : this.state.totalInclusion,
                    "IsParent" : this.state.isParent,
                    "IsChild" : this.state.isChild,
                    "ParentId" : this.state.parentId,
                    
                    "DateSubmittedToPayroll" : this.state.dateSubmittedToPayroll,
                    "IsAre" : this.state.isAre,
                    "IsPayroll" : this.state.isPayroll,
                    
                    "DateSubmittedToPayroll" : this.state.dateSubmittedToPayroll,
                    "IsAre" : this.state.isAre,
                    "IsPayroll" : this.state.isPayroll,
                    "LastModifiedBy" : this.state.userinfo.fullName
                    /* END */
                }
            ]
        }
        // console.log("param")
        // console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            // // console.log("data submit")
            // // console.log(data)
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleCLickReturn = async(statusId) => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let dateCleared = moment(this.state.dateCreated).format('MM/DD/YYYY')
        let resignationDate = (this.state.resignationDate) ? moment(this.state.resignationDate).format('MM/DD/YYYY') : ""
        let clearanceDate = (this.state.clearanceDate) ? moment(this.state.clearanceDate).format('MM/DD/YYYY') : ""
        let submissionDate = (this.state.submissionDate) ? moment(this.state.submissionDate).format('MM/DD/YYYY') : ""
        let workingDate = (this.state.workingDate) ? moment(this.state.workingDate).format('MM/DD/YYYY') : ""
        // let dateSubmitted = (this.state.dateSubmitted) ? moment(this.state.dateSubmitted).format('MM/DD/YYYY') : ""
        let dateRecieved = (this.state.dateRecieved) ? moment(this.state.dateRecieved).format('MM/DD/YYYY') : ""
        let dateforwarded = (this.state.dateForwarded) ? moment(this.state.dateForwarded).format('MM/DD/YYYY') : ""
        let arrLst = []

        let arrLstServices = []
        this.state.memberDDL.map(function(itm,i) {
            if(itm.isSelected === "1"){
                arrLst.push({
                    "ClearingId"        : itm.id,
                    "ProfileId"         : itm.profileId,
                    "CompanyName"       : itm.companyName,
                    "BranchName"        : itm.branchName,
                    "Position"          : itm.position,
                    "SalaryRate"        : itm.salaryRate,
                    "PeriodCovered"     : itm.periodCovered,
                    "ReasonForLeaving"  : itm.reasonForLeaving,
                    "ContractDateStart" : (itm.contractDateStart) ? moment(itm.contractDateStart).format('MM/DD/YYYY') : "",
                    "ContractDateEnd"   : (itm.contractDateEnd) ? moment(itm.contractDateEnd).format('MM/DD/YYYY') : "",
                    "DateHired"         : (itm.dateHired) ? moment(itm.dateHired).format('MM/DD/YYYY') : "",
                    "DateResigned"      : (itm.dateResigned) ? moment(itm.dateResigned).format('MM/DD/YYYY') : "",
                })
            }
        })
        if(arrLst.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select clearing client!",
                fade            :   true
            })
            return
        }
        if(this.state.isClearanceStatus === "2"){
            if(this.state.clientRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "3"){
            if(this.state.lrdRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "4"){
            if(this.state.rmtRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.isClearanceStatus === "5"){
            if(this.state.servicesRemarks === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Remarks required!",
                    fade            :   true
                })
            }
        }
        if(this.state.attachedDDL.length === 0){
            arrLstServices = []
        }
        else{
            this.state.attachedDDL.map(function(itm,i) {
                arrLstServices.push({
                    "FileName"          : itm.fileName,
                    "File"              : itm.file,
                    "EmployeeNo"        : itm.employeeNo,
                    "DateSubmitted"     : itm.dateSubmitted,
                    "IsModified"        : itm.isModified,
                })
            })
        }
        let clearanceStatus = ""
        if ( this.state.isClearanceStatus === "4" ) {
                clearanceStatus = "2"
        }else {
            clearanceStatus = String(parseFloat(this.state.isClearanceStatus) - 1)
        }
        // // console.log("this.state.attachment")
        // // console.log(this.state.servicesAttachments)
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance" : [
                {
                    "Id" : this.state.clearanceId,
                    "ClientId":this.state.clientId,
                    "ProfileId":this.state.profileId,
                    "EmployeeId":this.state.employeeId,
                    "PayPeriodId":this.state.payPeriodId,
                    "DateCreated":dateCleared,
                    "ClearanceDateMember":clearanceDate,
                    "ResignationDateMember":resignationDate,
                    "SubmissionDateMember":submissionDate ,
                    "LastWorkingDateMember":workingDate ,
                    "DateSubmitted":moment(new Date()).format('MM/DD/YYYY'),
                    "IsSSSNumberMember":this.state.isCheckSSS === true ? "1" : "0",
                    "IsTINNumberMember":this.state.isCheckTIN === true ? "1" : "0",
                    "IsPHICNumberMember":this.state.isCheckPHIC === true ? "1" : "0",
                    "IsHDMFNumberMember":this.state.isCheckHDMF === true ? "1" : "0",
                    "IsCashCardMember":this.state.isCheckCashCard === true ? "1" : "0",
                    "IsValidIdMember":this.state.isCheckValidId === true ? "1" : "0",
                    "IsResignationLetterMember":this.state.isCheckResignation === true ? "1" : "0",
                    "IsTurnoverChecklistMember":this.state.isCheckTurnover === true ? "1" : "0",
                    "IsCOERequestFormMember":this.state.isCheckRequestForm === true ? "1" : "0",
                    "IsTypeOfCashCardMember":this.state.isRadioCashcard === true ? "1" : "0" , // cash card mode of payment
                    "PaycardTypeIdMember":this.state.paycardTypeIdMember,
                    "TypeOfCashCardMember":this.state.cashCardType,
                    "CashCardNumberMember": typeof this.state.accountNumber === "undefined" ? "" : this.state.accountNumber,
                    "IsCheckMember":this.state.isRadioCheck === true ? "1" : "0"  ,
                    "IsMlhuillierMember":this.state.isRadioMlhuillier === true ? "1" : "0",
                    "IsPalawanMember":this.state.isRadioPalawan === true ? "1" : "0",
                    "RemarksMember":this.state.memberRemarks,
                    "NotesMember":this.state.memberNotes,
                    "ReasonForLeaving":this.state.reason,
                    "DateForwardedToAmt": dateRecieved,
                    "DateReceivedFromAmt": dateforwarded,
                    "IsEligible":this.state.eligible === true ? "1" : "0",
                    "IsNotEligible":this.state.notEligible === true ? "1" : "0",
                    "SssNumber": this.state.sssNumber,
                    "TinNumber": this.state.tinNumber,
                    "PhicNumber": this.state.phicNumber,
                    "HdmfNumber": this.state.hdmfNumber,

                    "IsClearedClient":"0"    ,
                    "IsEligibleClient":"0"    ,
                    "IsNonEligibleClient":"0"    ,

                    "IsClearedRmt":"0"    ,
                    "IsReturnedRmt":"0"  ,
                    "ValidateByRmt":"0"  ,
                    "IsForwardToARERmt":"0"   ,
                    "IsRetrunedServicesRmt":"0" ,
                    "IsCheckedByRmt":"0" ,

                    "RemarksClient":this.state.clientRemarks,
                    "RemarksRmt":this.state.rmtRemarks,
                    "RemarksLrd":this.state.lrdRemarks,
                    "ServicesRemarks":this.state.servicesRemarks ,

                    "IsClearanceStatus": clearanceStatus,
                    "clearanceDetails":arrLst,

                    /* ADDITIONAL */
                    "DateUpdatedByClient": this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? date : "",
                    "UpdatedByClient":this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByLRD":this.state.isClearanceStatus === "3" ? date : "",
                    "UpdatedByLRD":this.state.isClearanceStatus === "3" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByRMT":this.state.isClearanceStatus === "4" ? date : "",
                    "UpdatedByRMT":this.state.isClearanceStatus === "4" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByServices":this.state.isClearanceStatus === "5" ? date : this.state._dateServices,
                    "UpdatedByServices":this.state.isClearanceStatus === "5" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByARE":this.state.isClearanceStatus === "6" ? date : "",
                    "UpdatedByARE":this.state.isClearanceStatus === "6" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateUpdatedByFinance":this.state.isClearanceStatus === "8" ? date : "",
                    "UpdatedByFinance":this.state.isClearanceStatus === "8" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateSubmittedByStaff":date,
                    "SubmittedByStaff":this.state.userinfo.userId,

                    "DateSubmittedByLead":date,
                    "SubmittedByLead":this.state.userinfo.userId,

                    "DateReturnedByClient":"",
                    "ReturnedByClient":this.state.isClearanceStatus === "0" || this.state.isClearanceStatus === "1" || this.state.isClearanceStatus === "2"  ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByLRD":"",
                    "ReturnedByLRD":this.state.isClearanceStatus === "3" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByRMT":"",
                    "ReturnedByRMT":this.state.isClearanceStatus === "4" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByServices":"",
                    "ReturnedByServices":this.state.isClearanceStatus === "5" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByARE":"",
                    "ReturnedByARE":this.state.isClearanceStatus === "6" ? this.state.userinfo.userId : this.state.userinfo.userId,

                    "DateReturnedByFinance":"",
                    "ReturnedByFinance":this.state.isClearanceStatus === "8" ? this.state.userinfo.userId : this.state.userinfo.userId,


                    // 13/06/2023 additional
                    "DateSettle"        : this.state.dateSettle,
                    "IsSettleWithDole"  :  this.state.isSetteldWithDole === true ? "1" : "0",

                    "DateReceivedByAdmin" : this.state.dateReceivedByAdmin,
                    "IsCash" : this.state.isRadioCash === true ? "1" : "0",

                    "TotalBalance" : this.state.totalBalance,
                    "Submitted" : this.state.submitted,
                    "servicesAttachments" : this.state.attachedDDL.length===0?[]:arrLstServices,
                    "ClosingRemarks" : this.state.isSetteldWithDole === true ? "CLEARED" : "NOT CLEARED",
                    "ParamountId" : this.state.isParamountId === true ? "1" : "0",
                    "LastSalary" : "",
                    "Incentives" : "",
                    "Sil" : "",
                    "ThirteenthMonth" : "",
                    "Inclusions" : "",
                    "GrossPay" : "",
                    "NetPay" : "",
                    "PeriodCovered" : "",
                    "IsModifiedByARE" : "0",
                    "SeparationPay" : "",
                    "ReviewStatus" : "0",
                    "DateReturnedByPayroll" : "",
                    "AreRemarks" : this.state.areRemarks,
                    "PayrollRemarks" : this.state.payrollRemarks,
                    "FinanceRemarks" : this.state.financeRemarks,
                    "IsClearedSubmit" : "0",
                    "IsGenerated" : "0",
                    "IsFinalized" : "0",
                    // "ContractDateStart"  : this.state.contractDateStart,
                    // "ContractDateEnd"  : this.state.contractDateEnd,
                    "DateResigned" : this.state.dateResigned,
                    "DateHired" : this.state.dateHired,
                    "BatchPayroll" : this.state.batchPayroll,
                    "Adjustment" : this.state.adjustment,
                    "OtherPayroll" : this.state.otherPayroll,
                    "TotalInclusion" : this.state.totalInclusion,
                    "IsParent" : this.state.isParent,
                    "IsChild" : this.state.isChild,
                    "ParentId" : this.state.parentId,
                    
                    "DateSubmittedToPayroll" : this.state.dateSubmittedToPayroll,
                    "IsAre" : this.state.isAre,
                    "IsPayroll" : this.state.isPayroll,
                    "LastModifiedBy" : this.state.userinfo.fullName
                    /* END */
                }
            ]
        }
        // console.log("param")
        // console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disabledButtonMember : true,
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangeDateRecieved = (date) =>{
        this.setState({ dateRecieved : date })
    }
    handleChangeDateForwarded = (date) =>{
        this.setState({ dateForwarded : date })
    }
    handleTabOnSelect =(key)=>{
        this.setState({key: key});
    }


    onImageReplyChange(e) {
        this.setState({ files : this.fileInput.current.files[0].name })
    };
    onImageReplyChangeLrd(e) {
        this.setState({ filesLrd : this.fileInputLrd.current.files[0].name })
    };

    render() {
        let MemberColumn = [
            {
                dataField: 'companyName',
                text: 'Client',
                editable : false,
                headerStyle : () => {
                    return { width  : "60%"};
                }
            },
            {
                dataField: 'periodCovered',
                text: 'Tenure',
                editable : false,
                headerStyle : () => {
                    return { width  : "40%"};
                }
            },
        ];


        let memberSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            selected : this.state.selectedItems,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.memberDDL.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isSelected = isSelect ? "1" : "0"
                    }
                })
            }
        };


        let attachedColumn = [
            {
                dataField: 'fileName',
                text: 'File',
                editable : false,
                headerStyle : () => {
                    return { width  : "90%"};
                }
            },
            {
                dataField: 'atd',
                text: 'ATD',
                editable : true,
                editor: {
                    type: Type.CHECKBOX,
                    value: 'YES:NO'
                },
                headerStyle : () => {
                    return { width  : "10%", textAlign : "center"};
                },
                style :{ textAlign : "center" }
            },
        ];
        let attachedSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.attachedDDL.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
            }
        };

        let attachedColumnLrd = [
            {
                dataField: 'fileName',
                text: 'File',
                editable : false,
                headerStyle : () => {
                    return { width  : "100%"};
                }
            },
        ];
        let attachedSelectRowLrd = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.attachedDDLLrd.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; EDIT / UPDATE</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div>
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLIENT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.client}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder='SELECT CLIENT'
                                            selected={[this.state.clientName]}
                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    EMPLOYEE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.employee}
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder='SELECT EMPLOYEE'
                                            selected={[this.state.employeeName]}
                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    REASON FOR LEAVING
                                    </Form.Label>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            onChange={this.handleChangedReason}
                                            value={this.state.reason}
                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                        >
                                        {this.state.reasonDDL.map(item => (
                                            <option key={item.id} value={item.reason}>
                                            {item.name}
                                            </option>
                                        ))}
                                        </Form.Control>
                                        {/* <Typeahead
                                            ref={this.refReason}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedReason}
                                            options={this.state.reasonDDL}
                                            placeholder='Select reason for leaving'

                                        /> */}
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    RESiGNATION DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='resignationDate'
                                            selected={this.state.resignationDate}
                                            onChange={this.handleChangeResignationDate}
                                            minDate={this.minDate}
                                            value={this.props.resignationDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                            // placeholder='SELECT RESIGNATION DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLEARANCE DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.clearanceDate}
                                            onChange={this.handleChangeClearanceDate}
                                            value={this.props.clearanceDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Date Created
                                    </Form.Label>
                                    <Col>
                                        <Form.Label className='mt-2' style={{fontWeight : "bold"}}>
                                            { (this.state.dateCreated) ? moment(this.state.dateCreated).format('MM/DD/YYYY') : ""}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    DATE RECEIVED FROM MEMBER
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='submissionDate'
                                            selected={this.state.submissionDate}
                                            onChange={this.handleChangeSubmissionDate}
                                            minDate={this.minDate}
                                            value={this.props.submissionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                            // placeholder='SELECT SUBMISSION DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    LAST WORKING DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='workingDate'
                                            selected={this.state.workingDate}
                                            onChange={this.handleChangeWorkingDate}
                                            minDate={this.minDate}
                                            value={this.props.workingDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                            // placeholder='SELECT LAST WORKING DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    Notes
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Col>
                                        <Form.Control
                                            // className='mt-4'
                                            as="textarea"
                                            rows={6}
                                            onChange={this.handleChangedNotes}
                                            autoComplete="off"
                                            value={this.state.memberNotes}
                                            disabled = { this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Col>
                                        {/* <ButtonToolbar className="mt-1" >
                                            <Button variant="info"  className="ml-auto noser-button-mr1 noser-button" onClick={(e) => window.location.reload(true)}>
                                                NEW
                                            </Button>
                                            <Button variant="info" onClick={ this.handleCancelClick } className="noser-button-mr1 noser-button">
                                                CANCEL
                                            </Button>
                                        </ButtonToolbar> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    STATUS
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Label className='mt-2' style={{fontWeight : "bold", fontStyle : "italic", fontSize : "12px"}}>
                                            with &nbsp;&nbsp; {this.state.clearanceStatus} &nbsp;&nbsp; for {this.state.daysCount} day(s) now since { (this.state.dateSubmitted) ? moment(this.state.dateSubmitted).format('MM/DD/YYYY') : ""}
                                        </Form.Label>
                                    </Col>
                                    <Col sm="6">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    cycle days
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Label style={{fontWeight : "bold", /* color : "#FF3030", */ fontStyle : "italic", fontSize : "12px", marginTop: "5%"}}>
                                            { this.state.cycleDays }
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Tabs className="mt-3" defaultActiveKey={this.state.key} transition={false} id="noanim-tab-example" onSelect = { this.handleTabOnSelect } /* disabled = {this.state.dsabldmember} */>
                                    <Tab eventKey={0} title="MEMBER" activeKey = {this.state.key} >
                                        <Card>
                                            <Card.Body>
                                                <Card>
                                                    <Card.Header>EMPLOYEE DETAILS</Card.Header>
                                                    <Card.Body>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                                Last Name
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.lastName}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                                Branch / Location
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.locationName}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            First Name
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.firstName}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Postion
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.positionName}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Middle Name
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.middleName}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Start Date
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {(this.state.contractDateStart) ? moment(this.state.contractDateStart).format('MM/DD/YYYY') : ""}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Client
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.client}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                                <Card className='mt-5'>
                                                    <Card.Header>For Clearing Client</Card.Header>
                                                    <Card.Body>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.memberDDL }
                                                            columns = { MemberColumn }
                                                            // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                            cellEdit = { cellEditFactory({
                                                                mode: 'click',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            }
                                                            // rowEvents={ listOfTrainingRowEvents }
                                                            selectRow = { memberSelectRow }

                                                        />
                                                    </Card.Body>
                                                </Card>
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Col sm="2">
                                                        <Form.Label style={{fontWeight : "bold"}}>
                                                            date forwarded to amt
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            ref='dateForwarded'
                                                            selected={this.state.dateForwarded}
                                                            onChange={this.handleChangeDateForwarded}
                                                            minDate={this.minDate}
                                                            value={this.props.dateForwarded}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder='SELECT LAST WORKING DATE'
                                                            wrapperClassName="datepicker"
                                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                                        />
                                                    </Col>
                                                    <Col sm="2">

                                                    </Col>
                                                    <Col sm="2">
                                                        <Form.Label style={{fontWeight : "bold"}}>
                                                            date received from amt
                                                        </Form.Label>

                                                    </Col>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            ref='dateRecieved'
                                                            selected={this.state.dateRecieved}
                                                            onChange={this.handleChangeDateRecieved}
                                                            minDate={this.minDate}
                                                            value={this.props.dateRecieved}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder='SELECT LAST WORKING DATE'
                                                            wrapperClassName="datepicker"
                                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "89692" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" ? false : true }
                                                        />
                                                    </Col>
                                                    <Col sm="2">

                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Col sm="6">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            attachment (hardcopy)
                                                        </Form.Label>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={2}>
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckSSS"
                                                                    name='isCheckSSS'
                                                                    label="SSS"
                                                                    onChange={ e => this.onchangeCheckSSS(e) }
                                                                    checked = { this.state.isCheckSSS }
                                                                    value={ this.state.isCheckSSS }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckTIN"
                                                                    name='isCheckTIN'
                                                                    label="TIN"
                                                                    onChange={ e => this.onchangeCheckTIN(e) }
                                                                    checked = { this.state.isCheckTIN }
                                                                    value={ this.state.isCheckTIN }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckPHIC"
                                                                    name='isCheckPHIC'
                                                                    label="PhilHealth"
                                                                    onChange={ e => this.onchangeCheckPHIC(e)  }
                                                                    checked = { this.state.isCheckPHIC }
                                                                    value={ this.state.isCheckPHIC }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckHDMF"
                                                                    name='isCheckHDMF'
                                                                    label="PAGIBIG"
                                                                    onChange={ e => this.onchangeCheckHDMF(e)  }
                                                                    checked = { this.state.isCheckHDMF }
                                                                    value={ this.state.isCheckHDMF }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckCashCard"
                                                                    name='isCheckCashCard'
                                                                    label="Cash Card"
                                                                    onChange={ this.onchangeCheckCashCard }
                                                                    checked = { this.state.isCheckCashCard }
                                                                    value={ this.state.isCheckCashCard }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckValidId"
                                                                    name='isCheckValidId'
                                                                    label="Valid ID's (atleast 2)"
                                                                    onChange={ this.onchangeCheckValidId }
                                                                    checked = { this.state.isCheckValidId }
                                                                    value={ this.state.isCheckValidId }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckResignation"
                                                                    name='isCheckResignation'
                                                                    label="Resignation Letter"
                                                                    onChange={ this.onchangeCheckResignation }
                                                                    checked = { this.state.isCheckResignation }
                                                                    value={ this.state.isCheckResignation }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckTurnover"
                                                                    name='isCheckTurnover'
                                                                    label="Turnover Checklist"
                                                                    onChange={ this.onchangeCheckTurnover }
                                                                    checked = { this.state.isCheckTurnover }
                                                                    value={ this.state.isCheckTurnover }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckRequestForm"
                                                                    name='isCheckRequestForm'
                                                                    label="COE Request Form"
                                                                    onChange={ this.onchangeCheckRequestForm }
                                                                    checked = { this.state.isCheckRequestForm }
                                                                    value={ this.state.isCheckRequestForm }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isParamountId"
                                                                    name='isParamountId'
                                                                    label="PARAMOUNT ID"
                                                                    onChange={ this.onchangeCheckParamountId }
                                                                    checked = { this.state.isParamountId }
                                                                    value={ this.state.isParamountId }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                            </Col>
                                                            <Col sm={4} className='mt-3'>
                                                                {/* <Form.Control
                                                                    ref="sssNumber"
                                                                    name="sssNumber"
                                                                    value={this.state.sssNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangeSSS}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disablesss}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="sssNumber"
                                                                    value={this.state.sssNumber}
                                                                    onChange={this.onChangeSSS}
                                                                    autoComplete="off"
                                                                    format="##-#######-#"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disabledTmp}
                                                                    style={{ textAlign : "right" }}
                                                                />
                                                                {/* <Form.Control
                                                                    ref="tinNumber"
                                                                    name="tinNumber"
                                                                    value={this.state.tinNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangeTinNumber}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disabletin}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="tinNumber"
                                                                    value={this.state.tinNumber}
                                                                    onChange={this.onChangeTinNumber}
                                                                    autoComplete="off"
                                                                    format="###-###-###-000"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disabledTmp1}
                                                                    style={{ textAlign : "right" }}
                                                                />
                                                                {/* <Form.Control
                                                                    ref="phicNumber"
                                                                    name="phicNumber"
                                                                    value={this.state.phicNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangePhicNumber}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disablephic}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="phicNumber"
                                                                    value={this.state.phicNumber}
                                                                    onChange={this.onChangePhicNumber}
                                                                    autoComplete="off"
                                                                    format="##-#########-#"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disabledTmp2}
                                                                    style={{ textAlign : "right" }}
                                                                />

                                                                {/* <Form.Control
                                                                    ref="hdmfNumber"
                                                                    name="hdmfNumber"
                                                                    value={this.state.hdmfNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangeHdmfNumber}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disablehdmf}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="hdmfNumber"
                                                                    value={this.state.hdmfNumber}
                                                                    onChange={this.onChangeHdmfNumber}
                                                                    autoComplete="off"
                                                                    format="####-####-####"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disabledTmp3}
                                                                    style={{ textAlign : "right" }}
                                                                />
                                                            </Col>
                                                            <Col sm={1}>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Form.Label className='mb-2' column style={{fontWeight : "bolder"}}>
                                                            mode of remittance
                                                        </Form.Label>
                                                        
                                                        {
                                                            this.state.userinfo.userId === "34024" || this.state.userinfo.userId === "TEAMLEAD02" ? null : 
                                                            
                                                            <Row>
                                                                <Col sm={2}>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label="Cash Card"
                                                                        name="isRadioCashcard"
                                                                        style={{fontWeight : "bold"}}
                                                                        value={this.state.isRadioCashcard}
                                                                        checked={this.state.isRadioCashcard}
                                                                        onChange={this.handleRadioCashcard}
                                                                    />
                                                                </Col>
                                                                <Col sm={4}>
                                                                    {this.state.checktypeahead === true ? 
                                                                        <Typeahead
                                                                            ref={this.refCashCard}
                                                                            labelKey='name'
                                                                            id="basic-example"
                                                                            onChange={this.handleChangeRadioCashCard}
                                                                            options={this.state.radioCashCardDDL}
                                                                            style={{border : "solid 2px"}}
                                                                            placeholder="select cash card"
                                                                            disabled={this.state.cashCardField}
                                                                            // defaultSelected={[this.state.payCardType]}
                                                                            // selected={[this.state.payCardType]}
                                                                            // selected = {[this.state.cashCardType]}
                                                                        />
                                                                    :
                                                                        <Typeahead
                                                                            ref={this.refCashCard}
                                                                            labelKey='name'
                                                                            id="basic-example"
                                                                            onChange={this.handleChangeRadioCashCard}
                                                                            options={this.state.radioCashCardDDL}
                                                                            style={{border : "solid 2px"}}
                                                                            placeholder="select cash card"
                                                                            disabled={this.state.cashCardField}
                                                                            // defaultSelected={[this.state.payCardType]}
                                                                            // selected={[this.state.payCardType]}
                                                                            selected = {[this.state.cashCardType]}
                                                                        />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <Row className='mt-2'>
                                                            <Col sm={2}>
                                                                <Form.Label className='ml-4' style={{fontWeight: "bold"}}> Account #</Form.Label>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <Form.Control
                                                                    // ref={this.disengagementMode}
                                                                    // labelKey='name'
                                                                    // id="basic-example"
                                                                    onChange={this.handleChangeAccountNumber}
                                                                    value={this.state.accountNumber}
                                                                    placeholder=""
                                                                    disabled={this.state.cashCardField}
                                                                    autoComplete="off"
                                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className='mt-2'>
                                                            <Col sm={3}>
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Check"
                                                                    name="isRadioCheck"
                                                                    style={{fontWeight : "bold"}}
                                                                    value={this.state.isRadioCheck}
                                                                    checked={this.state.isRadioCheck}
                                                                    onChange={this.handleRadioCheck}
                                                                    //value={this.state.isRequired}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className='mt-2'>
                                                            <Col sm={3}>
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Mlhuillier"
                                                                    name="isRadioMlhuillier"
                                                                    style={{fontWeight : "bold"}}
                                                                    value={this.state.isRadioMlhuillier}
                                                                    checked={this.state.isRadioMlhuillier}
                                                                    onChange={this.handleRadioMlhuillier}
                                                                    //value={this.state.isRequired}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className='mt-2'>
                                                            <Col sm={3}>
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Palawan"
                                                                    name="isRadioPalawan"
                                                                    style={{fontWeight : "bold"}}
                                                                    value={this.state.isRadioPalawan}
                                                                    checked={this.state.isRadioPalawan}
                                                                    onChange={this.handleRadioPalawan}
                                                                    //value={this.state.isRequired}
                                                                />
                                                            </Col>
                                                        </Row> */}
                                                        <Row className='mt-2'>
                                                            <Col sm={3}>
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Cash"
                                                                    name="isRadioCash"
                                                                    style={{fontWeight : "bold"}}
                                                                    value={this.state.isRadioCash}
                                                                    checked={this.state.isRadioCash}
                                                                    onChange={this.handleRadioCash}
                                                                    //value={this.state.isRequired}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className='mt-2'></Row>
                                                        <Row className='mt-2'></Row>
                                                        <Row className='mt-2'></Row>
                                                        <Row className='mt-2'></Row>
                                                        {/* <div className={this.state.isshowClient === true ? 'display-block' : 'display-none'}> */}
                                                        {/* </div> */}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        Remarks
                                                    </Form.Label>
                                                    <Col sm="11">
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={6}
                                                            onChange={this.handleChangedMemeberRemarks}
                                                            autoComplete="off"
                                                            value={this.state.memberRemarks}
                                                            disabled = { this.state.userinfo.userId === "34007" || this.state.userinfo.userId === "91118" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.firstName === "CMWTL03" || this.state.userinfo.userId === "39965" ? false : true }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            MEMBER/CMW :  &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>                                                  
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                            </Card.Body>
                                            < hr />
                                            <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( LEAD ) :  {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                </Form.Label>
                                            </Form.Group>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={2} title="CLIENT" activeKey = {this.state.key} onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.clientTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Row className='mt-2'>
                                                    <Col sm={3}>
                                                        <Form.Label style={{fontWeight : "bold"}}>
                                                            Rehire Eligibility
                                                        </Form.Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={3} >
                                                        <Form.Check
                                                            inline
                                                            type="radio"
                                                            label="eligible"
                                                            name="eligible"
                                                            style={{fontWeight : "bold"}}
                                                            value={this.state.eligible}
                                                            checked={this.state.eligible}
                                                            onChange={this.handleRadioEligible}
                                                            //value={this.state.isRequired}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            type="radio"
                                                            label="not eligible"
                                                            name="notEligible"
                                                            style={{fontWeight : "bold"}}
                                                            value={this.state.notEligible}
                                                            checked={this.state.notEligible}
                                                            onChange={this.handleRadioNotEligible}
                                                            //value={this.state.isRequired}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedClientRemarks}
                                                            autoComplete="off"
                                                            value={this.state.clientRemarks}
                                                            disabled = {this.state.userinfo.userId === "34007" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.firstName === "CLIENTTL01" || this.state.userinfo.firstName === "CLIENTTL02" || this.state.userinfo.userId === "39965" ? false : true }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            MEMBER/CMW : &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                        CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>                                         
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                            </Card.Body>
                                            < hr />
                                            <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( LEAD ) :  {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                </Form.Label>
                                            </Form.Group>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={3} activeKey = {this.state.key} title="LRD" onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <Form.Group as={Row} controlId="formPlaintextEmail" className='mt-5'>
                                                    <Col sm="4">
                                                        <Form.Check
                                                            type='checkbox'
                                                            id="isSetteldWithDole"
                                                            name='isSetteldWithDole'
                                                            label="Settled With DOLE."
                                                            onChange={ e => this.onchangeCheckSttldWdDole(e) }
                                                            checked = { this.state.isSetteldWithDole }
                                                            value={ this.state.isSetteldWithDole }
                                                            className='no-text-transform-li'
                                                            style={{ fontWeight : "bold" }}
                                                            // disabled = { this.state.userinfo.firstName === "TEAMLEAD02" || this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.userId === "39965" ? false : true }
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        Date Settled
                                                    </Form.Label>
                                                    <Col  sm="6">
                                                        <DatePicker
                                                            ref='dateSettled'
                                                            selected={this.state.dateSettled}
                                                            onChange={this.handleChangeSettledDateDate}
                                                            minDate={this.minDate}
                                                            value={this.props.dateSettled}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled = {this.state.userinfo.userId === "34024" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.firstName === "TEAMLEAD02" || this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.userId === "39965" ? false : true }
                                                            // placeholder='SELECT RESIGNATION DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <Form.Group className='mt-5' controlId="formFileMultiple">
                                                    <Row>
                                                        <Col sm={1}>
                                                            <Form.Label  className="mt-2" style={{fontWeight : "bold"}}>Attachment File</Form.Label>
                                                        </Col>
                                                        <Col sm={2}>
                                                            {/*<Form.Control   type="file" multiple /> */}
                                                            <input ref={/* ref=>  */this.fileInputLrd/*  = ref */} name="file" className="mr-auto" type="file" onChange={this.onImageReplyChangeLrd}/>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Button variant="info" className="ml-auto noser-button-mr1 noser-button" onClick={this.handleUploadClickLrd} disabled = {this.state.userinfo.userId === "34024" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.lastName === "TEAMLEAD02" || this.state.userinfo.lastName === "LDTEAMLEADO1"  ? false : true }>
                                                                UPLOAD
                                                            </Button>
                                                        </Col>
                                                        <Col SM={5}></Col>
                                                    </Row>
                                                </Form.Group>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.attachedDDLLrd }
                                                    columns = { attachedColumnLrd }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { attachedSelectRowLrd }

                                                />
                                                <ButtonToolbar className="mt-1" >
                                                    <Button variant="info" /* className='float-right' */ className="ml-auto noser-button-mr1 noser-button" onClick={this.handleDeleteClickLrd} disabled = {  this.state.userinfo.userId === "89092" || this.state.userinfo.lastName === "TEAMLEAD02" || this.state.userinfo.lastName === "LDTEAMLEADO1" ? false : true }>
                                                        DELETE
                                                    </Button>
                                                </ButtonToolbar>
                                                <hr />
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.lrdTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedLrdRemarks}
                                                            autoComplete="off"
                                                            value={this.state.lrdRemarks}
                                                            disabled = {this.state.userinfo.userId === "34024" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.firstName === "TEAMLEAD02" || this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.userId === "39965" ? false : true }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                        MEMBER/CMW : &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>                                         
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                            </Card.Body>
                                            <hr />
                                            <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( LEAD ) :  {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                </Form.Label>
                                            </Form.Group>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={4} activeKey = {this.state.key} title="RMT" onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.rmtDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className='mt-5' as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedRmtRemarks}
                                                            autoComplete="off"
                                                            value={this.state.rmtRemarks}
                                                            disabled = {this.state.userinfo.userId === "36536" || this.state.userinfo.userId === "130303" || this.state.userinfo.userId === "40340" || this.state.userinfo.userId === "92659" || this.state.userinfo.userId === "45350" || this.state.userinfo.userId === "34022" || this.state.userinfo.userId === "42865" || this.state.userinfo.userId === "34005" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.lastName === "RMT01" || this.state.userinfo.firstName === "TLRMT02" || this.state.userinfo.userId === "39965" ? false : true }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                        MEMBER/CMW : &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>                                         
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                            </Card.Body>
                                            < hr />
                                            <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( LEAD ) :  {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                </Form.Label>
                                            </Form.Group>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={5} activeKey = {this.state.key} title="SERVICES" onSelect = { this.handleTabOnSelect }  >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.serviceTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />

                                                <Form.Group className='mt-5' controlId="formFileMultiple">
                                                    <Row>
                                                        <Col sm={1}>
                                                            <Form.Label  className="mt-2" style={{fontWeight : "bold"}}>Attachment File</Form.Label>
                                                        </Col>
                                                        <Col sm={2}>
                                                            {/*<Form.Control   type="file" multiple /> */}
                                                            <input ref={/* ref=>  */this.fileInput/*  = ref */} name="file" className="mr-auto" type="file" onChange={this.onImageReplyChange}/>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Button variant="info" className="ml-auto noser-button-mr1 noser-button" onClick={this.handleUploadClick} disabled = {this.state.userinfo.userId === "91551" || this.state.userinfo.userId === "91460" || this.state.userinfo.userId === "34006" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.lastName === "SERVICES01" || this.state.userinfo.lastName === "SERVICES02" || this.state.userinfo.userId === "39965" || this.state.userinfo.firstName === "APRILTL" || this.state.userinfo.firstName === "89692" || this.state.userinfo.userId === "89692" ? false : true }>
                                                                UPLOAD
                                                            </Button>
                                                        </Col>
                                                        <Col sm={2}></Col>
                                                        <Col sm={3}>
                                                            <Form.Label style={{fontWeight : "bold"}}>
                                                            DATE RECEIVED BY ADMIN
                                                            </Form.Label>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <DatePicker
                                                                ref='submissionDate'
                                                                selected={this.state.dateReceivedByAdmin}
                                                                onChange={this.handleChangeDateReceivedByAdmin}
                                                                minDate={this.minDate}
                                                                value={this.props.dateReceivedByAdmin}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                disabled = {this.state.userinfo.userId === "91551" || this.state.userinfo.userId === "91460" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" || this.state.userinfo.userId === "34006" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.lastName === "SERVICES01" || this.state.userinfo.userId === "39965" || this.state.userinfo.firstName === "APRILTL" || this.state.userinfo.firstName === "89692"  ? false : true }
                                                                // placeholder='SELECT SUBMISSION DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.attachedDDL }
                                                    columns = { attachedColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { attachedSelectRow }

                                                />

                                                <ButtonToolbar className="mt-1" >
                                                    <Button variant="info" /* className='float-right' */ className="ml-auto noser-button-mr1 noser-button" onClick={this.handleDeleteClick} disabled = {this.state.userinfo.userId === "91551" || this.state.userinfo.userId === "91460" || this.state.userinfo.userId === "34006" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" ||  this.state.userinfo.userId === "89092" || this.state.userinfo.lastName === "SERVICES01" || this.state.userinfo.lastName === "SERVICES02" || this.state.userinfo.userId === "39965" ? false : true }>
                                                        DELETE
                                                    </Button>
                                                </ButtonToolbar>
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedServicesRemarks}
                                                            autoComplete="off"
                                                            value={this.state.servicesRemarks}
                                                            disabled = {this.state.userinfo.userId === "91551" || this.state.userinfo.userId === "91460" || this.state.userinfo.userId === "34006" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" ||  this.state.userinfo.userId === "89657" || this.state.userinfo.userId === "89092" || this.state.userinfo.firstName === "SERVICES01" || this.state.userinfo.firstName === "SERVICES02" || this.state.userinfo.userId === "39965" || this.state.userinfo.firstName === "APRILTL" || this.state.userinfo.firstName === "89692" || this.state.userinfo.userId === "89692" ? false : true }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                        MEMBER/CMW : &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>                                         
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                            </Card.Body>
                                            < hr />
                                            <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    UPDATED BY ( LEAD ) : {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                    {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                </Form.Label>
                                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                    RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                </Form.Label>
                                            </Form.Group>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={6} title="AR-E" activeKey = {this.state.key} onSelect = { this.handleTabOnSelect }  >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.serviceTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedAreRemarks}
                                                            autoComplete="off"
                                                            value={this.state.areRemarks}
                                                            disabled = { this.state.userinfo.userId === "89092" ||  this.state.userinfo.userId === "89658" || this.state.userinfo.userId === "39965" || this.state.userinfo.userId === "89659" ? false : true } //
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                        MEMBER/CMW : &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                                < hr />
                                                <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        UPDATED BY ( LEAD ) : {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={7} title="PAYROLL" activeKey = {this.state.key} onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.serviceTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedPayrollRemarks}
                                                            autoComplete="off"
                                                            value={this.state.payrollRemarks}
                                                            disabled = {this.state.userinfo.userId === "34009" ||   this.state.userinfo.userId === "89092" ||  this.state.userinfo.userId === "89658" || this.state.userinfo.userId === "39965" || this.state.userinfo.userId === "39965" || this.state.userinfo.userId === "34009" ? false : true }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                        MEMBER/CMW : &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                                < hr />
                                                <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        UPDATED BY ( LEAD ) : {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={8} title="FINANCE" activeKey = {this.state.key} onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.serviceTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedFInanceRemarks}
                                                            autoComplete="off"
                                                            value={this.state.financeRemarks}
                                                            disabled = {this.state.userinfo.userId === "45162" ||   this.state.userinfo.userId === "89092" ||  this.state.userinfo.userId === "89658" || this.state.userinfo.userId === "39965" ? false : true }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <fieldset className="border p-3 ">
                                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>CONSOLIDATED REMARKS: </i></legend>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                        MEMBER/CMW : &nbsp;&nbsp;&nbsp;{this.state.memberRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            CLIENT : &nbsp;&nbsp;&nbsp;{this.state.clientRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            RMT : &nbsp;&nbsp;&nbsp;{this.state.rmtRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            SERVICES : &nbsp;&nbsp;&nbsp;{this.state.servicesRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            AR-E : &nbsp;&nbsp;&nbsp;{this.state.areRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            PAYROLL : &nbsp;&nbsp;&nbsp;{this.state.payrollRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            FINANCE : &nbsp;&nbsp;&nbsp;{this.state.financeRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="12" style={{fontWeight : "bold"}}>
                                                            LRD : &nbsp;&nbsp;&nbsp;{this.state.lrdRemarks}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </fieldset>
                                                < hr />
                                                <Form.Group className="mt-5 ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        CREATED BY : &nbsp;&nbsp;{this.state._createdby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._createddate}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        UPDATED BY ( STAFF ) : {this.state._updatedbystaff}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._updatedbystaffdate}
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        UPDATED BY ( LEAD ) : {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._dateSubmittedByLead}
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        SUBMITTED BY: {this.state._submittedByLead}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{ this.state._dateSubmittedByLead === "" ? "" : this.state._dateSubmittedByLead }
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="ml-1" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="8" style={{fontWeight : "bold"}}>
                                                        {/* CREATED BY : &nbsp;&nbsp;{}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{} */}
                                                    </Form.Label>
                                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                                        RETURNED BY: {this.state._returnedby}&nbsp;&nbsp;&nbsp;on &nbsp;&nbsp;&nbsp;{this.state._returnedbydate}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Form>
                        </Card.Body>
                    </Card>
                    <ButtonToolbar className="mt-3 mb-3" sm={12}>
                        <Button variant="info" className="ml-auto noser-button-mr1 noser-button" onClick={ this.handleClickSave } disabled = {this.state.userinfo.userId === "34006" || this.state.userinfo.userId === "91551" || this.state.userinfo.userId === "91460" || this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "34004" || this.state.userinfo.userId === "87961" ||  this.state.userinfo.firstName === "CMWTL03" || this.state.userinfo.firstName === "CMWTL04" || this.state.userinfo.userId === "89658" || this.state.userinfo.firstName === "TL" ||  this.state.userinfo.userId === "39965" || this.state.userinfo.employeeId === "91250" || this.state.userinfo.firstName === "SERVICES01" || this.state.userinfo.firstName === "APRILTL" || this.state.userinfo.userId === "89654" || this.state.userinfo.userId === "89734" || this.state.userinfo.firstName === "ARESTAFF" || this.state.userinfo.userId === "34007" ? false : true }>
                            SAVE
                        </Button>
                        <Button disabled={this.state.disabledButtonMember} className="noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickSubmit } >
                            submit
                        </Button>
                        <Button disabled={ this.state.userinfo.firstName === "ARESTAFF" || this.state.userinfo.userId === "89659" || this.state.userinfo.userId === "89659" ? true : false } className="noser-button-mr1 noser-button" onClick={this.handleCLickReturn } variant="info">
                            return
                        </Button>
                        <Button  href="/searchscreenclearance"  className="noser-button-mr1 noser-button" variant="danger">
                            back
                        </Button>
                    </ButtonToolbar>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}
export  default ClearanceEdit