import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect, Tabs, Tab
}
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import NumberFormat from 'react-number-format';

// import { aws } from 'aws-sdk';
// import S3FileUpload from 'react-s3';

//Optional Import
// import { uploadFile } from 'react-s3';
let ref = React.createRef();
class Clearance extends Component {
    constructor(props) {
        super(props);
        this.client = React.createRef();
        this.employee = React.createRef();
        this.disengagementMode = React.createRef();
        this.refCashCard = React.createRef();
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disabledaftrsave : false,
            disablacctno : true,

            clientDDL: [],
            clientId: "",
            clientName:"",
            employeeDDL: [],
            profileId: "",
            employeeId: "",

            // START MEMBER FIELDS
            reasonDDL: [
                // {'id' : '', 'name' : 'Select Reason'},
                // {'id':'1','name':'Clearance'},
                // {'id':'2','name':'Clearance & Resignation'},
                // {'id':'3','name':'Dole Cases'}
            ],
            reasonId: "",
            reason: "",
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            memberNotes:"",
            memberDDL:[],
            isCheckSSS:false,
            isCheckTIN:false,
            isCheckPHIC:false,
            isCheckHDMF:false,
            isCheckCashCard:false,
            isCheckValidId:false,
            isCheckResignation:false,
            isCheckTurnover:false,
            isCheckRequestForm:false,
            isRadioCashcard:false,
            isRadioCash : false,
            radioCashCardDDL:[],
            cashCardType:"",
            accountNumber:"",
            isRadioCheck:false,
            isRadioMlhuillier:false,
            isRadioPalawan:false,
            cashCardField:true,
            memberRemarks:"",
            dateCreated: new Date(),
            dateSubmitted: "",
            clearanceStatus:"",
            // END MEMBER FIELDS

            clientRemarks:"",
            clientTabDDL:[],
            lrdRemarks:"",
            lrdTabDDL:[],
            rmtRemarks:"",
            rmtDDL:[],
            serviceTabDDL:[],
            servicesRemarks:"",
            attachedDDL:[],

            disabledButtonMember:true,
            disabledCleintButton:true,
            disabledLrdButton:true,
            disabledRmtButton:true,
            disabledServicesButton:true,
            dateRecieved : "",
            dateForwarded : "",
            notEligible : false,
            eligible : false,
            isClearanceStatus : "",
            files : [],
            daysCount : "0",
            cycleDays : "",

            //from edit details
            employeeName:"",

            payCardNumber: "",
            payCardType:"",
            payCardTypeId:"",

            payCardNumberTmp: "",
            payCardTypeTmp:"",
            payCardTypeIdTmp:"",
            checkEmployee : "",
            dataChecking : [],
            defaultkey : "0",
            isCheckRemittance : "0",
            isCashcardCheck : "0",
            disablesss : true,
            disabletin : true,
            disablephic : true,
            disablehdmf : true,
            sssNumber : "",
            tinNumber : "",
            phicNumber : "",
            hdmfNumber : "",
            dateNow :   new Date(),

            rmtRemarks:"",
            lrdRemarks: "",
            isParamountId : false,
            payPeriodId : "",
            cashCardTypeId : "",
            dateHired : "",
            contractDateStart : "",
            contractDateEnd : "",
            employeeListt : [],
            selectpaycard : "0"

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clearanceeditdetails = JSON.parse(sessionStorage.getItem("clearanceeditdetails_"))
        this.onImageReplyChange = this.onImageReplyChange.bind(this);
        this.fileInput = React.createRef();

    }

    componentDidMount(){
        // // console.log('this.state.userinfo')
        // // console.log(this.state.userinfo)
        this.GetClient();
        // this.GetEmployeeList()
        this.GetData();
        this.GetClearanceforChecking();

    };
    GetData = async() => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            let data = res.data.disengagementMode;
            console.log("DisengagementMode")
            console.log(data)
            if(this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.userId === "89655"){
                this.setState({
                    reasonDDL            : data.filter( x=>x.name==="DOLE CASES"),
                    isloading           : false,
                });
            }
            else{
                this.setState({
                    reasonDDL            : data,
                    isloading           : false,
                });
            }
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetPayCardTypes()
    };
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            console.log("data")
            console.log(data)
            this.setState({radioCashCardDDL:data.payCardTypes.filter(x=>x.id!=="3"&&x.id!=="5"&&x.id!=="7"&&x.id!=="8")});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        // // console.log('this.state.cardtypeDDL')
        // // console.log(this.state.cardtypeDDL)
        // this.GetDataClearance()
    };





    handleChangedDisengagementMode = (e) => {
        this.setState({isloading:false})
        this.setState({ name: e.target.value.toUpperCase() });
    };
    GetClient = async() =>{
        this.setState({isloading:true})
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            let data = res.data; 
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,isloading   :   false})
            }
            else{
                this.setState({clientDDL : data.clients,isloading   :   false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    handleChangeClient = (e) => {
       if(e.length == 0) {
           this.state.clientId     =   ""
           this.state.clientName   =   ""
           return
       }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
        console.log(e[0])
       this.GetEmployees();
    };
    GetEmployeeList = () => {
        this.setState({isloading:true})
        let getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        // // consolelog("getParams")
        // // consolelog(getParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            let data = res.data
            this.setState({
                // employeeListt        :   data.employees,
                employeeDDL        :   data.employees,
                isloading           :   false,
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    };
    GetEmployees = () => {
        this.setState({isloading:true})
        let getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": this.state.userinfo.roleId==="1" ||
                          this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        // // consolelog("getParams")
        // // consolelog(getParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  getParams)
        .then(res => {
            let data = res.data
            console.log("GetEmployees")
            console.log(data)
            this.setState({
                employeeDDL        :   data.profiles.filter(x=>x.status==="INACTIVE"),
                isloading           :   false,
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    };
    handleChangeEmployee = (e) => {
        console.log("e")
        console.log(e)
        // let e = this.state.employeeListt.filter(f => this.state.employeeDDL.some(item => item.employeeid === f.employeeid))
        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.locationName =   ""
            this.state.contractDateStart  =   ""
            this.state.contractDateEnd  =   ""
            this.state.phicNumber = ""
            this.state.sssNumber = ""
            this.state.tinNumber = ""
            this.state.hdmfNumber = ""
            this.state.payPeriodId = ""
            this.state.accountNumber = ""
            this.state.dateHired = ""
            this.state.dateResigned = ""
            this.setState({ payCardTypeId: "" })
             return
        }
        else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.contractDateEnd  =   e[0].contractDateEnd
            this.state.dateResigned  =   e[0].dateResigned
            this.state.phicNumber       =   e[0].phicNumber
            this.state.sssNumber        =   e[0].sssNumber
            this.state.tinNumber        =   e[0].tinNumber
            this.state.hdmfNumber    =   e[0].hdmfNumber
            this.state.payCardNumberTmp = e[0].payCardNumber
            this.state.payCardTypeIdTmp = e[0].payCardTypeId
            this.state.checkEmployee = e[0].id
            this.state.payPeriodId = e[0].payPeriodId
            this.state.dateHired = e[0].dateHired
            this.state.payCardTypeTmp =e[0].payCardType
            //this.setState({ payCardTypeId: e[0].payCardTypeId })

            // this.state.referenceNo          =   e[0].referenceNo
            // this.setState({
            //     isshow:false,
            // })

            // this.refCashCard.current.clear()
        }
        // consolelog(e)
        if(e[0].sssNumber === ""|| e[0].sssNumber === "00-0000000-0") {
            this.setState ({ isCheckSSS : false, disablesss : false, sssNumber : "" })
        }
        else{
            this.setState ({ isCheckSSS : true, disablesss : true })
        }
        if(e[0].tinNumber === "" || e[0].tinNumber === "000-000-000-000" || e[0].tinNumber === "0__-___-___-___" || e[0].tinNumber === "000-000-000-0  " ) {
            this.setState ({ isCheckTIN : false, disabletin : false, tinNumber : "" })
        }
        else{
            this.setState ({ isCheckTIN : true, disabletin : true })
        }
        if( e[0].phicNumber === ""  || e[0].phicNumber === "0___-____-____" || e[0].phicNumber === "00-000000000-0" || e[0].phicNumber === "0 -         - ") {
            this.setState ({ isCheckPHIC : false, disablephic : false, phicNumber : ""  })
        }
        else{
            this.setState ({ isCheckPHIC : true, disablephic : true })
        }
        if(e[0].hdmfNumber === "" || e[0].hdmfNumber === "0___-____-____" || e[0].hdmfNumber === "0000-0000-0000" || e[0].hdmfNumber === "0   -    -    ") {
            this.setState ({ isCheckHDMF : false, disablehdmf : false, hdmfNumber : "" })
        }
        else{
            this.setState ({ isCheckHDMF : true, disablehdmf : true })
        }

       /*  if(this.state.payCardTypeId === ""){
            this.setState({ isCheckCashCard : false })
        } */
        if( this.state.payCardTypeId === "2"){
            // // // consolelog("if")
            this.setState({ isRadioCashcard : true, isCheckRemittance : "1" })

        }
        else{
            this.setState({ isRadioCashcard : false })
        };

        this.GetClearingClient(this.state.profileId, this.state.employeeId);
    };
    GetClearingClient = (profileId,employeeId) => {
        this.setState({isloading:true})
        let params = {
            "IpAddress"    :   "0.0.0.0",
            "ClientId"     :   this.state.userinfo.clientId,
            "UserId"       :   this.state.userinfo.userId,
            "ProfileId"    :   profileId,
            "EmployeeId"   :   employeeId
        }
        // // consolelog("params")
        // // consolelog(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearingClient",  params)
        .then(res => {
            let data = res.data.employeeHistory
            console.log("GetClearingClient")
            console.log(data)
            this.setState({
            memberDDL        :   data,
                isloading           :   false,
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    };
    GetClearanceforChecking() {

        // let isCheck = ""
        let param = {"IpAddress"    :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "FromDateCreated"       :   "",
            "ToDateCreated"         :   "",
            "FromClearanceDate"     :   "",
            "ToClearanceDate"       :   "",
            "FromDateReceive"       :   "",
            "ToDateReceive"         :   "",
            "FromLastWorkingDate"   :   "",
            "ToLastWorkingDate"     :   "",
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            // consolelog("res.data.clearance")
            // consolelog(data)
            this.setState({
                dataList    : data,
                isloading   : false,
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };









    handleChangedReason = (e) => {
        //this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        // this.setState({ reason: e.target.value,isshow:false, });
        if(e.length == 0) {
            this.state.reasonId=""
            this.state.reason=""
            return
        }
        this.state.reasonId = e[0].id
        this.state.reason = e[0].name
        // if (this.state.reasonId === "1"){
        //     this.setState ({ reasontmp : true })
        // }else{
        //     this.setState ({ reasontmp : false })
        // }
    };
    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false})
    };
    handleChangeClearanceDate = date =>{
        this.setState({ clearanceDate: date, isshow:false})
    };
    handleChangeSubmissionDate = date =>{
        this.setState({ submissionDate: date, isshow:false})
    };
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false})
    };
    handleChangedNotes = (e) =>{
        this.setState({ memberNotes : e.target.value.toUpperCase()})
    };
    onchangeCheckSSS (e){
        // this.setState({ isCheckSSS : e.target.checked })
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckSSS: true, disabledTmp : false })
        } else {
            this.setState({  isCheckSSS: false,disabledTmp: true })
        }
    };
    onchangeCheckSttldWdDole(e){
        this.setState({ [e.target.name] : e.target.checked })
    };
    onchangeCheckTIN (e){
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckTIN: true, disabledTmp1 : false })
        } else {
            this.setState({ isCheckTIN: false, disabledTmp1: true })
        }
    };
    onchangeCheckPHIC = (e) =>{
        // this.setState({ isCheckPHIC : e.target.checked })
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckPHIC: true, disabledTmp2 : false })
        } else {
            this.setState({ isCheckPHIC: false,disabledTmp2: true })
        }
    };
    onchangeCheckHDMF = (e) =>{
        // this.setState({ isCheckHDMF : e.target.checked })
        this.setState({ [e.target.name] : e.target.checked })
        if ( e.target.checked === true){
            this.setState({ isCheckHDMF: true, disabledTmp3 : false })
        } else {
            this.setState({ isCheckHDMF : false,disabledTmp3: true })
        }
    };
    onchangeCheckCashCard(e) {
        // // // consolelog("onchangeCheckCashCard")
        // // // consolelog(e.target.checked)
        this.setState({ [e.target.name] : e.target.checked })
        // if(e.target.checked === true){
        //     // // // consolelog('if')
        //     this.setState({
        //         accountNumber : this.state.payCardNumberTmp,
        //         cashCardType : this.state.payCardTypeTmp,
        //         payCardTypeId : this.state.payCardTypeIdTmp,
        //     })
        // }else {
        //     // // // consolelog('else')
        //     this.setState({
        //         payCardNumber : "",
        //         payCardType : "",
        //         payCardTypeId : "",
        //     })
        //     this.refCashCard.curremt.clear()
        // }

    };
    onchangeCheckValidId = (e) =>{
        this.setState({ isCheckValidId : e.target.checked })
    };
    onchangeCheckResignation = (e) =>{
        this.setState({ isCheckResignation : e.target.checked })
    };
    onchangeCheckTurnover = (e) =>{
        this.setState({ isCheckTurnover : e.target.checked })
    };
    onchangeCheckRequestForm = (e) =>{
        this.setState({ isCheckRequestForm : e.target.checked })
    };
    onchangeCheckParamountId = (e) =>{
        this.setState({ isParamountId : e.target.checked })
    };
    onChangeSSS = (e) =>{
        this.setState({ sssNumber : e.target.value })
    };
    onChangeTinNumber = (e) =>{
        this.setState({ tinNumber : e.target.value })
    };
    onChangePhicNumber = (e) =>{
        this.setState({ phicNumber : e.target.value })
    };
    onChangeHdmfNumber = (e) =>{
        this.setState({ hdmfNumber : e.target.value })
    };
    handleChangeDropdown = (e) =>{
        if(e.length === 0){
            this.state.cashCardType=""
            this.state.disablacctno = true
            return
        }
        // console.log("this.state.payCardTypeIdTmp")
        // console.log(this.state.payCardTypeIdTmp)
        console.log("e[0]")
        console.log(e[0])
        if(e[0].id==="15" || e[0].id === "4" || e[0].id === "8" || e[0].id === "1"){
            this.state.payCardTypeId = e[0].id
            this.state.cashCardType = e[0].name
            this.setState({
                disablacctno : true,
                selectpaycard: "1"
            })
        }
        else{
            if(e[0].id===this.state.payCardTypeIdTmp){
                console.log("if")
                this.setState({
                    payCardTypeId : this.state.payCardTypeIdTmp,
                    cashCardType : this.state.payCardTypeTmp,
                    accountNumber : this.state.payCardNumberTmp,
                    disablacctno : false,
                    selectpaycard: "1"
                })
            }
            else{
                console.log("else")
                this.setState({
                    payCardTypeId : e[0].id,
                    cashCardType : e[0].name,
                    accountNumber : "",
                    disablacctno : false,
                    selectpaycard: "1"
                })
            }
        }
    };
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    };
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
                theEvent.returnValue = false;
                if(theEvent.preventDefault) theEvent.preventDefault();
        }
    };
    handleChangeAccountNumber = (e) =>{
        // this.setState({ accountNumber : e.target.value, })
        // // consolelog( e.target.value )
        let re = /^[0-9\b]+$/
        if (e.target.value === "" || re.test(e.target.value)){
            this.setState({ accountNumber: e.target.value });
        }
    };
    handleRadioCashcard =(e) =>{
        this.setState({ isRadioCashcard: e.target.checked })
        //// // consolelog(e.target.checked)
        if(e.target.checked === true){
            this.setState({
                cashCardField : false,
                isRadioCheck : false,
                isRadioMlhuillier : false,
                isRadioPalawan : false,
                isRadioCash : false,
                isCheckRemittance : "1",
                isRadioCash : false,
            })
        }else {
            this.setState({
                cashCardField : true,
                isRadioCheck : true,
                isRadioMlhuillier : true,
                isRadioPalawan : true,
                isRadioCash : true,
                isCheckRemittance : "1",
                // payCardTypeId : "",
                // cashCardType : "",
                // accountNumber : "",
            })
            this.refCashCard.current.clear()
        }
    };
    handleRadioCheck = (e) =>{
        this.setState({ isRadioCheck : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioMlhuillier : false,
                isRadioPalawan : false,
                accountNumber: "",
                cashCardId: "",
                payCardType : "",
                isCheckRemittance : "1",
                isCashcardCheck : "0",
                // isRadioCash : false,
                cashCardType:"CHECK",
                isRadioCash : false,
                selectpaycard : "0"
            })
            this.refCashCard.current.clear()
        }
    };
    handleRadioMlhuillier = (e) =>{
        this.setState({ isRadioMlhuillier : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioCheck : false,
                isRadioPalawan : false,
                accountNumber: "",
                cashCardId: "",
                payCardTypeId : "4",
                isCheckRemittance : "1",
                isCashcardCheck : "0",
                isRadioCash : false,

            })
            this.refCashCard.current.clear()
        }
    };
    handleRadioPalawan = (e) =>{
        this.setState({ isRadioPalawan : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioCheck : false,
                isRadioMlhuillier : false,
                accountNumber: "",
                cashCardId: "",
                payCardTypeId : "15",
                isCheckRemittance : "1",
                isCashcardCheck : "0",
                isRadioCash : false,
            })
            this.refCashCard.current.clear()
        }
    };
    handleRadioCashAmount = (e) =>{
        this.setState({ isRadioCash : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                cashCardField : true,
                isRadioCashcard : false,
                isRadioCheck : false,
                isRadioMlhuillier : false,
                isRadioPalawan : false,
                accountNumber: "",
                cashCardId: "",
                isCheckRemittance : "1",
                isCashcardCheck : "0",
                cashCardType:"CASH",
                selectpaycard : "0"
            })
            this.refCashCard.current.clear()
        }
    };
    handleRadioEligible= (e) =>{
        // // consolelog("handleRadioEligible")
        // // consolelog(e.target.checked)
        this.setState({ eligible : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                notEligible : false
            })
            // this.refCashCard.current.clear()
        }
    };
    handleRadioNotEligible= (e) =>{
        // // consolelog("handleRadioNotEligible")
        // // consolelog(e.target.checked)
        this.setState({ notEligible : e.target.checked })
        if(e.target.checked === true){
            this.setState({
                eligible : false
            })
            // this.refCashCard.current.clear()
        }
    };
    handleChangedMemeberRemarks = (e) =>{
        this.setState({ memberRemarks : e.target.value.toUpperCase()})
    };
    handleChangedClientRemarks = (e) =>{
        this.setState({ clientRemarks : e.target.value.toUpperCase()})
    };
    handleChangedLrdRemarks = (e) =>{
        this.setState({ lrdRemarks : e.target.value.toUpperCase()})
    };
    handleChangedRmtRemarks = (e) =>{
        this.setState({ rmtRemarks : e.target.value.toUpperCase()})
    };
    handleChangedServicesRemarks = (e) =>{
        this.setState({ servicesRemarks : e.target.value.toUpperCase()})
    };


    handleClickSave = async() => {
        this.setState({isloading:true})

        // // consolelog("_checkDataifExist")
        // // consolelog(_checkDataifExist)

        let date = (this.state.dateNow) ? moment(this.state.dateNow).format('MM/DD/YYYY') : ""
        let dateCleared = moment(this.state.dateCreated).format('MM/DD/YYYY')
        let resignationDate = (this.state.resignationDate) ? moment(this.state.resignationDate).format('MM/DD/YYYY') : ""
        let clearanceDate = (this.state.clearanceDate) ? moment(this.state.clearanceDate).format('MM/DD/YYYY') : ""
        let submissionDate = (this.state.submissionDate) ? moment(this.state.submissionDate).format('MM/DD/YYYY') : ""
        let workingDate = (this.state.workingDate) ? moment(this.state.workingDate).format('MM/DD/YYYY') : ""
        let dateRecieved = (this.state.dateRecieved) ? moment(this.state.dateRecieved).format('MM/DD/YYYY') : ""
        let dateforwarded = (this.state.dateForwarded) ? moment(this.state.dateForwarded).format('MM/DD/YYYY') : ""

        let arrLst = []
        this.state.memberDDL.map(function(itm,i) {
            if(itm.isSelected === "1"){
                arrLst.push({
                    "ClearingId"        : itm.id,
                    "ProfileId"         : itm.profileId,
                    "CompanyName"       : itm.companyName,
                    "BranchName"        : itm.branchName,
                    "Position"          : itm.position,
                    "SalaryRate"        : itm.salaryRate,
                    "PeriodCovered"     : itm.periodCovered,
                    "ReasonForLeaving"  : itm.reasonForLeaving,
                    "ContractDateStart" : (itm.contractDateStart) ? moment(itm.contractDateStart).format('MM/DD/YYYY') : "",
                    "ContractDateEnd"   : (itm.contractDateEnd) ? moment(itm.contractDateEnd).format('MM/DD/YYYY') : "",
                    "DateHired"         : (itm.dateHired) ? moment(itm.dateHired).format('MM/DD/YYYY') : "",
                    "DateResigned"      : (itm.dateResigned) ? moment(itm.dateResigned).format('MM/DD/YYYY') : "",
                })
            }
        })
        if(this.state.clientId === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select client.",
                fade            :   true
            })
            return
        };
        if(this.state.employeeId === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select employee name.",
                fade            :   true
            })
            return
        };
        if(this.state.reason === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select reason for leaving.",
                fade            :   true
            })
            return
        };
        if(this.state.clearanceDate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select clearance date.",
                fade            :   true
            })
            return
        };
        if(this.state.submissionDate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select date recieve from member.",
                fade            :   true
            })
            return
        };
        if(this.state.workingDate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select working date.",
                fade            :   true
            })
            return
        };
        if(arrLst.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select clearing client!",
                fade            :   true
            })
            return
        };
        if(this.state.isCheckRemittance === "0"){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select mode of remittance.",
                fade            :   true
            })
            return

        };
        if (this.state.isCashcardCheck === "1") {
            if(this.state.selectpaycard === "0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please select paycard",
                    fade            :   true
                })
                return
            }
            if(this.state.payCardTypeId === ""){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Please select paycard",
                    fade            :   true
                })
                return
            }
            if(this.state.payCardTypeId === "2" ){
                if(this.state.accountNumber.length !== 16){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "BDO Account number invalid.",
                        fade            :   true
                    })
                    return
                }
            }
            else if(this.state.payCardTypeId === "6"){
                if(this.state.accountNumber.length !== 13){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "Metrobank Account number invalid.",
                        fade            :   true
                    })
                    return
                }
            }
            else if (this.state.payCardTypeId === "14"){
                if(this.state.accountNumber.length !== 12){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "Union Bank Account number invalid.",
                        fade            :   true
                    })
                    return
                }
            }
        }
        let _checkDataifExist = await this.CheckDataIfExist(this.state.employeeId)
        if (_checkDataifExist === true){
            // consolelog("update existing data")
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Employee already exists.",
                fade            :   true
            })
            return
        } 
        else {
            // consolelog("create new data")
            let param = {

                "IpAddress":"0.0.0.0",
                "UserId":this.state.userinfo.userId,
                "ClientId":this.state.clientId,
                "ProfileId":this.state.profileId,
                "PayPeriodId":this.state.payPeriodId,
                "EmployeeId":this.state.employeeId,
                "DateCreated":dateCleared,
                "ClearanceDateMember":clearanceDate,
                "ResignationDateMember":this.state.reason==="DOLE CASES"?date:resignationDate,
                "SubmissionDateMember":submissionDate , // date recieve from member
                "LastWorkingDateMember":workingDate ,
                "DateSubmitted":"",
                "IsSSSNumberMember":this.state.isCheckSSS === true ? "1" : "0",
                "IsTINNumberMember":this.state.isCheckTIN === true ? "1" : "0",
                "IsPHICNumberMember":this.state.isCheckPHIC === true ? "1" : "0",
                "IsHDMFNumberMember":this.state.isCheckHDMF === true ? "1" : "0",
                "IsCashCardMember":this.state.isCheckCashCard === true ? "1" : "0",
                "IsValidIdMember":this.state.isCheckValidId === true ? "1" : "0",
                "IsResignationLetterMember":this.state.isCheckResignation === true ? "1" : "0",
                "IsTurnoverChecklistMember":this.state.isCheckTurnover === true ? "1" : "0",
                "IsCOERequestFormMember":this.state.isCheckRequestForm === true ? "1" : "0",
                "IsTypeOfCashCardMember":this.state.isRadioCashcard === true ? "1" : "0" , // cash card mode of payment
                "PaycardTypeIdMember":this.state.payCardTypeId,
                "TypeOfCashCardMember":this.state.cashCardType,
                "CashCardNumberMember":this.state.accountNumber,
                "IsCheckMember":this.state.isRadioCheck === true ? "1" : "0"  ,
                "IsMlhuillierMember":this.state.isRadioMlhuillier === true ? "1" : "0",
                "IsPalawanMember":this.state.isRadioPalawan === true ? "1" : "0",
                "RemarksMember":this.state.memberRemarks,
                "NotesMember":this.state.memberNotes,
                //"ClearingClient":this.state.memberDDL,
                "ReasonForLeaving":this.state.reason,
                "DateForwardedToAmt": dateforwarded,
                "DateReceivedFromAmt": dateRecieved,
                "IsEligible":"0",
                "IsNotEligible":"0",
                "SssNumber": this.state.sssNumber,
                "TinNumber": this.state.tinNumber,
                "PhicNumber": this.state.phicNumber,
                "HdmfNumber": this.state.hdmfNumber,

                "IsClearedClient":"0"    ,
                "IsEligibleClient":"0"    ,
                "IsNonEligibleClient":"0"    ,
                "RemarksClient":""    ,

                "IsClearedRmt":"0"    ,
                "IsReturnedRmt":"0"  ,
                "ValidateByRmt":"0"  ,
                "IsForwardToARERmt":"0"   ,
                "IsRetrunedServicesRmt":"0" ,
                "IsCheckedByRmt":"0" ,
                "RemarksRmt":"" ,

                "RemarksLrd":"" ,
                "ServicesRemarks":this.state.servicesRemarks ,

                "IsClearanceStatus": this.state.userinfo.userId === "34024" || this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.firstName === "TEAMLEAD02" ? "2" : "0",
                "clearanceDetails" :arrLst,

                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":this.state.userinfo.userId,
                "DateUpdatedByLRD":"",
                "UpdatedByLRD":"",
                "DateUpdatedByRMT":"",
                "UpdatedByRMT":"",
                "DateUpdatedByServices":"",
                "UpdatedByServices":"",
                "DateUpdatedByARE":"",
                "UpdatedByARE":"",
                "DateUpdatedByFinance":"",
                "UpdatedByFinance":"",


                "DateReturnedByClient":"",
                "ReturnedByClient":"",
                "DateReturnedByLRD":"",
                "ReturnedByLRD":"",
                "DateReturnedByRMT":"",
                "ReturnedByRMT":"",
                "DateReturnedByServices":"",
                "ReturnedByServices":"",
                "DateReturnedByARE":"",
                "ReturnedByARE":"",
                "DateReturnedByFinance":"",
                "ReturnedByFinance":"",
                "DateSubmittedByStaff":"",
                "SubmittedByStaff":"",
                "DateSubmittedByLead":"",
                "SubmittedByLead":"",

                "BatchNumber": "",

                // 13/06/2023 additional
                "DateSettle"        : "",
                "IsSettleWithDole"  :  "0",
                "TotalBalance" : "",
                "Submitted" : "",
                "servicesAttachments" : [],
                "IsCash":  this.state.isRadioCash === true ? "1" : "0",
                "ClosingRemarks" : "NOT CLEARED",
                // 21/07/2023
                "ParamountId" : this.state.isParamountId === true ? "1" : "0",
                "LastSalary" : "",
                "Incentives" : "",
                "Sil" : "",
                "ThirteenthMonth" : "",
                "Inclusions" : "",
                "GrossPay" : "",
                "NetPay" : "",
                "PeriodCovered" : "",
                "IsModifiedByARE" : "0",
                "SeparationPay" : "",
                "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : "",
                "PayrollRemarks" : "",
                "FinanceRemarks" : "",
                "IsClearedSubmit" : "0",
                "IsGenerated" : "0",
                "IsFinalized" : "0",
                "DateResigned" : this.state.dateResigned,
                "DateHired" : this.state.dateHired,
                'BatchPayroll' : "",
                "Adjustment" : "",
                "OtherPayroll" : "",
                "CompletionDate" : "",
                "TotalInclusion" : "",
                "Cbu" : "",
                "Dividend" : "",
                "IsParent" : "NO",
                "IsChild" : "NO",
                "ParentId" : "",
                "DateSubmittedToPayroll" : "",
                "IsAre" : "0",
                "IsPayroll" : "0",
                "LastModifiedBy" : this.state.userinfo.fullName

                /* END */
            }
            console.log("add param")
            console.log(param)
            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/AddClearance",  param)
            .then(res => {
                let data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    disabledaftrsave    :   true
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        }
    };
    UpdateSubmitifExist = async() =>{
        this.setState({isloading:true})
        let date = (this.state.date) ? moment(this.state.date).format('MM/DD/YYYY') : ""
        let dateCleared = (this.state.dateCreated) ? moment(this.state.dateCreated).format('MM/DD/YYYY') : ""
        let resignationDate = (this.state.resignationDate) ? moment(this.state.resignationDate).format('MM/DD/YYYY') : ""
        let clearanceDate = (this.state.clearanceDate) ? moment(this.state.clearanceDate).format('MM/DD/YYYY') : ""
        let submissionDate = (this.state.submissionDate) ? moment(this.state.submissionDate).format('MM/DD/YYYY') : ""
        let workingDate = (this.state.workingDate) ? moment(this.state.workingDate).format('MM/DD/YYYY') : ""
        let dateSubmitted = (this.state.dateSubmitted) ? moment(this.state.dateSubmitted).format('MM/DD/YYYY') : ""
        let dateRecieved = (this.state.dateRecieved) ? moment(this.state.dateRecieved).format('MM/DD/YYYY') : ""
        let dateforwarded = (this.state.dateForwarded) ? moment(this.state.dateForwarded).format('MM/DD/YYYY') : ""
        let arrLst = []
        if(this.state.isClearanceStatus === "1"){
            this.state.clientTabDDL.map(function(itm,i) {
                arrLst.push({
                    "ClearingId"        : itm.id,
                    "ProfileId"         : itm.profileId,
                    "CompanyName"       : itm.companyName,
                    "BranchName"        : itm.branchName,
                    "Position"          : itm.position,
                    "SalaryRate"        : itm.salaryRate,
                    "PeriodCovered"     : itm.periodCovered,
                    "ReasonForLeaving"  : itm.reasonForLeaving,
                })
            })
        }else {
            this.state.memberDDL.map(function(itm,i) {
                if(itm.isSelected === "1"){
                    arrLst.push({
                        "ClearingId"        : itm.id,
                        "ProfileId"         : itm.profileId,
                        "CompanyName"       : itm.companyName,
                        "BranchName"        : itm.branchName,
                        "Position"          : itm.position,
                        "SalaryRate"        : itm.salaryRate,
                        "PeriodCovered"     : itm.periodCovered,
                        "ReasonForLeaving"  : itm.reasonForLeaving,
                    })
                }
            })
        }
        if(arrLst.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select clearing client!",
                fade            :   true
            })
            return
        }
        // let ClearanceStatus = ""
        // if(this.state.isClearanceStatus === "1"){
        //     if (this.state.reason === "Dole Cases"){
        //         ClearanceStatus = "3"
        //     }else {
        //         ClearanceStatus = "4"
        //     }
        // }else {
        //     ClearanceStatus = String(parseFloat(this.state.isClearanceStatus)  + 1)
        // }
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            // "FromDateCreated"       :   "",
            // "ToDateCreated"         :   "",
            // "FromClearanceDate"     :   "",
            // "ToClearanceDate"       :   "",
            // "FromDateReceive"       :   "",
            // "ToDateReceive"         :   "",
            // "FromLastWorkingDate"   :   "",
            // "ToLastWorkingDate"     :   "",
            "clearance" : [
                {
                    "Id" : this.state.clearanceId,
                    "ClientId":this.state.clientId,
                    "ProfileId":this.state.profileId,
                    "EmployeeId":this.state.employeeId,
                    "DateCreated":dateCleared,
                    "ClearanceDateMember":clearanceDate,
                    "ResignationDateMember":resignationDate,
                    "SubmissionDateMember":submissionDate ,
                    "LastWorkingDateMember":workingDate ,
                    "DateSubmitted":moment(new Date()).format('MM/DD/YYYY'),
                    "IsSSSNumberMember":this.state.isCheckSSS === true ? "1" : "0",
                    "IsTINNumberMember":this.state.isCheckTIN === true ? "1" : "0",
                    "IsPHICNumberMember":this.state.isCheckPHIC === true ? "1" : "0",
                    "IsHDMFNumberMember":this.state.isCheckHDMF === true ? "1" : "0",
                    "IsCashCardMember":this.state.isCheckCashCard === true ? "1" : "0",
                    "IsValidIdMember":this.state.isCheckValidId === true ? "1" : "0",
                    "IsResignationLetterMember":this.state.isCheckResignation === true ? "1" : "0",
                    "IsTurnoverChecklistMember":this.state.isCheckTurnover === true ? "1" : "0",
                    "IsCOERequestFormMember":this.state.isCheckRequestForm === true ? "1" : "0",
                    "IsTypeOfCashCardMember":this.state.isRadioCashcard === true ? "1" : "0" , // cash card mode of payment
                    "PaycardTypeIdMember":"0",
                    "TypeOfCashCardMember":this.state.cashCardType,
                    "CashCardNumberMember":this.state.accountNumber,
                    "IsCheckMember":this.state.isRadioCheck === true ? "1" : "0"  ,
                    "IsMlhuillierMember":this.state.isRadioMlhuillier === true ? "1" : "0",
                    "IsPalawanMember":this.state.isRadioPalawan === true ? "1" : "0",
                    "RemarksMember":this.state.memberRemarks,
                    "NotesMember":this.state.memberNotes,
                    //"ClearingClient":this.state.memberDDL,
                    "ReasonForLeaving":this.state.reason,
                    "DateForwardedToAmt": dateforwarded,
                    "DateReceivedFromAmt": dateRecieved,
                    "IsEligible":this.state.eligible === true ? "1" : "0",
                    "IsNotEligible":this.state.notEligible === true ? "1" : "0",
                    "SssNumber": this.state.sssNumber,
                    "TinNumber": this.state.tinNumber,
                    "PhicNumber": this.state.phicNumber,
                    "HdmfNumber": this.state.hdmfNumber,

                    "IsClearedClient":"0"    ,
                    "IsEligibleClient":"0"    ,
                    "IsNonEligibleClient":"0"    ,
                    "RemarksClient":this.state.clientRemarks,

                    "IsClearedRmt":"0"    ,
                    "IsReturnedRmt":"0"  ,
                    "ValidateByRmt":"0"  ,
                    "IsForwardToARERmt":"0"   ,
                    "IsRetrunedServicesRmt":"0" ,
                    "IsCheckedByRmt":"0" ,
                    "RemarksRmt":this.state.rmtRemarks,

                    "RemarksLrd":this.state.lrdRemarks,
                    "ServicesRemarks":this.state.servicesRemarks ,

                    "IsClearanceStatus": "0",
                    "clearanceDetails" :arrLst,
                }
            ]
        }
        // // consolelog("Update Submit param")
        // // consolelog(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   "Clearance successfully updated",
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    CheckDataIfExist(itemListCheck) {

        // // consolelog('this.state.employeeId')
        // // consolelog(this.state.employeeId)
        let isSubmit = typeof this.state.dataList === "undefined" ? [] : this.state.dataList
        // // consolelog('isSubmit')
        // // consolelog(isSubmit.length < 0)
        let _fltrExistingData = isSubmit.filter( x => x.employeeId === itemListCheck)
        let isExists = _fltrExistingData.length === 0 ? false : true
        // // consolelog('_fltrExistingData.length')
        // // consolelog(_fltrExistingData.length)
        // // consolelog('isExists')
        // // consolelog(isExists)
        return isExists

    };


    handleSearchClick = async() => {
        // // consolelog("this.state.memberDDL")
        // // consolelog(this.state.memberDDL)
        if(this.state.clientId === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select client.",
                fade            :   true
            })
            return
        }
        if(this.state.employeeId === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select employee.",
                fade            :   true
            })
            return
        }
        this.setState({isloading:true})
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   "",
            "FromDateCreated"       :   "",
            "ToDateCreated"         :   "",
            "FromClearanceDate"     :   "",
            "ToClearanceDate"       :   "",
            "FromDateReceive"       :   "",
            "ToDateReceive"         :   "",
            "FromLastWorkingDate"   :   "",
            "ToLastWorkingDate"     :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            // // consolelog("GetProfileClearance")
            // // consolelog(data)
            this.setState({
                dataList    : data,
                isloading   : false,
            });
            data.forEach(itm => {
                // consolelog('itm')
                // consolelog(itm)
                this.setState({
                    clearanceId : itm.id,
                    dateCreated : itm.dateCreated,
                    dateSubmitted : itm.dateSubmitted,
                    clearanceStatus : itm.clearanceStatus,
                    memberNotes : itm.notesMember,
                    cashCardType : itm.typeOfCashCardMember,
                    accountNumber : itm.cashCardNumberMember,
                    memberRemarks : itm.remarksMember,
                    reason : itm.reasonForLeaving,
                    lrdRemarks : itm.remarksLrd,
                    phicNumber : itm.phicNumber,
                    tinNumber : itm.tinNumber,
                    sssNumber : itm.sssNumber,
                    hdmfNumber : itm.hdmfNumber,
                    rmtRemarks : itm.remarksRmt,
                    // clearanceDate : itm.clearanceDateMember === ""? "" : new Date(itm.clearanceDateMember),
                    dateForwarded : itm.dateForwardedToAmt === "" ? "" : new Date(itm.dateForwardedToAmt),
                    dateRecieved : itm.dateReceivedFromAmt === "" ? "" : new Date(itm.dateReceivedFromAmt),
                    isClearanceStatus : itm.isClearanceStatus,
                    cycleDays : itm.cycleDays,
                })
                if(itm.isClearanceStatus === "1"){
                    this.setState({
                        disabledButtonMember:false,
                        clearanceStatus : "CMW",
                        defaultkey : "1",
                        isCheckRemittance : "1",

                    })
                }
                if(itm.isClearanceStatus === "2"){
                    this.setState({
                        disabledCleintButton:false,
                        clientTabDDL : itm.clearanceDetails,
                        returnStatus : "1",
                        defaultkey : "2",
                        isCheckRemittance : "1",
                    })
                }else{
                    this.setState({
                        disabledCleintButton:true,
                        clientTabDDL:[],
                    })
                }
                if(itm.isClearanceStatus === "3"){
                    this.setState({
                        disabledLrdButton:false,
                        lrdTabDDL : itm.clearanceDetails,
                        defaultkey : "2",
                        isCheckRemittance : "1"
                    })
                }else{
                    this.setState({
                        disabledLrdButton:true,
                        lrdTabDDL:[],
                    })
                }
                if(itm.isClearanceStatus === "4"){
                    this.setState({
                        disabledRmtButton:false,
                        rmtDDL:itm.clearanceDetails,
                        defaultkey : "4",
                        isCheckRemittance : "1"

                    })
                }else{
                    this.setState({
                        disabledRmtButton:true,
                        rmtDDL:[],
                    })
                }
                if(itm.isClearanceStatus === "5"){
                    this.setState({
                        disabledServicesButton:false,
                        serviceTabDDL:itm.clearanceDetails,
                        defaultkey : "5",
                        isCheckRemittance : "1"
                    })
                }else{
                    this.setState({
                        disabledServicesButton:true,
                        serviceTabDDL:[],
                    })
                }
                if(itm.resignationDateMember === ""){
                    this.setState({
                        resignationDate : ""
                    })
                }else {
                    this.setState({
                        resignationDate : new Date(itm.resignationDateMember)
                    })
                }
                if(itm.clearanceDateMember === ""){
                    this.setState({
                        clearanceDate : ""
                    })
                }else {
                    let startDate  = moment(itm.clearanceDateMember).format('MM/DD/YYYY');;
					let endDate  = moment(this.state.newDate).format('MM/DD/YYYY');
					let diffInMs   = new Date(endDate) - new Date(startDate)
					let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                    this.setState({
                        clearanceDate : new Date(itm.clearanceDateMember),
                        daysCount : diffInDays
                    })
                }
                if(itm.submissionDateMember === ""){
                    this.setState({
                        submissionDate : "",
                        daysCount : '0'
                    })
                }else {
                    this.setState({
                        submissionDate : new Date(itm.submissionDateMember)
                    })
                }
                if(itm.dateForwardedToAmt === ""){
                    this.setState({
                        dateForwarded : ""
                    })
                }else {
                    this.setState({
                        dateForwarded : new Date(itm.dateForwardedToAmt)
                    })
                }
                if(itm.dateReceivedFromAmt === ""){
                    this.setState({
                        dateRecieved : ""
                    })
                }else {
                    this.setState({
                        dateRecieved : new Date(itm.dateReceivedFromAmt)
                    })
                }
                if(itm.lastWorkingDateMember === ""){
                    this.setState({
                        workingDate : ""
                    })
                }else {
                    this.setState({
                        workingDate : new Date(itm.lastWorkingDateMember)
                    })
                }
                if(itm.isCashCardMember === "1"){
                    this.setState({
                        isCheckCashCard : true
                    })
                }else {
                    this.setState({
                        isCheckCashCard : false
                    })
                }
                if(itm.isValidIdMember === "1"){
                    this.setState({
                        isCheckValidId : true
                    })
                }else {
                    this.setState({
                        isCheckValidId : false
                    })
                }
                if(itm.isResignationLetterMember === "1"){
                    this.setState({
                        isCheckResignation : true
                    })
                }else {
                    this.setState({
                        isCheckResignation : false
                    })
                }
                if(itm.isTurnoverChecklistMember === "1"){
                    this.setState({
                        isCheckTurnover : true
                    })
                }else {
                    this.setState({
                        isCheckTurnover : false
                    })
                }
                if(itm.isCOERequestFormMember === "1"){
                    this.setState({
                        isCheckRequestForm : true
                    })
                }else {
                    this.setState({
                        isCheckRequestForm : false
                    })
                }
                if(itm.isTypeOfCashCardMember === "1"){
                    this.setState({
                        isRadioCashcard : true,
                        cashCardField : false,
                    })
                }else {
                    this.setState({
                        isRadioCashcard : false,
                        cashCardField : true,
                    })
                }
                if(itm.isCheckMember === "1"){
                    this.setState({
                        isRadioCheck : true,
                    })
                }else {
                    this.setState({
                        isRadioCheck : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isPalawanMember === "1"){
                    this.setState({
                        isRadioPalawan : true,
                    })
                }else {
                    this.setState({
                        isRadioPalawan : false,
                    })
                }
                if(itm.isEligible === "1"){
                    this.setState({
                        eligible : true,
                    })
                }else {
                    this.setState({
                        eligible : false,
                    })
                }
                if(itm.isNotEligible === "1"){
                    this.setState({
                        notEligible : true,
                    })
                }else {
                    this.setState({
                        notEligible : false,
                    })
                }
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleUploadClick = async() =>{
        // alert('test')
        let _selectedFiles = this.state.attachedDDL

        // START AWS UPLOADING **

        // let s3config ={
        //     s3_bucket : "myawsbucket-nosertech",
        //     REGION : "ap-southeast-2",
        //     accessKeyId: "romel",
        //     secretAccessKey: "Kanotix199607!",
        // }

        // S3FileUpload.uploadFile(this.state.files, s3config)
        // .then( data => {
        //     // consolelog('data')
        //     // consolelog(data)
        // })
        // .catch ( error => {
        //     // consolelog('error')
        //     // consolelog(error)
        // })
        // // // consolelog('config')
        // // // consolelog(config)
        // // // consolelog('accessKeyId')
        // // // consolelog(accessKeyId)
        // // // consolelog('secretAccessKey')
        // // // consolelog(secretAccessKey)
        // // // consolelog('s3_bucket')
        // // // consolelog(s3_bucket)
        // // // consolelog('REGION')
        // // // consolelog(REGION)
        // // // consolelog('awsconfig')
        // // // consolelog(awsconfig)
        // // // consolelog('s3')
        // // // consolelog(s3)

        // END AWS UPLOADING **

        /* UPLOADING CODE WORKING BELOW */

        let newData = {
            'id' : String(parseFloat(this.state.attachedDDL.length + 1)),
            'fileName' : this.state.files,
            'isDeleted' : "0",
            'isModified' : "0",
        }
        _selectedFiles.push(newData)
        this.setState({ attachedDDL : _selectedFiles })

        // // consolelog('this.state.attachedDDL')
        // // consolelog(this.state.attachedDDL)
        this.fileInput = ""
    };
    handleDeleteClick = () => {

        let GridItemsContents = this.state.attachedDDL.filter(x=>x.isDeleted=="0")
        this.state.attachedDDL = []
        this.setState({attachedDDL:GridItemsContents})

    };
    handleChangeDateRecieved = (date) =>{
        this.setState({ dateRecieved : date })
    };
    handleChangeDateForwarded = (date) =>{
        this.setState({ dateForwarded : date })
    };
    handleTabOnSelect = (key) =>{
        // // // // console.log('selected' + key);
        this.setState({key: key});
    };


    onImageReplyChange(e) {
        this.setState({ files : this.fileInput.current.files[0].name })
    };



    render() {
        let MemberColumn = [
            {
                dataField: 'companyName',
                text: 'Client',
                editable : false,
                headerStyle : () => {
                    return { width  : "40%"};
                }
            },
            {
                dataField: 'periodCovered',
                text: 'Tenure',
                editable : false,
                headerStyle : () => {
                    return { width  : "40%"};
                }
            },
            {
                dataField: '',
                text: 'Status',
                editable : false,
                headerStyle : () => {
                    return { width  : "20%"};
                }
            },
        ];


        let memberSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.memberDDL.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isSelected = isSelect ? "1" : "0"
                    }
                })
            }
        };


        let attachedColumn = [
            {
                dataField: 'fileName',
                text: 'File',
                editable : false,
                headerStyle : () => {
                    return { width  : "100%"};
                }
            },
        ];

        
        let attachedSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.attachedDDL.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; CREATE </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div>
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLIENT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.client}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder='SELECT CLIENT'
                                            //selected={[this.state.clientName]}
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    EMPLOYEE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.employee}
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder='SELECT EMPLOYEE'
                                            //defaultSelected={[this.state.employeeName]}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        REASON FOR LEAVING
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            ref={this.refReason}
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedReason}
                                            options={this.state.reasonDDL}
                                            selected={[this.state.reason]}
                                            placeholder="SELECT REASON"

                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    RESiGNATION DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='resignationDate'
                                            selected={this.state.resignationDate}
                                            onChange={this.handleChangeResignationDate}
                                            minDate={this.minDate}
                                            value={this.props.resignationDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT RESIGNATION DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLEARANCE DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='clearanceDate'
                                            selected={this.state.clearanceDate}
                                            onChange={this.handleChangeClearanceDate}
                                            value={this.props.clearanceDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT CLEARANCE DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Date Created
                                    </Form.Label>
                                    <Col>
                                        <Form.Label className='mt-2' style={{fontWeight : "bold"}}>
                                            { (this.state.dateCreated) ? moment(this.state.dateCreated).format('MM/DD/YYYY') : ""}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    DATE RECEIVED FROM MEMBER
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='submissionDate'
                                            selected={this.state.submissionDate}
                                            onChange={this.handleChangeSubmissionDate}
                                            minDate={this.minDate}
                                            value={this.props.submissionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT SUBMISSION DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    LAST WORKING DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='workingDate'
                                            selected={this.state.workingDate}
                                            onChange={this.handleChangeWorkingDate}
                                            minDate={this.minDate}
                                            value={this.props.workingDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            // placeholder='SELECT LAST WORKING DATE'
                                            // wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Col>
                                        <ButtonToolbar className="mt-1" >
                                            <Button variant="info" /* className='float-right' */ className="ml-auto noser-button-mr1 noser-button" onClick={(e) => window.location.reload(true)}>
                                                NEW
                                            </Button>
                                            {/* <Button variant="danger" onClick={ this.handleCancelClick } className="noser-button-mr1 noser-button">
                                                CANCEL
                                            </Button>
                                            <Button disabled={this.state.disabledSearch} variant="primary" onClick={ this.handleSearchClick } className="noser-button-mr1 noser-button">
                                                SEARCH
                                            </Button> */}
                                        </ButtonToolbar>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    STATUS
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Label className='mt-2' style={{fontWeight : "bold", fontStyle : "italic", fontSize : "12px"}}>
                                            with &nbsp;&nbsp; {this.state.clearanceStatus} &nbsp;&nbsp; for {this.state.daysCount} day(s) now since { (this.state.dateSubmitted) ? moment(this.state.dateSubmitted).format('MM/DD/YYYY') : ""}
                                        </Form.Label>
                                    </Col>
                                    <Col sm="6">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    cycle days
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Label style={{fontWeight : "bold", /* color : "#FF3030", */ fontStyle : "italic", fontSize : "12px"}}>
                                            { this.state.cycleDays }
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Tabs className="mt-3" defaultActiveKey="default" transition={false} id="noanim-tab-example" onSelect = { this.handleTabOnSelect } /* disabled = {this.state.dsabldmember} */ >
                                    <Tab eventKey="default" title="MEMBER" activeKey = {this.state.key} >
                                        <Card>
                                            <Card.Body>
                                                <Card>
                                                    <Card.Header>EMPLOYEE DETAILS</Card.Header>
                                                    <Card.Body>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                                Last Name
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.lastName}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                                Branch / Location
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.locationName}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            First Name
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.firstName}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Postion
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.positionName}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Middle Name
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.middleName}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Start Date
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {(this.state.dateHired) ? moment(this.state.dateHired).format('MM/DD/YYYY') : ""}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            Client
                                                            </Form.Label>
                                                            <Col>
                                                                <Form.Label className='mt-2' style={{fontWeight : "bold", color : "#0000FF", fontStyle : "italic", fontSize : "12px"}}>
                                                                {this.state.client}
                                                                </Form.Label>
                                                            </Col>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                                <Card className='mt-5'>
                                                    <Card.Header>For Clearing Client</Card.Header>
                                                    <Card.Body>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.memberDDL }
                                                            columns = { MemberColumn }
                                                            // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                            cellEdit = { cellEditFactory({
                                                                mode: 'click',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            }
                                                            // rowEvents={ listOfTrainingRowEvents }
                                                            selectRow = { memberSelectRow }

                                                        />
                                                    </Card.Body>
                                                </Card>
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Col sm="2">
                                                        <Form.Label style={{fontWeight : "bold"}}>
                                                            date forwarded to amt
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            ref='dateForwarded'
                                                            selected={this.state.dateForwarded}
                                                            onChange={this.handleChangeDateForwarded}
                                                            minDate={this.minDate}
                                                            value={this.props.dateForwarded}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder='SELECT LAST WORKING DATE'
                                                            wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Col sm="2">
                                                        <Form.Label style={{fontWeight : "bold"}}>
                                                            date received from amt
                                                        </Form.Label>

                                                    </Col>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            ref='dateRecieved'
                                                            selected={this.state.dateRecieved}
                                                            onChange={this.handleChangeDateRecieved}
                                                            minDate={this.minDate}
                                                            value={this.props.dateRecieved}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder='SELECT LAST WORKING DATE'
                                                            wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Col sm="6">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            attachment (hardcopy)
                                                        </Form.Label>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={2}>
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckSSS"
                                                                    name='isCheckSSS'
                                                                    label="SSS"
                                                                    onChange={ e => this.onchangeCheckSSS(e) }
                                                                    checked = { this.state.isCheckSSS }
                                                                    value={ this.state.isCheckSSS }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckTIN"
                                                                    name='isCheckTIN'
                                                                    label="TIN"
                                                                    onChange={ e => this.onchangeCheckTIN(e) }
                                                                    checked = { this.state.isCheckTIN }
                                                                    value={ this.state.isCheckTIN }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckPHIC"
                                                                    name='isCheckPHIC'
                                                                    label="PhilHealth"
                                                                    onChange={ e => this.onchangeCheckPHIC(e)  }
                                                                    checked = { this.state.isCheckPHIC }
                                                                    value={ this.state.isCheckPHIC }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckHDMF"
                                                                    name='isCheckHDMF'
                                                                    label="PAGIBIG"
                                                                    onChange={ e => this.onchangeCheckHDMF(e)  }
                                                                    checked = { this.state.isCheckHDMF }
                                                                    value={ this.state.isCheckHDMF }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckCashCard"
                                                                    name='isCheckCashCard'
                                                                    label="Cash Card"
                                                                    onChange={ e =>this.onchangeCheckCashCard(e) }
                                                                    checked = { this.state.isCheckCashCard }
                                                                    value={ this.state.isCheckCashCard }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckValidId"
                                                                    name='isCheckValidId'
                                                                    label="Valid ID's (atleast 2)"
                                                                    onChange={ this.onchangeCheckValidId }
                                                                    checked = { this.state.isCheckValidId }
                                                                    value={ this.state.isCheckValidId }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckResignation"
                                                                    name='isCheckResignation'
                                                                    label="Resignation Letter"
                                                                    onChange={ this.onchangeCheckResignation }
                                                                    checked = { this.state.isCheckResignation }
                                                                    value={ this.state.isCheckResignation }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckTurnover"
                                                                    name='isCheckTurnover'
                                                                    label="Turnover Checklist"
                                                                    onChange={ this.onchangeCheckTurnover }
                                                                    checked = { this.state.isCheckTurnover }
                                                                    value={ this.state.isCheckTurnover }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isCheckRequestForm"
                                                                    name='isCheckRequestForm'
                                                                    label="COE Request Form"
                                                                    onChange={ this.onchangeCheckRequestForm }
                                                                    checked = { this.state.isCheckRequestForm }
                                                                    value={ this.state.isCheckRequestForm }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id="isParamountId"
                                                                    name='isParamountId'
                                                                    label="PARAMOUNT ID"
                                                                    onChange={ this.onchangeCheckParamountId }
                                                                    checked = { this.state.isParamountId }
                                                                    value={ this.state.isParamountId }
                                                                    className='mt-2 no-text-transform-li mt-3'
                                                                    style={{ fontWeight : "bold" }}
                                                                />
                                                            </Col>
                                                            <Col sm={4} className='mt-3'>
                                                                {/* <Form.Control
                                                                    ref="sssNumber"
                                                                    name="sssNumber"
                                                                    value={this.state.sssNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangeSSS}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disablesss}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="sssNumber"
                                                                    value={this.state.sssNumber}
                                                                    onChange={this.onChangeSSS}
                                                                    autoComplete="off"
                                                                    format="##-#######-#"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disablesss}
                                                                    style={{ textAlign : "right" }}
                                                                />
                                                                {/* <Form.Control
                                                                    ref="tinNumber"
                                                                    name="tinNumber"
                                                                    value={this.state.tinNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangeTinNumber}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disabletin}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="tinNumber"
                                                                    value={this.state.tinNumber}
                                                                    onChange={this.onChangeTinNumber}
                                                                    autoComplete="off"
                                                                    format="###-###-###-000"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disabletin}
                                                                    style={{ textAlign : "right" }}
                                                                />
                                                                {/* <Form.Control
                                                                    ref="phicNumber"
                                                                    name="phicNumber"
                                                                    value={this.state.phicNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangePhicNumber}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disablephic}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="phicNumber"
                                                                    value={this.state.phicNumber}
                                                                    onChange={this.onChangePhicNumber}
                                                                    autoComplete="off"
                                                                    format="##-#########-#"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disablephic}
                                                                    style={{ textAlign : "right" }}
                                                                />

                                                                {/* <Form.Control
                                                                    ref="hdmfNumber"
                                                                    name="hdmfNumber"
                                                                    value={this.state.hdmfNumber}
                                                                    style={{ textAlign : "right" }}
                                                                    onChange={this.onChangeHdmfNumber}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disablehdmf}
                                                                    className='mt-1'
                                                                /> */}
                                                                <NumberFormat
                                                                    name="hdmfNumber"
                                                                    value={this.state.hdmfNumber}
                                                                    onChange={this.onChangeHdmfNumber}
                                                                    autoComplete="off"
                                                                    format="####-####-####"
                                                                    className="form-control"
                                                                    // placeholder="Enter PAGIBIG Number"
                                                                    disabled={this.state.disablehdmf}
                                                                    style={{ textAlign : "right" }}
                                                                />
                                                            </Col>
                                                            <Col sm={1}>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        this.state.userinfo.firstName === "LDTEAMLEADO1" || this.state.userinfo.firstName === "TEAMLEAD02" ?

                                                        <Col sm="6">
                                                            <Form.Label className='mb-2' column style={{fontWeight : "bolder"}}>
                                                                mode of remittance
                                                            </Form.Label>
                                                            <Row className='mt-2'>
                                                                <Col sm={3}>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label="Check"
                                                                        name="isRadioCheck"
                                                                        style={{fontWeight : "bold"}}
                                                                        value={this.state.isRadioCheck}
                                                                        checked={this.state.isRadioCheck}
                                                                        onChange={this.handleRadioCheck}
                                                                        //value={this.state.isRequired}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col sm={3}>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label="Cash"
                                                                        name="isRadioCash"
                                                                        style={{fontWeight : "bold"}}
                                                                        value={this.state.isRadioCash}
                                                                        checked={this.state.isRadioCash}
                                                                        onChange={this.handleRadioCash}
                                                                        //value={this.state.isRequired}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'>
                                                            </Row>
                                                        </Col>
                                                        :
                                                        
                                                        <Col sm="6">
                                                            <Form.Label className='mb-2' column style={{fontWeight : "bolder"}}>
                                                                mode of remittance
                                                            </Form.Label>
                                                            <Row>
                                                                <Col sm={2}>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label="Cash Card"
                                                                        name="isRadioCashcard"
                                                                        style={{fontWeight : "bold"}}
                                                                        value={this.state.isRadioCashcard}
                                                                        checked={this.state.isRadioCashcard}
                                                                        onChange={ this.handleRadioCashcard }
                                                                    />
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Typeahead
                                                                        ref={this.refCashCard}
                                                                        labelKey='name'
                                                                        id="basic-example"
                                                                        onChange={this.handleChangeDropdown}
                                                                        options={this.state.radioCashCardDDL}
                                                                        style={{border : "solid 2px"}}
                                                                        placeholder="select cash card"
                                                                        disabled={this.state.cashCardField}
                                                                        // selected={[this.state.payCardType]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col sm={2}>
                                                                    <Form.Label className='ml-4' style={{fontWeight: "bold"}}> Account #</Form.Label>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Form.Control
                                                                        ref="accountNumber"
                                                                        name="accountNumber"
                                                                        value={this.state.accountNumber}
                                                                        style={{ textAlign : "right" }}
                                                                        onChange={this.handleChangeAccountNumber}
                                                                        autoComplete="off"
                                                                        disabled={this.state.disablacctno}
                                                                        // onKeyPress={this.IsNumeric.bind(this)}
                                                                        // readOnly
                                                                        // className='mt-1'
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col sm={3}>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label="Check"
                                                                        name="isRadioCheck"
                                                                        style={{fontWeight : "bold"}}
                                                                        value={this.state.isRadioCheck}
                                                                        checked={this.state.isRadioCheck}
                                                                        onChange={this.handleRadioCheck}
                                                                        //value={this.state.isRequired}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col sm={3}>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label="Cash"
                                                                        name="isRadioCash"
                                                                        style={{fontWeight : "bold"}}
                                                                        value={this.state.isRadioCash}
                                                                        checked={this.state.isRadioCash}
                                                                        onChange={this.handleRadioCashAmount}
                                                                        //value={this.state.isRequired}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'></Row>
                                                            <Row className='mt-2'>
                                                            </Row>
                                                        </Col>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={6}
                                                            onChange={this.handleChangedMemeberRemarks}
                                                            autoComplete="off"
                                                            name="memberRemarks"
                                                            value={this.state.memberRemarks}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={2} title="CLIENT" activeKey = {this.state.key} onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.clientTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedClientRemarks}
                                                            autoComplete="off"
                                                            value={this.state.clientRemarks}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={3} activeKey = {this.state.key} title="LRD" onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.lrdTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedLrdRemarks}
                                                            autoComplete="off"
                                                            value={this.state.lrdRemarks}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={4} activeKey = {this.state.key} title="RMT" onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.rmtDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />
                                                <Form.Group className='mt-5' as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedRmtRemarks}
                                                            autoComplete="off"
                                                            value={this.state.rmtRemarks}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={5} activeKey = {this.state.key} title="SERVICES" onSelect = { this.handleTabOnSelect }  >
                                        <Card>
                                            <Card.Body>
                                                <Card.Header>For Clearing Client</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.serviceTabDDL }
                                                    columns = { MemberColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { memberSelectRow }

                                                />

                                                <Form.Group className='mt-5' controlId="formFileMultiple">
                                                    <Row>
                                                        <Col sm={1}>
                                                            <Form.Label  className="mt-2" style={{fontWeight : "bold"}}>Attachment File</Form.Label>
                                                        </Col>
                                                        <Col sm={2}>
                                                            {/*<Form.Control   type="file" multiple /> */}
                                                            <input ref={/* ref=>  */this.fileInput/*  = ref */} name="file" className="mr-auto" type="file" onChange={this.onImageReplyChange}/>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Button variant="info" className="ml-auto noser-button-mr1 noser-button" onClick={this.handleUploadClick}>
                                                                UPLOAD
                                                            </Button>
                                                        </Col>
                                                        <Col SM={5}></Col>
                                                    </Row>
                                                </Form.Group>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.attachedDDL }
                                                    columns = { attachedColumn }
                                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'click',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModifiedMember(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    // rowEvents={ listOfTrainingRowEvents }
                                                    selectRow = { attachedSelectRow }

                                                />

                                                <ButtonToolbar className="mt-1" >
                                                    <Button variant="info" /* className='float-right' */ className="ml-auto noser-button-mr1 noser-button" onClick={this.handleDeleteClick}>
                                                        DELETE
                                                    </Button>
                                                </ButtonToolbar>
                                                <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    Remarks
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            // className='mt-4'
                                                            as="textarea"
                                                            rows={3}
                                                            onChange={this.handleChangedServicesRemarks}
                                                            autoComplete="off"
                                                            value={this.state.servicesRemarks}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={6} title="AR-E" activeKey = {this.state.key} onSelect = { this.handleTabOnSelect }  >
                                        <Card>
                                            <Card.Body>

                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey={7} title="Finance" activeKey = {this.state.key} onSelect = { this.handleTabOnSelect } >
                                        <Card>
                                            <Card.Body>

                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Form>
                        </Card.Body>
                    </Card>
                    <ButtonToolbar className="mt-3 mb-3" sm={12}>
                        <Button variant="primary" className="ml-auto noser-button-mr1 noser-button" disabled = {this.state.disabledaftrsave} onClick={ this.handleClickSave } >
                            SAVE
                        </Button>
                        <Button  href="/searchscreenclearance"  className="noser-button-mr1 noser-button" variant="danger">
                            back
                        </Button>
                    </ButtonToolbar>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

export  default Clearance