import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal, Redirect
} 
from '../../noser-hris-component';
import { GetRequest, PostRequest } from '../../noser.dataaccess'

class PayCardAMT extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo        :   [],
            loadingcaption : "ako yung bagong label",
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientId        :   "",
            clientName      :   "",
            employeeId      :   "",
            employeeName    :   "",

            clientDDL       :   [],
            employeeDDL     :   [],
            
  
            disabledCardOne : false,
            disabledCardTwo : false,
            disabledCardThree : false,
            date            : "",
            data : {},

            

        }
        this.state.assessedBy = this.state.userinfo.fullName
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.setState({isloading:true})
        this.props.onRefModal(this)
    }

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }

    initialize=(row)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',

            headerId    :   row.id,
            createdBy    :   row.createdBy,
            applicationDate : row.applicationDate,
            remarks     : row.rEmarks,
            data        :   row
        })
        if(row.statusId === "1" || row.statusId === "3"){
            this.setState({ 
                disabledCardOne     : true,
                disabledCardTwo    : true,
                disabledCardThree   : true,
               
            })
         }
       
        if(row.statusId === "8" || row.statusId === "9" || row.statusId === "20"){
            this.setState({ 
                disabledCardTwo    : true,
                disabledCardThree   : true,
               
            })
         }
 
         if(row.statusId === "21" || row.statusId === "22"){
            this.setState({
                
                disabledCardOne     : true,
            })
         }
 
         if(row.statusId === "20"){
            this.setState({
              
                disabledCardTwo   : false,
                disabledCardOne     : true,
            })
         }
 
         if(row.statusId === "23"){
            this.setState({
              
                disabledCardThree   : true,
                disabledCardOne     : true,
            })
         }
         console.log("e.id")
     console.log(row.id)
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    
    handleReceiveClick = (statusId) => {
        console.log("this.state.data")
            console.log(this.state.data)
        this.setState({isloading:true})
        const areaParams = {
            "IpAddress"             : "0,0,0,0",
            "ClientId"              :   this.state.userinfo.clientId,
            "UserId"                :   this.state.userinfo.userId,
            "HeaderId"              :   this.state.headerId,
            "StatusId"              :   statusId,
            "RequestDate"           :    moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"             :   this.state.createdBy,
            "Remarks"               :   this.state.remarks
         };
    
        console.log("Submit Params")
        console.log(areaParams)
    // return
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            }
            // else {
            //     this.setState({
            //         isloading   :   false,
            //         alerttype   :   "Error!",
            //         isshow      :   true,
            //         color       :   "danger",
            //         message     :   data.message,
            //         fade        :   true
            //     });
            // }
        })
    
    }
     
    handleViewApplication = (e) => {
        sessionStorage.setItem("_paycardviewdetails", JSON.stringify(this.state.data))
        this.setState({navigate:true})
      }

    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/paycardviewdetails" push={true} />;
        } 
      
        return(
            <Modal
            {...this.props}
            return
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    RECEIVING / TRANSMITTAL / APPROVAL
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="themed-container" fluid={true}>
                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                        <div className={this.state.color}></div> 
                        {this.state.message}
                    </Alert>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                <div className="card-header-tab"></div>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className="w-auto">AMT</legend>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm="6" controlId="formGridEmail">
                                                    <Form.Label style={{fontWeight : "bold"}}>Officer In-Charge</Form.Label>
                                                        <Form.Control 
                                                            type="text" 
                                                            autoComplete="off" 
                                                            /* name="selectedClient" */
                                                            value={this.state.userinfo.fullName}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="6" controlId="formGridPassword">
                                                    <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                                        <Form.Control 
                                                            type="text" 
                                                            autoComplete="off" 
                                                            value={this.state.userinfo.roleName}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            </fieldset>
                                        </Form.Group>
                                    </Form.Row>
                                <Form.Row>
                                <Form.Group as={Col} sm={4} className="mt-4">
                                        <fieldset className="border p-2">
                                            <legend className="w-auto">Acknowledge Approved / Reject PayCard By Admin</legend>
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <DatePicker
                                                        ref='date'
                                                        selected={this.state.date}
                                                        onChange={this.handleChangeDate}
                                                        minDate={this.minDate}
                                                        value={this.props.date}
                                                        dateFormat={"MM/dd/yyyy"}
                                                        className="form-control"
                                                        wrapperClassName="datepicker"
                                                        placeholderText="Select Date" 
                                                        disabled={this.state.disabledCardOne}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="0" controlId="formGridPassword">
                                                    <ButtonToolbar>
                                                    <Button disabled={this.state.disabledCardOne} variant="success" className="noser-button"  onClick={ () => this.handleReceiveClick('20')} >Receive</Button>&nbsp;&nbsp;
                                                    </ButtonToolbar> 
                                                </Form.Group>
                                            </Form.Row>
                                            </Form.Group>
                                        </fieldset>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={8} className="mt-4">
                                        <fieldset className="border p-2">
                                            <legend className="w-auto">Employee To Acknowledge Approved PayCard</legend>
                                            <Form.Group as={Col} controlId="formGridEmail">
                                                {/* <Form.Row> */}
                                                    <Form.Group as={Row}  className="mt-1" controlId="formHorizontalEmail">
                                                        <Col sm={2}>
                                                            <DatePicker
                                                                   ref='date'
                                                                   selected={this.state.date}
                                                                   onChange={this.handleChangeDate}
                                                                   minDate={this.minDate}
                                                                   value={this.props.date}
                                                                   dateFormat={"MM/dd/yyyy"}
                                                                   className="form-control"
                                                                   wrapperClassName="datepicker"
                                                                   placeholderText="Select Date" 
                                                                  disabled={this.state.disabledCardTwo}
                                                            />
                                                        </Col>
                                                        <Col>
                                                        <ButtonToolbar>
                                                        <Button disabled={this.state.disabledCardTwo} variant="success" className="noser-button"  onClick={ () => this.handleReceiveClick('21')} >Receive</Button>&nbsp;&nbsp;
                                                        </ButtonToolbar>
                                                        </Col>
                                                        {/* </Form.Group>
                                                        <Form.Group as={Row}  className="mt-1" controlId="formHorizontalEmail"> */}
                                                        <Col sm={2}>
                                                             <Form.Control 
                                                                type="text" 
                                                                placeholder="Select Remarks" 
                                                                ref={this.textInput} 
                                                                onChange={() => this.handleChange()} 
                                                                autoComplete="off" 
                                                                disabled={this.state.disabledCardTwo}
                                                            />
                                                        </Col>
                                                        <Col>
                                                        <ButtonToolbar>
                                                        <Button disabled={this.state.disabledCardTwo} variant="success" className="noser-button"  onClick={ () => this.handleReceiveClick('22')} >Not Receive</Button>&nbsp;&nbsp;
                                                        </ButtonToolbar>
                                                        </Col>
                                                    </Form.Group>
                                                {/* </Form.Row> */}
                                            </Form.Group>
                                        </fieldset>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} className="mt-5">
                                        <fieldset className="border p-2">
                                            <legend className="w-auto">Transmittal Of Received / Not Received Paycard</legend>
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <DatePicker
                                                         ref='date'
                                                         selected={this.state.date}
                                                         onChange={this.handleChangeDate}
                                                         minDate={this.minDate}
                                                         value={this.props.date}
                                                         dateFormat={"MM/dd/yyyy"}
                                                         className="form-control"
                                                         wrapperClassName="datepicker"
                                                         placeholderText="Select Date" 
                                                        disabled={this.state.disabledCardThree}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="0" controlId="formGridPassword">
                                                    <ButtonToolbar>
                                                    <Button disabled={this.state.disabledCardThree} variant="success" className="noser-button"  onClick={ () => this.handleReceiveClick('23')} >Transmit</Button>&nbsp;&nbsp;
                                                    </ButtonToolbar> 
                                                </Form.Group>
                                            </Form.Row>
                                            </Form.Group>
                                        </fieldset>
                                    </Form.Group>
                                   
                                </Form.Row>
                            </Card.Body>
                        </Form.Group>
                    </Form.Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className="mt-1">
                   
                </div>
                <div className="mt-1">
                    <ButtonToolbar className="mt-2" style={{marginLeft:"-8px"}}>
                    <Button  variant="success" className="noser-button-mr1 ml-auto" onClick={this.handleViewApplication} >Application View Details</Button>&nbsp;&nbsp;
                        <Button variant="danger" className="noser-button"  style={{minWidth:'60px'}} href="/PayCardMonitoring" >Close</Button>
                    </ButtonToolbar>
                </div>
            </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>

        )
    }
}
export  default PayCardAMT
