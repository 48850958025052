import {React,Component} from '../../noser-hris-component';
import Banner from "../Nav/Banner"
class Home extends Component {
    componentDidMount(){
        let userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sessionStorage.setItem("employeeapprovalFilter_"  + userinfo.userId + userinfo.employeeId, null)
    }
    render() {
        return (
            <div>
                <Banner />
                <div className="noser-home">
                {/* <span>PHR Clearance © 2019-2020. All Rights Reserved</span> */}
                </div>
            </div>
        );
    }
}

export default Home;
